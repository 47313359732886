import React, { Component } from 'react';
import UserSession from '../helpers/user-session';
import Common from '../helpers/common';
import Config from '../config';
import RequestHandler from '../helpers/request-handler';
import Confirm from './Confirm';
class ChatComponent extends Component {
    constructor(props) {
        super(props); 
        let selectedChatGroup = 'general';
        this.listenerPrivate = false;
        // no interaction sessions
        this.noListenerInteractionSessions = ['5ffea7d29967920b16040ec0'];
        if(UserSession.getSession().role === 'listener' || UserSession.getSession().role === 'listenerhls'){
            if(this.noListenerInteractionSessions.indexOf(this.props.sessionId) > -1){
                selectedChatGroup = 'moderator';
                this.listenerPrivate = true;
            }
        }
        this.state = {confirm:false,chatList:[],sessionId:this.props.sessionId,currentChat:'',selectedChatGroup:selectedChatGroup,isChatGroupOpen:false,participants:{},connection:false,pvUserName:'',pvUserId:'',pvUserRole:'',
        selectedChatTab:'group'}
        this.lastDate = '';
        this.days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        

    }
    componentDidMount =()=> {
        this.getChat();
        this.listenMessage();
    }
    listenMessage = () =>{
        if(Common.getSession()){
            Common.getSession().on("signal", (event) => {
                if(event.from.id != Common.getSession().connection.connectionId){
                let data = JSON.parse(event.data)
                    this.onSignal(data)
                }
            });
        }else{
            setTimeout(this.listenMessage,1000);
            // console.log('Retry for connection object')
        }
    }
    onSignal = (data) => {
        switch (data.method) {
            case 'clearchat':
                this.setState({chatList:[]})
                this.props.showNotification('Chat history deleted by host',UserSession.getSession().userName);
            break;
            case 'chat':
                let allow = false;
                if(data.group.split('_')[0] === 'Private'){
                    if(data.group.split('_')[1] == UserSession.getSession().userId){
                        allow = true;
                    }
                }else{
                    if(data.group == 'general' || data.group == UserSession.getSession().role ){
                        allow = true;
                    }
                    if(data.group === 'general' && data.role ==='listener'){
                        if(UserSession.getSession().role === 'moderator'){
                            allow = true;
                        }else{
                            allow = false;
                        }
                    }
                    if(data.group == 'Technical Support' && (UserSession.getSession().role === 'moderator' || data.role === 'moderator')){
                        allow = true;
                    }
                    
                    if(data.group == 'Questions and Answers' && (UserSession.getSession().role === 'moderator' || UserSession.getSession().role === 'listener' || UserSession.getSession().role === 'speaker')){
                        allow = true;
                    }
                   
                }
                if(data.group == 'Technical Support Listener' || data.group == 'Technical Support'){
                        allow = false;
                }
                if(allow){
                    data.myChat = false;
                    let chatList = this.state.chatList;
                    let date = new Date(data.utcTime).toLocaleString('en-US').split(',')[0]
                    let time = new Date(data.utcTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                    data.date = date;
                    data.time = this.days[new Date(data.utcTime).getDay()]+' '+time;
                    chatList.push(data);
                    this.props.newChat(data);
                    this.setState({chatList:chatList})
                    setTimeout(() => {
                        document.getElementById('chat-scroll').scrollTop = document.getElementById('chat-scroll').scrollHeight;
                    }, 100);
                }
            break;
        }
    }
    updateParticipants = (participants)=>{
        this.setState({participants:participants})
    }
    
    disablePrivateChat = () =>{
        this.setState({disablePrivateChat : true})
    }
    enablePrivateChat = () =>{
        this.setState({disablePrivateChat : false})
    }
    disablePublicChat = () =>{
        this.onSelectedChatGroupChange('moderator')
        this.setState({disablePublicChat : true})
    }
    enablePublicChat = () =>{
        this.onSelectedChatGroupChange('general')
        this.setState({disablePublicChat : false})
    }
    openChat = () =>{
        setTimeout(() => {
            document.getElementById('chat-scroll').scrollTop = document.getElementById('chat-scroll').scrollHeight;
         }, 100);
    }
    sendChat = () =>{
         if(this.state.currentChat.replace(/\s/g, '')){
             let chatList = this.state.chatList;
             let dateObj = new Date();
             let utcTime = dateObj.toUTCString();
             let date = new Date().toLocaleString('en-US').split(',')[0]
             let time = this.days[new Date().getDay()]+' '+new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
             let chatObject = {method:'chat',group:this.state.selectedChatGroup,name:UserSession.getSession().userName,message:this.state.currentChat,myChat:true,date:date,time:time,role:UserSession.getSession().role,utcTime:utcTime,bot:false};
             if(UserSession.getSession().role === 'moderator'){
                 let botmsg = false;
                 if(this.state.currentChat === '!refreshall324242'){
                    botmsg = true;
                    Common.sendToAll({method:'refreshall'});
                    chatObject = {method:'chat',group:this.state.selectedChatGroup,name:UserSession.getSession().userName,message:'will refresh all users excluding moderator',myChat:true,date:date,time:time,role:UserSession.getSession().role,utcTime:utcTime,bot:true};
                 }
                 if(this.state.currentChat === '!refreshspeakers324242'){
                    botmsg = true;
                    Common.sendToAll({method:'refreshspeakers'});
                    chatObject = {method:'chat',group:this.state.selectedChatGroup,name:UserSession.getSession().userName,message:'will refresh all speakers',myChat:true,date:date,time:time,role:UserSession.getSession().role,utcTime:utcTime,bot:true};
                 }
                 if(this.state.currentChat === '!refreshinter324242'){
                    botmsg = true;
                    Common.sendToAll({method:'refreshinter'});
                    chatObject = {method:'chat',group:this.state.selectedChatGroup,name:UserSession.getSession().userName,message:'will refresh all interpreters',myChat:true,date:date,time:time,role:UserSession.getSession().role,utcTime:utcTime,bot:true};
                 }
                 if(this.state.currentChat === '!refreshlisteners324242'){
                    botmsg = true;
                    Common.sendToAll({method:'refreshlisteners'});
                    chatObject = {method:'chat',group:this.state.selectedChatGroup,name:UserSession.getSession().userName,message:'will refresh all listeners',myChat:true,date:date,time:time,role:UserSession.getSession().role,utcTime:utcTime,bot:true};
                 }
                 if(botmsg){
                    chatList.push(chatObject)
                    this.setState({chatList:chatList,currentChat:''})
                    setTimeout(() => {
                        document.getElementById('chat-scroll').scrollTop = document.getElementById('chat-scroll').scrollHeight;
                     }, 100);
    
                    return;
                 }
                
            }
             chatList.push(chatObject)
             this.setState({chatList:chatList})
             
             if(UserSession.getSession().role !== 'listenerhls'){
                if(this.state.connection){
                    chatObject.group = 'Private_'+this.state.pvUserId;
                    chatObject.name = chatObject.name+'_'+this.state.pvUserName+'-'+this.state.pvUserRole;
                    Common.sendToOne(this.state.connection,chatObject);
                }else{
                    Common.sendToAll(chatObject);
                }
            }
           
            Common.sendToAllPusher(chatObject);
            
             
             //alert(JSON.stringify(chatObject))
             setTimeout(() => {
                document.getElementById('chat-scroll').scrollTop = document.getElementById('chat-scroll').scrollHeight;
             }, 100);
             let data = {};
             data.session_id = this.state.sessionId;
             data.sender_name = UserSession.getSession().userName;
             data.sender_userid = UserSession.getSession().userId;
             data.sender_time = time;
             data.sender_role = UserSession.getSession().role;
             data.message = this.state.currentChat;
             data.sendto_group = this.state.selectedChatGroup;
             data.utc_time = utcTime;
             if(this.state.connection){
                data.sendto_group = 'Private_'+this.state.pvUserId;
                data.sender_name = UserSession.getSession().userName+'_'+this.state.pvUserName+'-'+this.state.pvUserRole;
            }
             this.saveChat(data)
             
         }
         this.setState({currentChat:''})
    }
    saveChat = (data) =>{
        RequestHandler.post(Config.API_URL+'save-chat',data,(response)=>{
            if(response.status == 'success'){

            }
        });
    }
    getChat = () =>{
        RequestHandler.post(Config.API_URL+'get-chats',{session_id:this.state.sessionId},(response)=>{
            if(response.status == 'success'){
                  let respData = response.data;
                  let chatList = [];
                  for(let key in respData){
                    let data = {method:'chat',group:respData[key].sendto_group,name:respData[key].sender_name,message:respData[key].message,myChat:respData[key].sender_userid ==UserSession.getSession().userId ? true:false,date:respData[key].date_time,time:respData[key].sender_time,role:respData[key].sender_role,utcTime:respData[key].utc_time};
                    let allow = false;
                    if(data.group.split('_')[0] === 'Private'){
                        if(data.group.split('_')[1] == UserSession.getSession().userId){
                            allow = true;
                        }
                    }else{
                        if(data.group == 'general' || data.group == UserSession.getSession().role ){
                            allow = true;
                        }
                        if(data.group === 'general' && data.role ==='listener'){
                            if(UserSession.getSession().role === 'moderator'){
                                allow = true;
                            }else{
                                allow = false;
                            }
                        }
                        if(data.group == 'Technical Support Listener' && (UserSession.getSession().role === 'listener' || UserSession.getSession().role === 'moderator')){
                            allow = true;
                        }
                        if(data.group == 'Technical Support' && (UserSession.getSession().role === 'moderator' || data.role === 'moderator')){
                            allow = true;
                        }
                        if(data.group == 'Questions and Answers' && (UserSession.getSession().role === 'moderator' || UserSession.getSession().role === 'listener' || UserSession.getSession().role === 'speaker')){
                            allow = true;
                        }
                    }
                    if(respData[key].sender_userid === UserSession.getSession().userId){
                        allow = true;
                    }
                    if(data.group == 'Technical Support Listener' || data.group == 'Technical Support'){
                        allow = false
                    }
                    if(allow){
                        let date = new Date(data.utcTime).toLocaleString('en-US').split(',')[0]
                        let time = this.days[new Date(data.utcTime).getDay()]+' '+new Date(data.utcTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                        data.date = date;
                        data.time = time;
                        chatList.push(data)
                    }
                      //alert(data[key].sender_name)
                  }
                  this.setState({chatList:chatList})
                  setTimeout(() => {
                    document.getElementById('chat-scroll').scrollTop = document.getElementById('chat-scroll').scrollHeight;
                 }, 100);
            }
        });
    }
    onMessageKeyUp = (e)=>{
        var code = (e.keyCode ? e.keyCode : e.which);
        if (code == 13) {
           this.sendChat();
        }
    }
    onSelectedChatGroupChange = (val)=>{
        this.setState({selectedChatGroup:val,isChatGroupOpen:false,connection:false})
    }
    onSelectedPrivateChatChange = (participant) =>{
        this.setState({connection:participant.connection,isChatGroupOpen:false,pvUserName:participant.userData.userName,pvUserId:participant.userData.userId,pvUserRole:participant.userData.role})
    }
    toggleChatGroups = () =>{
        this.props.blockBodyClick();
        let bol = this.state.isChatGroupOpen;
        bol = !bol;
        this.setState({isChatGroupOpen:bol})
    }
    hideGroup = () =>{
        this.setState({isChatGroupOpen:false})
    }
    onMsgInputChange = (e)=>{
         this.setState({currentChat:e.target.value})
    }
    chatClose = () => {
        this.props.chatClose();
    }
    blockBodyClick = ()=>{
        this.props.blockBodyClick();
    }
    chooseChatTab = (tab) =>{
        this.setState({selectedChatTab:tab})
    }
    clearAllChat = () =>{
        this.setState({confirm:true})
        this.confirmRef.show('Confirm','Are you sure, you want to delete all chat history ?',{})
    }

    onCancel = () =>{
        this.setState({confirm:false})
    }

    onProceed = (data) =>{
        this.setState({confirm:false})
        
        RequestHandler.post(Config.API_URL+'delete-chat-history',{session_id:this.state.sessionId},(response)=>{
            if(response.status == 'success'){
                this.setState({chatList:[]})
                this.props.showNotification('Chat history deleted successfully',UserSession.getSession().userName);
                let chatObject = {method:'clearchat'};
                Common.sendToAll(chatObject);
                Common.sendToAllPusher(chatObject);
            }
        });
    }
    
    render() {
        let general = 'send-chat-container';
        let moderator = 'send-chat-container';
        let interpreter = 'send-chat-container';
        let speaker = 'send-chat-container';
        let qanda   = 'send-chat-container';
        let tsupport    = 'send-chat-container';
        if(UserSession.getSession().role == 'moderator'){
            moderator = 'hide';
        }
        if(UserSession.getSession().role == 'speaker'){
           interpreter = 'hide';
           // speaker = 'hide';
           qanda = 'hide';
        }
        if(UserSession.getSession().role == 'listener'){
          interpreter = 'hide';
          speaker = 'hide';
       }
       if(UserSession.getSession().role == 'interpreter'){
          speaker = 'hide';
          qanda = 'hide';
       }
    
        this.lastDate = ''
        let flag = false;
        return(
          <div>
              <section className={this.state.confirm ? "alert-wrap":"hide"}>
                  <Confirm ref={(confirmRef) => this.confirmRef = confirmRef} cancel={this.onCancel} 
                          proceed={this.onProceed}
                          />
                </section>
    
              <div className="comm-chat-blk">
              <div className="d-flex align-items-center chat-header-blk">
                  <div className="chat-close-button" onClick={this.chatClose}>×</div>
                  <div className="chat-caption">Chat </div>
    
                  <div className={UserSession.getSession().role == 'moderator' ? "chat-clear":"hide"} onClick={this.clearAllChat}> 
                      <img src="/img/chat-delete-icon.svg"/>
                      <div className="custom-menu-tooltip">Clear All Chat</div>
                  </div>
              </div>
    
    
              <div className={UserSession.getSession().role === 'interpreter' ? "comm-chat-wrapper interpreter-chatwrap":"comm-chat-wrapper"} id="chat-scroll">
                  {
                      this.state.chatList.map((chat,index)=>{
                          chat.hideDate = true;
                          if(chat.date == this.lastDate){
                              chat.hideDate = true;
                          }else{
                              this.lastDate = true;//chat.date;
                          }
                          
                      })
                  }
    
                  {
                      this.state.chatList.map((chat,index)=>{
                          let nameCondition = chat.myChat ? ('Me to '+(chat.group == 'general'?'general':(chat.group.split('_')[0] === 'Private' ? chat.name.split('_')[1]: chat.group))):'<span className="chat-classified-name">'+chat.name.split('_')[0]+'-'+chat.role+'</span> to '+(chat.group.split('_')[0] === 'Private' ? 'You': chat.group);
                          if(chat.bot){
                            nameCondition = 'Rafiky Bot';
                          }
                          let chatGroupClass = 'bg-5';
                          if(nameCondition.indexOf('speaker') > -1){
                            chatGroupClass = 'bg-1';
                          }
                          if(nameCondition.indexOf('interpreter') > -1){
                            chatGroupClass = 'bg-2';
                          }
                          if(nameCondition.indexOf('moderator') > -1){
                            chatGroupClass = 'bg-3';
                          }
                          if(nameCondition.indexOf('Questions') > -1){
                            chatGroupClass = 'bg-4';
                          }
                          return(
                              <div key={index} className={chat.myChat ? "d-flex flex-column comm-chat-container right":"d-flex flex-column comm-chat-container left"}>				
                                  <div className="d-flex">
                                          <div className={chat.bot ? 'msg-pointer right bot '+chatGroupClass:(chat.myChat ?"msg-pointer right me "+chatGroupClass:"msg-pointer incoming left "+chatGroupClass)}>
                                              <div className="d-flex justify-content-between align-items-center chat-classifier-blk">
                                                  <div className="chat-classifier" dangerouslySetInnerHTML={{__html: nameCondition}}></div>
                                                  <div className="chat-time-disp">{chat.time}</div>
                                              </div>
                                              <div className="chat-type-spec">{chat.group.split('_')[0] === 'Private' ?'Private':''}</div>
                                              <p className="msg-blk" dangerouslySetInnerHTML={{ __html: chat.message}} ></p>
                                          </div>
                                  </div>
                              </div>
                          )
                      })
                  }
              </div>
              <div className="comm-chat-reply-blk">
                  <div className="comm-chat-input-holder reply-input-focused w-100">
                      <label className="chat-send-label">{'Message to '+(this.state.connection ? this.state.pvUserName+'-'+this.state.pvUserRole+'(Private)':(this.state.selectedChatGroup == 'general' ? 'Everyone':this.state.selectedChatGroup+'(s) Only'))}</label>
                      <input onKeyUp={this.onMessageKeyUp} value={this.state.currentChat} onChange={this.onMsgInputChange} type="text" className="chat-inputt"/>
                  </div>
                  <div className="d-flex align-items-center justify-content-between chat-send-blk">
                      <div className="d-flex align-items-center to-send">To&nbsp;:</div>
                      <div className="chat-send-type-spec" >
                          <div className="chat-custom-choose" onClick={this.toggleChatGroups}>{this.state.connection ? this.state.pvUserName:this.state.selectedChatGroup}</div>
                          <div className={this.state.isChatGroupOpen ? "send-chat-popup":"hide"}>
                              <div className="d-flex align-items-center send-chat-tab-selector" onClick={this.blockBodyClick}>
                                  <div className={this.state.selectedChatTab === 'private' ? "send-chat-tab":"send-chat-tab active"} onClick={this.chooseChatTab.bind(this,'group')}>Group</div>
                                  {!this.state.disablePrivateChat && (<div className={UserSession.getSession().role === 'listener' ? 'hide':( this.state.selectedChatTab === 'private' ? "send-chat-tab active":"send-chat-tab")} onClick={this.chooseChatTab.bind(this,'private')}>Private</div>)}
                              </div>
                              <div className="send-chat-wrapper">
                                  <div className={this.state.selectedChatTab === 'private' ? "hide":"public-chat"}>
                                      {!this.state.disablePublicChat && (<div className={this.listenerPrivate ? "hide": "pointer send-chat-container "+general} onClick={this.onSelectedChatGroupChange.bind(this,'general')}>General</div>)}
                                      <div className={"pointer send-chat-container "+moderator} onClick={this.onSelectedChatGroupChange.bind(this,'moderator')}>Moderator</div>
                                      <div className={"pointer send-chat-container "+speaker} onClick={this.onSelectedChatGroupChange.bind(this,'speaker')}>Speaker</div>
                                      <div className={"pointer send-chat-container "+interpreter} onClick={this.onSelectedChatGroupChange.bind(this,'interpreter')}>Interpreter</div>
                                      <div className={this.listenerPrivate ? "hide":"pointer send-chat-container "+qanda} onClick={this.onSelectedChatGroupChange.bind(this,'Questions and Answers')}>Questions and Answers</div>
                                      <div className={"pointer send-chat-container hide "+tsupport} onClick={this.onSelectedChatGroupChange.bind(this,'Technical Support')}>Technical Support</div>
                                  </div>
                                  <div className={this.state.selectedChatTab === 'private' ? "private-chat":"hide"}>
                                      {
                                          Object.keys(this.state.participants).map((key,index)=>{
    
                                              if(this.state.participants[key].userData.role !=  'listener' && this.state.participants[key].userData.userId !== UserSession.getSession().userId && UserSession.getSession().role != 'listener'){
                                                  return(
                                                      <div key={index} className="pointer send-chat-container" title={'send to '+this.state.participants[key].userData.userName} onClick={this.onSelectedPrivateChatChange.bind(this,this.state.participants[key])}>{this.state.participants[key].userData.userName+'-'+this.state.participants[key].userData.role}</div>
                                                  )
                                              }
                                          })
                                      } 
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div onClick={this.sendChat.bind(this)} className="chat-send-btn">Send</div>
                  </div>
              </div>
    
    
    
    
              
          </div>
       </div>
        )
    }
}

export default ChatComponent;
