import React, { Component } from 'react';
import Config from '../config';
import Common from '../helpers/common';
class AudioVideoSettings extends Component {
    constructor(props) {
        super(props); 

        this.state = {role:this.props.role,show:false,cameras:[],microPhones:[],srcObject:null,micIndicaterColor:'#43a047' ,micIndicaterWidth:'0%',
                    selectedCameraName:'',camListOpen:false,selectedMicName:'',micListOpen:false,
                    camEnabled:true,micEnabled:this.props.isSigned ? false:true,isSigned:this.props.isSigned,isConnected:true,camPermission:false,mountedCam:false,
                    spekerPlay:false,permPop:false,devicePop:false}
        this.count = 0;
        this.micActivityValue = 0;
        this.checkedMic = false;
        
    }
    componentDidMount =()=> {
            this.setState({show:true})
            if(!window.initcalled){
                window.initcalled = true;
                this.onInit();
            }
            
            //this.accessDevices()
            if(this.state.role !== 'interpreter'){
                this.props.setCamEnabled(this.state.camEnabled)
            }
            this.props.setMicEnabled(this.state.micEnabled)
            setTimeout(() => {
                this.setState({mountedCam:true})
            }, 3000);
            setTimeout(this.checkMicActivity,10000);
    }
    checkMicActivity = () =>{
        if(this.micActivityValue == 0 && this.state.micEnabled && !this.state.permPop){
            this.setState({devicePop:true})
        }
    }
    onContinue = () =>{
        if (window.stream) {
            window.stream.getTracks().forEach(track => {
              track.stop();
            });
          }
        this.setState({show:false})
        this.props.settingsContinue();
        this.stopSound();
    }
    onInit =()=> {
        navigator.mediaDevices.enumerateDevices().then(this.gotDevices.bind(this)).catch(this.handleError.bind(this));
    }
    controlCam = ()=>{
        let camEnabled = this.state.camEnabled;
        this.setState({camEnabled:!camEnabled})
        if (window.stream) {
            window.stream.getTracks().forEach(track => {
                track.stop();
            });
        }
        this.props.setCamEnabled(!camEnabled)
        this.onInit();

    }
    controlMic = ()=>{
        let micEnabled = this.state.micEnabled;
        this.setState({micEnabled:!micEnabled})
        if (window.stream) {
            window.stream.getTracks().forEach(track => {
                track.stop();
            });
        }
        this.props.setMicEnabled(!micEnabled)
        this.onInit();
    }
    gotDevices =(deviceInfos)=> {
        let microPhones   = [];
        let cameras       = [];
        this.devicenameavailable = false; 
        for (let i = 0; i !== deviceInfos.length; ++i) {
            const deviceInfo   = deviceInfos[i];
            let deviceObj      = {};
            deviceObj.deviceId = deviceInfo.deviceId;
            if (deviceInfo.kind === 'audioinput') {
                if(deviceInfo.label){
                    this.devicenameavailable = true;
                }
                deviceObj.name = deviceInfo.label || `microphone ${this.state.microPhones.length + 1}`;
                if(!this.state.selectedMicName && deviceInfo.label){
                    this.setState({selectedMicName:deviceObj.name})
                }
                microPhones.push(deviceObj);
                if(!Config.getItem('selectedMicrophone')){
                    Config.setItem('selectedMicrophone',deviceInfo.deviceId);
                }
            }else if (deviceInfo.kind === 'videoinput') {
                if(deviceInfo.label){
                    this.devicenameavailable = true;
                }
                deviceObj.name = deviceInfo.label || `camera ${this.state.cameras.length + 1}`;
                if(!this.state.selectedCameraName && deviceInfo.label){
                    this.setState({selectedCameraName:deviceObj.name})
                }
                cameras.push(deviceObj);
                if(!Config.getItem('selectedCamera')){
                    Config.setItem('selectedCamera',deviceInfo.deviceId);
                }
            }
        }

        this.setState({microPhones:microPhones,cameras:cameras});
        this.accessDevices();
        
        
    }
    accessDevices =()=> {
          const constraints = {
            audio: this.state.micEnabled ? {deviceId: Config.getItem('selectedMicrophone') ? {exact: Config.getItem('selectedMicrophone')} : undefined}:false,
            video: this.state.camEnabled ? {deviceId: Config.getItem('selectedCamera') ? {exact: Config.getItem('selectedCamera')} : undefined}:false
          };
          navigator.mediaDevices.getUserMedia(constraints).then(this.gotStream.bind(this)).catch(this.handleError.bind(this));
    }
    gotStream =(stream)=>{
        setTimeout(this.checkMicActivity,3000);
        if (window.stream) {
            window.stream.getTracks().forEach(track => {
              track.stop();
            });
          }
        if(this.devicenameavailable === false){
            this.onInit();
            return;
        }
        this.setState({camPermission:true})
        
        window.stream = stream;
        this.vid.srcObject = stream;
        this.micLevelIndicator(stream).bind(this);
       
    }
    handleError =(error)=> {
        if(error.toString() === 'NotAllowedError: Permission denied' || error.toString() === 'NotAllowedError: The request is not allowed by the user agent or the platform in the current context.'  || error.toString() === 'NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.'){
            //alert('Please allow permission to access both mic and camera')
            this.setState({camPermission:false,permPop:true})
            this.error = true;
        }
        
    }
    onCameraChange=(deviceId,name)=>{
        //alert(event.target.value)
        Config.setItem('selectedCamera',deviceId);
        this.setState({selectedCameraName:name,camListOpen:false});
        this.accessDevices();
    }
    onMicChange =(deviceId,name)=>{
        Config.setItem('selectedMicrophone',deviceId);
        this.setState({selectedMicName:name,micListOpen:false});
        this.accessDevices();
    }
    openCamList = ()=>{
        let camListOpen = this.state.camListOpen;
        this.setState({camListOpen:!camListOpen})
    }
    openMicList = ()=>{
        let micListOpen = this.state.micListOpen;
        this.setState({micListOpen:!micListOpen})
    }
    sessionConnected = () =>{
        this.setState({isConnected:true})
    }
    micLevelIndicator =(stream)=> {
        try{
        if(Common.getBrowser() === 'Safari' || Common.getBrowser() === 'Mobile Safari'){
            var audioContext = new window.webkitAudioContext;
        }else{
            var audioContext = new AudioContext; //or webkitAudioContext
            
        }
          var source = audioContext.createMediaStreamSource(stream);
          var analyser = audioContext.createAnalyser();
          var scriptNode = audioContext.createScriptProcessor(2048, 1, 1);
      
          var volume = audioContext.createGain();
          source.connect(volume);
          volume.gain.value = 0; //turn off the speakers
          volume.connect(audioContext.destination);
      
      
          analyser.smoothingTimeConstant = 0.3;
          analyser.fftSize = 1024;
          analyser.minDecibels = -90;
          analyser.maxDecibels = -10;
      
          source.connect(analyser);
          analyser.connect(scriptNode);
          scriptNode.connect(audioContext.destination);
      
          scriptNode.onaudioprocess = () => {
              if(document.getElementById('a1')){
              var array = new Uint8Array(analyser.frequencyBinCount);
              analyser.getByteFrequencyData(array);
              var values = 0;
      
              var length = array.length;
              for (var i = 0; i < length; i++) {
                  values += array[i];
              }
              var average = (values / length);
              if(this.micActivityValue === 0){
                this.micActivityValue = average;
              }
              
              average = average * 4;
              if (average > 100) {
                  average = 100;
              }
              let micIndicaterWidth = average+'%';
              let micIndicaterColor= '#43a047';
              document.getElementById('a1').style.opacity = '0';
              document.getElementById('a2').style.opacity = '0';
              document.getElementById('a3').style.opacity = '0';
              if (average > 0 && average < 30) {
                //this.state.micIndicaterColor = '#ffa726';
                document.getElementById('a1').style.opacity = '1';
            }
              if (average >= 30 && average <= 70) {
                  //this.state.micIndicaterColor = '#ffa726';
                  document.getElementById('a1').style.opacity = '1';
                  document.getElementById('a2').style.opacity = '1';
              }
              if (average > 70) {
                document.getElementById('a1').style.opacity = '1';
                document.getElementById('a2').style.opacity = '1';
                document.getElementById('a3').style.opacity = '1';
                //this.state.micIndicaterColor = '#e53935';
              }
              this.setState({micIndicaterWidth:micIndicaterWidth,micIndicaterColor:micIndicaterColor});
            }
          }
      
        }catch(e){
      
        }
      
      }
      playSound = ()=> {
            this.setState({spekerPlay:true})
            var snd = document.getElementById("audio");
            snd.currentTime = 0;
            snd.addEventListener('ended',  () => {
              this.stopSound();
            }, false);
            snd.play();
        }
        stopSound = () => {
            this.setState({spekerPlay:false})
            var snd = document.getElementById("audio");
            snd.pause();
        }
        onClosePermPop = () =>{
            this.setState({permPop:false})

        }
        onCloseDevicePop = () =>{
            this.setState({devicePop:false})

        }
        
      render() {
        return(
          <section className="vdo-sys-session settings-overl" id='settingsbg'>
      <div className="vdo-sys-check-wrapper">
          <div className="vdo-in-sys">
              <video className="object-cover" poster="/img/placeholder.png" ref={vid => {this.vid = vid}} autoPlay muted srcobject={this.state.srcObject}/>
              <div className="vdo-syschk-icons">
                  <div className="d-flex justify-content-center vdo-in-icon-wrapper">
                      <div onClick={this.controlCam} className={this.state.camEnabled && this.state.camPermission && this.state.role !== 'interpreter'? "d-flex align-items-center justify-content-center vdo-in-iconblk sett-active":this.state.role === 'interpreter'?"d-flex align-items-center justify-content-center vdo-in-iconblk inactive hide":"d-flex align-items-center justify-content-center vdo-in-iconblk"}>
                          <img alt="video" src="/img/video.svg" className="vdo-in-camicon"/>
                      </div>
                      <div onClick={this.controlMic} className={this.state.micEnabled && this.state.camPermission ? "d-flex align-items-center justify-content-center vdo-in-iconblk sett-active":(this.state.isSigned ? "hide": "d-flex align-items-center justify-content-center vdo-in-iconblk")}>
                          <img alt="mic" src="/img/mic.svg" className="vdo-in-micicon"/>
                      </div>
                  </div>
              </div>
          </div>
          <div className="vdo-syschk-basic">
              <div className={this.state.isSigned ? "hide":"formgroup-sys"}>
                  <div className="sys-ckk-label">Audio Check </div>
                  <div className={this.state.micEnabled ? "d-flex align-items-center":"d-flex align-items-center inactive"}>
                      <div className="droplist-setting sys-chk-down w-100">
                      <div className="selected-cam" onClick={this.openMicList}>{this.state.selectedMicName}</div>
                      <div className={this.state.micListOpen ? "vdo-chk-select-open show":"vdo-chk-select-open"}>
                        <ul className="vdo-chk-select-ul">
                          {
                              this.state.microPhones.map(function(mic, index){
                              return( 
                                  <li key={index} className="vdo-chk-select-container" onClick={this.onMicChange.bind(this,mic.deviceId,mic.name)}>{mic.name}</li>
                                  )
                              },this)
                          } 
                          
                          </ul>
                      </div>
                          
                      </div>
                      <div className="audio-chker-blk">
                          <div id="a1" className="first-dot"></div>
                          <div id="a2" className="second-dot"></div>
                          <div id="a3" className="third-dot"></div>
                      </div>
                  </div>
              </div>
              <div className="formgroup-sys">
                  <div className="sys-ckk-label">Video Check </div>
                  <div className={this.state.camEnabled ? "droplist-setting sys-chk-down vdo-chk-select":"droplist-setting sys-chk-down vdo-chk-select inactive"}>
                      <div className="selected-cam" onClick={this.openCamList}>{this.state.selectedCameraName}</div>
                      <div className={this.state.camListOpen ? "vdo-chk-select-open show":"vdo-chk-select-open"}>
                        <ul className="vdo-chk-select-ul">
                          {
                              this.state.cameras.map(function(camera, index){
                              return( 
                                  <li key={index} className="vdo-chk-select-container" onClick={this.onCameraChange.bind(this,camera.deviceId,camera.name)}>{camera.name}</li>
                                  )
                              },this)
                          } 
                          
                          </ul>
                      </div>
                  </div>
              </div>
              <div className="formgroup-sys">
                  <div className="sys-ckk-label height16"><div className='speaker-test-labal'>Test Your Speaker  </div> <div onClick={this.playSound} className={this.state.spekerPlay ? "hide":"speaker-test-img"}><i className="fa fa-play"></i></div>  <div onClick={this.stopSound} className={this.state.spekerPlay ? "speaker-test-img common-bg":"hide"}><i className="fa fa-stop"></i></div></div>
                  
                 
              </div>
              <div className={this.state.camPermission || !this.state.mountedCam ? "hide":"cam-permission"}>
              IMPORTANT: Make sure you have allow permission to access camera and microphone in this browser, if not then please reload and click the allow button on the pop-up window.
              <img alt="allow" className="hide" src="/img/allow.png" />
              </div>
             {/*   <iframe src="/precall-test/"></iframe>
             <a href="https://tokbox.com/developer/tools/precall/" target="_blank"><button type="button" className="vdo-sys-button pointer width100">System Check</button></a>*/}
              <div className="d-flex justify-content-between vdo-sys-button-wrapper">
                  <button type="button" className="vdo-sys-button hide">Cancel</button>
                  <button id="continue-settings" onClick={this.onContinue} type="button" className={this.state.camPermission && this.state.mountedCam && this.state.isConnected ? "vdo-sys-button pointer":"hide"}>Continue</button>
              </div>
          </div>
      </div>
      <div className={this.state.permPop ? "permission-pop" : "hide"}>
        <button className="close-permi" onClick={this.onClosePermPop}>X</button>
        <div className='perm-wrap'>
            <h2>To use this application, kindly provide permission for the camera and microphone.</h2>
            <h4>You can refer to the respective images for Chrome, Firefox, and Safari below. If the issue persists, try restarting your computer.</h4>
            <div className="perm-img-wrap">
                <div className="brow-img">
                    <h4>Chrome</h4>
                   <img src='/img/perm-chrome.png' />
                </div>
                <div className="brow-img">
                    <h4>Firefox</h4>
                    <img src='/img/perm-firefox.png' />
                </div>
                <div className="brow-img">
                    <h4>Safari</h4>
                    <img src='/img/perm-safari.png' />
                </div>
         
            </div>
        </div>
        
      </div>

      <div className={(this.state.devicePop && this.state.micEnabled) ? "permission-pop" : "hide"}>
        <button className="close-permi" onClick={this.onCloseDevicePop}>X</button>
        <div className='perm-wrap'>
            <h2>Your microphone is not working.</h2>
            <h4>There is a problem with your microphone. Verify your driver settings and restart your device </h4>
        </div>
        
      </div>
    </section>
        )
    }
    }

export default AudioVideoSettings;
