import React, { Component } from 'react';
import Common from '../helpers/common';
class ConferenceTime extends Component {
    constructor(props) {
        super(props); 
        this.state = {scheduleDate:false,time:0}
        if(this.timer){
            clearInterval(this.timer)
        }
        this.timer = setInterval(this.onTimeProgress,1000);
    }
    updateTime = (scheduleDate) =>{
        if(scheduleDate){
            this.setState({scheduleDate:scheduleDate})
        }
    }
    onTimeProgress = () =>{
       
        if(this.state.scheduleDate){
            let differenceInSec = parseInt((new Date() - this.state.scheduleDate) / 1000)
            if(differenceInSec > 0 ){
               // this.props.onUpdateTime(differenceInSec);
                this.setState({time:differenceInSec});
            }
        }
    }
    componentDidMount =()=> {
        this.setState({data:this.props.data,connection:this.props.connection});
    }
    
  render() {
      if(this.state.scheduleDate == false){
          return (
          <span></span>
          );
      }
      return(
      <span className={this.state.time > 0 ? '':'disabled'}>{this.state.time > 0 ? this.state.time > (3600 * 24) ? 'Expired':Common.toHHMMSS(this.state.time):'Not Started'}</span>
      )
  }
}

export default ConferenceTime;
