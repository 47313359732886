import React, { Component } from "react"
import { preloadScript } from "opentok-react"
import createSession from "opentok-react/dist/createSession"
import OTPublisher from "opentok-react/dist/OTPublisher"
import OTSubscriber from "opentok-react/dist/OTSubscriber"
import AudioVideoSettings from "./AudioVideoSettings"
import Config from "../config"
import Common from "../helpers/common"
import UserSession from "../helpers/user-session"
import Toast from "./Toast"
import Participant from "./Participant"
import ConferenceTime from "./ConferenceTime"
import { Redirect } from "react-router-dom"
import ChatComponent from "./ChatComponent"
import Alert from "./Alert"
import RequestHandler from "../helpers/request-handler"
import PollComponent from "./PollComponent"
import HlsPlayer from "./HlsPlayer"
import Pusher from "pusher-js"
import MediaUpload from "./MediaUpload"
import DocumentUpload from "./DocumentUpload"
import Confirm from "./Confirm"
import axios from "axios"
import Draggable from "react-draggable"
import TechnicalSupport from "./TechnicalSupport"
import { VideoSDKNoiseSuppressor } from "@videosdk.live/videosdk-media-processor-web";

const styles = {
  radioContainer: {
    display: "absolute",
    top: "50%",
    left: "50%",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    display: "flex",
    gap: "20px",
    display: 'none',
  },
  heading: {
    fontSize: "18px",
    marginBottom: "15px",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  radioOption: {
    color: "white",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
  },
  selectedDisplay: {
    marginTop: "20px",
    padding: "10px",
    backgroundColor: "#f5f5f5",
    borderRadius: "4px",
  },
}

const configs = {
  v1: {
    disableAudioProcessing: true,
    echoCancellation: false,
    autoGainControl: false,
    noiseSuppression: false,
  },
  v2: {
    disableAudioProcessing: true,
    echoCancellation: true,
    autoGainControl: false,
    noiseSuppression: false,
    enableDtx: true,
  },
  'v2+advanced': {
    disableAudioProcessing: true,
    echoCancellation: true,
    autoGainControl: false,
    noiseSuppression: false,
    enableDtx: true,
    audioFilter: {
      type: "advancedNoiseSuppression",
    },
  },
  'v2+custom1': {
    disableAudioProcessing: true,
    echoCancellation: true,
    autoGainControl: false,
    noiseSuppression: false,
    enableDtx: true,
  },
  original: {},
}

let audioContext;
function createAudioContext() {
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  const ac = AudioContext && new AudioContext();
  if (ac?.state !== 'running') {
    ac.resume();
  }
  return ac;
}

function getAudioContext() {
  if (!audioContext) {
    audioContext = createAudioContext();
  }
  window.__actx = audioContext;
  return audioContext;
}


const defaultStreamProcessor = async (stream) => stream;

const processStreamConfig = {
  'v2+custom1': async (stream) => {
    try {
      const audioContext = getAudioContext();
      const streamNew = audioContext.createMediaStreamSource(stream);
      const noiseProcessor = new VideoSDKNoiseSuppressor();
      const resStream = await noiseProcessor.getNoiseSuppressedAudioStream(streamNew.mediaStream);
      return resStream;
    } catch (error) {
      console.log('error', error);
      return stream;
    }
  }
}

const getStreamProcessor = (configName) => {
  return processStreamConfig[configName] ?? defaultStreamProcessor
}

class App extends Component {
  constructor(props) {
    super(props)
    this.loadTest = false
    this.noMicIndicator = false;
    this.dev = false
    this.speakerMode = false
    let video = true
    let audio = true
    let settingsOpen = false
    this.isSigned = false
    this.role = this.props.role
    this.userId = this.props.userId
    this.moderatorpanel = []
    this.isSpeakerPanel = false
    this.secondaryModerator = false
    this.source = null;
    this.analyser = null;
    this.scriptNode = null;
    if (this.role === "speakerpanel") {
      this.isSpeakerPanel = true
    }
    if (this.role === "moderatorpanel" || this.role === "speakerpanel") {
      this.moderatorpanel.push(1)
      this.role = "moderator"
      audio = false
      video = false
    }
    if (this.role === "moderator-s") {
      this.secondaryModerator = true
      this.role = "moderator"
    }
    if (this.role === "speakerslm") {
      this.isSigned = true
      this.role = "speaker"
    }
    if (this.role == "listener") {
      video = false
      audio = false
    }
    if (this.role == "interpreter") {
      video = false
      audio = true
      settingsOpen = true
    }
    if (this.role == "speaker") {
      settingsOpen = true
    }
    if (this.isSigned) {
      audio = false
      this.speakerMode = true
    }

    this.interpretersLabel = "Interpreting"
    this.pusherChannel = null
    this.initActivityStream = false
    this.selectedCamEnabled = true
    this.selectedMicEnabled = true
    this.subscriberMap = {}
    this.roomProperty = { onAir: true }
    // this.currentDocPlayerUrl = 'https://conference.rafikyconnect.net/uploads/6048404e004fbc4fd311d3a3-77255samplepptx/6048404e004fbc4fd311d3a3-77255samplepptx.html';



    this.state = {
      advancedNoise: true,
      pubPropsKey: this.role == "interpreter" ? "v2+custom1" : "v2+advanced",
      pubProps: this.role == "interpreter" ? configs["v2+custom1"] : configs["v2+advanced"],
      allowLoad: false,
      sessionId: this.props.sessionId,
      language: "",
      languageList: [],
      role: this.role,
      connected: false,
      streams: [],
      translators: [],
      speakers: [],
      audio: audio,
      video: video,
      screen: false,
      videoSource: "camera",
      selectedTranslator: "speaker",
      selectedTranslatorConId: "speaker",
      languageOptionOpen: false,
      deviceInit: false,
      numSubs: 0,
      userListToggle: this.moderatorpanel.length > 0 ? true : false,
      participants: {},
      speakerMode: this.speakerMode,
      raiseHanded: false,
      notification: false,
      speaker: true,
      chatEnabled: false,
      settingsOpen: settingsOpen,
      languageSelectOpen: false,
      alert: false,
      InterpreterMyvolumeSliderValue: 0,
      floorVolume: 100,
      floorLanguage: "Floor",
      floorLangPopOpen: false,
      selectedSpeakerInModerator: "Select Floor",
      selectedSpeakerConIdInModerator: "Select Floor",
      moderatorSpeakerlistOpen: false,
      speakerVolumeInModerator: 100,
      selectedInterpreterInModerator: this.interpretersLabel,
      selectedInterpreterConIdInModerator: "Select Interpreter",
      moderatorInterpreterlistOpen: false,
      InterpreterVolumeInModerator: 0,
      alertMessage: "",
      unReadChat: false,
      moderatorAvailable: false,
      notstartedtxt: "CONNECTING",
      screenLayout: false,
      handoverPop: false,
      handOverTime: 0,
      handOverTimeDisplay: "00:00",
      loadingMessage: "",
      conferenceTime: 0,
      logo: "https://admin.rafikyconnect.net/logo-2-01.png",
      logoText: "",
      volumeLevel: 0,
      fullscrn: false,
      pollOpen: false,
      polls: [],
      pollToggle: false,
      scheduleDate: false,
      mobMenuToggle: false,
      selectedStream: "Floor",
      avalilableLanguages: [],
      floorTitle: "Floor",
      screenAllow: true,
      coverImageUrl: [],
      isStreamingOut: false,
      isRecording: false,
      recordingEnabled: true,
      uploadVideoPop: false,
      uploadDocPop: false,
      requestPartnerVideo: false,
      confirm: false,
      acceptPartnerVideo: false,
      countDownConnection: false,
      speakerCount: "",
      presentationTimer: 0,
      presentationTimerDisplay: "00:00",
      countDownSpeakername: "",
      showInterpreterVideo: false,
      parnterSelfVideoCreated: false,
      captionPopUp: false,
      captionData: {
        title: { value: "", error: "" },
        name: { value: "", error: "" },
        role: { value: "", error: "" },
        duration: { value: 10, error: "" },
      },
      captionLogoFile: null,
      captionlogoerr: false,
      captionLogoCurrentUrl: "",
      videoprogress: 0,
      moreIcon: false,
      speakerInfoChoice: "1",
      editCountDown: false,
      userIdLanguageMap: {},
      userIdPinnedMap: {},
      userIdCaptionMap: {},
      isAudioProcessing: false,
      isPublishProcess: false,
      myCaption: {},
      currentLayout: "grid",
      speedOfMe: true,
      teamAppMobile: false,
      maxUserCnt: 8,
      onAir: false,
      waitingRoomResource: "/img/banner.png",
      speakerUserList: false,
      enableDownloadPpt: false,
      docLayout: false,
      showTechnicalSupport: false,
      apiresponse: false,
      allowMasterSpeaker: false,
      audioStream: null,
      customAudioStream: this.role == "interpreter",
    }
    this.selectedLanguage = false
    this.imageExtensions = ["jpg", "jpeg", "png", "PNG", "JPEG", "JPG"]
    this.captionId = ""
    this.captionUserId = ""
    this.connectionTimeout = 0 //Math.floor(Math.random() * 5);
    this.loadTimeoutStarted = false
    this.selectedStream = ""
    this.allowTimeupdate = false
    this.translators = []
    this.screenStreamId = false
    this.connectedUsers = {}
    this.subscibers = {}
    this.subscriberVolume = {}
    this.blockPopupClose = false
    this.firstTimeSettings = true
    this.timeRef = React.createRef()
    this.interpreterLangMap = {}
    this.picModeElement = false
    this.browserTime = 0
    this.archiveId = 0
    this.currentVideoPlayerUrl = false
    this.currentVideoPlayerTime = 0
    this.currentVideoPlayerState = "paused"
    this.eventId = "0"
    this.langSet = false
    this.currentPptScroll = 0
    this.local = {}
    this.partnerPresent = false
    this.reqVid = false
    this.currentPage = 1
    this.currentStep = 0
    this.totalStep = 0
    this.currentType = "next"
    this.currentCoutDownUser = null
    this.testRequested = false
    this.mySpeedStatus = "white"
    this.captionMap = {}
    this.choosedLanguageInLogin = false
    this.userListUpdated = false
    this.currentBigId = ""
    this.masterSpeaker = false
    this.pinned = false

    this.onLogoClick = () => { }
    this.onSubscribeError = (error) => {
      //setTimeout(this.render.bind(this),2000);
      // alert(error)
    }
    this.subscriberEventHandlers = {
      connected: (event) => {
        if (event.target.stream.name.split("_")[3] === "screen") {
          if (this.state.screen) {
            this.setState({ screen: false, screenLayout: true })
            this.showNotification("Your screen sharing has ended", "Screen")
            if (this.picModeElement) {
              this.picModeElement.disablePictureInPicture = true
              this.picModeElement = false
            }
          } else {
            this.setState({ screenLayout: true })
          }
          this.closeDocPlayerFromCall()
          this.closeVideoPlayerFromCall()
          this.layoutVideos()
        } else {
          let connectionId = event.target.stream.connection.id
          this.subscibers[connectionId] = event.target
          let language
          if (event.target.stream.name.split("_")[0] === "interpreter") {
            if (
              this.state.userIdLanguageMap.hasOwnProperty(
                event.target.stream.name.split("_")[1],
              )
            ) {
              language =
                this.state.userIdLanguageMap[
                event.target.stream.name.split("_")[1]
                ]
            }
          } else {
            language = event.target.stream.name.split("_")[1]
          }
          if (
            this.subscriberVolume[connectionId] &&
            this.subscriberVolume[connectionId] < 100
          ) {
            event.target.setAudioVolume(
              parseInt(this.subscriberVolume[connectionId]),
            )
          }

          if (
            (event.target.stream.name.split("_")[0] == "speaker" ||
              event.target.stream.name.split("_")[0] == "moderator") &&
            this.state.role != "interpreter"
          ) {
            event.target.setAudioVolume(this.state.speakerVolumeInModerator)
          }
          if (
            event.target.stream.name.split("_")[0] === "interpreter" &&
            this.state.role != "interpreter"
          ) {
            event.target.setAudioVolume(
              parseInt(this.state.InterpreterVolumeInModerator),
            )
          }

          if (this.state.role == "interpreter") {
            if (this.state.floorLanguage === "Floor") {
              if (event.target.stream.name.split("_")[0] !== "interpreter") {
                event.target.setAudioVolume(this.state.floorVolume)
              }
            }
            if (this.state.floorLanguage !== "Floor") {
              if (language == this.state.floorLanguage) {
                event.target.setAudioVolume(this.state.floorVolume)
              } else {
                event.target.setAudioVolume(0)
              }
            }

            if (
              language == this.state.language &&
              this.state.floorLanguage != this.state.language
            ) {
              if (this.state.video) {
                event.target.setAudioVolume(100)
              } else {
                event.target.setAudioVolume(
                  this.state.InterpreterMyvolumeSliderValue,
                )
              }
            }
          }
        }

        if (
          this.isSigned === true &&
          event.target.stream.name.split("_")[3] === "signed" &&
          this.browserTime > 10
        ) {
          this.showAlert("Message", "New Sign Language Interpreter has joined.")
          /*

                setTimeout(() => {
                    let newUrl = window.location.href.replace('speakerslm','speaker');;
                    window.location.href =  newUrl;
                }, 7000);
                */
        }
      },
      videoElementCreated: (event) => { },
    }

    UserSession.setSession({
      userName: this.props.userName,
      userId: this.props.userId,
      role: this.role,
      language: this.props.language,
    })
    setInterval(this.commonTimer, 1000)
    if (this.role === "speaker") {
      this.getMyCaption(this.props.userId)
    }

    this.getRoomProperty()
    this.getAllPolls()
  }
  customizeChat = (data) => {
    try {
      this.setState({ disableChat: data.disableChat })
      if (this.role !== "moderator" && !this.state.allowMasterSpeaker) {
        if (this.chatRef) {
          if (data.disablePublicCL && this.role === "listener") {
            this.chatRef.disablePublicChat()
          }
          if (data.disablePublicCS && this.role === "speaker") {
            this.chatRef.disablePublicChat()
          }
          if (data.disablePrivateCL && this.role === "listener") {
            this.chatRef.disablePrivateChat()
          }
          if (data.disablePrivateCS && this.role === "speaker") {
            this.chatRef.disablePrivateChat()
          }
        }
      }
    } catch (e) { }
  }
  enableAllChat = () => {
    this.setState({ disableChat: false })
    if (this.chatRef) {
      this.chatRef.enablePublicChat()
      this.chatRef.enablePrivateChat()
    }
  }
  getSessionDetails = () => {
    RequestHandler.post(
      Config.API_URL + "get-session-details",
      {
        session_id: this.state.sessionId,
        role:
          this.state.role === "listenerhls"
            ? "listener"
            : this.state.role === "speakerslm"
              ? "speaker"
              : this.state.role,
        userId: UserSession.getSession().userId,
      },
      (response) => {
        setTimeout(() => {
          this.setState({ apiresponse: true })
        }, 500)
        if (response.status == "success") {
          // response.data.conference_page_bg = '/img/banner.png';
          this.getSessionResponse = response.data
          this.customizeChat(this.getSessionResponse)
          if (response.data.conference_page_bg) {
            this.conferenceBg = response.data.conference_page_bg
            this.setConferenceBg()
          }
          if (response.data.enableMasterSpeaker) {
            this.masterSpeaker = response.data.enableMasterSpeaker
          }

          if (response.data.enableDownloadPpt) {
            this.setState({ enableDownloadPpt: true })
          }
          if (response.data.lobby_resource_url) {
            // alert(response.data.lobby_resource_url)
            this.setState({
              waitingRoomResource: response.data.lobby_resource_url,
            })
            /*
                if(document.getElementById('waitingRoomVideo')){
                    if(this.role === 'listener'){
                        document.getElementById('waitingRoomVideo').srcObject = response.data.lobby_resource_url; 
                        document.getElementById('waitingRoomVideo').play();
                    }
                }
                */
          }
          if (response.data.event_logo_image) {
            let logoText = ""
            if (
              response.data.event_logo_image.indexOf("logo.3r4r5t.png") > -1
            ) {
              logoText = ""
            }
            let scheduleDate = false
            if (
              response.data.session_date &&
              response.data.session_start_date
            ) {
              // app ready
              //response.data.session_date = '2020-10-16T08:24:00.000Z';
              //let dateTimeString = response.data.session_date.split('-')[2]+' '+response.data.session_date.split('-')[0]+' '+response.data.session_date.split('-')[1]+' '+response.data.event_start_date+' UTC';
              scheduleDate = new Date(response.data.session_date)

              //alert('utc : '+response.data.session_date+' mytime : '+scheduleDate)

              //let differenceInSec = parseInt((new Date() - scheduleData) / 1000)
              //alert(Common.toHHMMSS(differenceInSec)); //
            }
            this.setState({
              logo: response.data.event_logo_image,
              logoText: logoText,
              scheduleDate: scheduleDate,
            })
            localStorage.setItem("logo", response.data.event_logo_image)
          }
          Config.TOKEN_SUBSCRIBER =
            this.state.role == "listener" ? response.data.listener_token : ""
          Config.TOKEN_PUBLISHER =
            this.state.role != "listener" ? response.data.publisher_token : ""
          Config.SESSION_ID = response.data.session_id
          this.listenPusherEvent()
          if (response.data.interpreter_to_language) {
            let language = [response.data.interpreter_to_language]
            // // language.push('hindi');
            if (response.data.interpreter_two_way_allowed) {
              language.push(response.data.interpreter_from_language)
            }
            this.setState({
              languageList: language,
              language: response.data.interpreter_to_language,
            })
          }
          if (this.state.role == "listener") {
            if (response.data.publisher_token) {
              Config.TOKEN_PUBLISHER = response.data.publisher_token
            }
          }
          if (response.data.event_id) {
            this.eventId = response.data.event_id
          }
          if (response.data.interpreting_languages) {
            this.setState({
              avalilableLanguages: response.data.interpreting_languages,
            })
          }
          if (response.data.session_floor_title) {
            this.setState({ floorTitle: response.data.session_floor_title })
          }
          // response.data.recording_enabled = true;
          if (response.data.hasOwnProperty("recording_enabled")) {
            this.setState({ recordingEnabled: response.data.recording_enabled })
          }

          //response.data.event_cover_image = ['/img/sponser.jpg'];
          if (response.data.event_cover_image) {
            this.setState({ coverImageUrl: response.data.event_cover_image })
          }
          if (response.data.streaming_out) {
            this.setState({ isStreamingOut: true })
          }
          if (response.data.speakerUserList) {
            this.setState({ speakerUserList: true })
          }

          if (this.state.role !== "listenerhls") {
            if (
              this.state.role === "listener" ||
              this.moderatorpanel.length > 0
            ) {
              ///setTimeout(this.connectingToSessionBefore,this.connectionTimeout * 1000)
              if (this.moderatorpanel.length > 0) {
                this.connectingToSession()
              } else {
                this.connectingToSessionBefore()
                this.loadTimeoutStarted = true
              }
            } else {
              if (this.state.role === "listener") {
                setTimeout(this.connectingToSession, 2000)
                this.setState({ confirmAudioPlay: false })
                setTimeout(this.layoutVideos, 5000)
              } else {
                //this.connectingToSession();
              }
            }
          } else {
            this.connectingToSessionBefore()
          }
          // response.data.show_interpreter_video = true;
          if (response.data.show_interpreter_video) {
            this.setState({
              showInterpreterVideo: response.data.show_interpreter_video,
            })
          }
          // response.data.selected_language = 'English';
          if (response.data.selected_language) {
            if (response.data.selected_language !== "Floor") {
              this.choosedLanguageInLogin = response.data.selected_language
              if (
                this.state.role !== "listener" &&
                this.moderatorpanel.length === 0
              ) {
                this.onChangeSelectedInterpreterInModerator(
                  response.data.selected_language,
                  1,
                )
              } else {
                if (
                  this.state.role === "listener" &&
                  this.choosedLanguageInLogin &&
                  this.moderatorpanel.length === 0
                ) {
                  this.onChangeSelectedInterpreterWithoutChecking(
                    this.choosedLanguageInLogin,
                    1,
                  )
                }
              }
            }
          }

          setTimeout(() => {
            this.pingServer()
            this.setState({
              notstartedtxt:
                this.state.role == "listener" ||
                  this.state.role == "interpreter" ||
                  this.state.role == "speaker"
                  ? "Thank you for your patience, the host will present soon"
                  : "No one else is presenting at the moment",
            })
          }, 3000)
        } else {
          this.setState({ loadingMessage: response.data + "!" })
          if (document.getElementById("image-loader")) {
            document.getElementById("image-loader").style.display = "none"
          }
        }
      },
    )
  }
  getRoomProperty = () => {
    RequestHandler.post(
      Config.API_URL + "get-room-property",
      { session_id: this.state.sessionId },
      (response) => {
        if (response.status == "success") {
          if (response.data.property) {
            this.roomProperty = response.data.property
            if (this.roomProperty.onAir) {
              this.setState({ onAir: true })
            }
          }
        }
        this.getSessionDetails()
      },
    )
  }
  startMySpeedTest = () => {
    this.testRequested = true
    this.startSpeedOfMeTest()
    this.setState({ mobMenuToggle: false })
  }
  showTechnicalSupport = () => {
    this.setState({ showTechnicalSupport: true })
  }
  cloeSupportPop = () => {
    this.setState({ showTechnicalSupport: false })
  }

  initSpeedOfMe = () => {
    if (this.state.speedOfMe && window.SomApi) {
      window.SomApi.account = "SOM603c70b8306f6" //your API Key here
      window.SomApi.domainName = "conference.rafikyconnect.net" //your domain or sub-domain here
      window.SomApi.onTestCompleted = this.onTestCompleted
      window.SomApi.onError = this.onError
      window.SomApi.onProgress = this.onProgress
    }
  }
  // updated live
  startSpeedOfMeTest = () => {
    //set config values
    if (this.state.speedOfMe && window.SomApi) {
      window.SomApi.config.sustainTime = 4
      window.SomApi.config.testServerEnabled = true
      window.SomApi.config.userInfoEnabled = true
      window.SomApi.config.latencyTestEnabled = true
      window.SomApi.config.uploadTestEnabled = true
      window.SomApi.config.progress.enabled = true
      window.SomApi.config.progress.verbose = true
      window.SomApi.startTest()
    }
  }

  onTestCompleted = (testResult) => {
    if (testResult.download) {
      Config.MY_NETWORK_STATUS = JSON.stringify({
        status: "Completed",
        data: testResult,
      })
    }

    if (this.testRequested) {
      this.showAlert(
        "NETWORK INFO",
        this.generateTestHtml({ status: "Completed", data: testResult }),
      )
    }
    this.testRequested = false
    if (parseInt(testResult.download) >= 50) {
      this.mySpeedStatus = "yellow"
    }
    if (parseInt(testResult.download) >= 100) {
      this.mySpeedStatus = "green"
    }
    if (parseInt(testResult.download) < 50) {
      this.mySpeedStatus = "red"
    }
    //console.log('My test status : '+this.mySpeedStatus)
    this.syncMyUserStatus()
  }
  generateTestHtml = (result) => {
    let testResult = result.data
    if (result.status === "Completed") {
      return (
        "<h4>" +
        (parseInt(testResult.download) > 100
          ? '<h2 className="green">VERY GOOD INTERNET SPEED<h2/>'
          : parseInt(testResult.download) > 50
            ? '<h2 className="yellow">GOOD INTERNET SPEED<h2/>'
            : '<h2 className="red">POOR INTERNET SPEED<h2/>') +
        "Download: " +
        testResult.download +
        " Mbps <br/>" +
        "Upload: " +
        testResult.upload +
        " Mbps <br/>" +
        "Latency: " +
        testResult.latency +
        " ms <br/>" +
        "Jitter: " +
        testResult.jitter +
        " ms <br/>" +
        "Test Server: " +
        testResult.testServer +
        "<br/>" +
        "IP: " +
        testResult.ip_address +
        "<br/>" +
        "Hostname: " +
        testResult.hostname +
        "<br/>" +
        "</h4>"
      )
    } else if (result.status === "Progress") {
      return (
        "<h4>" +
        "Speed testing on progress.....<br/>" +
        "Percent Done: " +
        testResult.percentDone +
        "% <br/>" +
        "Current Speed: " +
        testResult.currentSpeed +
        " Mbps <br/>" +
        "</h4>"
      )
    } else {
      return "<h4> Unable to calculate speed at this time</h4>"
    }
  }

  onError = (error) => {
    // Config.MY_NETWORK_STATUS = JSON.stringify({status:'Error',data:error});
    if (this.testRequested) {
      this.showAlert(
        "NETWORK INFO",
        this.generateTestHtml({ status: "Error", data: error }),
      )
    }
    this.testRequested = false
  }

  onProgress = (progress) => {
    if (progress) {
      Config.MY_NETWORK_STATUS = JSON.stringify({
        status: "Progress",
        data: progress,
      })
    }

    if (this.testRequested) {
      this.showAlert(
        "NETWORK INFO",
        this.generateTestHtml({ status: "Progress", data: progress }),
      )
    }
    // alert(Config.MY_NETWORK_STATUS)
  }
  connectingToSessionBefore = () => {
    this.confirmRef.show(
      "Message",
      "IMPORTANT: Make sure your computer audio is working.If not please check your speaker settings and join again.<br/><br/>",
    )
    this.setState({ confirmAudioPlay: true })
    //this.connectingToSession();
  }
  onCancelAudioPlay = () => {
    this.connectingToSession()
    this.setState({ confirmAudioPlay: false })
    this.layoutVideos()
  }

  onProceedAudioPlay = (data) => {
    if (this.role === "listenerhls") {
      this.setState({ onAir: true, confirmAudioPlay: false, connected: true })
    } else {
      this.connectingToSession()
      this.setState({ confirmAudioPlay: false })
      setTimeout(this.layoutVideos, 5000)
    }
    setTimeout(() => {
      if (this.timeRef) {
        if (this.timeRef.updateTime) {
          this.timeRef.updateTime(this.state.scheduleDate)
        }
      }
      if (this.timeRefMob) {
        if (this.timeRefMob.updateTime) {
          this.timeRefMob.updateTime(this.state.scheduleDate)
        }
      }
    }, 5000)
  }
  onChangeCaptionChoice = (key, event) => {
    this.setState({ speakerInfoChoice: key })
  }
  onChangeCaptionInput = (key, event) => {
    let captionData = this.state.captionData
    captionData[key] = { value: event.target.value, error: "" }
    this.setState({ captionData: captionData })
  }
  getAllPolls = () => {
    RequestHandler.post(
      Config.API_URL + "polls/list",
      { session_id: this.state.sessionId },
      (response) => {
        if (response.status == "success") {
          this.setState({ polls: response.data })
        }
      },
    )
  }
  checkInterpreterExist = () => {
    if (this.state.role !== "interpreter") {
      let part = this.state.participants
      let exist = false
      for (let keyid in part) {
        if (
          this.state.selectedInterpreterInModerator ===
          part[keyid].userData.language &&
          part[keyid].userData.audio
        ) {
          exist = true
        }
      }
      if (!exist) {
        this.switchToFloor()
      }
      this.switchBackToInterpreter()
    }
  }
  switchBackToInterpreter = () => {
    if (this.state.role !== "interpreter") {
      let selectedInterpreterExist = false
      let part = this.state.participants

      if (this.selectedLanguage) {
        for (let keyid in part) {
          if (
            this.selectedLanguage === part[keyid].userData.language &&
            part[keyid].userData.audio
          ) {
            selectedInterpreterExist = true
          }
        }
      }
      if (selectedInterpreterExist) {
        if (
          this.state.selectedInterpreterInModerator !== this.selectedLanguage
        ) {
          this.onChangeSelectedInterpreterInModerator(this.selectedLanguage, 1)
        }
      }
    }
  }
  switchToFloor = () => {
    this.setState({
      speakerVolumeInModerator: 100,
      InterpreterVolumeInModerator: 0,
    })
    this.changeVolumeByRole("floor", 100)
    this.changeVolumeByRole("interpreter", 0)
    this.setState({ selectedInterpreterInModerator: this.interpretersLabel })
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.customAudioStream && ["speaker", "moderator"].includes(this.state.role) && !this.state.audioStream) {
      const connected = this.state.connected &&
        (this.state.onAir === true ||
          (this.state.role !== "listener" && this.state.role !== "listenerhls"))
      const prevConnected = prevState.connected &&
        (prevState.onAir === true ||
          (prevState.role !== "listener" && prevState.role !== "listenerhls"))

      if (connected && connected !== prevConnected && this.state.audio) {
        navigator.getUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia
        navigator.getUserMedia(
          {
            audio: {
              deviceId: Config.getItem("selectedMicrophone")
                ? Config.getItem("selectedMicrophone")
                : undefined,
            }, video: false
          },
          async (stream) => {
            stream = await getStreamProcessor(this.state.pubPropsKey)(stream);
            if (this.state.role === "interpreter" && !this.noMicIndicator) {
              this.micLevelIndicator(stream);
            }
            if (this.state.customAudioStream) {
              this.setState({ audioStream: stream })
            }
          },
          (error) => { },
        )
      }
    }
  }
  componentDidMount = () => {
    console.log("####### == BUILD ID october 30 4353 ==###")
    if (this.loadTest) {
      if (this.state.role === "speaker") {
        setTimeout(() => {
          document.getElementById("continue-settings").click()
        }, 4000)
      }
    }
    if (!this.loadTest) {
      this.initSpeedOfMe()
      this.startSpeedOfMeTest()
    }

    //this.connectingToSession();
    window.addEventListener("beforeunload", this.unload)
    this.layout = false
    window.addEventListener("resize", () => {
      if (this.layout) {
        this.layout()
      }
    })
    // alert('os :'+Common.getOS()+' browser : '+Common.getBrowser())
    if (Common.getOS() == "Android") {
      switch (Common.getBrowser()) {
        case "Chrome":
          /*
                if(Common.getBrowserVesion().version < 80){
                    this.showAlert('Outdated Browser','Your browser version is too old. Please update to the latest version.')
                }
                */
          break

        default:
          this.setState({ teamAppMobile: true })
          /*
                this.showAlert('Not Supported','This application support only in Chrome browser for Android devices.')
                setTimeout(() => {
                    window.location.href = '/notsupported';
                }, 5000);
                */
          break
      }
      this.setState({ screenAllow: false })
    } else if (Common.getOS() == "iOS") {
      switch (Common.getBrowser()) {
        case "Mobile Safari":
          /*
                if(Common.getBrowserVesion().version < 13){
                    this.showAlert('Outdated Browser','Your Safari version '+Common.getBrowserVesion().version+' is too old , Please update to latest version.')
                }
                this.setState({screenAllow:false})
                */
          break

        default:
          /*
                this.showAlert('Not Supported',' This application only supports Safari browser for IOS devices.')
                setTimeout(() => {
                    window.location.href = '/notsupported';
                }, 5000);
                */
          this.setState({ teamAppMobile: true })
          break
      }
      this.setState({ screenAllow: false })
    } else {
      switch (Common.getBrowser()) {
        case "Chrome":
          if (Common.getBrowserVesion().version < 80) {
            this.showAlert(
              "Outdated Browser",
              "Your Chrome version " +
              Common.getBrowserVesion().version +
              " is too old , Please update to latest version.",
            )
          }
          break
        case "Firefox":
          if (Common.getBrowserVesion().version < 75) {
            //this.showAlert('Outdated Browser','Your Firefox version '+Common.getBrowserVesion().version+' is too old , Please update to latest version.')
          }
          break
        case "Safari":
          if (Common.getBrowserVesion().version < 13) {
            this.showAlert(
              "Outdated Browser",
              "Your Safari version " +
              Common.getBrowserVesion().version +
              " is too old , Please update to latest version.",
            )
            this.setState({ screenAllow: false })
          }
          break
        case "Edge":
          if (Common.getBrowserVesion().version < 80) {
            this.showAlert(
              "Outdated Browser",
              "Your Edge version " +
              Common.getBrowserVesion().version +
              " is too old , Please update to latest version.",
            )
          }
          this.setState({ screenAllow: false })
          break
        default:
          this.showAlert(
            "Warning",
            "This browser is not recommended for this application.You may face problems with video/audio.",
          )
          setTimeout(() => {
            // window.location.href = '/notsupported';
          }, 5000)

          break
      }
    }
  }
  commonTimer = () => {
    let handOverTime = this.state.handOverTime
    handOverTime--
    if (this.loadTimeoutStarted && this.connectionTimeout > 0) {
      this.connectionTimeout--
    }

    let presentationTimer = this.state.presentationTimer
    if (presentationTimer > 0) {
      presentationTimer--
    }
    //loadingMessage:(this.loadTimeoutStarted ? 'You will joined in '+Common.toHHMMSS(this.connectionTimeout):'')
    this.setState({
      presentationTimer: presentationTimer,
      presentationTimerDisplay: Common.toHHMMSS(presentationTimer),
      handOverTime: handOverTime,
      handOverTimeDisplay: Common.toHHMMSS(handOverTime),
    })
    if (
      presentationTimer === 1 &&
      this.state.audio &&
      this.role === "speaker"
    ) {
      this.showAlert(
        "Message",
        "The time for your presentation is over. Thank you for your speech today!",
      )
    }

    this.browserTime++
    if (this.browserTime % 30 === 0) {
      this.pingServer()
    }

    if (
      this.browserTime % 10 === 0 &&
      this.state.role === "moderator" &&
      this.moderatorpanel.length === 0
    ) {
      if (this.state.videoLayout) {
        this.currentVideoPlayerTime =
          document.getElementById("videoPlayer").currentTime
        //Common.sendToAll({method:'videoPlayer',url:this.currentVideoPlayerUrl,time:document.getElementById('videoPlayer').currentTime,state:this.currentVideoPlayerState})
      }
    }
  }
  pingServer = () => {
    RequestHandler.post(
      Config.API_URL + "save-event-end-time",
      { user_id: UserSession.getSession().userId },
      (response) => {
        if (response.status == "success") {
          //console.log('PING SUCCESS - '+response.message)
        }
      },
    )
  }
  unload = () => {
    this.pingServer()
  }
  bodyClick = () => {
    if (!this.blockPopupClose) {
      this.setState({
        pollToggle: false,
        userListToggle: false,
        moderatorInterpreterlistOpen: false,
        floorLangPopOpen: false,
        languageSelectOpen: false,
        handoverPop: false,
      })
      this.chatRef.hideGroup()
    }
    this.blockPopupClose = false
  }
  blockBodyClick = () => {
    this.blockPopupClose = true
  }
  setVolume = (id, value) => {
    if (this.subscibers[id]) {
      this.subscibers[id].setAudioVolume(parseInt(value))
    }
    this.subscriberVolume[id] = value
  }
  changeVolumeByRole = (role, volume) => {
    // console.log("Changing Volume : role : " + role + " volume : " + volume)
    volume = parseInt(volume)
    for (let key in this.subscibers) {
      if (this.subscibers[key]) {
        // alert('present')
        if (this.subscibers[key].stream) {
          let userRole = this.subscibers[key].stream.name.split("_")[0]
          if (role == "floor") {
            if (userRole === "speaker" || userRole === "moderator") {
              this.subscibers[key].setAudioVolume(volume)
            }
          } else if (role == "interpreter") {
            if (userRole === "interpreter") {
              // alert(1)
              this.subscibers[key].setAudioVolume(volume)
            }
          }
        }
      }
    }
    if (role == "floor") {
      this.onChangeVidioplayerVolume(volume)
    }
  }
  /**
   *
   * Moderator
   */

  onChangeSelectedInterpreterInModerator = (val, connectionId) => {
    if (val !== "Interpreting") {
      RequestHandler.post(
        Config.API_URL + "event/updateSelectedLanguage",
        { user_id: UserSession.getSession().userId, language: val },
        (response) => {
          if (response.status == "success") {
          }
        },
      )
    }
    // alert(val+'//'+connectionId)
    if (window.interchecktimeout) {
      clearTimeout(window.interchecktimeout)
    }
    // alert(this.userListUpdated)
    if (this.userListUpdated) {
      window.interchecktimeout = setTimeout(this.checkInterpreterExist, 3000)
    } else {
      window.interchecktimeout = setTimeout(this.checkInterpreterExist, 7000)
    }
    if (val !== "Interpreting") {
      this.selectedLanguage = val
    }
    this.setState({
      selectedInterpreterInModerator: val,
      selectedInterpreterConIdInModerator: connectionId,
      moderatorInterpreterlistOpen: false,
      speakerVolumeInModerator: 0,
      InterpreterVolumeInModerator: 100,
      selectedStream: val,
    })
    if (this.state.role === "listenerhls") {
      this.playerRef.switchStream(val)
      return
    }

    this.changeVolumeByRole("interpreter", 100)
    this.changeVolumeByRole("floor", 0)
    this.showNotification("Switched to " + val, "Viewer")
    this.layoutVideos()
  }
  onChangeSelectedInterpreterWithoutChecking = (val, connectionId) => {
    this.setState({
      selectedInterpreterInModerator: val,
      selectedInterpreterConIdInModerator: connectionId,
      moderatorInterpreterlistOpen: false,
      speakerVolumeInModerator: 0,
      InterpreterVolumeInModerator: 100,
      selectedStream: val,
    })
    if (this.state.role === "listenerhls") {
      this.playerRef.switchStream(val)
      return
    }

    this.changeVolumeByRole("interpreter", 100)
    this.changeVolumeByRole("floor", 0)
    this.showNotification("Switched to " + val, "Viewer")
    this.layoutVideos()
  }
  onChangeSelectedInterpreterInModeratorBefore = (val, connectionId) => {
    if (val !== "Interpreting") {
      RequestHandler.post(
        Config.API_URL + "event/updateSelectedLanguage",
        { user_id: UserSession.getSession().userId, language: val },
        (response) => { },
      )
    }
    //alert(val+'//'+connectionId)
    this.setState({
      selectedInterpreterInModerator: val,
      selectedInterpreterConIdInModerator: connectionId,
      moderatorInterpreterlistOpen: false,
      speakerVolumeInModerator: 0,
      InterpreterVolumeInModerator: 100,
      selectedStream: val,
    })
    if (this.state.role === "listenerhls") {
      this.playerRef.switchStream(val)
      return
    } else {
      this.changeVolumeByRole("interpreter", 100)
      this.changeVolumeByRole("floor", 0)
      this.showNotification(
        "The selected interpreter is not available at the moment",
        "Viewer",
      )
      if (window.interchecktimeout) {
        clearTimeout(window.interchecktimeout)
      }
      window.interchecktimeout = setTimeout(this.checkInterpreterExist, 3000)
    }
  }

  onInterpreterVolumeChangeInModerator = (event) => {
    this.setState({
      speakerVolumeInModerator: 100 - event.target.value,
      InterpreterVolumeInModerator: event.target.value,
    })
    this.changeVolumeByRole("floor", 100 - event.target.value)
    this.changeVolumeByRole("interpreter", event.target.value)
  }
  onSpeakerVolumeChangeInModerator = (event) => {
    this.setState({
      speakerVolumeInModerator: event.target.value,
      InterpreterVolumeInModerator: 100 - event.target.value,
    })
    this.changeVolumeByRole("floor", event.target.value)
    this.changeVolumeByRole("interpreter", 100 - event.target.value)
  }
  onChangeVidioplayerVolume = (volume) => {
    var vid = document.getElementById("videoPlayer")
    if (vid) {
      vid.volume = volume / 100
    }
  }
  onToggleInterpreterListInModerator = () => {
    this.blockBodyClick()
    let bol = this.state.moderatorInterpreterlistOpen
    bol = !bol
    this.setState({ moderatorInterpreterlistOpen: bol })
  }
  checkParticipantExist = (participant) => {
    if (this.loadTest) {
      return false
    }

    let keyArray = Object.keys(this.state.participants)
    for (let i = 0; i < keyArray.length; i++) {
      if (
        this.state.participants[keyArray[i]].userData.userId ==
        participant.userId
      ) {
        return keyArray[i]
      }
    }
    return false
  }

  /**
   * Interpreter
   */

  onToggleSelectLang = () => {
    this.blockBodyClick()
    let bol = this.state.languageSelectOpen
    bol = !bol
    this.setState({ languageSelectOpen: bol })
  }
  onToggleFloorLanguageOption = () => {
    this.blockBodyClick()
    let bol = this.state.floorLangPopOpen
    bol = !bol
    this.setState({ floorLangPopOpen: bol })
  }
  onChangeFloorLanguage = (val) => {
    this.setState({ floorLanguage: val, floorLangPopOpen: false })
    this.layoutVideos()
    if (val == "Floor") {
      this.changeVolumeByRole("floor", this.state.floorVolume)
      this.changeVolumeByRole("interpreter", 0)
    } else {
      this.changeVolumeByRole("floor", 0)
      this.changeVolumeByRole("interpreter", this.state.floorVolume)
    }
    if (val == "Floor") {
      this.changePartnerVolume(this.state.InterpreterMyvolumeSliderValue)
    }
  }
  onChangeSelectedLanguage = (val) => {
    if (this.state.language !== val) {
      this.setState({ language: val, languageSelectOpen: false })
      /*
        if(this.state.audio){
            this.setState({audio:false})
            setTimeout(() => {
                this.setState({audio:true})
                setTimeout(this.syncMyUserStatus,500);
            }, 1000);
        }
*/
      setTimeout(this.syncMyUserStatus, 500)
    }
  }
  onInterpreterMyVolumeChange = (event) => {
    this.setState({ InterpreterMyvolumeSliderValue: event.target.value })
    setTimeout(this.syncMyUserStatus, 500)
    if (this.state.floorLanguage != this.state.language) {
      this.changePartnerVolume(event.target.value)
    }
  }
  changePartnerVolume = (volume) => {
    for (let key in this.subscibers) {
      if (this.subscibers[key]) {
        if (this.subscibers[key].stream) {
          let userRole = this.subscibers[key].stream.name.split("_")[0]
          if (userRole === "interpreter") {
            let language = this.subscibers[key].stream.name.split("_")[1]
            language = this.state.userIdLanguageMap[language]
            if (language == this.state.language) {
              this.subscibers[key].setAudioVolume(volume)
            }
          }
        }
      }
    }
  }
  onFloorVolumeChange = (event) => {
    let flrvol = parseInt(Math.round(event.target.value))
    this.setState({ floorVolume: flrvol })
    if (this.state.floorLanguage === "Floor") {
      this.changeVolumeByRole("floor", flrvol)
    } else {
      this.changeVolumeByRole("interpreter", flrvol)
    }
  }
  onHandOver = (time) => {
    this.showNotification("Handover request sent to Boothmate.", "Interpreter")
    Common.sendToAll({
      method: "handover",
      floorLanguage: this.state.floorLanguage,
      outlanguage: this.state.language,
      userName: UserSession.getSession().userName,
      time: time,
    })
    this.setState({
      handOverTime: time,
      handOverTimeDisplay: Common.toHHMMSS(time),
    })
  }
  showHandover = () => {
    this.blockBodyClick()
    let handoverPop = this.state.handoverPop
    this.setState({ handoverPop: !handoverPop })
  }
  /**
   * Listener
   */
  onChangeTranslationLanguage = (val, connectionId) => {
    this.setState({
      selectedTranslator: val,
      selectedTranslatorConId: connectionId,
      languageOptionOpen: false,
    })
    this.layoutVideos()
  }

  onToggleLanguageOption = () => {
    this.blockBodyClick()
    let bol = this.state.languageOptionOpen
    bol = !bol
    this.setState({ languageOptionOpen: bol })
  }

  componentWillUnmount = () => {
    this.sessionHelper.disconnect()
  }
  settingsContinue = () => {
    if (this.firstTimeSettings) {
      let video = this.state.video
      let audio = this.state.audio
      if (this.role == "speaker" && this.speakerMode == false) {
        video = false
        audio = false
        this.showNotification(
          "Raise your hand to be allowed to present by Moderator",
          "Speaker",
        )
      }
      if (this.role == "interpreter") {
        video = false
        audio = false
      }
      if (this.loadTest) {
        if (this.state.role === "speaker") {
          video = false
          audio = false
        }
      }
      this.setState({
        deviceInit: true,
        settingsOpen: false,
        video: video,
        audio: audio,
      })
      setTimeout(this.connectingToSession, 1000)
    } else {
      this.setState({ deviceInit: true, settingsOpen: false })
    }
    this.firstTimeSettings = false
    setTimeout(this.syncMyUserStatus, 500)
    this.layoutVideos()
  }
  setCamEnabled = (camEnabled) => {
    if (this.state.speakerMode || this.state.role != "speaker") {
      this.state.video = camEnabled
    }
    this.selectedCamEnabled = camEnabled
  }
  setMicEnabled = (micEnabled) => {
    if (this.state.speakerMode || this.state.role != "speaker") {
      this.state.audio = micEnabled
    }
    this.selectedMicEnabled = micEnabled
  }
  checkAnyStreamsDisconnected = (availableStreams) => {
    let existingStreams = this.state.streams
    let deletedStream = false
    let role = ""
    for (let i in existingStreams) {
      if (existingStreams[i]) {
        if (existingStreams[i].name) {
          let params = existingStreams[i].name.split("_")
          role = params[0]
          let isPartner = params[5]
          let screen = false
          if (params[3]) {
            if (params[3].indexOf("screen") > -1) {
              screen = true
            }
          }
          deletedStream = existingStreams[i].id
          for (let j in availableStreams) {
            // console.log('existing = '+existingStreams[i].id+' ///'+'available = '+availableStreams[j].id+'//deelted='+existingStreams[i].deleted+'//ispartner='+isPartner+'//role='+this.state.role+'//screen='+screen)
            if (
              existingStreams[i].id === availableStreams[j].id ||
              existingStreams[i].destroyed ||
              (isPartner === "true" && this.state.role === "interpreter") ||
              screen
            ) {
              deletedStream = false
            }
          }
          if (deletedStream) {
            break
          }
        }
      }
    }
    return deletedStream + "_" + role
    //console.log('Final deleted = '+deletedStream)
  }
  setDeletedStream = (deletedId) => {
    for (let k in this.state.streams) {
      if (this.state.streams[k].id === deletedId) {
        this.state.streams[k].deleted = true
      }
    }
  }

  connectingToSession = () => {
    let token =
      this.state.role === "listener"
        ? Config.TOKEN_SUBSCRIBER
        : Config.TOKEN_PUBLISHER
    let opt = {}
    if (this.state.role === "listener") {
      // opt = {connectionEventsSuppressed: true}
    }
    this.sessionHelper = createSession({
      apiKey: Config.API_KEY,
      sessionId: Config.SESSION_ID,
      token: token,
      options: opt,
      onStreamsUpdated: (strm) => {
        /*
          this.layoutVideos();
          if(window.updt){
              return;
          }
          window.updt = true;
          */
        let streamList = []
        let split
        let screen = false
        for (let key in strm) {
          split = strm[key].name.split("_")
          streamList.push(strm[key])
          if (split[3] == "screen") {
            screen = true
          }
        }
        let screenLayout = true
        if (!screen) {
          screenLayout = false
        }
        let deledata = this.checkAnyStreamsDisconnected(streamList)
        let deletedId = deledata.split("_")[0]
        let rl = deledata.split("_")[1]
        if (deletedId && deletedId !== "false") {
          if (
            document.getElementById("video-" + deletedId) ||
            rl === "interpreter"
          ) {
            if (document.getElementById("video-" + deletedId)) {
              document.getElementById("video-" + deletedId).style.display =
                "none"
              //this.layoutVideos();
              this.onChangeLayout(this.state.currentLayout)
            }
            //this.state.streams = streamList;
            // setTimeout(this.setDeletedStream,4000,deletedId)
            this.state.screenLayout = screenLayout
            return
          } else {
          }
        }
        // falert(3)
        this.setState({ streams: streamList, screenLayout })

        //setTimeout(this.layoutVideos,1000);
        this.layoutVideos()
      },
      onError: (error) => {
        //alert(error)
        this.showAlert(
          "Connection Failed",
          'Connection to server failed, Please try after some time !  <br/><span className="err">ERROE DETAILS : ' +
          error +
          "</span><br/>Please note the above error for future reference, Thank you",
        )
      },
      onConnect: () => {
        if (this.moderatorpanel.length === 0) {
          this.showNotification(
            "Successfully Connected",
            UserSession.getSession().userName,
          )
        }
        if (this.settingRef) {
          //this.settingRef.sessionConnected();
        }
      },
    })

    Common.setSession(this.sessionHelper.session)
    this.sessionHelper.session.on("connectionCreated", (event) => {
      if (
        event.connection.connectionId ==
        this.sessionHelper.session.connection.connectionId
      ) {
        if (this.state.role === "listener") {
          setTimeout(Common.sendToAll, 5000, {
            method: "userJoined",
            userName: UserSession.getSession().userName,
            userId: UserSession.getSession().userId,
            video: false,
            audio: false,
            speakerMode: this.state.speakerMode,
            allowMasterSpeaker: this.state.allowMasterSpeaker,
            raiseHanded: this.state.raiseHanded,
            role: this.state.role,
            speakerMode: this.state.speakerMode,
            speaker: this.state.speaker,
            language: this.state.language,
            isSigned: this.isSigned,
            mySpeedStatus: this.mySpeedStatus,
            caption: this.state.myCaption,
            panelLength: this.moderatorpanel.length,
            secondaryModerator: this.secondaryModerator,
          })
          if (this.loadTest) {
            for (let i = 0; i < 3; i++) {
              console.log("gl", i, this.state.role, this.state.speakerMode)
              Common.sendToAll({
                method: "userJoined",
                userName: UserSession.getSession().userName + i,
                userId: UserSession.getSession().userId + i,
                video: false,
                audio: false,
                speakerMode: this.state.speakerMode,
                raiseHanded: this.state.raiseHanded,
                role: this.state.role,
                speakerMode: this.state.speakerMode,
                speaker: this.state.speaker,
                language: this.state.language,
                isSigned: this.isSigned,
                mySpeedStatus: this.mySpeedStatus,
                caption: this.state.myCaption,
              })
            }
          }
        } else {
          Common.sendToAll({
            method: "userJoined",
            userName: UserSession.getSession().userName,
            userId: UserSession.getSession().userId,
            video: false,
            audio: false,
            speakerMode: this.state.speakerMode,
            allowMasterSpeaker: this.state.allowMasterSpeaker,
            raiseHanded: this.state.raiseHanded,
            role: this.state.role,
            speakerMode: this.state.speakerMode,
            speaker: this.state.speaker,
            language: this.state.language,
            isSigned: this.isSigned,
            mySpeedStatus: this.mySpeedStatus,
            caption: this.state.myCaption,
            panelLength: this.moderatorpanel.length,
            secondaryModerator: this.secondaryModerator,
          })
          // LOADTEST
          if (this.loadTest) {
            for (let i = 0; i < 30; i++) {
              console.log("g", i, this.state.role, this.state.speakerMode)
              Common.sendToAll({
                method: "userJoined",
                userName: UserSession.getSession().userName + i,
                userId: UserSession.getSession().userId + i,
                video: false,
                audio: false,
                speakerMode: this.state.speakerMode,
                raiseHanded: this.state.raiseHanded,
                role: this.state.role,
                speakerMode: this.state.speakerMode,
                speaker: this.state.speaker,
                language: this.state.language,
                isSigned: this.isSigned,
                mySpeedStatus: this.mySpeedStatus,
                caption: this.state.myCaption,
              })
            }
          }
        }
        if (this.state.role === "listener" && this.choosedLanguageInLogin) {
          setTimeout(
            this.onChangeSelectedInterpreterInModerator,
            3000,
            this.choosedLanguageInLogin,
            1,
          )
        }
      } else {
        if (this.state.role === "interpreter") {
          document.getElementById("totalCon").innerHTML =
            parseInt(document.getElementById("totalCon").innerHTML) + 1
        }
      }
      if (this.state.role !== "listener") {
        if (this.timeRef) {
          if (this.timeRef.updateTime) {
            this.timeRef.updateTime(this.state.scheduleDate)
          }
        }
        if (this.timeRefMob) {
          if (this.timeRefMob.updateTime) {
            this.timeRefMob.updateTime(this.state.scheduleDate)
          }
        }
      }
    })

    this.sessionHelper.session.on("connectionDestroyed", (event) => {
      if (this.state.role === "interpreter") {
        if (parseInt(document.getElementById("totalCon").innerHTML) !== 0) {
          document.getElementById("totalCon").innerHTML =
            parseInt(document.getElementById("totalCon").innerHTML) - 1
        }
        // return;
      }
      let participants = this.state.participants
      if (participants[event.connection.connectionId]) {
        if (
          participants[event.connection.connectionId].userData.language ===
          this.state.selectedInterpreterInModerator
        ) {
          /*
                    let partnerPresent = false;
                    for(let key in participants){
                       if(participants[key].userData.language === this.state.selectedInterpreterInModerator && event.connection.connectionId !== key){
                        partnerPresent = true;
                       }
                    }
                    if(!partnerPresent){
                        if(this.state.role !== 'interpreter'){
                            this.switchToFloor()
                        }
                    }
                    */
          if (window.interchecktimeout) {
            clearTimeout(window.interchecktimeout)
          }
          window.interchecktimeout = setTimeout(
            this.checkInterpreterExist,
            3000,
          )
        }
        if (
          participants[event.connection.connectionId].userData.role ===
          "moderator"
        ) {
          this.closeDocPlayerFromCall()
          this.closeVideoPlayerFromCall()
        }
        if (
          this.state.role === "interpreter" &&
          participants[event.connection.connectionId].userData.language ===
          this.state.language &&
          participants[event.connection.connectionId].userData.role ===
          "interpreter"
        ) {
          if (this.state.video) {
            this.setState({
              requestPartnerVideo: false,
              video: false,
              confirm: false,
              acceptPartnerVideo: false,
            })
          }
        }

        delete participants[event.connection.connectionId]
      }
      this.sorterParticipants = this.sortParticpants(participants)
      //alert('1-'+JSON.stringify(this.sorterParticipants))
      this.setState({ participants: this.sorterParticipants })
      if (this.chatRef) {
        this.chatRef.updateParticipants(this.sorterParticipants)
      }
    })
    this.sessionHelper.session.on("sessionReconnecting", (event) => {
      // this.showAlert('Message','Your internet has been disconnected, Trying to reconnect....')
      console.log(
        "TOKBOX MESSAGE : Your internet has been disconnected, Trying to reconnect....",
      )
    })
    this.sessionHelper.session.on("sessionReconnected", (event) => {
      // this.showAlert('Message','You have been successfully reconnected.')
      console.log("TOKBOX MESSAGE : You have been successfully reconnected.")
    })

    this.sessionHelper.session.on("signal", (event) => {
      if (event.from.id != this.sessionHelper.session.connection.connectionId) {
        let data = JSON.parse(event.data)
        this.onSignal(data, event)
      }
      //  Process the event.data property, if there is any data.
    })
    if (this.state.role === "listener") {
      setTimeout(() => {
        this.setState({ connected: true })
        this.setConferenceBg()
      }, 4000)
    } else {
      this.setState({ connected: true })
      this.setConferenceBg()
    }
    if (window.screen.width <= 768) {
      // this.makeFullScreen('root')
    }
  }
  onSignal = (data, event) => {
    switch (data.method) {
      case "refreshall":
        if (UserSession.getSession().role !== "moderator") {
          this.showAlert(
            "Message",
            "There is a important software update, You will be reloaded in a moment.",
          )
          let timeoutval = Math.floor(Math.random() * 10)
          if (timeoutval < 3) {
            timeoutval = 3
          }
          setTimeout(() => {
            window.location.reload()
          }, timeoutval * 1000)
        }
        break
      case "refreshspeakers":
        if (UserSession.getSession().role === "speaker") {
          this.showAlert(
            "Message",
            "There is a important software update, You will be reloaded in a moment.",
          )
          let timeoutval = Math.floor(Math.random() * 10)
          if (timeoutval < 3) {
            timeoutval = 3
          }
          setTimeout(() => {
            window.location.reload()
          }, timeoutval * 1000)
        }
        break
      case "refreshinter":
        if (UserSession.getSession().role === "interpreter") {
          this.showAlert(
            "Message",
            "There is a important software update, You will be reloaded in a moment.",
          )
          let timeoutval = Math.floor(Math.random() * 10)
          if (timeoutval < 3) {
            timeoutval = 3
          }
          setTimeout(() => {
            window.location.reload()
          }, timeoutval * 1000)
        }
        break
      case "refreshlisteners":
        if (UserSession.getSession().role === "listener") {
          this.showAlert(
            "Message",
            "There is a important software update, You will be reloaded in a moment.",
          )
          let timeoutval = Math.floor(Math.random() * 10)
          if (timeoutval < 3) {
            timeoutval = 3
          }
          setTimeout(() => {
            window.location.reload()
          }, timeoutval * 1000)
        }
        break
      case "captiondelete":
        /*

            if(document.getElementById(data.id+'speakerinfo')){
                if(document.getElementById(data.id+'speakerinfo').classList.contains(data.captionId)){
                    document.getElementById(data.id+'speakerinfo').style.display = 'none';
                }
            }
            if(data.id === UserSession.getSession().userId){
              this.getMyCaption(data.id)
            }
            */
        // alert(data.id +'//'+UserSession.getSession().userId)
        if (data.id === UserSession.getSession().userId) {
          this.getMyCaption(UserSession.getSession().userId)
        }

        break
      case "caption":
        if (data.id === UserSession.getSession().userId) {
          this.getMyCaption(UserSession.getSession().userId)
        }
        let speakerInfoChoice = data.speakerInfoChoice
        if (document.getElementById(data.id + "1")) {
          document.getElementById(data.id + "1").style.display = "none"
          document.getElementById(data.id + "2").style.display = "none"
          document.getElementById(data.id + "3").style.display = "none"
          document.getElementById(data.id + "4").style.display = "none"
          document.getElementById(data.id + "5").style.display = "none"
        }

        if (data.status === "enabled") {
          if (document.getElementById(data.id + speakerInfoChoice)) {
            document.getElementById(data.id + speakerInfoChoice).style.display =
              "flex"
            document
              .getElementById(data.id + speakerInfoChoice)
              .classList.add(data.capid)
            document.getElementById(
              data.id + speakerInfoChoice + "name",
            ).innerHTML = data.captionData.name.value
            document.getElementById(
              data.id + speakerInfoChoice + "role",
            ).innerHTML = data.captionData.role.value

            if (data.logo) {
              document.getElementById(
                data.id + speakerInfoChoice + "captionlogo",
              ).src = data.logo
              document.getElementById(
                data.id + speakerInfoChoice + "captionlogowrap",
              ).style.display = "block"
            } else {
              document.getElementById(
                data.id + speakerInfoChoice + "captionlogowrap",
              ).style.display = "none"
            }
            document
              .getElementById(data.id + speakerInfoChoice)
              .classList.add(data.capid)
            if (window.captionTimeout) {
              if (window.captionTimeout[data.id]) {
                clearTimeout(window.captionTimeout[data.id])
              }
            } else {
              window.captionTimeout = {}
            }
            window.captionTimeout[data.id] = setTimeout(
              (capId, speakerInfoChoice) => {
                document.getElementById(
                  capId + speakerInfoChoice,
                ).style.display = "none"
              },
              data.captionData.duration.value * 1000,
              data.id,
              speakerInfoChoice,
            )
          }
        }
        break
      case "videoPlayer":
        if (document.getElementById("videoPlayer").src !== data.url) {
          this.currentVideoPlayerUrl = data.url
          this.onPlayMediaFromCall(this.currentVideoPlayerUrl)
          this.currentVideoPlayerTime = data.time
          this.currentVideoPlayerState = data.state
          document.getElementById("videoPlayer").currentTime = data.time
          if (this.currentVideoPlayerState === "pause") {
            document.getElementById("videoPlayer").pause()
          } else {
            document.getElementById("videoPlayer").play()
          }
        } else {
          if (
            Math.abs(
              document.getElementById("videoPlayer").currentTime - data.time,
            ) > 10
          ) {
            this.currentVideoPlayerTime = data.time
            document.getElementById("videoPlayer").currentTime = data.time
          }
          if (this.currentVideoPlayerState !== data.state) {
            this.currentVideoPlayerState = data.state
            if (this.currentVideoPlayerState === "pause") {
              document.getElementById("videoPlayer").pause()
            } else {
              document.getElementById("videoPlayer").play()
            }
          }
        }
        break
      case "docPlayer":
        this.currentPage = data.currentPage
        this.currentStep = data.currentStep
        this.currentType = data.currentType
        this.totalstep = data.totalstep
        this.onLoadDocumentFromCall(data.url)
        break

      case "requestVideoAccept":
        if (
          this.state.role === "interpreter" &&
          data.language === this.state.language
        ) {
          if (this.state.requestPartnerVideo) {
            this.showNotification(
              "Boothmate has accepted your request to turn on camera",
              "Interpreter",
            )
          }
          this.setState({
            requestPartnerVideo: true,
            video: true,
            confirm: false,
          })
          /*
                let audio = this.state.audio;
                if(audio){
                    this.setState({audio:!audio})
                    setTimeout(this.syncMyUserStatus,500);
                    // this.showAlert('Message','You have requested private communication with your partner.')
                }
                */
        }
        break
      case "requestVideoReject":
        if (
          this.state.role === "interpreter" &&
          data.language === this.state.language
        ) {
          if (this.state.requestPartnerVideo) {
            this.showNotification(
              "Boothmate has rejected your request to turn on camera",
              "Interpreter",
            )
          }
          this.setState({
            requestPartnerVideo: false,
            acceptPartnerVideo: false,
          })
        }
        break
      case "requestVideoStop":
        if (
          this.state.role === "interpreter" &&
          data.language === this.state.language
        ) {
          if (this.state.requestPartnerVideo) {
            this.showNotification(
              "Partner has been stopped video",
              "Interpreter",
            )
          }
          this.setState({
            requestPartnerVideo: false,
            acceptPartnerVideo: false,
          })
        }
        break
      case "requestVideoStopPermission":
        if (
          this.state.role === "interpreter" &&
          data.language === this.state.language
        ) {
          if (this.state.requestPartnerVideo) {
            this.showNotification(
              "Boothmate did not allow the browser to activate camera",
              "Interpreter",
            )
          }
          this.setState({
            requestPartnerVideo: false,
            acceptPartnerVideo: false,
          })
        }
        break

      case "partnerPresent":
        this.partnerPresent = true
        break
      case "requestVideo":
        if (
          this.state.role === "interpreter" &&
          data.language === this.state.language
        ) {
          if (data.requestVideo) {
            this.reqVid = true
            Common.sendToOne(event.from, { method: "partnerPresent" })
            this.confirmRef.show(
              "Confirm",
              "Your partner has been requested to turn on your video.Are you sure do you want to enable video ? (video will be visible only to your partner , You should Allow camera permission in browser for publishing)",
            )
            this.setState({ confirm: true })
          } else {
            this.setState({
              video: false,
              acceptPartnerVideo: false,
              requestPartnerVideo: false,
            })
          }
        }
        break
      case "videoPlayerClose":
        this.closeVideoPlayerFromCall()
        break
      case "docPlayerClose":
        this.closeDocPlayerFromCall()
        break
      case "videoPlayerPause":
        document.getElementById("videoPlayer").pause()
        break
      case "videoPlayerPlay":
        document.getElementById("videoPlayer").play()
        break
      case "videoPlayerSeek":
        document.getElementById("videoPlayer").currentTime = data.time
        break
      case "docScroll":
        document
          .getElementById("docPlayer")
          .contentWindow.document.getElementById("page-container").scrollTop =
          data.value
        break
      case "onNextOrPrevious":
        this.prevOrNext(data.page, data.step, data.totalstep, data.type)
        break

      case "onPollResult":
        if (this.state.role != "interpreter") {
          this.getPollReport(data.data)
          this.showNotification(
            "Moderator has shared poll results.",
            "Moderator",
          )
        }
        break
      case "onPoll":
        if (this.state.role == "moderator") {
          this.getAllPolls()
        }
        break
      case "handRaised":
        if (this.state.role === "moderator") {
          if (data.status) {
            this.showNotification("Hand raised by Speaker.", "Speaker")
            Common.playAudio()
          } else {
            this.showNotification("Hand lowered by Speaker", "Speaker")
          }
        }
        break
      case "pinuser":
        this.pinned = false
        if (data.status) {
          if (data.usrid === UserSession.getSession().userId) {
            this.pinned = data.status
            document.getElementById("publisher-parent").classList.add("pinned")
          } else {
            document.getElementById(data.id).classList.add("pinned")
          }
        } else {
          if (data.usrid === UserSession.getSession().userId) {
            this.pinned = data.status
            document
              .getElementById("publisher-parent")
              .classList.remove("pinned")
          } else {
            document.getElementById(data.id).classList.remove("pinned")
          }
        }
        document.querySelectorAll(".pinned").forEach((div) => {
          if (data.usrid === UserSession.getSession().userId) {
            if (div.id !== "publisher-parent") {
              div.classList.remove("pinned")
            }
          } else {
            if (div.id !== data.id) {
              div.classList.remove("pinned")
            }
          }
        })

        break

      case "userJoined":
        if (data.panelLength > 0) {
          if (this.moderatorpanel.length > 0) {
            /*
                    this.showAlert('Message','This moderator control panel will be exited soon since the panel opened multiple times.')
                    setTimeout(() => {
                        window.location.href =  Config.HOME_PAGE_URL//'/thankyou';
                    }, 5000);
                    return;
                    */
            return
          }
        }
        this.userListUpdated = true
        if (data.role === "interpreter") {
          let userIdLanguageMap = this.state.userIdLanguageMap
          userIdLanguageMap[data.userId] = data.language
          this.setState({ userIdLanguageMap: userIdLanguageMap })
        }
        if (data.role === "speaker") {
          let userIdCaptionMap = this.state.userIdCaptionMap
          userIdCaptionMap[data.userId] = data.caption
          this.setState({ userIdCaptionMap: userIdCaptionMap })
        }
        this.syncMyUserStatusToYou(event.from)
        if (data.role === "listener" && this.state.role !== "moderator") {
          return
        }
        /*
            if(this.state.role !== 'moderator' && data.role !== 'interpreter'){
                return;
            }
            */
        let participants = this.state.participants
        let partConId = this.checkParticipantExist(data)
        if (!partConId) {
          if (this.loadTest) {
            event.from.id = data.userId
          }
          participants[event.from.id] = {
            userData: data,
            connection: event.from,
          }
        } else {
          participants[partConId].userData = data
        }
        this.sorterParticipants = this.sortParticpants(participants)
        //alert('1-'+JSON.stringify(this.sorterParticipants))
        this.setState({ participants: this.sorterParticipants })

        if (
          data.role == "moderator" &&
          this.state.role === "moderator" &&
          this.moderatorpanel.length === 0 &&
          data.panelLength === 0
        ) {
          //if(window.location.href.indexOf('localhost') == -1){
          //this.confirmRef.show('Confirm','Moderator already joined in the session. If you joined in then person who joined before will be exited from the session. Are you sure to join ?')
          if (!data.secondaryModerator && !this.secondaryModerator) {
            this.showAlert(
              "Message",
              "You will be exited from the session soon since another moderator joined the session",
            )
            setTimeout(() => {
              window.location.href = Config.HOME_PAGE_URL //'/thankyou';
            }, 5000)
          }

          //}He
        }

        //if(data.role !== 'listener' || this.state.role === 'interpreter'){

        //}

        if (data.role === "moderator") {
          this.setState({ moderatorAvailable: true })
        }
        if (this.chatRef) {
          this.chatRef.updateParticipants(this.sorterParticipants)
        }

        break
      case "userExist":
        this.userListUpdated = true
        if (data.role === "interpreter") {
          let userIdLanguageMap = this.state.userIdLanguageMap
          userIdLanguageMap[data.userId] = data.language
          this.setState({ userIdLanguageMap: userIdLanguageMap })
          if (window.interchecktimeout) {
            clearTimeout(window.interchecktimeout)
          }
          window.interchecktimeout = setTimeout(
            this.checkInterpreterExist,
            3000,
          )
        }
        if (data.role === "speaker") {
          let userIdCaptionMap = this.state.userIdCaptionMap
          userIdCaptionMap[data.userId] = data.caption
          this.setState({ userIdCaptionMap: userIdCaptionMap })

          let userIdPinnedMap = this.state.userIdPinnedMap
          userIdPinnedMap[data.userId] = data.pinned
          this.setState({ userIdPinnedMap: userIdPinnedMap })
        }
        if (
          data.userId == UserSession.getSession().userId &&
          data.role != "moderator"
        ) {
          if (
            window.location.href.indexOf("localhost") == -1 &&
            !this.dev &&
            this.moderatorpanel.length === 0
          ) {
            this.showAlert(
              "Message",
              "Access denied! This User ID is already being used",
            )
            setTimeout(() => {
              window.location.href = Config.HOME_PAGE_URL //'/thankyou';
            }, 5000)
          }
        }
        if (
          data.role == "moderator" &&
          this.state.role === "moderator" &&
          this.moderatorpanel.length === 0
        ) {
          if (!data.secondaryModerator && !this.secondaryModerator) {
            this.showAlert(
              "Message",
              "Moderator already joined in the session.He will be exited soon since the platorm does not allow multiple moderators in a session.",
            )
          }
        }
        if (this.state.role !== "moderator" && data.role !== "interpreter") {
          //return;
        }
        let part = this.state.participants
        let partConIdExist = this.checkParticipantExist(data)
        if (!partConIdExist) {
          part[event.from.id] = { userData: data, connection: event.from }
        } else {
          part[partConIdExist].userData = data
        }

        /*
            if(data.audio === false){
                if(data.language === this.state.selectedInterpreterInModerator){
                     this.switchToFloor();
                }
            }
            let exist = false;
            for(let keyid in part){
                 if(this.state.selectedInterpreterInModerator === part[keyid].userData.language){
                     exist = true;
                 }
            }
            if(!exist){
                if(this.state.role !== 'interpreter'){
                    this.switchToFloor();
                }
            }
            */
        this.sorterParticipants = this.sortParticpants(part)
        if (this.moderatorpanel.length > 0) {
        }
        this.setState({ participants: this.sorterParticipants })
        if (data.role === "moderator" && !data.secondaryModerator) {
          console.log("LOGGER : MODERATOR DATA LOADED")
          this.setState({ moderatorAvailable: true })
          if (data.currentDocPlayerUrl) {
            //alert(document.getElementById('docPlayer').src+'//'+data.currentDocPlayerUrl)
            if (
              document
                .getElementById("docPlayer")
                .src.indexOf(data.currentDocPlayerUrl) === -1
            ) {
              this.onLoadDocumentFromCall(data.currentDocPlayerUrl)
            }
            this.currentPage = data.currentPage
            this.currentStep = data.currentStep
            this.currentType = data.currentType
            this.totalstep = data.totalstep
          }

          if (data.currentVideoPlayerUrl) {
            if (this.currentVideoPlayerUrl !== data.currentVideoPlayerUrl) {
              this.onPlayMediaFromCall(data.currentVideoPlayerUrl)
            }

            if (data.currentVideoPlayerTime) {
              setTimeout(
                (currTime) => {
                  if (
                    Math.abs(
                      currTime -
                      document.getElementById("videoPlayer").currentTime,
                    ) > 10
                  ) {
                    // alert(Math.abs(currTime - document.getElementById('videoPlayer').currentTime))
                    document.getElementById("videoPlayer").currentTime =
                      currTime
                  }
                },
                1000,
                data.currentVideoPlayerTime,
              )
            }
            if (data.currentVideoPlayerState === "pause") {
              setTimeout(
                (currTime) => {
                  document.getElementById("videoPlayer").pause()
                },
                1000,
                data.currentVideoPlayerTime,
              )
            }
          }
        }
        if (this.chatRef) {
          this.chatRef.updateParticipants(this.sorterParticipants)
        }

        break
      case "allowMasterSpeaker":
        if (data.enable) {
          this.setState({ allowMasterSpeaker: true })
          setTimeout(this.syncMyUserStatus, 500)
          this.layoutVideos()
          this.showNotification(
            "You have been assigned as Master Speaker.",
            "Moderator",
          )
          Common.playAudio()
          //this.enableAllChat();
          if (this.chatRef) {
            this.chatRef.enablePublicChat()
            this.chatRef.enablePrivateChat()
          }
          setTimeout(this.syncMyUserStatus, 500)
        } else {
          this.setState({ allowMasterSpeaker: false })
          this.showNotification(
            "Your Master Speaker power revoked by Moderator",
            "Moderator",
          )
          setTimeout(this.syncMyUserStatus, 500)
          Common.playAudio()
          this.customizeChat(this.getSessionResponse)
          setTimeout(this.syncMyUserStatus, 500)
        }

        break
      case "allowRaisePermission":
        if (data.enable) {
          let video = this.selectedCamEnabled
          let audio = this.selectedMicEnabled
          this.setState({
            raiseHanded: false,
            speakerMode: true,
            video: video,
            audio: audio,
          })
          setTimeout(this.syncMyUserStatus, 500)
          this.layoutVideos()
          this.showNotification(
            "You have been assigned to Speaker.",
            "Moderator",
          )
          Common.playAudio()
          if (document.getElementById("docPlayer")) {
            var nextBut = document
              .getElementById("docPlayer")
              .contentWindow.document.getElementById("playN")
            var prevBut = document
              .getElementById("docPlayer")
              .contentWindow.document.getElementById("playP")
            if (nextBut) {
              nextBut.style.display = "block"
              prevBut.style.display = "block"
            }
          }
        } else {
          this.setState({
            raiseHanded: false,
            speakerMode: false,
            video: false,
            audio: false,
            screen: false,
          })
          this.showNotification(
            "Permission to present revoked by Moderator",
            "Moderator",
          )
          //this.showAlert('Revoked','Moderator has been revoked your presenting power.')
          setTimeout(this.syncMyUserStatus, 500)
          this.layoutVideos()
          Common.playAudio()
          if (this.picModeElement) {
            this.picModeElement.disablePictureInPicture = true
            this.picModeElement = false
          }
          if (document.getElementById("docPlayer")) {
            var nextBut = document
              .getElementById("docPlayer")
              .contentWindow.document.getElementById("playN")
            var prevBut = document
              .getElementById("docPlayer")
              .contentWindow.document.getElementById("playP")
            if (nextBut) {
              nextBut.style.display = "none"
              prevBut.style.display = "none"
            }
          }
        }

        break
      case "controlSpeaker":
        if (data.enable) {
          this.setState({ speaker: true })
          this.showNotification(
            "Audio output enabled by Moderator",
            "Moderator",
          )
          Common.playAudio()
        } else {
          this.setState({ speaker: false })
          this.showNotification(
            "Audio output disabled by Moderator",
            "Moderator",
          )
          Common.playAudio()
        }

        break
      case "handover":
        if (this.state.role == "interpreter") {
          //alert(data.floorLanguage.split('-')[0] +'//'+ this.state.floorLanguage.split('-')[0]+'//'+this.state.language +'//'+ data.outlanguage)
          if (this.state.language == data.outlanguage) {
            this.showNotification(
              "Handover request received by your Boothmate",
              "Interpreter",
            )
            this.setState({
              handOverTime: data.time,
              handOverTimeDisplay: Common.toHHMMSS(data.time),
            })
            Common.playAudio()
          }
        }
        break
      case "lowerHand":
        this.setState({ raiseHanded: false })
        this.showNotification(
          "Your raise hand request has been rejected by Moderator",
          "Moderator",
        )
        Common.playAudio()
        break
      case "refresh":
        window.location.reload()
        break
      case "remove":
        this.showAlert("Removed", "You have been removed from this session")
        setTimeout(() => {
          window.location.href = Config.HOME_PAGE_URL //'/thankyou';
        }, 3000)
        break
      case "onStatus":
        try {
          this.showAlert(
            "NETWORK INFO",
            this.generateTestHtml(JSON.parse(data.data)),
          )
        } catch (e) {
          this.showAlert("NETWORK INFO", data.data)
        }

        break
      case "onGetStatus":
        // this.testNetwork(event.from);
        Common.sendToOne(event.from, {
          method: "onStatus",
          data: Config.MY_NETWORK_STATUS,
          error: false,
        })

        break
      case "SpeakerCountdown":
        this.showAlert(
          "Count Down",
          "Timer for presentation set by Moderator. Check time left on the bottom menu bar",
        )
        this.setState({ presentationTimer: data.value * 60 })
        break
      case "SpeakerCountdownUpdate":
        this.showAlert(
          "Count Down",
          "Timer for presentation updated by Moderator. Check time left on the bottom menu bar",
        )
        this.setState({ presentationTimer: data.value * 60 })
        break
      case "SpeakerCountdownDelete":
        this.showAlert(
          "Count Down",
          "Timer for presentation removed by Moderator.",
        )
        this.setState({ presentationTimer: 0 })
        break

      case "slowDown":
        if (this.state.role == "speaker" || this.state.role == "moderator") {
          this.showNotification(
            "Interpreter has been requested to slow down speech",
            "Interpreter",
          )
          Common.playAudio()
        }
        break
      case "roomProperty":
        const property = data.property
        if (property) {
          this.manageProperty(property)
        }
        break

      default:
        break
    }
  }
  manageProperty = (property) => {
    for (let key in property) {
      switch (key) {
        case "onAir":
          if (property[key]) {
            this.setState({ onAir: true })
            if (this.role === "listener") {
              this.layout = null
              setTimeout(this.layoutVideos, 2000)
              this.setConferenceBg()
            }
          } else {
            /*
                    if(this.role === 'listener'){
                        window.location.reload();
                    }*/
            this.setState({ onAir: false })
          }
          break

        default:
          break
      }
    }
  }
  addCuePointViaHttp = (json) => {
    let room =
      this.state.selectedInterpreterInModerator === this.interpretersLabel
        ? "Floor"
        : this.state.selectedInterpreterInModerator
    let urlwowza =
      Config.CUE_POINT_URL +
      "?" +
      "application=" +
      encodeURIComponent(Config.WOWZA_APP) +
      "&streamName=" +
      encodeURIComponent(room) +
      "&url=" +
      btoa(json)
    //console.log(urlwowza);
    //let url = Config.API_URL + '/addCuePoint/?url='+Base64.encode(urlwowza);
    let url = "https://localhost:9091/addCuePoint/?url=" + btoa(urlwowza)
    window.$.get(url, function (data, status) { })
  }
  sendToAllPusher = (data) => {
    data.channel = Config.SESSION_ID
    if (UserSession.getSession().role === "listenerhls") {
      data.sendFromHls = true
    }
    data.userId = UserSession.getSession().userId
    data.requestId =
      (new Date(Date.now() + new Date().getTimezoneOffset() * 60000).getTime() /
        1000) |
      0
    //RequestHandler.post('https://localhost:9091/pusher-send',data,(response)=>{
    //alert(JSON.stringify(data))
    RequestHandler.post(Config.API_URL + "pusher-send", data, (response) => {
      if (response.status == "success") {
      }
    })

    // this.addCuePointViaHttp(JSON.stringify(data))
  }

  listenPusherEvent = () => {
    const pusher = new Pusher(Config.PUSHER_KEY, {
      cluster: "ap2",
      encrypted: true,
    })

    pusher.connection.bind("connected", () => {
      if (this.state.role === "listenerhls") {
        this.sendToAllPusher({
          sendFromHls: true,
          method: "userJoined",
          userName: UserSession.getSession().userName,
          userId: UserSession.getSession().userId,
          video: false,
          audio: false,
          speakerMode: this.state.speakerMode,
          allowMasterSpeaker: this.state.allowMasterSpeaker,
          raiseHanded: this.state.raiseHanded,
          role: this.state.role,
          speakerMode: this.state.speakerMode,
          speaker: this.state.speaker,
          language: this.state.language,
          isSigned: this.isSigned,
          mySpeedStatus: this.mySpeedStatus,
          panelLength: this.moderatorpanel.length,
          secondaryModerator: this.secondaryModerator,
        })
      } else if (this.state.role === "moderator") {
        if (this.moderatorpanel.length === 0) {
          this.sendToAllPusher({ method: "moderatorJoined" })
        }
      }
    })
    this.pusherChannel = pusher.subscribe(Config.SESSION_ID)

    this.pusherChannel.bind("client-message", (data, metadata) => {
      //this.onSignal(data);
      //alert(UserSession.getSession().userId+'//'+data.userId)
      //if(this.local[data.requestId]){
      // return;
      //}
      //this.local[data.requestId] = 'true';

      let currentTimeStamp =
        (new Date(
          Date.now() + new Date().getTimezoneOffset() * 60000,
        ).getTime() /
          1000) |
        0
      if (currentTimeStamp - data.requestId > 5) {
        return
      }

      if (UserSession.getSession().userId !== data.userId) {
        if (this.state.role == "listenerhls" || data.sendFromHls) {
          //this.chatRef.onSignal(data)
          //alert(JSON.stringify(data))
          switch (data.method) {
            case "controlSpeaker":
              if (data.sendTo === UserSession.getSession().userId) {
                if (data.enable) {
                  this.setState({ speaker: true })
                  this.showNotification(
                    "Audio input enabled by Moderator.",
                    "Moderator",
                  )
                  Common.playAudio()
                } else {
                  this.setState({ speaker: false })
                  this.showNotification(
                    "Audio input disabled by Moderator.",
                    "Moderator",
                  )
                  Common.playAudio()
                }
              }
              break
            case "remove":
              if (data.sendTo === UserSession.getSession().userId) {
                this.showAlert(
                  "Removed",
                  "You have been removed from this session",
                )
                setTimeout(() => {
                  window.location.href = Config.HOME_PAGE_URL //'/thankyou';
                }, 3000)
              }
              break
            case "refresh":
              if (data.sendTo === UserSession.getSession().userId) {
                window.location.reload()
              }
              break
            case "onPollResult":
              if (this.state.role != "interpreter") {
                this.getPollReport(data.data)
                this.showNotification(
                  "Moderator has shared poll results.",
                  "Moderator",
                )
              }
              break
            case "onPoll":
              if (this.state.role == "moderator") {
                this.getAllPolls()
              }

              this.pollRef.onPoll(data)
              break
            case "moderatorJoined":
              this.sendToAllPusher({
                sendFromHls: true,
                method: "userJoined",
                userName: UserSession.getSession().userName,
                userId: UserSession.getSession().userId,
                video: false,
                audio: false,
                speakerMode: this.state.speakerMode,
                raiseHanded: this.state.raiseHanded,
                role: this.state.role,
                speakerMode: this.state.speakerMode,
                allowMasterSpeaker: this.state.allowMasterSpeaker,
                speaker: this.state.speaker,
                language: this.state.language,
                isSigned: this.isSigned,
                mySpeedStatus: this.mySpeedStatus,
                panelLength: this.moderatorpanel.length,
                secondaryModerator: this.secondaryModerator,
              })

              break

            case "userJoined":
              //data.role = 'listener';
              if (this.state.role == "moderator") {
                let participants = this.state.participants
                let partConId = this.checkParticipantExist(data)
                if (!partConId) {
                  participants[data.userId] = {
                    userData: data,
                    connection: data.userId,
                  }
                } else {
                  participants[partConId].userData = data
                }
                this.sorterParticipants = this.sortParticpants(participants)
                this.setState({ participants: this.sorterParticipants })
              }
              break
            default:
              break
          }
        }
      }
    })
  }
  testNetwork = (connection) => {
    const sessionInfo = {
      apiKey: Config.API_KEY, // Add the API key for your OpenTok project here.
      sessionId: Config.SESSION_ID, // Add a test session ID for that project
      token: Config.TOKEN_PUBLISHER
        ? Config.TOKEN_PUBLISHER
        : Config.TOKEN_SUBSCRIBER, // Add a token for that session here
    }
  }
  newChat = (data) => {
    if (!this.state.chatEnabled) {
      //this.showNotification(data.name+' : '+data.message,data.name)
      this.setState({ unReadChat: true })
      // Common.playAudio();
    }
    if (this.getSessionResponse.enablePopupNot) {
      this.showNotification(
        data.name + ":" + data.message.substring(0, 100) + "....",
        data.name,
      )
    }
  }
  newSupportChat = (data) => {
    this.setState({ supportChat: true })
    if (this.state.role === "moderator") {
      this.showNotification(
        "New support query from " +
        data.name +
        " : " +
        data.message.substring(0, 100),
        data.name,
      )
    } else {
      if (data.role === "moderator") {
        this.showNotification(
          "New support reply : " + data.message.substring(0, 100),
          data.name,
        )
      }
    }
  }
  sendSlowDownMessage = () => {
    this.showNotification(
      "Slow down request sent to all Speakers",
      UserSession.getSession().userName,
    )
    Common.sendToAll({
      method: "slowDown",
      userName: UserSession.getSession().userName,
    })
  }
  setName = (stream) => {
    try {
      let split = stream.name.split("_")
      if (split[2]) {
        let parent = document.getElementById("video-" + stream.id).childNodes[0]
          .childNodes[0]
        if (parent) {
          for (var i = 0; i < parent.childNodes.length; i++) {
            if (parent.childNodes[i].className.indexOf("OT_name") > -1) {
              if (
                this.state.currentLayout === "vertical" &&
                "video-" + stream.id !== this.currentBigId &&
                split[3] !== "screen"
              ) {
                parent.childNodes[i].innerHTML =
                  split[2] +
                  ' <img style="float:right;margin-top:7px;cursor:pointer;margin-right:20px;height:18px" src="/img/pin1.svg" />'
              } else {
                parent.childNodes[i].innerHTML = split[2]
              }
              parent.childNodes[i].style.visibility = "visible"
            }
            if (
              parent.childNodes[i].className.indexOf("OT_widget-container") > -1
            ) {
              if (split[2].length > 1) {
                parent.childNodes[i].childNodes[0].innerHTML =
                  '<div className="vdo-user-avatar-blk"><div className="notranslate vdo-user-avatar">' +
                  split[2].charAt(0).toUpperCase() +
                  split[2].charAt(1).toUpperCase() +
                  "</div></div>" // '<div>'+split[2].charAt(0).toUpperCase()+split[2].charAt(1).toUpperCase()+'</div>';
              } else {
                parent.childNodes[i].childNodes[0].innerHTML =
                  '<div className="vdo-user-avatar-blk"><div className="notranslate vdo-user-avatar">A</div></div>'
              }
            }
          }
        }
      }
    } catch (e) { }
  }
  setNamePublisher = () => {
    try {
      let parent =
        document.getElementById("publisher-parent").childNodes[0].childNodes[0]
      if (parent) {
        for (var i = 0; i < parent.childNodes.length; i++) {
          if (parent.childNodes[i].className.indexOf("OT_name") > -1) {
            parent.childNodes[i].innerHTML =
              UserSession.getSession().userName + "(Me)"
            parent.childNodes[i].style.visibility = "visible"
            break
          }
          if (
            parent.childNodes[i].className.indexOf("OT_widget-container") > -1
          ) {
            if (
              parent.childNodes[i].childNodes[0].innerHTML !=
              '<div className="vdo-user-avatar-blk"><div className="notranslate vdo-user-avatar">ME</div></div>'
            ) {
              parent.childNodes[i].childNodes[0].innerHTML =
                '<div className="vdo-user-avatar-blk"><div className="notranslate vdo-user-avatar">ME</div></div>'
            }
          }
        }
      }
    } catch (e) { }
    try {
      let parentScreen = document.getElementById("publisher-parent-screen")
        .childNodes[0].childNodes[0]
      if (parentScreen) {
        for (var i = 0; i < parentScreen.childNodes.length; i++) {
          if (parentScreen.childNodes[i].className.indexOf("OT_name") > -1) {
            parentScreen.childNodes[i].innerHTML =
              UserSession.getSession().userName + "(Me)"
            parentScreen.childNodes[i].style.visibility = "visible"
            //break;
          }

          if (
            parentScreen.childNodes[i].className.indexOf(
              "OT_widget-container",
            ) > -1
          ) {
            if (parentScreen.childNodes[i].childElementCount >= 3) {
              if (this.picModeElement == false) {
                this.picModeElement = parentScreen.childNodes[i].childNodes[2]

                setTimeout(
                  (parentScreen) => {
                    try {
                      //parentScreen.childNodes[i].childNodes[2].requestPictureInPicture();
                    } catch (e) { }
                  },
                  1000,
                  parentScreen,
                )
              }
            }
            break
          }
        }
      }
    } catch (e) { }
  }
  showNotification = (text, userName) => {
    this.setState({ notification: { text: text, name: userName } })
    if (this.notTimeout) {
      clearTimeout(this.notTimeout)
    }
    this.notTimeout = setTimeout(() => {
      this.setState({ notification: false })
    }, 5000)
  }
  closeNotification = () => {
    this.setState({ notification: false })
  }
  syncMyUserStatus = () => {
    if (this.moderatorpanel.length === 0) {
      Common.sendToAll({
        method: "userExist",
        userName: UserSession.getSession().userName,
        userId: UserSession.getSession().userId,
        video: this.state.settingsOpen ? false : this.state.video,
        audio: this.state.settingsOpen ? false : this.state.audio,
        speakerMode: this.state.speakerMode,
        raiseHanded: this.state.raiseHanded,
        role: this.state.role,
        speakerMode: this.state.speakerMode,
        allowMasterSpeaker: this.state.allowMasterSpeaker,
        speaker: this.state.speaker,
        myVolume: this.state.InterpreterMyvolumeSliderValue,
        language: this.state.language,
        isSigned: this.isSigned,
        currentDocPlayerUrl: this.currentDocPlayerUrl,
        currentPptScroll: this.currentPptScroll,
        currentPage: this.currentPage,
        currentStep: this.currentStep,
        totalStep: this.totalStep,
        currentType: this.currentType,
        currentVideoPlayerUrl: this.currentVideoPlayerUrl,
        currentVideoPlayerTime: this.currentVideoPlayerTime,
        mySpeedStatus: this.mySpeedStatus,
        caption: this.state.myCaption,
        currentVideoPlayerState: this.currentVideoPlayerState,
        secondaryModerator: this.secondaryModerator,
        pinned: this.pinned,
      })
    }
  }
  syncMyUserStatusToYou = (connection) => {
    if (this.moderatorpanel.length === 0) {
      Common.sendToOne(connection, {
        method: "userExist",
        userName: UserSession.getSession().userName,
        userId: UserSession.getSession().userId,
        video: this.state.settingsOpen ? false : this.state.video,
        audio: this.state.settingsOpen ? false : this.state.audio,
        speakerMode: this.state.speakerMode,
        raiseHanded: this.state.raiseHanded,
        role: this.state.role,
        speakerMode: this.state.speakerMode,
        allowMasterSpeaker: this.state.allowMasterSpeaker,
        speaker: this.state.speaker,
        myVolume: this.state.InterpreterMyvolumeSliderValue,
        language: this.state.language,
        isSigned: this.isSigned,
        currentDocPlayerUrl: this.currentDocPlayerUrl,
        currentPptScroll: this.currentPptScroll,
        currentVideoPlayerUrl: this.currentVideoPlayerUrl,
        currentPage: this.currentPage,
        currentStep: this.currentStep,
        totalStep: this.totalStep,
        currentType: this.currentType,
        currentVideoPlayerTime: this.currentVideoPlayerTime,
        mySpeedStatus: this.mySpeedStatus,
        caption: this.state.myCaption,
        currentVideoPlayerState: this.currentVideoPlayerState,
        secondaryModerator: this.secondaryModerator,
        pinned: this.pinned,
      })
    }
  }
  onErrorPublishing = (err) => {
    this.destroyCustomStream();
    if (err.originalMessage == "Permission denied") {
      if (!this.state.screen) {
        this.showNotification(
          "The application is not allowed to access camera or microphone.",
          UserSession.getSession().userName.toUpperCase(),
        )
        this.setState({ video: false, audio: false })
        this.syncMyUserStatus()
        if (this.reqVid) {
          Common.sendToAll({
            method: "requestVideoStopPermission",
            language: this.state.language,
          })
          this.reqVid = false
        }
      }
    }
    if (
      err.originalMessage ==
      "The request is not allowed by the user agent or the platform in the current context."
    ) {
      this.showNotification(
        "Permission to access screen or media device is restricted on this browser.Please check browser settings.",
        UserSession.getSession().userName.toUpperCase(),
      )
    }
    this.setState({ screen: false })
    if (this.picModeElement) {
      this.picModeElement.disablePictureInPicture = true
      this.picModeElement = false
    }
  }

  makeFullScreen = (id) => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    } else {
      let element = document.getElementById(id)
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      }
    }
  }
  renderStreamsPartner = (stream) => {
    let params = stream.name.split("_")
    let role = params[0]
    let language
    let screen = false
    let signed = false
    if (params.length <= 1) {
      return
    }
    if (params.length > 1) {
      if (role === "interpreter") {
        if (this.state.userIdLanguageMap.hasOwnProperty(params[1])) {
          language = this.state.userIdLanguageMap[params[1]]
        }
      } else {
        language = params[1]
      }
    }
    if (params.length > 3) {
      if (params[3] == "screen") {
        screen = true
      }
      if (params[3] == "screen") {
        signed = true
      }
    }
    let isParner = false
    if (params.length > 5) {
      isParner = params[5]
    }
    // if listener do this
    if (this.state.role === "interpreter" && role === "interpreter") {
      if (
        (this.state.floorLanguage !== "Floor" &&
          this.state.floorLanguage === language) ||
        this.state.language === language ||
        isParner === true
      ) {
        // console.log((this.state.floorLanguage !== 'Floor' && this.state.floorLanguage === language) || (this.state.language === language) || isParner === true)
        return (
          <div
            className="video-wrap"
            id={"video-" + stream.id}
            onDoubleClick={this.makeFullScreen.bind(this, "video-" + stream.id)}
          >
            <OTSubscriber
              onSubscribe={(e) => console.log("SUBED TO OT INT", e)}
              key={stream.id}
              session={this.sessionHelper.session}
              stream={stream}
              eventHandlers={this.subscriberEventHandlers}
              properties={{
                subscribeToAudio: true,
                subscribeToVideo: true,
                width: "100%",
                height: "100%",
              }}
              onError={this.onSubscribeError}
            />
          </div>
        )
      }
    }
  }
  generateSpeakerInfoHtml = (id, myCaption, streamId) => {
    // console.log('Given Caption = >'+myCaption.id)
    let captionChoice = false
    let splitRole = []
    if (myCaption) {
      if (myCaption.role) {
        splitRole = myCaption.role.split("_")
        captionChoice = splitRole[2]
      }
    }
    // console.log("----- " + splitRole[1] + " // " + id + " ///" + captionChoice)
    /*
        if(window.captionTimeout){
            if(window.captionTimeout[myCaption.id]){
                clearTimeout(window.captionTimeout[myCaption.id])
            }
        
        }else{
            window.captionTimeout = {};
        }
        */
    if (!window.captionTimeout) {
      window.captionTimeout = {}
    }
    if (
      parseInt(splitRole[1]) > 0 &&
      !window.captionTimeout[id] &&
      id &&
      captionChoice
    ) {
      window.captionTimeout[id] = setTimeout(
        (capId, captionChoice) => {
          if (document.getElementById(capId + captionChoice)) {
            document.getElementById(capId + captionChoice).style.display =
              "none"
            clearTimeout(window.captionTimeout[capId])
            delete window.captionTimeout[capId]
          }
        },
        parseInt(splitRole[1]) * 1000,
        id,
        captionChoice,
      )
    }

    return (
      <section
        className={
          "cap-streamid-" + streamId + " cap-userid-" + id + " captionsection"
        }
      >
        <div
          id={id + "2"}
          style={{ display: captionChoice === "2" ? "flex" : "none" }}
          className={myCaption.id + " preset-speaker_4 speaker-info-wrap"}
        >
          <div className="speaker4-info-part">
            <div id={id + "2name"} className="speaker4-desc-part speaker-name">
              {captionChoice === "2" ? myCaption.name : ""}
            </div>
            <div id={id + "2role"} className="speaker4-desc-part">
              {captionChoice === "2" ? splitRole[0] : ""}
            </div>
          </div>
          <div
            id={id + "2captionlogowrap"}
            style={{ display: myCaption.logo ? "block" : "none" }}
            className="speaker4-logo-blk logoblk"
          >
            <div className="speaker-logo-blk">
              <img
                id={id + "2captionlogo"}
                src={
                  captionChoice === "2" ? myCaption.logo : "/img/sponser.jpg"
                }
                className="object-fill"
              />
            </div>
          </div>
        </div>

        <div
          id={id + "3"}
          style={{ display: captionChoice === "3" ? "flex" : "none" }}
          className={
            myCaption.id +
            " preset-speaker_4 preset-speaker_nobg speaker-info-wrap"
          }
        >
          <div className="speaker2-info-part">
            <div id={id + "3name"} className="speaker2-name">
              {captionChoice === "3" ? myCaption.name : ""}
            </div>
            <div id={id + "3role"} className="speaker2-role">
              {captionChoice === "3" ? splitRole[0] : ""}
            </div>
          </div>
          <div
            id={id + "3captionlogowrap"}
            style={{ display: myCaption.logo ? "block" : "none" }}
            className="preset-speaker2-logo logoblk"
          >
            <div className="speaker-logo-blk">
              <img
                id={id + "3captionlogo"}
                src={
                  captionChoice === "3" ? myCaption.logo : "/img/sponser.jpg"
                }
                className="object-fill"
              />
            </div>
          </div>
        </div>

        <div
          id={id + "4"}
          style={{ display: captionChoice === "4" ? "flex" : "none" }}
          className={
            myCaption.id +
            " preset-speaker_4 preset-speaker_nobg speaker-info-wrap"
          }
        >
          <div
            id={id + "4captionlogowrap"}
            style={{ display: myCaption.logo ? "block" : "none" }}
            className="preset-speaker5-logo logoblk"
          >
            <div className="speaker-logo-blk">
              <img
                id={id + "4captionlogo"}
                src={
                  captionChoice === "4" ? myCaption.logo : "/img/sponser.jpg"
                }
                className="object-fill"
              />
            </div>
          </div>
          <div className="speaker5-info-part">
            <div id={id + "4role"} className="speaker5-desc-part role">
              {captionChoice === "4" ? splitRole[0] : ""}
            </div>
            <div id={id + "4name"} className="speaker5-desc-part speaker-name">
              {captionChoice === "4" ? myCaption.name : ""}
            </div>
          </div>
        </div>

        <div
          id={id + "5"}
          style={{ display: captionChoice === "5" ? "flex" : "none" }}
          className={
            myCaption.id +
            " preset-speaker_4 preset-speaker_nobg speaker-info-wrap cp-5"
          }
        >
          <div
            id={id + "5captionlogowrap"}
            style={{ display: myCaption.logo ? "block" : "none" }}
            className="preset-speaker3-logo logoblk"
          >
            <div className="speaker-logo-blk">
              <img
                id={id + "5captionlogo"}
                src={
                  captionChoice === "5" ? myCaption.logo : "/img/sponser.jpg"
                }
                className="object-fill"
              />
            </div>
          </div>
          <div className="speaker3-info-part">
            <div id={id + "5name"} className="speaker3-name">
              {captionChoice === "5" ? myCaption.name : ""}
            </div>
            <div id={id + "5role"} className="speaker3-role">
              {captionChoice === "5" ? splitRole[0] : ""}
            </div>
          </div>
        </div>

        <div
          id={id + "1"}
          style={{ display: captionChoice === "1" ? "flex" : "none" }}
          className={myCaption.id + " speaker-info-wrap"}
        >
          <div className="speaker-info-mod" title="sample caption for speaker">
            <div className="speaker-desc-left-part">
              <div className="speaker-info-col">
                <div className="user-info-name">
                  name <span>:</span>
                </div>
                <div
                  id={id + "1name"}
                  className="speaker-info-desc-val speaker-name"
                >
                  {captionChoice === "1" ? myCaption.name : ""}
                </div>
              </div>
              <div className="speaker-info-col">
                <div className="user-info-name">
                  role <span>:</span>
                </div>
                <div id={id + "1role"} className="speaker-info-desc-val">
                  {captionChoice === "1" ? splitRole[0] : ""}
                </div>
              </div>
            </div>
            <div
              id={id + "1captionlogowrap"}
              style={{ display: myCaption.logo ? "block" : "none" }}
              className="speaker-desc-right-part logoblk"
            >
              <div className="speaker-logo-blk">
                <img
                  id={id + "1captionlogo"}
                  src={
                    captionChoice === "1" ? myCaption.logo : "/img/sponser.jpg"
                  }
                  className="object-fill"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
  pinSpeaker = (id, userId) => {
    id = "video-" + id
    let pinned = false
    if (document.getElementById(id).classList.contains("pinned")) {
      document.getElementById(id).classList.remove("pinned")
    } else {
      document.getElementById(id).classList.add("pinned")
      pinned = true
    }
    this.setState({ userIdPinnedMap: {} })
    Common.sendToAll({
      method: "pinuser",
      id: id,
      usrid: userId,
      userName: UserSession.getSession().userName,
      status: pinned,
    })
    document.querySelectorAll(".pinned").forEach((div) => {
      if (div.id !== id) {
        div.classList.remove("pinned")
      }
    })
  }
  renderStreams = (stream) => {
    if (stream.destroyed === true || this.moderatorpanel.length > 0) {
      return ""
    } else {
      let params = stream.name.split("_")
      let role = params[0]

      let language
      let screen = false
      let signed = false
      let userId = ""
      let caption = []
      let pinned = false
      let isParner = "false"
      if (params.length > 5) {
        isParner = params[5]
      }
      if (isParner === "true") {
        return
      }
      if (params.length <= 1) {
        return
      }
      if (params.length > 1) {
        if (role === "interpreter") {
          if (this.state.userIdLanguageMap.hasOwnProperty(params[1])) {
            language = this.state.userIdLanguageMap[params[1]]
          }
          // console.log('Interpreter Language = '+language)
        } else {
          language = params[1]
        }
      }
      if (params.length > 3) {
        if (params[3] == "screen") {
          screen = true
        }
        if (params[3] == "screen") {
          signed = true
        }
      }
      if (params.length > 4) {
        userId = params[4]
      }
      if (this.state.userIdCaptionMap.hasOwnProperty(params[4])) {
        caption = this.state.userIdCaptionMap[params[4]]
      }
      if (this.state.userIdPinnedMap.hasOwnProperty(params[4])) {
        pinned = this.state.userIdPinnedMap[params[4]]
      }

      setTimeout(this.setName, 100, stream)
      // if listener do this
      if (
        this.state.role === "listener" ||
        this.state.role === "moderator" ||
        this.state.role === "speaker"
      ) {
        if (
          (this.state.selectedInterpreterInModerator !==
            this.interpretersLabel &&
            this.state.selectedInterpreterInModerator === language) ||
          role !== "interpreter"
        ) {
          // if select interpreter
          //alert(1)
          // alert(JSON.stringify(stream))
          //console.log('------ '+this.state.selectedInterpreterInModerator)
          const subs = []
          if (this.loadTest) {
            for (let i = 0; i < 199; i++) {
              subs.push(
                <OTSubscriber
                  onSubscribe={(e) => console.log("SUBBED", i, e)}
                  key={stream.id + i}
                  session={this.sessionHelper.session}
                  stream={stream}
                  eventHandlers={this.subscriberEventHandlers}
                  properties={{
                    subscribeToAudio: this.state.speaker,
                    subscribeToVideo: role == "interpreter" ? false : true,
                    width: "100%",
                    height: "100%",
                    testNetwork: true,
                  }}
                  onError={this.onSubscribeError}
                />,
              )
            }
          }

          return (
            <div
              key={stream.id + "parent"}
              className={pinned ? "video-wrap pinned" : "video-wrap"}
              onClick={this.makeOtBig.bind(this, "video-" + stream.id)}
              id={"video-" + stream.id}
              onDoubleClick={this.makeFullScreen.bind(
                this,
                "video-" + stream.id,
              )}
            >
              <OTSubscriber
                onSubscribe={(e) => console.log("SUBED TO OT NOT INT", e)}
                key={stream.id}
                session={this.sessionHelper.session}
                stream={stream}
                eventHandlers={this.subscriberEventHandlers}
                properties={{
                  subscribeToAudio: this.state.speaker,
                  subscribeToVideo: role == "interpreter" ? false : true,
                  width: "100%",
                  height: "100%",
                  testNetwork: true,
                }}
                onError={this.onSubscribeError}
              />
              {this.state.allowLoad && subs}

              {role === "speaker"
                ? this.generateSpeakerInfoHtml(userId, caption, stream.id)
                : ""}
              {role === "speaker" && this.role === "moderator" && (
                <img
                  onClick={this.pinSpeaker.bind(this, stream.id, userId)}
                  className="pinicon"
                  src="/assets/img/pin.svg"
                  alt="pin"
                />
              )}
            </div>
          )
        }
      }
      // if interpreter
      if (this.state.role === "interpreter") {
        if (
          role === "moderator" ||
          role === "speaker" ||
          (this.state.floorLanguage !== "Floor" &&
            this.state.floorLanguage === language) ||
          this.state.language == language
        ) {
          //alert(4)
          let subAudio = true
          if (this.state.floorLanguage !== "Floor") {
            if (role === "moderator" || role === "speaker") {
              subAudio = false
            }
          }
          let properties = {
            subscribeToAudio: this.state.speaker ? subAudio : false,
            subscribeToVideo: role == "interpreter" ? false : true,
            width: "100%",
            height: "100%",
            testNetwork: true,
          }
          //console.log('sub audio ===== '+JSON.stringify(properties))

          return (
            <div
              key={stream.id + "parent"}
              className="video-wrap"
              onClick={this.makeOtBig.bind(this, "video-" + stream.id)}
              id={"video-" + stream.id}
              ondblclick={this.makeFullScreen.bind(this, "video-" + stream.id)}
            >
              <OTSubscriber
                onSubscribe={(e) => console.log("SUBED TO OT MOD SPK", e)}
                key={stream.id}
                session={this.sessionHelper.session}
                stream={stream}
                eventHandlers={this.subscriberEventHandlers}
                properties={properties}
                onError={this.onSubscribeError}
              />
              {role === "speaker"
                ? this.generateSpeakerInfoHtml(userId, caption, stream.id)
                : ""}
            </div>
          )
        }
      }
    }
  }

  destroyCustomStream = () => {
    if (!this.state.audioStream) {
      return;
    }
    this.state.audioStream.getTracks().forEach(t => t.stop());
    this.setState({ audioStream: null }, () => {
      this.initActivityStream = false;
    });

  }

  renderPublisher = (isPartner) => {
    // console.log('mycam  sdfs'+this.state.video)
    if (
      (this.state.role !== "listener" || this.state.settingsOpen) &&
      this.moderatorpanel.length === 0
    ) {
      if (!this.state.deviceInit) {
        return (
          <AudioVideoSettings
            settingsContinue={this.settingsContinue}
            setCamEnabled={this.setCamEnabled}
            setMicEnabled={this.setMicEnabled}
            role={this.state.role}
            isSigned={this.isSigned}
            ref={(settingRef) => (this.settingRef = settingRef)}
          />
        )
      } else if (this.state.connected) {
        let name
        if (this.state.role === "interpreter") {
          name =
            this.state.role +
            "_" +
            this.props.userId +
            "_" +
            UserSession.getSession().userName +
            "_" +
            (this.isSigned ? "signed" : false) +
            "_" +
            UserSession.getSession().userId +
            "_" +
            isPartner
        } else {
          name =
            this.state.role +
            "_" +
            this.state.language +
            "_" +
            UserSession.getSession().userName +
            "_" +
            (this.isSigned ? "signed" : false) +
            "_" +
            UserSession.getSession().userId +
            "_" +
            isPartner
        }
        const customAudioTrack = this.state.audioStream && this.state.audioStream.getAudioTracks()[0];
        const audioReady = this.state.audio && (!this.state.customAudioStream || customAudioTrack)
        if (audioReady || (this.state.video && !this.state.audio)) {
          let publisherProperties = {
            frameRate: 30,
            width: "100%",
            height: "100%",
            publishAudio: isPartner ? false : this.state.audio,
            publishVideo:
              this.state.role !== "interpreter"
                ? this.state.video
                : this.state.video,
            videoSource: Config.getItem("selectedCamera")
              ? Config.getItem("selectedCamera")
              : undefined,
            audioSource: Config.getItem("selectedMicrophone")
              ? Config.getItem("selectedMicrophone")
              : undefined,
            name: name,
            //disableAudioProcessing: (this.state.role !== 'interpreter' || isPartner) ? false:true,

            disableAudioProcessing: false,
            echoCancellation: true,
            noiseSuppression:
              this.state.role !== "interpreter" || isPartner ? true : false,
            ...this.state.pubProps,
          }
          if (!this.getSessionResponse?.enableHighResolution) {
            publisherProperties.resolution = "480x270"
            publisherProperties.videoBitrate = 350
          }
          if (this.state.customAudioStream && customAudioTrack) {
            publisherProperties.audioSource = customAudioTrack;
          }
          // disableAudioProcessing: false,
          // //(this.state.role !== 'interpreter' || isPartner) ? false:true,
          //console.log('publisher properties : '+publisherProperties);
          setTimeout(this.setNamePublisher, 100)
          if (this.isSigned) {
            return (
              <Draggable>
                <div
                  id="publisher-parent"
                  className={
                    isPartner
                      ? "hide"
                      : this.state.numSubs === 0
                        ? "publisher-video"
                        : "publisher-video publisher-small"
                  }
                >
                  <OTPublisher
                    session={this.sessionHelper.session}
                    onPublish={() => console.log("OTD", publisherProperties)}
                    properties={publisherProperties}
                    eventHandlers={{
                      streamCreated: (event) => {
                        this.layoutVideos()
                        const mediaStream = document
                          .getElementById("publisher-parent")
                          .querySelector("video").srcObject
                        if (isPartner) {
                          this.setState({ parnterSelfVideoCreated: true })
                        }
                      },
                      streamDestroyed: (event) => {
                        this.destroyCustomStream();
                        this.layoutVideos()
                        if (isPartner) {
                          this.setState({ parnterSelfVideoCreated: false })
                        }
                      },
                    }}
                    onError={this.onErrorPublishing}
                  />
                  {this.state.role === "speaker"
                    ? this.generateSpeakerInfoHtml(
                      this.props.userId,
                      this.state.myCaption,
                      "00",
                    )
                    : ""}
                </div>
              </Draggable>
            )
          } else {
            const pubs = []
            if (this.loadTest) {
              for (let i = 0; i < 9; i++) {
                pubs.push(
                  <OTPublisher
                    onPublish={(e) => console.log("OTD", i, e)}
                    session={this.sessionHelper.session}
                    properties={publisherProperties}
                    eventHandlers={{
                      streamCreated: (event) => {
                        this.layoutVideos()
                        const mediaStream = document
                          .getElementById("publisher-parent")
                          .querySelector("video").srcObject
                        if (isPartner) {
                          this.setState({ parnterSelfVideoCreated: true })
                        }
                      },
                      streamDestroyed: (event) => {
                        this.destroyCustomStream();
                        this.layoutVideos()
                        if (isPartner) {
                          this.setState({ parnterSelfVideoCreated: false })
                        }
                      },
                    }}
                    onError={this.onErrorPublishing}
                  />,
                )
              }
            }

            return (
              <div
                id="publisher-parent"
                className={
                  isPartner
                    ? "hide"
                    : this.state.numSubs === 0
                      ? "publisher-video"
                      : "publisher-video publisher-small"
                }
              >
                <OTPublisher
                  onPublish={() => {
                    const {
                      disableAudioProcessing,
                      echoCancellation,
                      autoGainControl,
                      noiseSuppression,
                      enableDtx,
                      audioFilter,
                    } = publisherProperties

                    console.log("OT PUBD 2", {
                      disableAudioProcessing,
                      echoCancellation,
                      autoGainControl,
                      noiseSuppression,
                      enableDtx,
                      audioFilter,
                      customAudioTrack,
                    })
                  }}
                  session={this.sessionHelper.session}
                  properties={publisherProperties}
                  eventHandlers={{
                    streamCreated: (event) => {
                      this.layoutVideos()
                      const mediaStream = document
                        .getElementById("publisher-parent")
                        .querySelector("video").srcObject
                      if (isPartner) {
                        this.setState({ parnterSelfVideoCreated: true })
                      }
                    },
                    streamDestroyed: (event) => {
                      this.destroyCustomStream();
                      this.layoutVideos()
                      if (isPartner) {
                        this.setState({ parnterSelfVideoCreated: false })
                      }
                    },
                  }}
                  onError={this.onErrorPublishing}
                />
                {this.state.allowLoad && pubs}
                {this.state.role === "speaker"
                  ? this.generateSpeakerInfoHtml(
                    this.props.userId,
                    this.state.myCaption,
                    "00",
                  )
                  : ""}
              </div>
            )
          }
        }
      }
    }
  }
  renderScreenPublisher = () => {
    let width = 1024
    let height = 768
    if (window.screen.width) {
      width = window.screen.width * 1
      height = window.screen.height * 1
    }
    // alert(width+'//'+height)
    if (this.state.screen) {
      let publisherProperties = {
        frameRate: 7,
        resolution: width + "x" + height,
        width: "100%",
        height: "100%",
        publishAudio: false,
        publishVideo: true,
        videoSource: "screen",
        name:
          this.state.role +
          "_" +
          this.state.language +
          "_" +
          UserSession.getSession().userName +
          "_screen",
      }
      //console.log('publisher screen properties : '+publisherProperties);
      setTimeout(this.setNamePublisher, 100)
      return (
        <div className="screenshare-parent">
          <h2>You're Presenting to Everyone</h2>
          <button
            onClick={this.screenControl}
            className="stop-share-btn pointer"
          >
            Stop Presenting
          </button>
          <div id="publisher-parent-screen" className="screen-myvideo">
            <OTPublisher
              onPublish={(e) => console.log("OTD 1", e)}
              session={this.sessionHelper.session}
              properties={publisherProperties}
              eventHandlers={{
                streamCreated: (event) => {
                  //console.log('Publisher stream created!');
                  this.screenStreamId = event.stream.id
                  this.layoutVideos()
                },
                streamDestroyed: (event) => {
                  this.setState({ screen: false })
                  this.layoutVideos()
                  if (this.picModeElement) {
                    this.picModeElement.disablePictureInPicture = true
                    this.picModeElement = false
                  }
                },
              }}
              onError={this.onErrorPublishing}
            />
          </div>
        </div>
      )
    }
  }
  onRaiseHand = () => {
    let raiseHanded = this.state.raiseHanded
    this.setState({ raiseHanded: !raiseHanded })
    setTimeout(this.syncMyUserStatus, 500)
    Common.sendToAll({
      method: "handRaised",
      userName: UserSession.getSession().userName,
      status: !this.state.raiseHanded,
    })
  }
  audioControl = () => {
    const enableStream = this.state.role === "interpreter" || (this.state.customAudioStream && this.state.role === "speaker");
    console.log("INIT", this.state.customAudioStream, enableStream, this.initActivityStream)
    if (enableStream) {
      /*
          if(this.state.video){
            this.showAlert('Message', 'You are in video conversation with your partner, You cannot publish mic until you finish it')
            return;
          }
          */
      if (!this.initActivityStream) {
        navigator.getUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia
        navigator.getUserMedia(
          {
            audio: {
              deviceId: Config.getItem("selectedMicrophone")
                ? Config.getItem("selectedMicrophone")
                : undefined,
            }, video: false
          },
          async (stream) => {
            stream = await getStreamProcessor(this.state.pubPropsKey)(stream);
            if (this.state.role === "interpreter" && !this.noMicIndicator) {
              this.micLevelIndicator(stream);
            }
            if (this.state.customAudioStream) {
              this.setState({ audioStream: stream })
            }
          },
          (error) => { },
        )
        this.initActivityStream = true
      }
      this.setState({ isAudioProcessing: true })
      setTimeout(() => {
        this.setState({ isAudioProcessing: false })
      }, 1000)
    }
    let audio = this.state.audio
    this.setState({ audio: !audio })
    setTimeout(this.syncMyUserStatus, 500)
    this.layoutVideos()
    this.setState({ isPublishProcess: true })
    setTimeout(() => {
      this.setState({ isPublishProcess: false })
    }, 1000)
  }
  videoControl = () => {
    let video = this.state.video
    let audio = this.state.audio
    if (this.isSigned) {
      this.setState({ video: !video, audio: false })
    } else {
      this.setState({ video: !video, audio: !video ? true : audio })
    }

    setTimeout(this.syncMyUserStatus, 500)
    this.layoutVideos()
    this.setState({ isPublishProcess: true })
    setTimeout(() => {
      this.setState({ isPublishProcess: false })
    }, 1000)
  }
  screenControl = () => {
    let screen = this.state.screen
    if (screen) {
      if (this.picModeElement) {
        this.picModeElement.disablePictureInPicture = true
        this.picModeElement = false
      }
    } else {
      this.closeDocPlayerFromCall()
      this.closeVideoPlayerFromCall()
    }
    this.setState({ screen: !screen })
  }
  enableOrDisableChat = () => {
    let chatEnabled = this.state.chatEnabled
    this.setState({ chatEnabled: !chatEnabled, unReadChat: false })
    this.layoutVideos()
    this.chatRef.openChat()
    this.customizeChat(this.getSessionResponse)
  }
  requestVideo = () => {
    if (this.state.requestPartnerVideo || this.state.acceptPartnerVideo) {
      Common.sendToAll({
        method: "requestVideo",
        requestVideo: false,
        language: this.state.language,
      })
      this.setState({
        requestPartnerVideo: false,
        video: false,
        acceptPartnerVideo: false,
      })
    } else {
      Common.sendToAll({
        method: "requestVideo",
        requestVideo: true,
        language: this.state.language,
      })
      this.setState({ requestPartnerVideo: true })
      /*
        let audio = this.state.audio;
        if(audio){
            this.setState({audio:!audio})
            setTimeout(this.syncMyUserStatus,500);
            this.showAlert('Message','You have requested private communication with your partner , so public audio will stop now.')
        }
        */
    }
    if (window.inttimeout) {
      clearTimeout(window.inttimeout)
    }
    window.inttimeout = setTimeout(() => {
      //alert(this.partnerPresent+'//'+this.state.requestPartnerVideo)
      if (!this.partnerPresent && this.state.requestPartnerVideo) {
        this.showAlert(
          "Partner Unavailable",
          "Boothmate is not available for video sharing.",
        )
        this.setState({ requestPartnerVideo: false, video: false })
      }
      /*
        if(this.partnerPresent && this.state.requestPartnerVideo){
            this.setState({requestPartnerVideo:true,video:true,confirm:false})
        }
        */
      this.partnerPresent = false
    }, 5000)
  }
  closeMyVidePartner = () => {
    this.setState({ video: false, confirm: false })
    this.setState({ confirm: false })
    Common.sendToAll({
      method: "requestVideoStop",
      language: this.state.language,
    })
  }
  chatClose = () => {
    this.setState({ chatEnabled: false })
    this.layoutVideos()
  }
  onParticipantsToggle = () => {
    this.blockBodyClick()
    let userListToggle = this.state.userListToggle
    this.setState({ userListToggle: !userListToggle })
  }
  hangup = () => {
    this.sessionHelper.disconnect()
    this.setState({ connected: false })
    window.location.href = Config.HOME_PAGE_URL
  }
  onLogout = () => {
    // this.sessionHelper.disconnect();
    window.location.href = Config.HOME_PAGE_URL
  }
  onRefresh = () => {
    window.location.reload()
  }
  onSettings = () => {
    this.setState({ settingsOpen: true, deviceInit: false })
    window.initcalled = false
    setTimeout(() => {
      this.settingRef.sessionConnected()
    }, 2000)
  }
  onCloseAlert = (type) => {
    this.blockPopupClose = true
    this.setState({ alert: false })
    if (type == "turnoffmic") {
      if (this.state.audio === true) {
        this.audioControl()
      }
    }
    if (type == "turnonmic") {
      if (this.state.audio === false) {
        this.audioControl()
      }
    }
  }
  showHandoverTime = (data) => {
    this.showAlert(
      "Hand Over Notification",
      "Handover request sent by your Boothmate. You have 30 seconds to accept Handover",
    )
  }
  onUpdateTime = (time) => {
    this.setState({ conferenceTime: time })
  }
  showAlert = (head, message) => {
    this.setState({ alert: head, alertMessage: message })
  }
  onErrorImg = (event) => {
    event.target.src = "https://admin.rafikyconnect.net/logo-2-01.png"
  }
  micLevelIndicator = (stream) => {
    try {
      if (
        Common.getBrowser() === "Safari" ||
        Common.getBrowser() === "Mobile Safari"
      ) {
        var audioContext = getAudioContext()//new window.webkitAudioContext()
      } else {
        var audioContext = getAudioContext()//new AudioContext() //or webkitAudioContext
      }
      if (this.source) {
        this.source.disconnect();
      }
      if (this.analyser) {
        this.analyser.disconnect();
      }
      if (this.scriptNode) {
        this.scriptNode.disconnect();
      }
    
      this.source = audioContext.createMediaStreamSource(stream)
      this.analyser = audioContext.createAnalyser();
      this.scriptNode = audioContext.createScriptProcessor(2048, 1, 1)

      var volume = audioContext.createGain()
      this.source.connect(volume)
      volume.gain.value = 0 //turn off the speakers
      volume.connect(audioContext.destination)

      this.analyser.smoothingTimeConstant = 0.3
      this.analyser.fftSize = 1024
      this.analyser.minDecibels = -90
      this.analyser.maxDecibels = -10

      this.source.connect(this.analyser)
      this.analyser.connect(this.scriptNode)
      this.scriptNode.connect(audioContext.destination)
      this.scriptNode.onaudioprocess = () => {
        if (this.state.audio) {
          var array = new Uint8Array(this.analyser.frequencyBinCount)
          this.analyser.getByteFrequencyData(array)
          var values = 0

          var length = array.length
          for (var i = 0; i < length; i++) {
            values += array[i]
          }
          var average = values / length
          average = average * 4
          if (average > 100) {
            average = 100
          }
          this.setState({ volumeLevel: average })
        }
      }
    } catch (e) { }
  }
  onStageFullscrn = () => {
    this.toggleFullScreen(document.getElementById("middle-video-content"))
    this.layoutVideos()
  }
  toggleFullScreen = (elem) => {
    //   ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
    if (
      (document.fullScreenElement !== undefined &&
        document.fullScreenElement === null) ||
      (document.msFullscreenElement !== undefined &&
        document.msFullscreenElement === null) ||
      (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
      (document.webkitIsFullScreen !== undefined &&
        !document.webkitIsFullScreen)
    ) {
      if (elem.requestFullScreen) {
        elem.requestFullScreen()
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen()
      }
      this.setState({ fullscrn: true })
      //setFullscreen();
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
      this.setState({ fullscrn: false })
    }
  }
  createPoll = () => {
    this.pollRef.loadPollState("create", {})
    this.setState({ pollOpen: true })
  }
  closePoll = () => {
    this.setState({ pollOpen: false })
  }
  onPollReceive = () => {
    this.pollRef.loadPollState("answering", {})
    this.setState({ pollOpen: true })
  }
  onDeletePoll = (poll) => {
    RequestHandler.delete(
      Config.API_URL + "polls/delete",
      { poll_id: poll._id },
      (response) => {
        if (response.status == "success") {
          this.showNotification(
            "Poll deleted successfully",
            UserSession.getSession().userName,
          )
          this.getAllPolls()
        }
      },
    )
  }
  onEditPoll = (poll) => {
    this.setState({ pollOpen: true })
    this.pollRef.loadPollState("edit", poll)
  }
  getPollReport = (poll) => {
    RequestHandler.post(
      Config.API_URL + "polls/getreport",
      { poll_id: poll._id },
      (response) => {
        if (response.status == "success") {
          if (response.data[0].responses.length == 0) {
            this.showAlert("Poll Report", "No reports available for this poll")
          } else {
            this.setState({ pollOpen: true })
            this.pollRef.loadPollState("report", response.data)
          }
        }
      },
    )
  }

  togglePollList = () => {
    this.blockBodyClick()
    let pollToggle = this.state.pollToggle
    this.setState({ pollToggle: !pollToggle })
    this.getAllPolls()
  }
  mobMenuToggle = () => {
    let mobMenuToggle = this.state.mobMenuToggle
    this.setState({ mobMenuToggle: !mobMenuToggle })
  }
  selectFloorStream = () => {
    this.setState({ selectedStream: "Floor" })
    if (this.state.role === "listenerhls") {
      this.playerRef.switchStream("Floor")
      return
    }
  }
  showRtmpLink = () => {
    this.showAlert(
      "External Streaming Info",
      'INFO : Download and install OBS software from <a href="https://obsproject.com/download" target="_blank">https://obsproject.com/download</a> and put the following details on streaming tab to start external streaming. </br> RTMP URL : ' +
      Config.WOWZA_URL +
      " <br/>STREAM KEY : " +
      (this.state.selectedInterpreterInModerator === this.interpretersLabel
        ? "Floor"
        : this.state.selectedInterpreterInModerator),
    )
  }

  goOnAirOffAir = () => {
    let onAir = this.state.onAir
    if (onAir) {
      this.confirmRef.show(
        "Confirm",
        "Are you sure that viewers will exit from the session once go off air?",
        { type: "offAir" },
      )
      this.setState({ confirm: true })
    } else {
      this.confirmRef.show(
        "Confirm",
        "Are you sure that viewers can join this session once go on air?",
        { type: "onAir" },
      )
      this.setState({ confirm: true })
    }
  }
  onOffAirConfirm = () => {
    this.setState({ onAir: false })
    this.roomProperty.onAir = false
    this.saveRoomProperty()
    Common.sendToAll({ method: "roomProperty", property: this.roomProperty })
    this.setState({ confirm: false })
  }
  onOnAirConfirm = () => {
    this.setState({ onAir: true })
    this.roomProperty.onAir = true
    this.saveRoomProperty()
    Common.sendToAll({ method: "roomProperty", property: this.roomProperty })
    this.setState({ confirm: false })
  }
  saveRoomProperty = () => {
    let data = {}
    data.sessionId = this.state.sessionId
    data.property = this.roomProperty
    RequestHandler.post(
      Config.API_URL + "save-room-property",
      data,
      (response) => {
        if (response.status == "success") {
        }
      },
    )
  }
  startOrStopRecording = () => {
    let isRecording = this.state.isRecording
    if (isRecording) {
      this.setState({ isRecording: false })
      this.callStopRecordApi()
    } else {
      this.setState({ isRecording: true })
      this.callStartRecordApi()
    }
  }
  callStartRecordApi = () => {
    // individual
    RequestHandler.post(
      Config.API_URL + "archive/startArchive",
      {
        event_id: this.eventId,
        session_id: Config.SESSION_ID,
        name: "archive",
        outputMode: "individual",
      },
      (response) => {
        this.showNotification("Recording started successfully", "Moderator")
        this.archiveId = response.data.id
      },
    )
  }
  callStopRecordApi = () => {
    RequestHandler.post(
      Config.API_URL + "archive/stopArchive",
      { archive_id: this.archiveId },
      (response) => {
        this.showNotification("Recording stopped successfully", "Moderator")
      },
    )
  }
  showHideVideoShare = () => {
    let uploadVideoPop = this.state.uploadVideoPop
    uploadVideoPop = !uploadVideoPop
    this.setState({ uploadVideoPop: uploadVideoPop })
    this.mediaUpldRef.getFile()
  }
  showHideDocShare = () => {
    let uploadDocPop = this.state.uploadDocPop
    uploadDocPop = !uploadDocPop
    this.setState({ uploadDocPop: uploadDocPop })

    this.docUpldRef.getFile()
  }
  onCloseMediaPop = () => {
    this.setState({ uploadVideoPop: false })
  }
  onCloseDocPop = () => {
    this.setState({ uploadDocPop: false })
  }
  onPlayMediaFromCall = (src) => {
    this.setState({ videoLayout: true })
    let videoPlayer = document.getElementById("videoPlayer")
    videoPlayer.src = src
    videoPlayer.play()
    this.layoutVideos()
    this.currentVideoPlayerUrl = src
    this.currentVideoPlayerTime = 0
    this.currentVideoPlayerState = "play"

    videoPlayer.removeEventListener("play", this.onPlayEventFromCall)
    videoPlayer.removeEventListener("pause", this.onPauseEventFromCall)
    videoPlayer.removeEventListener("seeked", this.onseekEventFromCall)

    videoPlayer.addEventListener("play", this.onPlayEventFromCall, true)
    videoPlayer.addEventListener("pause", this.onPauseEventFromCall, true)
    videoPlayer.addEventListener("seeked", this.onseekEventFromCall, true)
    if (this.state.screen) {
      this.setState({ screen: false, screenLayout: false })
    }
    this.closeDocPlayerFromCall()
  }
  onPlayEventFromCall = () => {
    this.currentVideoPlayerState = "play"
  }
  onPauseEventFromCall = () => {
    this.currentVideoPlayerState = "pause"
  }
  onseekEventFromCall = () => {
    this.currentVideoPlayerTime =
      document.getElementById("videoPlayer").currentTime
  }

  onPlayMedia = (src) => {
    this.setState({ videoLayout: true })
    let videoPlayer = document.getElementById("videoPlayer")
    videoPlayer.src = src
    videoPlayer.play()
    this.layoutVideos()
    this.currentVideoPlayerUrl = src
    this.currentVideoPlayerTime = 0
    this.currentVideoPlayerState = "play"
    Common.sendToAll({
      method: "videoPlayer",
      url: this.currentVideoPlayerUrl,
      time: this.currentVideoPlayerTime,
      state: this.currentVideoPlayerState,
    })

    videoPlayer.removeEventListener("play", this.onPlayEvent)
    videoPlayer.removeEventListener("pause", this.onPauseEvent)
    videoPlayer.removeEventListener("seeked", this.onseekEvent)

    videoPlayer.addEventListener("play", this.onPlayEvent, true)
    videoPlayer.addEventListener("pause", this.onPauseEvent, true)
    videoPlayer.addEventListener("seeked", this.onseekEvent, true)
    if (this.state.screen) {
      this.setState({ screen: false, screenLayout: false })
    }
    this.closeDocPlayerFromCall()
  }
  onLoadDocument = (src) => {
    this.setState({ docLayout: true })
    let docPlayer = document.getElementById("docPlayer")
    docPlayer.src = src
    this.currentDocPlayerUrl = src
    Common.sendToAll({
      method: "docPlayer",
      url: this.currentDocPlayerUrl,
      currentPage: this.currentPage,
      currentStep: this.currentStep,
      totalStep: this.totalStep,
      currentType: this.currentType,
    })
    this.layoutVideos()
    if (this.state.screen) {
      this.setState({ screen: false, screenLayout: false })
    }
    this.closeVideoPlayerFromCall()
  }
  onLoadDocumentFromCall = (src) => {
    this.setState({ docLayout: true })
    let docPlayer = document.getElementById("docPlayer")
    docPlayer.src = src
    this.currentDocPlayerUrl = src
    this.layoutVideos()
    if (this.state.screen) {
      this.setState({ screen: false, screenLayout: false })
    }
    this.closeVideoPlayerFromCall()
  }
  onPlayEvent = () => {
    this.currentVideoPlayerState = "play"
    Common.sendToAll({ method: "videoPlayerPlay" })
  }
  onPauseEvent = () => {
    this.currentVideoPlayerState = "pause"
    Common.sendToAll({ method: "videoPlayerPause" })
  }
  onseekEvent = () => {
    this.currentVideoPlayerTime =
      document.getElementById("videoPlayer").currentTime
    Common.sendToAll({
      method: "videoPlayerSeek",
      time: this.currentVideoPlayerTime,
    })
  }

  closeDocPlayer = () => {
    this.setState({ docLayout: false })
    document.getElementById("docPlayer").removeAttribute("src")
    this.layoutVideos()
    Common.sendToAll({ method: "docPlayerClose" })
    this.currentDocPlayerUrl = false
  }
  closeDocPlayerFromCall = () => {
    this.setState({ docLayout: false })
    document.getElementById("docPlayer").removeAttribute("src")
    this.layoutVideos()
    this.currentDocPlayerUrl = false
  }
  closeVideoPlayer = () => {
    this.setState({ videoLayout: false })
    document.getElementById("videoPlayer").pause()
    document.getElementById("videoPlayer").src = null
    this.layoutVideos()
    Common.sendToAll({ method: "videoPlayerClose" })
    this.currentVideoPlayerUrl = false
  }
  closeVideoPlayerFromCall = () => {
    this.setState({ videoLayout: false })
    document.getElementById("videoPlayer").pause()
    document.getElementById("videoPlayer").src = null
    this.layoutVideos()
    this.currentVideoPlayerUrl = false
  }

  sortParticpants = (data) => {
    // console.log('Initial = '+ JSON.stringify(data))
    var arr = []
    for (var prop in data) {
      if (data.hasOwnProperty(prop)) {
        var obj = {}
        obj[prop] = data[prop]
        obj.tempSortName = data[prop]["userData"]["userName"].toLowerCase()
        arr.push(obj)
      }
    }

    arr.sort(function (a, b) {
      var at = a.tempSortName,
        bt = b.tempSortName
      return at > bt ? 1 : at < bt ? -1 : 0
    })

    var result = {}
    for (var i = 0, l = arr.length; i < l; i++) {
      var obj = arr[i]
      delete obj.tempSortName
      for (let p in obj) {
        result[p] = obj[p]
      }
    }
    //console.log('Final = '+ JSON.stringify(result))
    return result
  }

  onLoadCompleteFrame = (event) => {
    setTimeout((event) => {
      if (
        document
          .getElementById("docPlayer")
          .contentWindow.document.getElementById("playN")
      ) {
        var nextBut = document
          .getElementById("docPlayer")
          .contentWindow.document.getElementById("playN")
        var prevBut = document
          .getElementById("docPlayer")
          .contentWindow.document.getElementById("playP")
        var playBut = document
          .getElementById("docPlayer")
          .contentWindow.document.getElementById("playB")
        var pageBox = document
          .getElementById("docPlayer")
          .contentWindow.document.getElementById("statusCurrent")
        var noteButton = document
          .getElementById("docPlayer")
          .contentWindow.document.getElementById("notesButton")
        var progressContainer = document
          .getElementById("docPlayer")
          .contentWindow.document.getElementById("progressContainer")
        var status = document
          .getElementById("docPlayer")
          .contentWindow.document.getElementById("status")
        var time = document
          .getElementById("docPlayer")
          .contentWindow.document.getElementById("time")
        var statusCurrent = document
          .getElementById("docPlayer")
          .contentWindow.document.getElementById("statusCurrent")
        var resizer = document
          .getElementById("docPlayer")
          .contentWindow.document.getElementById("resizer")

        // resizer.style.pointerEvents = "none";

        statusCurrent.style.pointerEvents = "none"
        time.style.display = "none"

        // status.style.display = 'none';
        progressContainer.style.display = "none"
        noteButton.style.display = "none"
        playBut.style.display = "none"
        nextBut.addEventListener(
          "click",
          (event) => {
            this.onNextOrPrev("next")
          },
          { passive: true },
        )
        prevBut.addEventListener(
          "click",
          (event) => {
            this.onNextOrPrev("previous")
          },
          { passive: true },
        )
        if (
          this.state.role == "moderator" ||
          (this.state.role == "speaker" &&
            this.state.speakerMode &&
            !this.isSigned)
        ) {
        } else {
          nextBut.style.display = "none"
          prevBut.style.display = "none"
        }

        this.prevOrNext(
          this.currentPage,
          this.currentStep,
          this.totalStep,
          this.currentType,
        )
      }
    }, 1000)
  }
  onNextOrPrev = (type) => {
    setTimeout(() => {
      var ifrm = document.getElementById("docPlayer")
      var win = ifrm.contentWindow
      var presentation = win.Presentation
      var currentstatus = presentation.CurrentStatus()
      this.currentPage = currentstatus.slide
      this.currentStep = currentstatus.step
      this.totalStep = presentation.ClickCount()
      this.currentType = type
      Common.sendToAll({
        method: "onNextOrPrevious",
        page: currentstatus.slide,
        step: currentstatus.step,
        totalstep: presentation.ClickCount(),
        type: type,
      })
    }, 100)
  }
  prevOrNext = (page, step, totalstep, type) => {
    var ifrm = document.getElementById("docPlayer")
    var win = ifrm.contentWindow
    var presentation = win.Presentation
    if (totalstep == 0) {
      presentation.GotoSlide(page)
    } else {
      if (type == "next") {
        presentation.JumpToAnim(step, page, true)
      } else {
        presentation.JumpToAnim(step, page, true)
      }
    }
  }

  onCancelConfirm = () => {
    this.setState({ confirm: false })
    Common.sendToAll({
      method: "requestVideoReject",
      language: this.state.language,
    })
  }

  onProceedParterStream = (data) => {
    if (data) {
      if (data.type === "onAir") {
        this.onOnAirConfirm()
      } else if (data.type === "offAir") {
        this.onOffAirConfirm()
      }
    } else {
      this.setState({ video: true, acceptPartnerVideo: true })
      this.setState({ confirm: false })
    }

    /*
    let audio = this.state.audio;
    if(audio){
        this.setState({audio:!audio})
        setTimeout(this.syncMyUserStatus,500);
        this.showAlert('Message','You have started private communication with your partner , so public audio will stop now.')
    }
    */
    Common.sendToAll({
      method: "requestVideoAccept",
      language: this.state.language,
    })
  }
  sendCountDown = (connection, name) => {
    this.currentCoutDownUser = connection
    this.setState({
      countDownConnection: connection,
      countDownSpeakername: name,
      speakerCount: "",
    })
  }
  openCaptionPop = (data, id, viewData, type, enabled) => {
    this.captionId = id
    this.captionEnabled = enabled
    //alert(JSON.stringify(data))
    this.captionUserId = data.userId
    this.capdata = viewData
    if (viewData) {
      this.speakerId = viewData.id
      let splitRole = viewData.role.split("_")
      this.setState({
        captionData: {
          title: { value: "", error: "" },
          name: { value: viewData.name, error: "" },
          role: { value: splitRole[0], error: "" },
          duration: { value: parseInt(splitRole[1]), error: "" },
        },
        captionLogoFile: null,
        captionlogoerr: false,
        captionLogoCurrentUrl: viewData.logo,
        speakerInfoChoice: splitRole[2],
      })
    }
    this.setState({ captionPopUp: type })
  }

  cancelCaptionPop = () => {
    this.setState({ captionPopUp: false })
    this.reset()
  }

  cancelCountDown = () => {
    this.reset()
    this.setState({
      countDownConnection: false,
      speakerCount: "",
      editCountDown: false,
    })
  }
  shareCountDown = () => {
    if (this.state.speakerCount > 0 && this.state.speakerCount) {
      Common.sendToOne(this.state.countDownConnection, {
        method: "SpeakerCountdown",
        value: this.state.speakerCount,
      })
      this.setState({
        countDownConnection: false,
        presentationTimer: this.state.speakerCount * 60,
        editCountDown: false,
      })
    }
  }
  countDownEditPop = () => {
    if (this.state.editCountDown) {
      this.setState({
        countDownConnection: false,
        editCountDown: true,
        speakerCount: "",
      })
    } else {
      this.setState({
        countDownConnection: false,
        editCountDown: true,
        speakerCount: "",
      })
    }
  }
  clearCountDown = () => {
    Common.sendToOne(this.currentCoutDownUser, {
      method: "SpeakerCountdownDelete",
    })
    this.setState({
      countDownConnection: false,
      editCountDown: false,
      presentationTimer: 0,
    })
  }
  shareCountDownUpdate = () => {
    if (this.state.speakerCount > 0 && this.state.speakerCount) {
      let min =
        parseFloat(this.state.speakerCount) +
        parseFloat(this.state.presentationTimer / 60)
      Common.sendToOne(this.currentCoutDownUser, {
        method: "SpeakerCountdownUpdate",
        value: min,
      })
      this.setState({
        countDownConnection: false,
        editCountDown: false,
        presentationTimer:
          this.state.speakerCount * 60 + this.state.presentationTimer,
      })
    }
  }

  onChangeCountInput = (event) => {
    this.setState({ speakerCount: event.target.value })
  }
  handleUploadFile = (event) => {
    //this.upload(event.target.files)
    this.setState({ captionLogoFile: event.target.files })
  }
  sendCaption = (edit) => {
    if (this.capSavetimeout) {
      clearTimeout(this.capSavetimeout)
    }
    document.getElementById("capsave").classList.add("disabled")
    document.getElementById("capedit").classList.add("disabled")
    this.capSavetimeout = setTimeout(() => {
      document.getElementById("capsave").classList.remove("disabled")
      document.getElementById("capedit").classList.remove("disabled")
    }, 3000)
    let captionData = this.state.captionData
    let error = false
    /*
    if(!captionData.title.value){
         captionData.title.error = true;
         error = true;
    }else{
        captionData.title.error = '';
    }
    */
    if (!captionData.name.value) {
      captionData.name.error = true
      error = true
    } else {
      captionData.name.error = ""
    }
    if (!captionData.role.value) {
      captionData.role.error = true
      error = true
    } else {
      captionData.role.error = ""
    }
    if (!captionData.duration.value) {
      captionData.duration.error = true
      error = true
    } else {
      captionData.duration.error = ""
    }
    if (error) {
      this.setState({ captionData: captionData })
      return
    }
    this.setState({ captionData: captionData })

    if (edit && !this.state.captionLogoFile) {
      this.setState({ captionProg: true })
      RequestHandler.post(
        Config.API_URL + "speaker/save_user/" + this.captionUserId,
        {
          name: captionData.name.value,
          role:
            captionData.role.value +
            "_" +
            captionData.duration.value +
            "_" +
            this.state.speakerInfoChoice,
          speaker_id: this.speakerId,
        },
        (response) => {
          //alert(JSON.stringify(this.capdata))
          if (response.status == "success") {
            if (this.captionEnabled) {
              this.showCaptionAfterEdit(this.capdata.logo)
            }
            this.showNotification("Caption edited successfully", "Moderator")
            this.cancelCaptionPop()
          }
        },
      )
    } else {
      if (this.state.captionLogoFile) {
        this.upload(this.state.captionLogoFile, edit)
      } else {
        RequestHandler.post(
          Config.API_URL + "speaker/save_user/" + this.captionUserId,
          {
            name: captionData.name.value,
            role:
              captionData.role.value +
              "_" +
              captionData.duration.value +
              "_" +
              this.state.speakerInfoChoice,
          },
          (response) => {
            //alert(JSON.stringify(response))
            if (response.status == "success") {
              if (this.captionEnabled) {
                this.showCaptionAfterEdit(this.capdata.logo)
              }
              this.showNotification(
                "Caption saved successfully. You can add caption from the participant list.",
                "Moderator",
              )
              this.cancelCaptionPop()
            }
          },
        )
      }
    }
  }
  getMyCaption = (userId) => {
    RequestHandler.post(
      Config.API_URL + "speaker/get_user_details",
      { user_id: userId },
      (response) => {
        let captionSet = false
        if (response.status == "success") {
          if (response.result.length > 0) {
            for (let key in response.result) {
              if (response.result[key].speaker_status === "enabled") {
                captionSet = true
                let selectedCaption = response.result[key]
                this.setState({ myCaption: selectedCaption })
              }
            }
          }
        }
        if (!captionSet) {
          this.setState({ myCaption: {} })
        }
        setTimeout(this.syncMyUserStatus, 1000)
      },
    )
  }
  deleteCaption = (userId, captionId) => {
    /*
    alert(captionId+'//'+userId)
    if(document.getElementById(userId+'speakerinfo')){
        if(document.getElementById(userId+'speakerinfo').classList.contains(captionId)){
            document.getElementById(userId+'speakerinfo').style.display = 'none';
        }
    }
    */
  }
  reset = () => {
    this.setState({
      captionProg: false,
      captionData: {
        title: { value: "", error: "" },
        name: { value: "", error: "" },
        role: { value: "", error: "" },
        duration: { value: 10, error: "" },
      },
      captionLogoFile: null,
      speakerInfoChoice: "1",
      captionlogoerr: false,
    })
  }
  upload = (files, isEdit) => {
    let captionData = this.state.captionData
    this.setState({ videoprogress: 0, showprogress: true })
    var ext = files[0].name.substr(files[0].name.lastIndexOf(".") + 1)
    var filesizeMb = files[0].size / (1024 * 1024)
    if (this.imageExtensions.indexOf(ext) == -1) {
      document.getElementById("upload-file-speaker").value = ""
      this.showNotification(
        "File extension not supported",
        UserSession.getSession().userName,
      )
      return
    }
    if (filesizeMb > 50) {
      document.getElementById("upload-file-speaker").value = ""
      this.showNotification(
        "File size should be less than 50 Mb",
        UserSession.getSession().userName,
      )
      return
    }

    const data = new FormData()
    var fileob = files[0]
    data.append("logo", fileob)
    data.append("name", captionData.name.value)
    data.append(
      "role",
      captionData.role.value +
      "_" +
      captionData.duration.value +
      "_" +
      this.state.speakerInfoChoice,
    )
    if (isEdit) {
      data.append("speaker_id", this.speakerId)
    }
    this.setState({ captionProg: true })
    axios
      .post(Config.API_URL + "speaker/save_user/" + this.captionUserId, data, {
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length",
            )
          if (totalLength !== null) {
            let videoprogress = Math.round(
              (progressEvent.loaded * 100) / totalLength,
            )
            this.setState({ videoprogress: videoprogress })
          }
        },
      })
      .then((response) => {
        document.getElementById("upload-file-speaker").value = ""
        this.setState({ videoprogress: 0 })
        if (response.data.status == "success") {
          //alert(JSON.stringify(response.data))
          if (isEdit) {
            if (this.captionEnabled) {
              // alert(response.data.data.logo)
              this.showCaptionAfterEdit(response.data.data.logo)
            }
            //  this.captionSaveSuccess(response.data.data.logo,response.data.data._id)
            this.showNotification("Caption edited successfully", "Moderator")
            this.cancelCaptionPop()
          } else {
            //this.captionSaveSuccess(response.data.data.logo,response.data.data._id)
            //this.showNotification('Caption published successfully','Moderator')
            this.showNotification(
              "Caption saved successfully. You can add caption from the participant list",
              "Moderator",
            )
            this.cancelCaptionPop()
          }
        } else {
          this.hideProgress()
          this.showNotification(
            "File uploaded failed",
            UserSession.getSession().userName,
          )
          //.reset();
          this.setState({ captionProg: false })
        }
      })
      .catch((err) => {
        alert(err)
        this.showNotification(
          "File size exceeded",
          UserSession.getSession().userName,
        )
        this.hideProgress()
        this.cancelCaptionPop()
      })
  }
  showCaptionAfterEdit(logo) {
    // alert(logo)
    let param = {
      method: "caption",
      id: this.captionId,
      captionData: this.state.captionData,
      logo: logo,
      speakerInfoChoice: this.state.speakerInfoChoice,
      status: "enabled",
    }

    Common.sendToAll(param)
    if (document.getElementById(this.captionId + "1")) {
      document.getElementById(this.captionId + "1").style.display = "none"
      document.getElementById(this.captionId + "2").style.display = "none"
      document.getElementById(this.captionId + "3").style.display = "none"
      document.getElementById(this.captionId + "4").style.display = "none"
      document.getElementById(this.captionId + "5").style.display = "none"
    }
    if (
      document.getElementById(this.captionId + this.state.speakerInfoChoice)
    ) {
      document.getElementById(
        this.captionId + this.state.speakerInfoChoice,
      ).style.display = "flex"
      document.getElementById(
        this.captionId + this.state.speakerInfoChoice + "name",
      ).innerHTML = this.state.captionData.name.value
      document.getElementById(
        this.captionId + this.state.speakerInfoChoice + "role",
      ).innerHTML = this.state.captionData.role.value

      if (logo) {
        document.getElementById(
          this.captionId + this.state.speakerInfoChoice + "captionlogo",
        ).src = logo
        document.getElementById(
          this.captionId + this.state.speakerInfoChoice + "captionlogowrap",
        ).style.display = "block"
      } else {
        document.getElementById(
          this.captionId + this.state.speakerInfoChoice + "captionlogowrap",
        ).style.display = "none"
      }
      if (window.captionTimeout) {
        if (window.captionTimeout[this.captionId]) {
          clearTimeout(window.captionTimeout[this.captionId])
        }
      } else {
        window.captionTimeout = {}
      }
      window.captionTimeout[this.captionId] = setTimeout(
        (capId, captionChoice) => {
          document.getElementById(capId + captionChoice).style.display = "none"
        },
        this.state.captionData.duration.value * 1000,
        this.captionId,
        this.state.speakerInfoChoice,
      )
      this.showNotification("Caption added successfully", "Moderator")
    }
  }
  hideProgress = () => {
    this.setState({ showprogress: false })
  }
  moreIconShowHide = () => {
    let moreIcon = this.state.moreIcon
    moreIcon = !moreIcon
    this.setState({ moreIcon: moreIcon })
  }
  layoutVideos = () => {
    if (this.state.role !== "listenerhls") {
      if (document.getElementById("layout")) {
        if (!this.layout) {
          this.layout = window.initLayoutContainer(
            document.getElementById("layout"),
            {
              maxRatio: 3 / 4, // The narrowest ratio that will be used (default 2x3)
              minRatio: 9 / 16, // The widest ratio that will be used (default 16x9)
              /*
                fixedRatio: 3/4,         // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
                */
              scaleLastRow: true, // If there are less elements on the last row then we can scale them up to take up more space
              alignItems: "center", // Can be 'start', 'center' or 'end'. Determines where to place items when on a row or column that is not full
              bigClass: "OT_big", // The class to add to elements that should be sized bigger
              bigPercentage: 0.8, // The maximum percentage of space the big ones should take up
              bigFixedRatio: false, // fixedRatio for the big ones
              bigScaleLastRow: true, // scale last row for the big elements
              bigAlignItems: "center", // How to align the big items
              smallAlignItems: "center", // How to align the small row or column of items if there is a big one
              maxWidth: Infinity, // The maximum width of the elements
              maxHeight: Infinity, // The maximum height of the elements
              smallMaxWidth: Infinity, // The maximum width of the small elements
              smallMaxHeight: Infinity, // The maximum height of the small elements
              bigMaxWidth: Infinity, // The maximum width of the big elements
              bigMaxHeight: Infinity, // The maximum height of the big elements
              bigMaxRatio: 3 / 2, // The narrowest ratio to use for the big elements (default 2x3)
              bigMinRatio: 9 / 16, // The widest ratio to use for the big elements (default 16x9)
              bigFirst: true, // Whether to place the big one in the top left (true) or bottom right (false).
              // You can also pass 'column' or 'row' to change whether big is first when you are in a row (bottom) or a column (right) layout
              animate: true, // Whether you want to animate the transitions using jQuery (not recommended, use CSS transitions instead)
              window: window, // Lets you pass in your own window object which should be the same window that the element is in
              ignoreClass: "OT_ignore", // Elements with this class will be ignored and not positioned. This lets you do things like picture-in-picture
              onLayout: null, // A function that gets called every time an element is moved or resized, (element, { left, top, width, height }) => {}
            },
          ).layout
        }
        setTimeout(this.onChangeLayout, 500, this.state.currentLayout)
        /*
          this.layout();
          this.setState({numSubs:document.getElementById('layout').childElementCount});
          setTimeout(()=>{
              this.layout();
              this.setState({numSubs:document.getElementById('layout').childElementCount});
          },500);
          
          */
      }
    }
  }
  changeLayout = (layout) => {
    this.setState({ currentLayout: layout })
    this.onChangeLayout(layout)
  }
  makeOtBig = (id) => {
    if (this.state.currentLayout === "vertical") {
      let layoutpar = document.getElementById("layout")
      for (var i = 0; i < layoutpar.children.length; i++) {
        var layoutel = layoutpar.children[i]
        layoutel.classList.remove("OT_big")
        this.currentBigId = ""
      }
      for (var i = 0; i < layoutpar.children.length; i++) {
        var layoutel = layoutpar.children[i]
        if (layoutel.id === id) {
          layoutel.classList.add("OT_big")
          this.currentBigId = layoutel.id
          break
        }
      }
      let captions = document.getElementsByClassName("captionsection")
      let streamIdbig = this.currentBigId.replace("video-", "")
      // console.log('current big id = '+this.currentBigId)
      for (let j = 0; j < captions.length; j++) {
        //console.log('class list '+captions[j].classList)
        if (!captions[j].classList.contains("cap-streamid-" + streamIdbig)) {
          captions[j].classList.add("hide")
        } else {
          captions[j].classList.remove("hide")
        }
      }
      this.layout()
    }
  }
  setConferenceBg = () => {
    if (document.getElementById("confbg") && this.conferenceBg) {
      document.getElementById("confbg").style.backgroundImage =
        "url('" + this.conferenceBg + "')"
      document.getElementById("confbg").style.backgroundPosition = "center"
      document.getElementById("confbg").style.backgroundRepeat = " no-repeat"
      document.getElementById("confbg").style.backgroundSize = "cover"
      document.getElementById("confbg").classList.add("confbg")
    }
    if (document.getElementById("settingsbg") && this.conferenceBg) {
      document.getElementById("settingsbg").style.backgroundImage =
        "url('" + this.conferenceBg + "')"
      document.getElementById("settingsbg").style.backgroundPosition = "center"
      document.getElementById("settingsbg").style.backgroundRepeat =
        " no-repeat"
      document.getElementById("settingsbg").style.backgroundSize = "cover"
      document.getElementById("settingsbg").classList.add("settingsbg")
    }
  }
  onChangeLayout = (layout) => {
    let layoutpar = document.getElementById("layout")
    let count = 0
    for (var i = 0; i < layoutpar.children.length; i++) {
      var layoutel = layoutpar.children[i]
      layoutel.classList.remove("OT_big")
      layoutel.classList.remove("OT_ignore")
      // this.currentBigId = '';
      if (layoutel.style.display !== "none") {
        count++
      }
    }
    let captions = document.getElementsByClassName("captionsection")
    for (var j = 0; j < captions.length; j++) {
      captions[j].classList.remove("hide")
    }

    if (layout === "vertical") {
      let bigIdPresent = false
      for (var i = 0; i < layoutpar.children.length; i++) {
        var layoutel = layoutpar.children[i]
        if (layoutel.id === this.currentBigId) {
          bigIdPresent = layoutel.id
          break
        }
      }
      for (var i = 0; i < layoutpar.children.length; i++) {
        var layoutel = layoutpar.children[i]
        if (layoutel.id !== "publisher-parent") {
          if (bigIdPresent) {
            if (layoutel.id === bigIdPresent) {
              layoutel.classList.add("OT_big")
              this.currentBigId = layoutel.id
              captions = document.getElementsByClassName("captionsection")
              let streamIdbig = this.currentBigId.replace("video-", "")
              for (j = 0; j < captions.length; j++) {
                if (
                  !captions[j].classList.contains("cap-streamid-" + streamIdbig)
                ) {
                  captions[j].classList.add("hide")
                }
              }
              break
            }
          } else {
            layoutel.classList.add("OT_big")
            this.currentBigId = layoutel.id
            captions = document.getElementsByClassName("captionsection")
            let streamIdbig = this.currentBigId.replace("video-", "")
            for (j = 0; j < captions.length; j++) {
              if (
                !captions[j].classList.contains("cap-streamid-" + streamIdbig)
              ) {
                captions[j].classList.add("hide")
              }
            }
            break
          }
        }
      }
    } else if (layout === "2user") {
      for (var i = 0; i < layoutpar.children.length; i++) {
        var layoutel = layoutpar.children[i]
        if (layoutel.id !== "publisher-parent") {
          layoutel.classList.add("OT_big")
        } else {
          layoutel.classList.add("OT_ignore")
          setTimeout(() => {
            let offsetLeft = "5px"
            if (document.getElementsByClassName("OT_big").length > 0) {
              offsetLeft =
                document.getElementsByClassName("OT_big")[0].offsetLeft + 20
            }
            if (this.state.chatEnabled) {
              offsetLeft = offsetLeft + 320
            }
            if (offsetLeft !== "5px") {
              offsetLeft = offsetLeft + "px"
            }
            if (document.getElementsByClassName("OT_ignore").length > 0) {
              document.getElementsByClassName("OT_ignore")[0].style.right =
                offsetLeft
            }
          }, 500)
          captions = document.getElementsByClassName("captionsection")
          for (j = 0; j < captions.length; j++) {
            if (
              captions[j].classList.contains(
                "cap-userid-" + UserSession.getSession().userId,
              )
            ) {
              captions[j].classList.add("hide")
            }
          }
        }
      }
    } else if (layout === "grid") {
      for (var i = 0; i < layoutpar.children.length; i++) {
        var layoutel = layoutpar.children[i]
        if (document.getElementById("layout").childElementCount === 2) {
          layoutel.classList.add("OT_big")
        } else {
          layoutel.classList.remove("OT_big")
        }
      }
    }
    if (count <= 2) {
      if (layout === "grid" || layout === "vertical") {
        this.changeLayout("vertical2")
      } else if (layout === "2user") {
        if (count <= 1) {
          this.changeLayout("vertical2")
        }
      }
    } else {
      if (layout === "vertical2" || layout === "2user") {
        this.changeLayout("grid")
      }
    }
    this.setState({ numSubs: count })
    this.layout()
  }
  participantListScroll = () => {
    //console.log(document.getElementById('participants-list').scrollTop+'//'+document.getElementById('participants-list').scrollHeight)
    /*
    if((document.getElementById('participants-list').scrollHeight - document.getElementById('participants-list').scrollTop) == 300){
        if(!this.scrollupdating){
            
            this.scrollupdating = true;
            let cn = this.state.maxUserCnt;
            console.log('Updating User Limit '+cn)
            this.setState({maxUserCnt : cn+50})
        }
        if(this.scruptimeout){
            clearTimeout(this.scruptimeout)
        }
        this.scruptimeout  = setTimeout(()=>{
            this.scrollupdating = false;
        },2000)
        
    }
    */
    let cn = this.state.maxUserCnt
    this.setState({ maxUserCnt: cn + 30 })
  }

  skipTrans = () => {
    document
      .getElementById(":1.container")
      .contentWindow.document.getElementById(":1.close")
      .click()
  }
  donwloadDocument = () => { }
  toggleSidbar = () => {
    let sidebaropen = this.state.sidebaropen
    if (sidebaropen) {
      this.setState({ sidebaropen: false })
    } else {
      this.setState({ sidebaropen: true })
    }
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    let pptDownlodUrl = ""
    if (this.currentDocPlayerUrl) {
      let fNAme = this.currentDocPlayerUrl.substring(
        this.currentDocPlayerUrl.lastIndexOf("/") + 1,
      )
      fNAme = fNAme.replace(".html", "")
      let fid = fNAme.split("-")[0]
      pptDownlodUrl = "/uploads/" + fid + "/event_files/" + fNAme + ".pptx"
    }
    if (
      this.state.connected &&
      (this.state.onAir === true ||
        (this.state.role !== "listener" && this.state.role !== "listenerhls"))
    ) {
      let addedLanguage = {}
      if (this.moderatorpanel.length > 0) {
        let linsterCount = 0
        let speakerCount = 0
        let interpreterCount = 0
        let signcount = 0
        let streamoutCount = 0
        let spkrct = 0
        let totalpartcount = 0
        return (
          <div
            id="participants_info_div"
            onClick={this.blockBodyClick}
            className={
              this.state.userListToggle
                ? "participants_info align-items-center mod-participants_info arrow-info " +
                (this.moderatorpanel.length > 0 ? "part-moderator-panel" : "")
                : "hide"
            }
          >
            <div className={this.state.notification ? "" : "hide"}>
              <Toast
                closeNotification={this.closeNotification}
                notification={this.state.notification}
              />
            </div>

            <div
              className={
                this.state.countDownConnection ? "fixed-backdrop" : "hide"
              }
            >
              <div className="custom-caption-pop-up">
                <div className="d-flex align-items-center justify-content-between custom-popup-cap-header-blk">
                  <div className="custom-caption-header">
                    Create Speaker Countdown
                  </div>
                </div>
                <div className="caption-holder">
                  <div className="d-flex align-items-center form-grp-caption">
                    <div className="custom-choice-wrap">
                      <span>Countdown</span>
                      <span>:</span>
                    </div>
                    <div className="caption-input-holder">
                      <input
                        value={this.state.speakerCount}
                        onChange={this.onChangeCountInput}
                        type="number"
                        placeholder="in Minutes"
                        className="caption-input"
                      />
                    </div>
                  </div>
                  <div className="cap-btn-blk">
                    <button
                      type="button"
                      className="cap-cancel-btn"
                      onClick={this.cancelCountDown}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="cap-launch-btn"
                      onClick={this.shareCountDown}
                    >
                      Share
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={this.state.captionPopUp ? "fixed-backdrop" : "hide"}
            >
              <div className="custom-caption-pop-up">
                <div className="d-flex align-items-center justify-content-between custom-popup-cap-header-blk">
                  <div className="custom-caption-header">
                    {this.state.captionPopUp === "view"
                      ? "Caption Details"
                      : "Create Caption"}
                  </div>
                </div>
                <div className="caption-holder-parent">
                  <div
                    className={
                      this.state.captionPopUp === "view"
                        ? "nomouseac caption-holder"
                        : "caption-holder"
                    }
                  >
                    <div className="d-flex align-items-center form-grp-caption hide">
                      <div className="custom-choice-wrap">
                        <span>Title</span>
                        <span>:</span>
                      </div>
                      <div className="caption-input-holder">
                        <input
                          onChange={this.onChangeCaptionInput.bind(
                            this,
                            "title",
                          )}
                          value={this.state.captionData.title.value}
                          type="text"
                          placeholder="Title"
                          className={
                            this.state.captionData.title.error
                              ? "caption-input caption-error"
                              : "caption-input"
                          }
                        />
                      </div>
                    </div>

                    <div className="d-flex align-items-center form-grp-caption">
                      <div className="custom-choice-wrap">
                        <span>Name</span>
                        <span>:</span>
                      </div>
                      <div className="caption-input-holder">
                        <input
                          onChange={this.onChangeCaptionInput.bind(
                            this,
                            "name",
                          )}
                          value={this.state.captionData.name.value}
                          type="text"
                          placeholder="Name"
                          className={
                            this.state.captionData.name.error
                              ? "caption-input caption-error"
                              : "caption-input"
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center form-grp-caption">
                      <div className="custom-choice-wrap">
                        <span>Preset</span>
                        <span>:</span>
                      </div>
                      <div className="radio-btn-holder">
                        <label className=" d-flex preset-radiobtn-parent">
                          <input
                            type="radio"
                            name="preset"
                            className="preset-radiobtn"
                            onChange={this.onChangeCaptionChoice.bind(
                              this,
                              "1",
                            )}
                            checked={
                              this.state.speakerInfoChoice === "1"
                                ? true
                                : false
                            }
                          />
                          Choice 1
                        </label>
                        <label className=" d-flex preset-radiobtn-parent">
                          <input
                            type="radio"
                            name="preset"
                            className="preset-radiobtn"
                            onChange={this.onChangeCaptionChoice.bind(
                              this,
                              "2",
                            )}
                            checked={
                              this.state.speakerInfoChoice === "2"
                                ? true
                                : false
                            }
                          />
                          Choice 2
                        </label>
                        <label className=" d-flex preset-radiobtn-parent">
                          <input
                            type="radio"
                            name="preset"
                            className="preset-radiobtn"
                            onChange={this.onChangeCaptionChoice.bind(
                              this,
                              "3",
                            )}
                            checked={
                              this.state.speakerInfoChoice === "3"
                                ? true
                                : false
                            }
                          />
                          Choice 3
                        </label>
                        <label className=" d-flex preset-radiobtn-parent">
                          <input
                            type="radio"
                            name="preset"
                            className="preset-radiobtn"
                            onChange={this.onChangeCaptionChoice.bind(
                              this,
                              "4",
                            )}
                            checked={
                              this.state.speakerInfoChoice === "4"
                                ? true
                                : false
                            }
                          />
                          Choice 4
                        </label>
                        <label className=" d-flex preset-radiobtn-parent">
                          <input
                            type="radio"
                            name="preset"
                            className="preset-radiobtn"
                            onChange={this.onChangeCaptionChoice.bind(
                              this,
                              "5",
                            )}
                            checked={
                              this.state.speakerInfoChoice === "5"
                                ? true
                                : false
                            }
                          />
                          Choice 5
                        </label>
                      </div>
                    </div>
                    <div className="d-flex align-items-center form-grp-caption">
                      <div className="custom-choice-wrap">
                        <span>Role</span>
                        <span>:</span>
                      </div>
                      <div className="caption-input-holder">
                        <input
                          onChange={this.onChangeCaptionInput.bind(
                            this,
                            "role",
                          )}
                          value={this.state.captionData.role.value}
                          type="text"
                          placeholder="Role"
                          className={
                            this.state.captionData.role.error
                              ? "caption-input caption-error"
                              : "caption-input"
                          }
                        />
                      </div>
                    </div>
                    <div className={"d-flex form-grp-caption"}>
                      <div className="custom-choice-wrap">
                        <span>Upload Logo</span>
                        <span>:</span>
                      </div>
                      <div
                        className={
                          this.state.captionlogoerr
                            ? "logo-err caption-logo-holder"
                            : "caption-logo-holder"
                        }
                      >
                        <label
                          className={
                            this.state.captionPopUp === "view"
                              ? "caption-logo-container fulllog"
                              : "caption-logo-container"
                          }
                        >
                          <input
                            id="upload-file-speaker"
                            type="file"
                            className="d-none"
                            onChange={this.handleUploadFile}
                          />
                          <img
                            src={
                              this.state.captionPopUp === "view" ||
                                this.state.captionPopUp === "edit"
                                ? this.state.captionLogoCurrentUrl
                                  ? this.state.captionLogoCurrentUrl
                                  : "/img/logo-demo.png"
                                : "/img/logo-demo.png"
                            }
                          />
                        </label>
                      </div>
                    </div>
                    <div className="suppform">
                      Supported Formats : jpg,jpeg,png
                    </div>
                    <div className="d-flex form-grp-caption">
                      <div className="file-upload-progress">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            style={{ width: this.state.videoprogress + "%" }}
                            role="progressbar"
                            aria-valuenow={this.state.videoprogress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center form-grp-caption">
                      <div className="custom-choice-wrap">
                        <span>Display Time</span>
                        <span>:</span>
                      </div>
                      <div className="caption-input-holder">
                        <div className="cap-dropdown padding0">
                          <select
                            onChange={this.onChangeCaptionInput.bind(
                              this,
                              "duration",
                            )}
                            value={this.state.captionData.duration.value}
                            className={
                              this.state.captionData.duration.error
                                ? "caption-opt caption-error"
                                : "caption-opt"
                            }
                          >
                            <option value="10">10 Sec</option>
                            <option value="30">30 Sec</option>
                            <option value="60">1 Minute</option>
                            <option value="300">5 Minute</option>
                            <option value="900">15 Minute</option>
                            <option value="1800">30 Minute</option>
                            <option value="3600">1Hour Minute</option>
                            <option value="7200">2Hour Minute</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        this.state.captionPopUp === "view"
                          ? "hide"
                          : "cap-btn-blk"
                      }
                    >
                      <button
                        type="button"
                        className="cap-cancel-btn"
                        onClick={this.cancelCaptionPop}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        id="capedit"
                        className={
                          this.state.captionPopUp === "edit"
                            ? "hide"
                            : this.state.captionProg
                              ? "nomouse cap-launch-btn"
                              : "cap-launch-btn"
                        }
                        onClick={this.sendCaption.bind(this, false)}
                      >
                        Create
                      </button>
                      <button
                        type="button"
                        id="capsave"
                        className={
                          this.state.captionPopUp !== "edit"
                            ? "hide"
                            : this.state.captionProg
                              ? "nomouse cap-launch-btn"
                              : "cap-launch-btn"
                        }
                        onClick={this.sendCaption.bind(this, true)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <div className="caption-preset-holder">
                    <div className="preview-caption-header">
                      Preview Caption
                    </div>
                    <div className="caption-preset-wrapper">
                      <img
                        src={"/img/a" + this.state.speakerInfoChoice + ".PNG"}
                        className="object-fill"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    this.state.captionPopUp !== "view"
                      ? "hide"
                      : "cap-btn-blk marginbt15"
                  }
                >
                  <button
                    type="button"
                    className="cap-launch-btn"
                    onClick={this.cancelCaptionPop}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
            <section className={this.state.alert ? "alert-wrap" : "hide"}>
              <Alert
                onCloseAlert={this.onCloseAlert}
                alert={this.state.alert}
                message={this.state.alertMessage}
              />
            </section>
            {Object.keys(this.state.participants).map((key) => {
              if (this.state.participants[key].userData.role !== "moderator") {
                totalpartcount++
              }
            })}
            <ul
              className="nav nav-tabs vc-participants-list-menu"
              id="participants-list-menu"
              role="tablist"
            >
              <li className="nav-item waves-effect waves-light participants-nav">
                <a
                  className="nav-link active"
                  id="participants-tab"
                  data-toggle="tab"
                  href="#participants-list"
                  role="tab"
                  aria-controls="participants-list"
                  aria-selected="true"
                >
                  Participants ({totalpartcount})
                  <div className="vc-participants-list-count">1</div>
                </a>
              </li>
              <li className="nav-item waves-effect waves-light">
                <a
                  className="nav-link"
                  id="observers-tab"
                  data-toggle="tab"
                  href="#observers-tab-list"
                  role="tab"
                  aria-controls="observers-tab-list"
                  aria-selected="false"
                >
                  <div className="vc-participants-list-count">0</div>
                </a>
              </li>
            </ul>
            {this.moderatorpanel.map(() => {
              return (
                <div className="tab-content" id="paticipants-tabContent">
                  <div
                    className={
                      this.moderatorpanel.length > 0
                        ? "part-inner-panel tab-pane fade show active"
                        : "tab-pane fade show active"
                    }
                    id="participants-list"
                    role="tabpanel"
                    aria-labelledby="participants-list"
                  >
                    {Object.keys(this.state.participants).map((key) => {
                      if (
                        this.state.participants[key].userData.role ===
                        "interpreter"
                      ) {
                        interpreterCount++
                      }
                      if (
                        this.state.participants[key].userData.role ===
                        "speaker" &&
                        this.state.participants[key].userData.isSigned === false
                      ) {
                        speakerCount++
                      }
                      if (
                        this.state.participants[key].userData.role ===
                        "listener"
                      ) {
                        linsterCount++
                      }
                      if (
                        this.state.participants[key].userData.role ===
                        "listenerhls"
                      ) {
                        streamoutCount++
                      }
                      if (
                        this.state.participants[key].userData.isSigned === true
                      ) {
                        signcount++
                      }
                    })}
                    <div
                      className={
                        speakerCount > 0
                          ? "mod-userlisting-header speaker"
                          : "hide"
                      }
                    >
                      Speakers
                    </div>
                    {Object.keys(this.state.participants).map((key) => {
                      if (
                        this.state.participants[key].userData.raiseHanded &&
                        !this.state.participants[key].userData.speakerMode &&
                        this.state.participants[key].userData.isSigned === false
                      ) {
                        spkrct++
                        return (
                          <Participant
                            masterSpeaker={this.masterSpeaker}
                            key={key}
                            isSpeakerPanel={this.isSpeakerPanel}
                            deleteCaption={this.deleteCaption}
                            openCaptionPop={this.openCaptionPop}
                            sendCountDown={this.sendCountDown}
                            sendToAllPusher={this.sendToAllPusher}
                            showNotification={this.showNotification}
                            data={this.state.participants[key].userData}
                            connection={this.state.participants[key].connection}
                          />
                        )
                      }
                    })}
                    {Object.keys(this.state.participants).map((key) => {
                      if (
                        this.state.participants[key].userData.speakerMode &&
                        this.state.participants[key].userData.role ===
                        "speaker" &&
                        this.state.participants[key].userData.isSigned === false
                      ) {
                        spkrct++
                        return (
                          <Participant
                            masterSpeaker={this.masterSpeaker}
                            key={key}
                            isSpeakerPanel={this.isSpeakerPanel}
                            deleteCaption={this.deleteCaption}
                            openCaptionPop={this.openCaptionPop}
                            sendCountDown={this.sendCountDown}
                            sendToAllPusher={this.sendToAllPusher}
                            showNotification={this.showNotification}
                            data={this.state.participants[key].userData}
                            connection={this.state.participants[key].connection}
                          />
                        )
                      }
                    })}
                    {Object.keys(this.state.participants).map((key) => {
                      if (
                        spkrct <= this.state.maxUserCnt &&
                        !this.state.participants[key].userData.raiseHanded &&
                        this.state.participants[key].userData.role ===
                        "speaker" &&
                        !this.state.participants[key].userData.speakerMode
                      ) {
                        spkrct++
                        return (
                          <Participant
                            masterSpeaker={this.masterSpeaker}
                            key={key}
                            isSpeakerPanel={this.isSpeakerPanel}
                            deleteCaption={this.deleteCaption}
                            openCaptionPop={this.openCaptionPop}
                            sendCountDown={this.sendCountDown}
                            sendToAllPusher={this.sendToAllPusher}
                            showNotification={this.showNotification}
                            data={this.state.participants[key].userData}
                            connection={this.state.participants[key].connection}
                          />
                        )
                      }
                    })}
                    <div
                      className={
                        interpreterCount > 0
                          ? "mod-userlisting-header interpreter"
                          : "hide"
                      }
                    >
                      Interpreters
                    </div>
                    {Object.keys(this.state.participants).map((key) => {
                      if (
                        this.state.participants[key].userData.role ===
                        "interpreter"
                      ) {
                        spkrct++
                        return (
                          <Participant
                            masterSpeaker={this.masterSpeaker}
                            key={key}
                            isSpeakerPanel={this.isSpeakerPanel}
                            deleteCaption={this.deleteCaption}
                            openCaptionPop={this.openCaptionPop}
                            sendCountDown={this.sendCountDown}
                            sendToAllPusher={this.sendToAllPusher}
                            showNotification={this.showNotification}
                            data={this.state.participants[key].userData}
                            connection={this.state.participants[key].connection}
                          />
                        )
                      }
                    })}
                    <div
                      className={
                        signcount > 0
                          ? "mod-userlisting-header signlang"
                          : "hide"
                      }
                    >
                      Sign Language Interpreters
                    </div>
                    {Object.keys(this.state.participants).map((key) => {
                      if (this.state.participants[key].userData.isSigned) {
                        spkrct++
                        return (
                          <Participant
                            masterSpeaker={this.masterSpeaker}
                            key={key}
                            isSpeakerPanel={this.isSpeakerPanel}
                            openCaptionPop={this.openCaptionPop}
                            sendCountDown={this.sendCountDown}
                            sendToAllPusher={this.sendToAllPusher}
                            showNotification={this.showNotification}
                            data={this.state.participants[key].userData}
                            connection={this.state.participants[key].connection}
                          />
                        )
                      }
                    })}
                    <div
                      className={
                        linsterCount > 0
                          ? "mod-userlisting-header viewer"
                          : "hide"
                      }
                    >
                      Listeners
                    </div>
                    {Object.keys(this.state.participants).map((key) => {
                      if (
                        spkrct <= this.state.maxUserCnt &&
                        this.state.participants[key].userData.role ===
                        "listener"
                      ) {
                        spkrct++
                        return (
                          <Participant
                            masterSpeaker={this.masterSpeaker}
                            key={key}
                            isSpeakerPanel={this.isSpeakerPanel}
                            deleteCaption={this.deleteCaption}
                            openCaptionPop={this.openCaptionPop}
                            sendCountDown={this.sendCountDown}
                            sendToAllPusher={this.sendToAllPusher}
                            showNotification={this.showNotification}
                            data={this.state.participants[key].userData}
                            connection={this.state.participants[key].connection}
                          />
                        )
                      }
                    })}
                    <div
                      className={
                        streamoutCount > 0
                          ? "mod-userlisting-header viewer"
                          : "hide"
                      }
                    >
                      Listeners ( HLS )
                    </div>
                    {Object.keys(this.state.participants).map((key) => {
                      if (
                        this.state.participants[key].userData.role ===
                        "listenerhls"
                      ) {
                        spkrct++
                        return (
                          <Participant
                            masterSpeaker={this.masterSpeaker}
                            key={key}
                            isSpeakerPanel={this.isSpeakerPanel}
                            deleteCaption={this.deleteCaption}
                            openCaptionPop={this.openCaptionPop}
                            sendCountDown={this.sendCountDown}
                            sendToAllPusher={this.sendToAllPusher}
                            showNotification={this.showNotification}
                            data={this.state.participants[key].userData}
                            connection={this.state.participants[key].connection}
                          />
                        )
                      }
                    })}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="observers-tab-list"
                    role="tabpanel"
                    aria-labelledby="observers-tab-list"
                  >
                    <div className="participant-list-tile notactive">
                      <div className="participant-profile-icon-section">
                        <div className="avatar-section">
                          <div className="avatar_initial">VT</div>
                        </div>
                      </div>
                      <div className="participant-name-section">
                        <div className="participant-name">Vignesh</div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
            <div className="load-more-wrap">
              <button
                className={totalpartcount - spkrct > 0 ? "load-more" : "hide"}
                onClick={this.participantListScroll}
              >
                Load More ({totalpartcount - spkrct})
              </button>
            </div>
          </div>
        )
      } else if (this.state.role == "moderator") {
        let linsterCount = 0
        let speakerCount = 0
        let interpreterCount = 0
        let signcount = 0
        let streamoutCount = 0
        let spkrct = 0
        return (
          <section onClick={this.bodyClick.bind(this)}>
            <section className={this.state.confirm ? "alert-wrap" : "hide"}>
              <Confirm
                ref={(confirmRef) => (this.confirmRef = confirmRef)}
                cancel={this.onCancelConfirm}
                proceed={this.onProceedParterStream}
              />
            </section>
            <section className={this.state.uploadVideoPop ? "" : "hide"}>
              <MediaUpload
                onPlayMedia={this.onPlayMedia}
                eventId={this.eventId}
                onClose={this.onCloseMediaPop}
                showNotification={this.showNotification}
                showAlert={this.showAlert}
                ref={(mediaUpldRef) => (this.mediaUpldRef = mediaUpldRef)}
              />
            </section>
            <section className={this.state.uploadDocPop ? "" : "hide"}>
              <DocumentUpload
                onPlayMedia={this.onLoadDocument}
                eventId={this.eventId}
                onClose={this.onCloseDocPop}
                showNotification={this.showNotification}
                showAlert={this.showAlert}
                ref={(docUpldRef) => (this.docUpldRef = docUpldRef)}
              />
            </section>
            <div className={this.state.pollOpen ? "poll-backdrop" : "hide"}>
              <PollComponent
                sendToAllPusher={this.sendToAllPusher}
                getAllPolls={this.getAllPolls}
                role={this.state.role}
                showNotification={this.showNotification}
                onPollReceive={this.onPollReceive}
                closePoll={this.closePoll}
                ref={(pollRef) => (this.pollRef = pollRef)}
                sessionId={this.state.sessionId}
              />
            </div>
            <div
              className={
                this.state.showTechnicalSupport ? "fixed-backdrop" : "hide"
              }
            >
              <div className="custom-caption-pop-up">
                <div className="d-flex align-items-center justify-content-between custom-popup-cap-header-blk">
                  <div className="custom-caption-header">Technical Support</div>
                  <div className="support-close" onClick={this.cloeSupportPop}>
                    X
                  </div>
                </div>
                <TechnicalSupport
                  showNotification={this.showNotification}
                  sessionId={this.state.sessionId}
                  newChat={this.newSupportChat}
                />
              </div>
            </div>
            <div
              className={this.state.captionPopUp ? "fixed-backdrop" : "hide"}
            >
              <div className="custom-caption-pop-up">
                <div className="d-flex align-items-center justify-content-between custom-popup-cap-header-blk">
                  <div className="custom-caption-header">
                    {this.state.captionPopUp === "view"
                      ? "Caption Details"
                      : "Create Caption"}
                  </div>
                </div>
                <div className="caption-holder-parent">
                  <div
                    className={
                      this.state.captionPopUp === "view"
                        ? "nomouseac caption-holder"
                        : "caption-holder"
                    }
                  >
                    <div className="d-flex align-items-center form-grp-caption hide">
                      <div className="custom-choice-wrap">
                        <span>Title</span>
                        <span>:</span>
                      </div>
                      <div className="caption-input-holder">
                        <input
                          onChange={this.onChangeCaptionInput.bind(
                            this,
                            "title",
                          )}
                          value={this.state.captionData.title.value}
                          type="text"
                          placeholder="Title"
                          className={
                            this.state.captionData.title.error
                              ? "caption-input caption-error"
                              : "caption-input"
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center form-grp-caption">
                      <div className="custom-choice-wrap">
                        <span>Name</span>
                        <span>:</span>
                      </div>
                      <div className="caption-input-holder">
                        <input
                          onChange={this.onChangeCaptionInput.bind(
                            this,
                            "name",
                          )}
                          value={this.state.captionData.name.value}
                          type="text"
                          placeholder="Name"
                          className={
                            this.state.captionData.name.error
                              ? "caption-input caption-error"
                              : "caption-input"
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center form-grp-caption">
                      <div className="custom-choice-wrap">
                        <span>Preset</span>
                        <span>:</span>
                      </div>
                      <div className="radio-btn-holder">
                        <label className=" d-flex preset-radiobtn-parent">
                          <input
                            type="radio"
                            name="preset"
                            className="preset-radiobtn"
                            onChange={this.onChangeCaptionChoice.bind(
                              this,
                              "1",
                            )}
                            checked={
                              this.state.speakerInfoChoice === "1"
                                ? true
                                : false
                            }
                          />
                          Choice 1{" "}
                        </label>
                        <label className=" d-flex preset-radiobtn-parent">
                          <input
                            type="radio"
                            name="preset"
                            className="preset-radiobtn"
                            onChange={this.onChangeCaptionChoice.bind(
                              this,
                              "2",
                            )}
                            checked={
                              this.state.speakerInfoChoice === "2"
                                ? true
                                : false
                            }
                          />
                          Choice 2{" "}
                        </label>
                        <label className=" d-flex preset-radiobtn-parent">
                          <input
                            type="radio"
                            name="preset"
                            className="preset-radiobtn"
                            onChange={this.onChangeCaptionChoice.bind(
                              this,
                              "3",
                            )}
                            checked={
                              this.state.speakerInfoChoice === "3"
                                ? true
                                : false
                            }
                          />
                          Choice 3{" "}
                        </label>
                        <label className=" d-flex preset-radiobtn-parent">
                          <input
                            type="radio"
                            name="preset"
                            className="preset-radiobtn"
                            onChange={this.onChangeCaptionChoice.bind(
                              this,
                              "4",
                            )}
                            checked={
                              this.state.speakerInfoChoice === "4"
                                ? true
                                : false
                            }
                          />
                          Choice 4{" "}
                        </label>
                        <label className=" d-flex preset-radiobtn-parent">
                          <input
                            type="radio"
                            name="preset"
                            className="preset-radiobtn"
                            onChange={this.onChangeCaptionChoice.bind(
                              this,
                              "5",
                            )}
                            checked={
                              this.state.speakerInfoChoice === "5"
                                ? true
                                : false
                            }
                          />
                          Choice 5{" "}
                        </label>
                      </div>
                    </div>
                    <div className="d-flex align-items-center form-grp-caption">
                      <div className="custom-choice-wrap">
                        <span>Role</span>
                        <span>:</span>
                      </div>
                      <div className="caption-input-holder">
                        <input
                          onChange={this.onChangeCaptionInput.bind(
                            this,
                            "role",
                          )}
                          value={this.state.captionData.role.value}
                          type="text"
                          placeholder="Role"
                          className={
                            this.state.captionData.role.error
                              ? "caption-input caption-error"
                              : "caption-input"
                          }
                        />
                      </div>
                    </div>
                    <div className={"d-flex form-grp-caption"}>
                      <div className="custom-choice-wrap">
                        <span>Upload Logo</span>
                        <span>:</span>
                      </div>
                      <div
                        className={
                          this.state.captionlogoerr
                            ? "logo-err caption-logo-holder"
                            : "caption-logo-holder"
                        }
                      >
                        <label
                          className={
                            this.state.captionPopUp === "view"
                              ? "caption-logo-container fulllog"
                              : "caption-logo-container"
                          }
                        >
                          <input
                            id="upload-file-speaker"
                            type="file"
                            className="d-none"
                            onChange={this.handleUploadFile}
                          />
                          <img
                            src={
                              this.state.captionPopUp === "view" ||
                                this.state.captionPopUp === "edit"
                                ? this.state.captionLogoCurrentUrl
                                  ? this.state.captionLogoCurrentUrl
                                  : "/img/logo-demo.png"
                                : "/img/logo-demo.png"
                            }
                          />
                        </label>
                      </div>
                    </div>
                    <div className="suppform">
                      Supported Formats : jpg,jpeg,png
                    </div>
                    <div className="d-flex form-grp-caption">
                      <div className="file-upload-progress">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            style={{ width: this.state.videoprogress + "%" }}
                            role="progressbar"
                            aria-valuenow={this.state.videoprogress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center form-grp-caption">
                      <div className="custom-choice-wrap">
                        <span>Display Time</span>
                        <span>:</span>
                      </div>
                      <div className="caption-input-holder">
                        <div className="cap-dropdown padding0">
                          <select
                            onChange={this.onChangeCaptionInput.bind(
                              this,
                              "duration",
                            )}
                            value={this.state.captionData.duration.value}
                            className={
                              this.state.captionData.duration.error
                                ? "caption-opt caption-error"
                                : "caption-opt"
                            }
                          >
                            <option value="10">10 Sec</option>
                            <option value="30">30 Sec</option>
                            <option value="60">1 Minute</option>
                            <option value="300">5 Minute</option>
                            <option value="900">15 Minute</option>
                            <option value="1800">30 Minute</option>
                            <option value="3600">1Hour Minute</option>
                            <option value="7200">2Hour Minute</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        this.state.captionPopUp === "view"
                          ? "hide"
                          : "cap-btn-blk"
                      }
                    >
                      <button
                        type="button"
                        className="cap-cancel-btn"
                        onClick={this.cancelCaptionPop}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        id="capedit"
                        className={
                          this.state.captionPopUp === "edit"
                            ? "hide"
                            : this.state.captionProg
                              ? "nomouse cap-launch-btn"
                              : "cap-launch-btn"
                        }
                        onClick={this.sendCaption.bind(this, false)}
                      >
                        Create
                      </button>
                      <button
                        type="button"
                        id="capsave"
                        className={
                          this.state.captionPopUp !== "edit"
                            ? "hide"
                            : this.state.captionProg
                              ? "nomouse cap-launch-btn"
                              : "cap-launch-btn"
                        }
                        onClick={this.sendCaption.bind(this, true)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <div className="caption-preset-holder">
                    <div className="preview-caption-header">
                      Preview Caption
                    </div>
                    <div className="caption-preset-wrapper">
                      <img
                        src={"/img/a" + this.state.speakerInfoChoice + ".PNG"}
                        className="object-fill"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={
                    this.state.captionPopUp !== "view"
                      ? "hide"
                      : "cap-btn-blk marginbt15"
                  }
                >
                  <button
                    type="button"
                    className="cap-launch-btn"
                    onClick={this.cancelCaptionPop}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
            <div
              className={this.state.editCountDown ? "fixed-backdrop" : "hide"}
            >
              <div className="custom-caption-pop-up">
                <div className="d-flex align-items-center justify-content-between custom-popup-cap-header-blk">
                  <div className="custom-caption-header">
                    Edit Speaker Countdown
                  </div>
                </div>
                <div className="caption-holder">
                  <div className="d-flex align-items-center form-grp-caption">
                    <div className="custom-choice-wrap">
                      <span>Extra Time </span>
                      <span> : </span>
                    </div>
                    <div className="caption-input-holder">
                      <input
                        value={this.state.speakerCount}
                        onChange={this.onChangeCountInput}
                        type="number"
                        placeholder="Eg : Add 5 more minutes to existing time"
                        className="caption-input"
                      />
                    </div>
                  </div>
                  <div className="cap-btn-blk">
                    <button
                      type="button"
                      className="cap-cancel-btn"
                      onClick={this.cancelCountDown}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="cap-launch-btn"
                      onClick={this.shareCountDownUpdate}
                    >
                      Update
                    </button>
                    &nbsp;{" "}
                    <button
                      type="button"
                      className="cap-launch-btn"
                      onClick={this.clearCountDown}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.countDownConnection ? "fixed-backdrop" : "hide"
              }
            >
              <div className="custom-caption-pop-up">
                <div className="d-flex align-items-center justify-content-between custom-popup-cap-header-blk">
                  <div className="custom-caption-header">
                    Create Speaker Countdown
                  </div>
                </div>
                <div className="caption-holder">
                  <div className="d-flex align-items-center form-grp-caption">
                    <div className="custom-choice-wrap">
                      <span>Countdown</span>
                      <span>:</span>
                    </div>
                    <div className="caption-input-holder">
                      <input
                        value={this.state.speakerCount}
                        onChange={this.onChangeCountInput}
                        type="number"
                        placeholder="in Minutes"
                        className="caption-input"
                      />
                    </div>
                  </div>
                  <div className="cap-btn-blk">
                    <button
                      type="button"
                      className="cap-cancel-btn"
                      onClick={this.cancelCountDown}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="cap-launch-btn"
                      onClick={this.shareCountDown}
                    >
                      Share
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="topnav_blk left-conference-blk moderator-view-page">
              <div className="d-flex justify-content-between vdo-top-part">
                <div className="d-flex align-items-center fixed-top-left">
                  <div className="logoo" onClick={this.onLogoClick}>
                    <img
                      src={this.state.logo}
                      onError={this.onErrorImg.bind(this)}
                    />
                  </div>
                  <span className="username">{this.state.logoText}</span>
                </div>
                <div className="d-flex align-items-center top-menu-wrapper">
                  <div className="tot-participants float-part-count d-flex align-items-center">
                    <i className="fa fa-user"></i>
                    <span>{Object.keys(this.state.participants).length}</span>
                  </div>
                  <div className="speaker-lang-blk">
                    <div className="interpreter-section arrow-up">
                      <div
                        className="d-flex align-items-center lang-container interpreter-arrow-down"
                        onClick={this.onToggleInterpreterListInModerator}
                      >
                        <div className="flag-avatar">
                          <img src="/img/interpreter.svg" />
                        </div>
                        <div className="flag-lang notranslate">
                          {this.state.selectedInterpreterInModerator}
                        </div>
                      </div>
                      <div
                        className={
                          this.state.moderatorInterpreterlistOpen
                            ? "interpreter-popover"
                            : "hide"
                        }
                      >
                        <div className="interpret-wrapper">
                          {" "}
                          {Object.keys(this.state.participants).map(
                            (key, index) => {
                              if (
                                this.state.participants[key].userData.role ===
                                "interpreter" &&
                                this.state.participants[key].userData.audio &&
                                !addedLanguage.hasOwnProperty(
                                  this.state.participants[key].userData
                                    .language,
                                )
                              ) {
                                addedLanguage[
                                  this.state.participants[key].userData.language
                                ] = "true"
                                return (
                                  <div
                                    key={key}
                                    onClick={this.onChangeSelectedInterpreterInModerator.bind(
                                      this,
                                      this.state.participants[key].userData
                                        .language,
                                      this.state.participants[key].connection
                                        .id,
                                    )}
                                    className="d-flex align-items-center interpreter-container pointer"
                                  >
                                    <div
                                      className={
                                        this.state.participants[key].userData
                                          .audio
                                          ? "active-blink"
                                          : "absolute"
                                      }
                                    ></div>
                                    <div className="flag-lang notranslate">
                                      {" "}
                                      {
                                        this.state.participants[key].userData
                                          .language
                                      }
                                    </div>
                                  </div>
                                )
                              }
                            },
                          )}{" "}
                          {Object.keys(this.state.participants).map(
                            (key, i) => {
                              if (
                                this.state.participants[key].userData.role ===
                                "interpreter" &&
                                !this.state.participants[key].userData.audio &&
                                !addedLanguage.hasOwnProperty(
                                  this.state.participants[key].userData
                                    .language,
                                )
                              ) {
                                addedLanguage[
                                  this.state.participants[key].userData.language
                                ] = "true"
                                return (
                                  <div
                                    key={i}
                                    onClick={this.onChangeSelectedInterpreterInModerator.bind(
                                      this,
                                      this.state.participants[key].userData
                                        .language,
                                      this.state.participants[key].connection
                                        .id,
                                    )}
                                    className="d-flex align-items-center interpreter-container pointer"
                                  >
                                    <div
                                      className={
                                        this.state.participants[key].userData
                                          .audio
                                          ? "active-blink"
                                          : "absolute"
                                      }
                                    ></div>
                                    <div className="flag-lang notranslate">
                                      {" "}
                                      {
                                        this.state.participants[key].userData
                                          .language
                                      }
                                    </div>
                                  </div>
                                )
                              }
                            },
                          )}{" "}
                          {this.state.avalilableLanguages.map((lang, i) => {
                            if (!addedLanguage.hasOwnProperty(lang)) {
                              addedLanguage[lang] = "true"
                              return (
                                <div
                                  key={i}
                                  onClick={this.onChangeSelectedInterpreterInModeratorBefore.bind(
                                    this,
                                    lang,
                                    1,
                                  )}
                                  className="d-flex align-items-center interpreter-container pointer"
                                >
                                  <div className="absolute"></div>
                                  <div className="flag-lang notranslate">
                                    {" "}
                                    {lang}
                                  </div>
                                </div>
                              )
                            }
                          })}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="voice-controller">
                      <div className="voice-slidecontainer top-volume">
                        <input
                          type="range"
                          min="0"
                          max="100"
                          onChange={this.onInterpreterVolumeChangeInModerator.bind(
                            this,
                          )}
                          value={this.state.InterpreterVolumeInModerator}
                          className={
                            this.state.selectedInterpreterInModerator ===
                              this.interpretersLabel
                              ? "voice-slider inactive"
                              : "voice-slider pointer"
                          }
                        />
                        <div className="custom-menu-tooltip">
                          {this.state.InterpreterVolumeInModerator}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="speaker-lang-blk">
                    <div className="interpreter-section speaker-floor-blk">
                      <div className="d-flex align-items-center lang-container">
                        <div className="d-flex align-items-center justify-content-center floor-avatar">
                          <i className="fa fa-user"></i>
                        </div>
                        <div className="floor-caption">
                          {this.state.floorTitle}
                        </div>
                      </div>
                    </div>
                    <div className="voice-controller">
                      <div className="voice-slidecontainer top-volume">
                        <input
                          type="range"
                          min="0"
                          max="100"
                          onChange={this.onSpeakerVolumeChangeInModerator.bind(
                            this,
                          )}
                          value={this.state.speakerVolumeInModerator}
                          className="voice-slider pointer"
                        />
                        <div className="custom-menu-tooltip">
                          {this.state.speakerVolumeInModerator}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vdo-top-right-part">
                  <a className="bandwidth-section trouble">
                    <div
                      className="notranslate"
                      id="google_translate_element"
                    ></div>
                    <button
                      className="skiptr notranslate"
                      onClick={this.skipTrans}
                    >
                      Skip Translation
                    </button>
                  </a>
                  <div
                    onClick={this.showTechnicalSupport}
                    className="toggle-menu ml-3 help-butn"
                    data-toggle="offcanvas"
                  >
                    <img src="/img/support.svg" />
                    <div className="custom-menu-tooltip">Technical Support</div>
                  </div>
                  <div
                    onClick={this.toggleSidbar}
                    className="toggle-menu ml-3 mobile-hide"
                    data-toggle="offcanvas"
                  >
                    <img src="/img/menu-icon.svg" />
                  </div>
                  <div
                    onClick={this.mobMenuToggle}
                    className="toggle-menu ml-3 desktop-hide"
                    data-toggle="offcanvas"
                  >
                    <img src="/img/menu-icon.svg" />
                  </div>
                </div>
              </div>

              <div
                className={
                  this.state.mobMenuToggle ? "sidenav-blk open" : "hide"
                }
              >
                <div className="d-flex align-items-center justify-content-end sidebar-top-part">
                  <span className="close" onClick={this.mobMenuToggle}>
                    ×
                  </span>
                </div>
                <ul className="sidenav-menu-wrap">
                  <li>
                    <span>
                      Participants Connected :
                      <span className="c-time">
                        {Object.keys(this.state.participants).length}
                      </span>{" "}
                    </span>
                  </li>
                  <li>
                    <span>
                      Time :
                      <span className="c-time">
                        <ConferenceTime
                          ref={(timeRefMob) => (this.timeRefMob = timeRefMob)}
                          onUpdateTime={this.onUpdateTime}
                        />
                      </span>
                    </span>
                  </li>
                  <li>
                    <span
                      onClick={this.onToggleInterpreterListInModerator}
                      className="interpreter-drop"
                    >
                      Interpreting{" "}
                      <span
                        className={
                          this.state.moderatorInterpreterlistOpen
                            ? "arrow-open"
                            : "arrow-close"
                        }
                      ></span>
                    </span>
                    {(addedLanguage = [])}
                    <ul
                      className={
                        this.state.moderatorInterpreterlistOpen
                          ? "Interpreters-lang"
                          : "hide"
                      }
                    >
                      {Object.keys(this.state.participants).map(
                        (key, index) => {
                          if (
                            this.state.participants[key].userData.role ===
                            "interpreter" &&
                            this.state.participants[key].userData.audio &&
                            !addedLanguage.hasOwnProperty(
                              this.state.participants[key].userData.language,
                            )
                          ) {
                            addedLanguage[
                              this.state.participants[key].userData.language
                            ] = "true"
                            return (
                              <li
                                key={key}
                                onClick={this.onChangeSelectedInterpreterInModerator.bind(
                                  this,
                                  this.state.participants[key].userData
                                    .language,
                                  this.state.participants[key].connection.id,
                                )}
                              >
                                <div
                                  className={
                                    this.state.participants[key].userData.audio
                                      ? "active-blink blinkmob"
                                      : "absolute"
                                  }
                                ></div>
                                <span
                                  className={
                                    this.state.participants[key].userData
                                      .language ===
                                      this.state.selectedInterpreterInModerator
                                      ? "menulst-active"
                                      : ""
                                  }
                                >
                                  {
                                    this.state.participants[key].userData
                                      .language
                                  }
                                </span>
                              </li>
                            )
                          }
                        },
                      )}
                      {Object.keys(this.state.participants).map((key) => {
                        if (
                          this.state.participants[key].userData.role ===
                          "interpreter" &&
                          !this.state.participants[key].userData.audio &&
                          !addedLanguage.hasOwnProperty(
                            this.state.participants[key].userData.language,
                          )
                        ) {
                          addedLanguage[
                            this.state.participants[key].userData.language
                          ] = "true"
                          return (
                            <li
                              key={key}
                              onClick={this.onChangeSelectedInterpreterInModerator.bind(
                                this,
                                this.state.participants[key].userData.language,
                                this.state.participants[key].connection.id,
                              )}
                            >
                              <div
                                className={
                                  this.state.participants[key].userData.audio
                                    ? "active-blink blinkmob"
                                    : "absolute"
                                }
                              ></div>
                              <span
                                className={
                                  this.state.participants[key].userData
                                    .language ===
                                    this.state.selectedInterpreterInModerator
                                    ? "menulst-active"
                                    : ""
                                }
                              >
                                {this.state.participants[key].userData.language}
                              </span>
                            </li>
                          )
                        }
                      })}
                    </ul>
                  </li>
                  <li>
                    <span>
                      Interpreter Audio <span className="arrow-open"></span>
                    </span>
                    <div className="mobile-voice-controller">
                      <div className="voice-slidecontainer">
                        <input
                          type="range"
                          min="0"
                          max="100"
                          onChange={this.onInterpreterVolumeChangeInModerator.bind(
                            this,
                          )}
                          value={this.state.InterpreterVolumeInModerator}
                          className={
                            this.state.selectedInterpreterInModerator ===
                              "Select Interpreter"
                              ? "voice-slider nomouse"
                              : "voice-slider pointer"
                          }
                        />
                        <div className="custom-menu-tooltip">
                          {this.state.InterpreterVolumeInModerator}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="hide">
                    <span>
                      Floor <span className="arrow-open"></span>
                    </span>
                  </li>
                  <li>
                    <span>
                      Floor Audio <span className="arrow-open"></span>
                    </span>
                    <div className="mobile-voice-controller">
                      <div className="voice-slidecontainer">
                        <input
                          type="range"
                          min="0"
                          max="100"
                          onChange={this.onSpeakerVolumeChangeInModerator.bind(
                            this,
                          )}
                          value={this.state.speakerVolumeInModerator}
                          className="voice-slider pointer"
                        />
                        <div className="custom-menu-tooltip">
                          {this.state.speakerVolumeInModerator}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a
                      href={
                        "/precall-test/index.html?id=" +
                        Config.SESSION_ID +
                        "&token=" +
                        Config.TOKEN_PUBLISHER
                      }
                      target="_blank"
                    >
                      <span>Trouble with connection ?</span>
                    </a>
                  </li>
                  <li>
                    <a onClick={this.startMySpeedTest} target="_blank">
                      <span>Speed Test</span>
                    </a>
                  </li>
                  <li onClick={this.onSettings}>
                    <span>Media Settings</span>
                  </li>
                  <li onClick={this.onRefresh}>
                    <span>Refresh</span>
                  </li>
                  <li onClick={this.onLogout}>
                    <span>Logout</span>
                  </li>
                </ul>
              </div>
              <div className="meeting-display-section">
                <div
                  className={this.state.sidebaropen ? "sidebar open" : "hide"}
                >
                  <ul>
                    <li>
                      <a
                        href={
                          "/precall-test/index.html?id=" +
                          Config.SESSION_ID +
                          "&token=" +
                          Config.TOKEN_PUBLISHER
                        }
                        target=" _blank"
                        className="nav-link"
                      >
                        <span className="icon">?</span>
                        <span className="link">Run Test</span>
                      </a>
                      <a className="nav-link" onClick={this.togglePollList}>
                        <span
                          className={this.state.pollToggle ? "hide" : "icon"}
                        >
                          <img src="/img/poll-icon.svg" />
                        </span>
                        <span
                          className={this.state.pollToggle ? "hide" : "link"}
                        >
                          Poll{" "}
                        </span>
                        <div
                          className={
                            this.state.pollToggle
                              ? "poll-dropdown-wrapper right0"
                              : "hide"
                          }
                        >
                          <div className="d-flex flex-column">
                            <div className="poll-display-wrapper">
                              <div
                                className={
                                  this.state.polls.length === 0
                                    ? "no-polls"
                                    : "hide"
                                }
                              >
                                No polls yet{" "}
                              </div>{" "}
                              {this.state.polls.map((value, index) => {
                                return (
                                  <div
                                    key={value.title}
                                    className="d-flex poll-display-container"
                                  >
                                    <div
                                      className="poll-display-title"
                                      title={value.title}
                                    >
                                      {value.title.length > 20
                                        ? value.title.substr(0, 20) + "...."
                                        : value.title}
                                    </div>
                                    <div className="d-flex align-items-center poll-btn-acn">
                                      <div
                                        className="poll-tool"
                                        onClick={this.getPollReport.bind(
                                          this,
                                          value,
                                        )}
                                      >
                                        <i className="fa fa-eye"></i>
                                      </div>
                                      <div
                                        className="poll-tool"
                                        onClick={this.onDeletePoll.bind(
                                          this,
                                          value,
                                        )}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </div>
                                      <div
                                        className="poll-edit-icon"
                                        onClick={this.onEditPoll.bind(
                                          this,
                                          value,
                                        )}
                                      >
                                        <img src="/img/edit-poll.svg" />
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                            <div
                              className="create-poll-session"
                              onClick={this.createPoll}
                            >
                              Create Poll
                            </div>
                          </div>
                        </div>
                      </a>
                      <a
                        onClick={this.startMySpeedTest}
                        className={
                          this.state.speedOfMe ? "nav-link mobile-hide" : "hide"
                        }
                      >
                        <span className="icon">
                          <img src="/img/bandwidth.svg" />
                        </span>
                        <span className="link">Speed Test</span>
                      </a>
                      <a className="nav-link" onClick={this.onRefresh}>
                        <span className="icon">
                          <img src="/img/refresh.svg" />
                        </span>
                        <span className="link">Refresh Page</span>
                      </a>
                      <a className="nav-link" onClick={this.onSettings}>
                        <span className="icon">
                          <img src="/img/settings.svg" />
                        </span>
                        <span className="link">Audio / Video Settings</span>
                      </a>

                      <a className="nav-link" onClick={this.onLogout}>
                        <span className="icon">
                          <img src="/img/logout.svg" />
                        </span>
                        <span className="link">Logout</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  id="participants_info_div"
                  onClick={this.blockBodyClick}
                  className={
                    this.state.userListToggle
                      ? "participants_info align-items-center mod-participants_info arrow-info " +
                      (this.moderatorpanel.length > 0
                        ? "part-moderator-panel"
                        : "")
                      : "hide"
                  }
                >
                  {" "}
                  <ul
                    className="nav nav-tabs vc-participants-list-menu"
                    id="participants-list-menu"
                    role="tablist"
                  >
                    <li className="nav-item waves-effect waves-light participants-nav">
                      <a
                        className="nav-link active"
                        id="participants-tab"
                        data-toggle="tab"
                        href="#participants-list"
                        role="tab"
                        aria-controls="participants-list"
                        aria-selected="true"
                      >
                        {" "}
                        Participants{" "}
                        <div className="vc-participants-list-count">1</div>
                      </a>
                    </li>
                    <li className="nav-item waves-effect waves-light">
                      <a
                        className="nav-link"
                        id="observers-tab"
                        data-toggle="tab"
                        href="#observers-tab-list"
                        role="tab"
                        aria-controls="observers-tab-list"
                        aria-selected="false"
                      >
                        <div className="vc-participants-list-count">0</div>
                      </a>
                    </li>
                  </ul>{" "}
                  {this.moderatorpanel.map(() => {
                    return (
                      <div className="tab-content" id="paticipants-tabContent">
                        <div
                          className={
                            this.moderatorpanel.length > 0
                              ? "part-inner-panel tab-pane fade show active"
                              : "tab-pane fade show active"
                          }
                          id="participants-list"
                          role="tabpanel"
                          aria-labelledby="participants-list"
                        >
                          {" "}
                          {Object.keys(this.state.participants).map(
                            (key, index) => {
                              if (
                                this.state.participants[key].userData.role ===
                                "interpreter"
                              ) {
                                interpreterCount++
                              }
                              if (
                                this.state.participants[key].userData.role ===
                                "speaker" &&
                                this.state.participants[key].userData
                                  .isSigned === false
                              ) {
                                speakerCount++
                              }
                              if (
                                this.state.participants[key].userData.role ===
                                "listener"
                              ) {
                                linsterCount++
                              }
                              if (
                                this.state.participants[key].userData.role ===
                                "listenerhls"
                              ) {
                                streamoutCount++
                              }
                              if (
                                this.state.participants[key].userData
                                  .isSigned === true
                              ) {
                                signcount++
                              }
                            },
                          )}{" "}
                          <div
                            className={
                              speakerCount > 0
                                ? "mod-userlisting-header speaker"
                                : "hide"
                            }
                          >
                            Speakers
                          </div>{" "}
                          {Object.keys(this.state.participants).map(
                            (key, index) => {
                              if (
                                this.state.participants[key].userData
                                  .raiseHanded &&
                                !this.state.participants[key].userData
                                  .speakerMode &&
                                this.state.participants[key].userData
                                  .isSigned === false
                              ) {
                                return (
                                  <Participant
                                    masterSpeaker={this.masterSpeaker}
                                    key={key}
                                    isSpeakerPanel={this.isSpeakerPanel}
                                    deleteCaption={this.deleteCaption}
                                    openCaptionPop={this.openCaptionPop}
                                    sendCountDown={this.sendCountDown}
                                    sendToAllPusher={this.sendToAllPusher}
                                    showNotification={this.showNotification}
                                    data={this.state.participants[key].userData}
                                    connection={
                                      this.state.participants[key].connection
                                    }
                                  />
                                )
                              }
                            },
                          )}{" "}
                          {Object.keys(this.state.participants).map(
                            (key, index) => {
                              if (
                                this.state.participants[key].userData
                                  .speakerMode &&
                                this.state.participants[key].userData.role ===
                                "speaker" &&
                                this.state.participants[key].userData
                                  .isSigned === false
                              ) {
                                return (
                                  <Participant
                                    masterSpeaker={this.masterSpeaker}
                                    key={key}
                                    isSpeakerPanel={this.isSpeakerPanel}
                                    deleteCaption={this.deleteCaption}
                                    openCaptionPop={this.openCaptionPop}
                                    sendCountDown={this.sendCountDown}
                                    sendToAllPusher={this.sendToAllPusher}
                                    showNotification={this.showNotification}
                                    data={this.state.participants[key].userData}
                                    connection={
                                      this.state.participants[key].connection
                                    }
                                  />
                                )
                              }
                            },
                          )}{" "}
                          {Object.keys(this.state.participants).map(
                            (key, index) => {
                              if (
                                spkrct <= this.state.maxUserCnt &&
                                !this.state.participants[key].userData
                                  .raiseHanded &&
                                this.state.participants[key].userData.role ===
                                "speaker" &&
                                !this.state.participants[key].userData
                                  .speakerMode
                              ) {
                                spkrct++
                                return (
                                  <Participant
                                    key={key}
                                    isSpeakerPanel={this.isSpeakerPanel}
                                    deleteCaption={this.deleteCaption}
                                    openCaptionPop={this.openCaptionPop}
                                    sendCountDown={this.sendCountDown}
                                    sendToAllPusher={this.sendToAllPusher}
                                    showNotification={this.showNotification}
                                    data={this.state.participants[key].userData}
                                    connection={
                                      this.state.participants[key].connection
                                    }
                                  />
                                )
                              }
                            },
                          )}{" "}
                          <div
                            className={
                              interpreterCount > 0
                                ? "mod-userlisting-header interpreter"
                                : "hide"
                            }
                          >
                            Interpreters
                          </div>{" "}
                          {Object.keys(this.state.participants).map(
                            (key, index) => {
                              if (
                                this.state.participants[key].userData.role ===
                                "interpreter"
                              ) {
                                return (
                                  <Participant
                                    masterSpeaker={this.masterSpeaker}
                                    key={key}
                                    isSpeakerPanel={this.isSpeakerPanel}
                                    deleteCaption={this.deleteCaption}
                                    openCaptionPop={this.openCaptionPop}
                                    sendCountDown={this.sendCountDown}
                                    sendToAllPusher={this.sendToAllPusher}
                                    showNotification={this.showNotification}
                                    data={this.state.participants[key].userData}
                                    connection={
                                      this.state.participants[key].connection
                                    }
                                  />
                                )
                              }
                            },
                          )}{" "}
                          <div
                            className={
                              signcount > 0
                                ? "mod-userlisting-header signlang"
                                : "hide"
                            }
                          >
                            Sign Language Interpreters
                          </div>{" "}
                          {Object.keys(this.state.participants).map(
                            (key, index) => {
                              if (
                                this.state.participants[key].userData.isSigned
                              ) {
                                return (
                                  <Participant
                                    masterSpeaker={this.masterSpeaker}
                                    key={key}
                                    isSpeakerPanel={this.isSpeakerPanel}
                                    openCaptionPop={this.openCaptionPop}
                                    sendCountDown={this.sendCountDown}
                                    sendToAllPusher={this.sendToAllPusher}
                                    showNotification={this.showNotification}
                                    data={this.state.participants[key].userData}
                                    connection={
                                      this.state.participants[key].connection
                                    }
                                  />
                                )
                              }
                            },
                          )}{" "}
                          <div
                            className={
                              linsterCount > 0
                                ? "mod-userlisting-header viewer"
                                : "hide"
                            }
                          >
                            Listeners
                          </div>{" "}
                          {Object.keys(this.state.participants).map(
                            (key, index) => {
                              if (
                                spkrct <= this.state.maxUserCnt &&
                                this.state.participants[key].userData.role ===
                                "listener"
                              ) {
                                spkrct++
                                return (
                                  <Participant
                                    key={key}
                                    isSpeakerPanel={this.isSpeakerPanel}
                                    deleteCaption={this.deleteCaption}
                                    openCaptionPop={this.openCaptionPop}
                                    sendCountDown={this.sendCountDown}
                                    sendToAllPusher={this.sendToAllPusher}
                                    showNotification={this.showNotification}
                                    data={this.state.participants[key].userData}
                                    connection={
                                      this.state.participants[key].connection
                                    }
                                  />
                                )
                              }
                            },
                          )}{" "}
                          <div
                            className={
                              linsterCount > 0
                                ? "mod-userlisting-header viewer"
                                : "hide"
                            }
                          >
                            Listeners ( HLS )
                          </div>{" "}
                          {Object.keys(this.state.participants).map(
                            (key, index) => {
                              if (
                                this.state.participants[key].userData.role ===
                                "listenerhls"
                              ) {
                                return (
                                  <Participant
                                    masterSpeaker={this.masterSpeaker}
                                    key={key}
                                    isSpeakerPanel={this.isSpeakerPanel}
                                    deleteCaption={this.deleteCaption}
                                    openCaptionPop={this.openCaptionPop}
                                    sendCountDown={this.sendCountDown}
                                    sendToAllPusher={this.sendToAllPusher}
                                    showNotification={this.showNotification}
                                    data={this.state.participants[key].userData}
                                    connection={
                                      this.state.participants[key].connection
                                    }
                                  />
                                )
                              }
                            },
                          )}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="observers-tab-list"
                          role="tabpanel"
                          aria-labelledby="observers-tab-list"
                        >
                          <div className="participant-list-tile notactive">
                            <div className="participant-profile-icon-section">
                              <div className="avatar-section">
                                <div className="avatar_initial">VT</div>
                              </div>
                            </div>
                            <div className="participant-name-section">
                              <div className="participant-name">Vignesh</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}{" "}
                </div>
                <div
                  className="row screen_share mod-screenshare"
                  id="video-frame"
                >
                  <div className="parent-meeting-section mod-parent-meet-session moder">
                    <div
                      className={
                        this.state.chatEnabled
                          ? "meeting-section mod-meeting-section chat-enabled"
                          : "meeting-section mod-meeting-section"
                      }
                    >
                      <div className="vdo-display-wrapper" id="confbg">
                        <div
                          id="middle-video-content"
                          className="meeting-section"
                        >
                          <Draggable>
                            <div
                              className={
                                this.state.settingsOpen ||
                                  !this.state.deviceInit
                                  ? "hide"
                                  : this.state.screenLayout
                                    ? "signed-video right300"
                                    : "signed-video"
                              }
                            >
                              {" "}
                              {this.state.streams.map((stream) => {
                                let split = stream.name.split("_")
                                return split[3] == "signed" &&
                                  this.isSigned == false
                                  ? this.renderStreams(stream)
                                  : ""
                              })}{" "}
                            </div>
                          </Draggable>
                          <div
                            id="layout"
                            className={
                              this.state.screenLayout ||
                                this.state.videoLayout ||
                                this.state.docLayout
                                ? "screen-layout"
                                : ""
                            }
                          >
                            {" "}
                            {this.renderPublisher(false)}{" "}
                            {this.state.streams.map((stream) => {
                              let split = stream.name.split("_")
                              let role = split[0]
                              return role !== "interpreter" &&
                                split[3] != "screen" &&
                                split[3] != "signed"
                                ? this.renderStreams(stream)
                                : ""
                            })}{" "}
                          </div>{" "}
                          {this.renderScreenPublisher()}{" "}
                          <div id="layout-hidden" className="hide">
                            {" "}
                            {this.state.streams.map((stream) => {
                              let splithidden = stream.name.split("_")
                              let rolehidden = splithidden[0]
                              return rolehidden === "interpreter" &&
                                splithidden[3] != "screen" &&
                                splithidden[3] != "signed"
                                ? this.renderStreams(stream)
                                : ""
                            })}{" "}
                          </div>
                          <div
                            id="layout-screen"
                            className={
                              this.state.screenLayout ? "layout-screen" : "hide"
                            }
                          >
                            {" "}
                            {this.state.streams.map((stream) => {
                              let screendt = stream.name.split("_")
                              return screendt[3] == "screen"
                                ? this.renderStreams(stream)
                                : ""
                            })}{" "}
                          </div>
                          <div
                            className={
                              this.state.videoLayout
                                ? "layout-screen mob-vdo-layout-screen"
                                : "hide"
                            }
                          >
                            <div
                              onClick={this.closeVideoPlayer}
                              className={
                                this.state.role === "moderator"
                                  ? "d-flex justify-content-end close-btn videoclose"
                                  : "hide"
                              }
                            ></div>
                            <video
                              onContextMenu={() => false}
                              id="videoPlayer"
                              width="100%"
                              height="100%"
                              controls
                              poster="/img/audioonly.jpeg"
                            />
                          </div>
                          <div
                            className={this.state.numSubs < 1 ? "hide" : ""}
                            id="layout-manage"
                          >
                            <img
                              className={
                                this.state.numSubs <= 2
                                  ? "disable-lay"
                                  : this.state.currentLayout === "grid"
                                    ? "bg-white"
                                    : ""
                              }
                              src="/img/grid.svg"
                              onClick={this.changeLayout.bind(this, "grid")}
                            />
                            <img
                              className={
                                this.state.numSubs <= 2
                                  ? "disable-lay"
                                  : this.state.currentLayout === "vertical"
                                    ? "bg-white flipimg"
                                    : "flipimg"
                              }
                              src="/img/hangout_vertical.svg"
                              onClick={this.changeLayout.bind(this, "vertical")}
                            />
                            <img
                              className={
                                this.state.numSubs !== 2 ||
                                  (!this.state.video && !this.state.audio) ||
                                  this.isSigned
                                  ? "disable-lay"
                                  : this.state.currentLayout === "2user"
                                    ? "bg-white"
                                    : ""
                              }
                              src="/img/float.svg"
                              onClick={this.changeLayout.bind(this, "2user")}
                            />
                            <img
                              className={
                                this.state.numSubs !== 2
                                  ? "disable-lay"
                                  : this.state.currentLayout === "vertical2"
                                    ? "bg-white"
                                    : ""
                              }
                              src="/img/f2f_vertical.svg"
                              onClick={this.changeLayout.bind(
                                this,
                                "vertical2",
                              )}
                            />
                            <img
                              className="hide"
                              src="/img/f2f_horizontal.svg"
                              onClick={this.changeLayout.bind(
                                this,
                                "horizontal2",
                              )}
                            />
                          </div>
                          <div
                            id="docParent"
                            className={
                              this.state.docLayout
                                ? "layout-screen doc-index-2 noscroll"
                                : "hide"
                            }
                          >
                            <div
                              onClick={this.closeDocPlayer}
                              className={
                                this.state.role === "moderator"
                                  ? "d-flex justify-content-end close-btn videoclose"
                                  : "hide"
                              }
                            >
                              ×
                            </div>
                            <iframe
                              onLoad={this.onLoadCompleteFrame}
                              id="docPlayer"
                              width="100%"
                              hight="100%"
                            ></iframe>
                          </div>
                          <div
                            className={
                              document.getElementById("layout")
                                ? document.getElementById("layout")
                                  .childElementCount > 0 ||
                                  document.getElementById("layout-screen")
                                    .childElementCount > 0 ||
                                  this.state.screen
                                  ? "vdo-enlarge-blk"
                                  : "hide"
                                : "hide"
                            }
                            onClick={this.onStageFullscrn}
                          >
                            {" "}
                            <div className="d-flex align-items-center justify-content-center vdo-enlarge-container">
                              <img
                                src={
                                  this.state.fullscrn
                                    ? "/img/vdo_compress.svg"
                                    : "/img/resize.svg"
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className={this.state.notification ? "" : "hide"}>
                          <Toast
                            closeNotification={this.closeNotification}
                            notification={this.state.notification}
                          />
                        </div>
                        <div
                          className={
                            document.getElementById("layout")
                              ? document.getElementById("layout")
                                .childElementCount > 0 ||
                                document.getElementById("layout-screen")
                                  .childElementCount > 0 ||
                                this.state.videoLayout ||
                                this.state.docLayout ||
                                this.state.screen
                                ? "hide"
                                : "meeting-section sess-not-started absolute"
                              : "meeting-section sess-not-started absolute"
                          }
                        >
                          {" "}
                          <h1>{this.state.notstartedtxt}</h1>
                        </div>
                      </div>
                      <ChatComponent
                        getSessionResponse
                        showNotification={this.showNotification}
                        sessionId={this.state.sessionId}
                        chatClose={this.chatClose}
                        newChat={this.newChat}
                        blockBodyClick={this.blockBodyClick}
                        ref={(chatRef) => (this.chatRef = chatRef)}
                      />{" "}
                      <div
                        className={
                          this.state.chatEnabled
                            ? "hide"
                            : "chat-mobile-view-wrapper"
                        }
                        onClick={this.enableOrDisableChat}
                      >
                        <div className="d-flex align-items-center justify-content-center chat-mobile-icon-container">
                          <img
                            src="/img/chat-icon.svg"
                            className="chat-mobile-icon"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        this.state.settingsOpen || !this.state.deviceInit
                          ? "hide"
                          : "vdo_actions_media moderator-media mobile-mod-botton"
                      }
                    >
                      <div className="d-flex justify-content-start align-items-center confer-cover-img-blk-inner">
                        <div
                          className={
                            this.state.coverImageUrl.length > 0
                              ? "confer-cover-img-blk"
                              : "hide"
                          }
                        >
                          <div className="confer-cover-wrapper">
                            {this.state.coverImageUrl.map((img, index) => {
                              return (
                                <div
                                  key={img}
                                  className={
                                    index < 2
                                      ? "confer-cover-container"
                                      : "hide"
                                  }
                                >
                                  <img src={img} className="object-fill" />
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        <div className="tot-participants mr-3">
                          Time:{" "}
                          <ConferenceTime
                            ref={(timeRef) => (this.timeRef = timeRef)}
                            onUpdateTime={this.onUpdateTime}
                          />
                        </div>
                      </div>

                      <div
                        className={
                          this.state.connected
                            ? "activityIcon d-flex align-items-center justify-content-center  mod-mob-botton-tool"
                            : "hide"
                        }
                      >
                        <div className="vdo-menu-list-wrap">
                          <div
                            className={
                              this.state.onAir
                                ? "vdo-menu-list onair-active onair"
                                : "vdo-menu-list onair"
                            }
                            onClick={this.goOnAirOffAir}
                          >
                            <img src="/img/locked.png" />
                          </div>
                          <h4>{this.state.onAir ? "On Air" : "Off Air"}</h4>
                        </div>
                        <div className="vdo-menu-list-wrap screenshare">
                          <div
                            className={
                              this.state.screenAllow
                                ? this.state.screen
                                  ? "vdo-menu-list active"
                                  : "vdo-menu-list"
                                : "hide"
                            }
                            onClick={this.screenControl}
                          >
                            <img
                              src={
                                this.state.screen
                                  ? "/img/share-screen.svg"
                                  : "/img/share-screen-cross.svg"
                              }
                            />
                          </div>
                          <h4>Share</h4>
                        </div>
                        <div className="d-flex align-items-center vdo-menu-blk fixed-leave-call-wrapper">
                          <div className="vdo-menu-list-wrap hide">
                            <div
                              className="vdo-menu-list leave-call sett-active leave"
                              onClick={this.hangup}
                            >
                              <img src="/img/call-white.svg" />
                            </div>
                            <h4>Leave</h4>
                          </div>
                          <div className="vdo-menu-list-wrap">
                            <div
                              className={
                                this.state.isPublishProcess
                                  ? this.state.video
                                    ? "vdo-menu-list video active isAudioProcessing"
                                    : "vdo-menu-list video isAudioProcessing"
                                  : this.state.video
                                    ? "vdo-menu-list video active"
                                    : "vdo-menu-list video"
                              }
                              onClick={this.videoControl}
                            >
                              <img
                                src={
                                  this.state.video
                                    ? "/img/video.svg"
                                    : "/img/video-cross.svg"
                                }
                              />
                              <i className="fas fa-spinner fa-spin"></i>
                            </div>
                            <h4>Camera</h4>
                          </div>
                          <div className="vdo-menu-list-wrap">
                            <div
                              className={
                                this.state.isPublishProcess
                                  ? this.state.audio
                                    ? "vdo-menu-list mic active isAudioProcessing"
                                    : "vdo-menu-list mic isAudioProcessing"
                                  : this.state.audio
                                    ? "vdo-menu-list mic active"
                                    : "vdo-menu-list mic"
                              }
                              onClick={this.audioControl}
                            >
                              <img
                                src={
                                  this.state.audio
                                    ? "/img/mic.svg"
                                    : "/img/mic-cross.svg"
                                }
                              />
                              <i className="fas fa-spinner fa-spin"></i>
                            </div>
                            <h4>Mic</h4>
                          </div>
                          <div className="vdo-menu-list-wrap hide">
                            <div className="vdo-menu-list slowdown">
                              <img src="/img/traffic-lights.svg" />
                            </div>
                            <h4>Slow down</h4>
                          </div>
                          <div className="vdo-menu-list-wrap hide">
                            <div className="vdo-menu-list raisehand">
                              <img src="/img/hand-raise.svg" />
                            </div>
                            <h4>Raise Hand</h4>
                          </div>

                          <div
                            className={
                              this.state.recordingEnabled
                                ? "vdo-menu-list-wrap"
                                : "hide"
                            }
                          >
                            <div
                              onClick={this.startOrStopRecording}
                              className={
                                this.state.recordingEnabled
                                  ? this.state.isRecording
                                    ? "vdo-menu-list record active"
                                    : "vdo-menu-list record"
                                  : "hide"
                              }
                            >
                              <i className="fa fa-stop"></i>
                            </div>
                            <h4>Record</h4>
                          </div>
                          <div className="vdo-menu-list-wrap">
                            <a
                              className="vdo-menu-list part"
                              href={
                                "/" +
                                this.state.sessionId +
                                "/moderatorpanel/panel/" +
                                this.userId
                              }
                              target="_blank"
                            >
                              <i className="fa fa-users"></i>
                            </a>
                            <h4>Participants</h4>
                          </div>
                          {!this.state.disableChat && (
                            <div className="vdo-menu-list-wrap">
                              <div
                                className={
                                  this.state.chatEnabled
                                    ? "vdo-menu-list message active"
                                    : this.state.unReadChat
                                      ? "vdo-menu-list message chat-not"
                                      : "vdo-menu-list message"
                                }
                                onClick={this.enableOrDisableChat}
                              >
                                <img src="/img/message.svg" />
                              </div>
                              <h4>Message</h4>
                            </div>
                          )}

                          <div className="vdo-menu-list-wrap">
                            <div
                              onClick={this.moreIconShowHide}
                              className="vdo-menu-list more-settings-dots hide-xl-screen"
                            >
                              <img src="/img/vertical-dots.svg" />
                              <div
                                className={
                                  this.state.moreIcon
                                    ? "more-settings-blk"
                                    : "hide"
                                }
                              >
                                <div className="vdo-menu-list-wrap">
                                  <div
                                    className={
                                      this.state.uploadVideoPop
                                        ? "vdo-menu-list vdo-file-upload active"
                                        : "vdo-menu-list vdo-file-upload hidelowres"
                                    }
                                    onClick={this.showHideVideoShare}
                                  >
                                    <img src="/img/video_upload.svg" />
                                  </div>
                                  <h4>Videos</h4>
                                </div>

                                <div className="vdo-menu-list-wrap">
                                  <div
                                    className={
                                      this.state.uploadDocPop
                                        ? "vdo-menu-list vdo-file-upload active"
                                        : "vdo-menu-list vdo-file-upload"
                                    }
                                    onClick={this.showHideDocShare}
                                  >
                                    <img src="/img/doc_upload.svg" />
                                  </div>
                                  <h4>Documents</h4>
                                </div>
                                <div
                                  className={
                                    this.state.docLayout &&
                                      this.state.enableDownloadPpt
                                      ? "vdo-menu-list-wrap"
                                      : "hide"
                                  }
                                  onClick={this.donwloadDocument}
                                >
                                  <a
                                    href={pptDownlodUrl}
                                    target="_blank"
                                    className="vdo-menu-list docdown"
                                  >
                                    <i className="fa fa-download"></i>
                                  </a>
                                  <h4>Download</h4>
                                </div>
                              </div>
                            </div>
                            <h4 className="hide-xl-screen">More</h4>
                          </div>
                          <div className="vdo-menu-list-wrap hidelowres">
                            <div
                              className={
                                this.state.uploadVideoPop
                                  ? "vdo-menu-list vdo-file-upload active toggle-sm-screen"
                                  : "vdo-menu-list vdo-file-upload hidelowres toggle-sm-screen"
                              }
                              onClick={this.showHideVideoShare}
                            >
                              <img src="/img/video_upload.svg" />
                            </div>
                            <h4 className="toggle-sm-screen">Videos</h4>
                          </div>
                          <div className="vdo-menu-list-wrap hidelowres">
                            <div
                              className={
                                this.state.uploadDocPop
                                  ? "vdo-menu-list vdo-file-upload active toggle-sm-screen"
                                  : "vdo-menu-list vdo-file-upload hidelowres toggle-sm-screen"
                              }
                              onClick={this.showHideDocShare}
                            >
                              <img src="/img/doc_upload.svg" />
                            </div>
                            <h4 className="toggle-sm-screen">Documents</h4>
                          </div>
                          <div
                            className={
                              this.state.docLayout &&
                                this.state.enableDownloadPpt
                                ? "vdo-menu-list-wrap hidelowres"
                                : "hide"
                            }
                          >
                            <a
                              href={pptDownlodUrl}
                              target="_blank"
                              className="vdo-menu-list vdo-file-upload toggle-sm-screen docdown"
                              onClick={this.donwloadDocument}
                            >
                              <i className="fa fa-download"></i>
                            </a>
                            <h4 className="toggle-sm-screen">Download</h4>
                          </div>
                          <div
                            onClick={this.countDownEditPop}
                            className={
                              this.state.presentationTimer > 0
                                ? "vdo-menu-list message active cntmenu"
                                : "hide"
                            }
                          >
                            {" "}
                            {this.state.presentationTimerDisplay}{" "}
                            <div className="custom-menu-tooltip">
                              Countdown For {this.state.countDownSpeakername}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bottom-right-side-section hide">
                        <div className="presentation_section">
                          <div className="presentation_section_icon mr-2">
                            <img
                              src="assets//img/present-white.svg"
                              alt="present_icon"
                              className="img-fluid"
                            />
                          </div>
                          <div className="presentation_section_text">
                            Present Now
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          this.state.coverImageUrl.length > 0
                            ? "confer-cover-img-blk"
                            : "confer-cover-img-blk"
                        }
                      >
                        <div className="confer-cover-wrapper">
                          {this.state.coverImageUrl.map((img, index) => {
                            return (
                              <div
                                key={img}
                                className={
                                  index >= 2 ? "confer-cover-container" : "hide"
                                }
                              >
                                <img src={img} className="object-fill" />
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className={this.state.alert ? "alert-wrap" : "hide"}>
              <Alert
                onCloseAlert={this.onCloseAlert}
                alert={this.state.alert}
                message={this.state.alertMessage}
              />
            </section>
          </section>
        )
      } else if (this.state.role == "speaker") {
        return (
          <section
            onClick={this.bodyClick.bind(this)}
            className={
              this.state.chatEnabled ? "vc-session chat-enabled" : "vc-session"
            }
          >
            <div className={this.state.pollOpen ? "poll-backdrop" : "hide"}>
              <PollComponent
                sendToAllPusher={this.sendToAllPusher}
                getAllPolls={this.getAllPolls}
                role={this.state.role}
                showNotification={this.showNotification}
                onPollReceive={this.onPollReceive}
                closePoll={this.closePoll}
                ref={(pollRef) => (this.pollRef = pollRef)}
                sessionId={this.state.sessionId}
              />
            </div>
            <div
              className={
                this.state.showTechnicalSupport ? "fixed-backdrop" : "hide"
              }
            >
              <div className="custom-caption-pop-up">
                <div className="d-flex align-items-center justify-content-between custom-popup-cap-header-blk">
                  <div className="custom-caption-header">Technical Support</div>
                  <div className="support-close" onClick={this.cloeSupportPop}>
                    X
                  </div>
                </div>
                <TechnicalSupport
                  showNotification={this.showNotification}
                  sessionId={this.state.sessionId}
                  newChat={this.newSupportChat}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between vdo-top-part">
              <div className="d-flex align-items-center">
                <div className="logoo">
                  <img
                    src={this.state.logo}
                    onError={this.onErrorImg.bind(this)}
                  />
                  {/* <button
                    id="load-test"
                    style={{
                      display: "absolute",
                      top: "35%",
                      left: "35%",
                      backgroundColor: this.state.allowLoad ? "green" : "red",
                    }}
                    onClick={() => this.setState({ allowLoad: true })}
                  >
                    LOAD TEST 2
                  </button>
									*/}
                  <div style={styles.radioContainer}>
                    <div id="config-radio-group" style={styles.radioGroup}>
                      <label style={styles.radioOption}>Config</label>
                      {["v2", "v1", "original"].map((value) => (
                        <label key={value} style={styles.radioOption}>
                          <input
                            type="radio"
                            name="stateGroup"
                            value={value}
                            checked={this.state.pubPropsKey === value}
                            onChange={() =>
                              this.setState({
                                pubPropsKey: value,
                                pubProps: configs[value],
                              })
                            }
                          />
                          {value}
                        </label>
                      ))}
                    </div>
                    <div id="noise-radio-group" style={styles.radioGroup}>
                      <label style={styles.radioOption}>Anti Noise</label>
                      {["on", "off"].map((value) => (
                        <label key={value} style={styles.radioOption}>
                          <input
                            type="radio"
                            name="noiseGroup"
                            value={value}
                            checked={
                              this.state.advancedNoise === (value === "on")
                            }
                            onChange={() =>
                              this.setState({
                                advancedNoise: value === "on",
                              })
                            }
                          />
                          {value}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
                <span className="username">{this.state.logoText}</span>
              </div>
              <div className="d-flex align-items-center top-menu-wrapper speaker">
                <div className="tot-participants float-part-count d-flex align-items-center">
                  <i className="fa fa-user"></i>
                  <span id="totalCon">
                    {Object.keys(this.state.participants).length}
                  </span>
                </div>
                <div className="speaker-lang-blk">
                  <div className="interpreter-section arrow-up">
                    <div
                      className="d-flex align-items-center lang-container interpreter-arrow-down"
                      onClick={this.onToggleInterpreterListInModerator}
                    >
                      <div className="flag-avatar">
                        <img src="/img/interpreter.svg" />
                      </div>
                      <div className="flag-lang notranslate">
                        {this.state.selectedInterpreterInModerator}
                      </div>
                    </div>
                    <div
                      className={
                        this.state.moderatorInterpreterlistOpen
                          ? "interpreter-popover"
                          : "hide"
                      }
                    >
                      <div className="interpret-wrapper">
                        {Object.keys(this.state.participants).map(
                          (key, index) => {
                            if (
                              this.state.participants[key].userData.role ===
                              "interpreter" &&
                              this.state.participants[key].userData.audio &&
                              !addedLanguage.hasOwnProperty(
                                this.state.participants[key].userData.language,
                              )
                            ) {
                              addedLanguage[
                                this.state.participants[key].userData.language
                              ] = "true"
                              //console.log('Added language = '+JSON.stringify(addedLanguage))
                              return (
                                <div
                                  key={key}
                                  onClick={this.onChangeSelectedInterpreterInModerator.bind(
                                    this,
                                    this.state.participants[key].userData
                                      .language,
                                    this.state.participants[key].connection.id,
                                  )}
                                  className="d-flex align-items-center interpreter-container pointer"
                                >
                                  <div
                                    className={
                                      this.state.participants[key].userData
                                        .audio
                                        ? "active-blink"
                                        : "absolute"
                                    }
                                  ></div>
                                  <div className="flag-lang notranslate">
                                    {" "}
                                    {
                                      this.state.participants[key].userData
                                        .language
                                    }
                                  </div>
                                </div>
                              )
                            }
                          },
                        )}
                        {Object.keys(this.state.participants).map((key) => {
                          if (
                            this.state.participants[key].userData.role ===
                            "interpreter" &&
                            !this.state.participants[key].userData.audio &&
                            !addedLanguage.hasOwnProperty(
                              this.state.participants[key].userData.language,
                            )
                          ) {
                            addedLanguage[
                              this.state.participants[key].userData.language
                            ] = "true"
                            //console.log('Added language = '+JSON.stringify(addedLanguage))
                            return (
                              <div
                                key={key}
                                onClick={this.onChangeSelectedInterpreterInModerator.bind(
                                  this,
                                  this.state.participants[key].userData
                                    .language,
                                  this.state.participants[key].connection.id,
                                )}
                                className="d-flex align-items-center interpreter-container pointer"
                              >
                                <div
                                  className={
                                    this.state.participants[key].userData.audio
                                      ? "active-blink"
                                      : "absolute"
                                  }
                                ></div>
                                <div className="flag-lang notranslate">
                                  {" "}
                                  {
                                    this.state.participants[key].userData
                                      .language
                                  }
                                </div>
                              </div>
                            )
                          }
                        })}
                        {this.state.avalilableLanguages.map((lang, i) => {
                          if (!addedLanguage.hasOwnProperty(lang)) {
                            addedLanguage[lang] = "true"
                            //console.log('Added language = '+JSON.stringify(addedLanguage))
                            return (
                              <div
                                key={i}
                                onClick={this.onChangeSelectedInterpreterInModeratorBefore.bind(
                                  this,
                                  lang,
                                  1,
                                )}
                                className="d-flex align-items-center interpreter-container pointer"
                              >
                                <div className="absolute"></div>
                                <div className="flag-lang notranslate">
                                  {" "}
                                  {lang}
                                </div>
                              </div>
                            )
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="voice-controller">
                    <div className="voice-slidecontainer top-volume">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        onChange={this.onInterpreterVolumeChangeInModerator.bind(
                          this,
                        )}
                        value={this.state.InterpreterVolumeInModerator}
                        className={
                          this.state.selectedInterpreterInModerator ===
                            this.interpretersLabel
                            ? "voice-slider inactive"
                            : "voice-slider pointer"
                        }
                      />
                      <div className="custom-menu-tooltip">
                        {this.state.InterpreterVolumeInModerator}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="speaker-lang-blk">
                  <div className="interpreter-section speaker-floor-blk">
                    <div className="d-flex align-items-center lang-container">
                      <div className="d-flex align-items-center justify-content-center floor-avatar">
                        <i className="fa fa-user"></i>
                      </div>
                      <div className="floor-caption">
                        {this.state.floorTitle}
                      </div>
                    </div>
                  </div>
                  <div className="voice-controller">
                    <div className="voice-slidecontainer top-volume">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        onChange={this.onSpeakerVolumeChangeInModerator.bind(
                          this,
                        )}
                        value={this.state.speakerVolumeInModerator}
                        className="voice-slider pointer"
                      />
                      <div className="custom-menu-tooltip">
                        {this.state.speakerVolumeInModerator}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vdo-top-right-part">
                <a className="bandwidth-section trouble">
                  <div
                    className="notranslate"
                    id="google_translate_element"
                  ></div>
                  <button
                    className="skiptr notranslate"
                    onClick={this.skipTrans}
                  >
                    Skip Translation
                  </button>
                </a>
                <div
                  onClick={this.showTechnicalSupport}
                  className="toggle-menu ml-3 help-butn"
                  data-toggle="offcanvas"
                >
                  <img src="/img/support.svg" />
                  <div className="custom-menu-tooltip">Technical Support</div>
                </div>
                <div
                  onClick={this.toggleSidbar}
                  className="toggle-menu ml-3 mobile-hide"
                  data-toggle="offcanvas"
                >
                  <img src="/img/menu-icon.svg" />
                </div>
                <div
                  onClick={this.mobMenuToggle}
                  className="toggle-menu ml-3 desktop-hide"
                  data-toggle="offcanvas"
                >
                  <img src="/img/menu-icon.svg" />
                </div>
              </div>
            </div>

            <div
              className={this.state.mobMenuToggle ? "sidenav-blk open" : "hide"}
            >
              <div className="d-flex align-items-center justify-content-end sidebar-top-part">
                <span className="close" onClick={this.mobMenuToggle}>
                  ×
                </span>
              </div>
              <ul className="sidenav-menu-wrap">
                <li>
                  <span>
                    Time :
                    <span className="c-time">
                      <ConferenceTime
                        ref={(timeRefMob) => (this.timeRefMob = timeRefMob)}
                        onUpdateTime={this.onUpdateTime}
                      />
                    </span>{" "}
                  </span>
                </li>

                <li>
                  <span
                    onClick={this.onToggleInterpreterListInModerator}
                    className="interpreter-drop"
                  >
                    Interpreting{" "}
                    <span
                      className={
                        this.state.moderatorInterpreterlistOpen
                          ? "arrow-open"
                          : "arrow-close"
                      }
                    ></span>
                  </span>
                  {(addedLanguage = [])}
                  <ul
                    className={
                      this.state.moderatorInterpreterlistOpen
                        ? "Interpreters-lang"
                        : "hide"
                    }
                  >
                    {Object.keys(this.state.participants).map((key) => {
                      if (
                        this.state.participants[key].userData.role ===
                        "interpreter" &&
                        this.state.participants[key].userData.audio &&
                        !addedLanguage.hasOwnProperty(
                          this.state.participants[key].userData.language,
                        )
                      ) {
                        addedLanguage[
                          this.state.participants[key].userData.language
                        ] = "true"
                        return (
                          <li
                            key={key}
                            onClick={this.onChangeSelectedInterpreterInModerator.bind(
                              this,
                              this.state.participants[key].userData.language,
                              this.state.participants[key].connection.id,
                            )}
                          >
                            <div
                              className={
                                this.state.participants[key].userData.audio
                                  ? "active-blink blinkmob"
                                  : "absolute"
                              }
                            ></div>
                            <span
                              className={
                                this.state.participants[key].userData
                                  .language ===
                                  this.state.selectedInterpreterInModerator
                                  ? "menulst-active"
                                  : ""
                              }
                            >
                              {this.state.participants[key].userData.language}
                            </span>
                          </li>
                        )
                      }
                    })}
                    {Object.keys(this.state.participants).map((key) => {
                      if (
                        this.state.participants[key].userData.role ===
                        "interpreter" &&
                        !this.state.participants[key].userData.audio &&
                        !addedLanguage.hasOwnProperty(
                          this.state.participants[key].userData.language,
                        )
                      ) {
                        addedLanguage[
                          this.state.participants[key].userData.language
                        ] = "true"
                        return (
                          <li
                            key={key}
                            onClick={this.onChangeSelectedInterpreterInModerator.bind(
                              this,
                              this.state.participants[key].userData.language,
                              this.state.participants[key].connection.id,
                            )}
                          >
                            <div
                              className={
                                this.state.participants[key].userData.audio
                                  ? "active-blink blinkmob"
                                  : "absolute"
                              }
                            ></div>
                            <span
                              className={
                                this.state.participants[key].userData
                                  .language ===
                                  this.state.selectedInterpreterInModerator
                                  ? "menulst-active"
                                  : ""
                              }
                            >
                              {this.state.participants[key].userData.language}
                            </span>
                          </li>
                        )
                      }
                    })}
                  </ul>
                </li>
                <li>
                  <span>
                    Interpreter Audio <span className="arrow-open"></span>
                  </span>
                  <div className="mobile-voice-controller">
                    <div className="voice-slidecontainer">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        onChange={this.onInterpreterVolumeChangeInModerator.bind(
                          this,
                        )}
                        value={this.state.InterpreterVolumeInModerator}
                        className={
                          this.state.selectedInterpreterInModerator ===
                            "Select Interpreter"
                            ? "voice-slider nomouse"
                            : "voice-slider pointer"
                        }
                      />
                      <div className="custom-menu-tooltip">
                        {this.state.InterpreterVolumeInModerator}
                      </div>
                    </div>
                  </div>
                </li>
                <li className="hide">
                  <span>
                    Floor <span className="arrow-open"></span>
                  </span>
                </li>
                <li>
                  <span>
                    Floor Audio <span className="arrow-open"></span>
                  </span>
                  <div className="mobile-voice-controller">
                    <div className="voice-slidecontainer">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        onChange={this.onSpeakerVolumeChangeInModerator.bind(
                          this,
                        )}
                        value={this.state.speakerVolumeInModerator}
                        className="voice-slider pointer"
                      />
                      <div className="custom-menu-tooltip">
                        {this.state.speakerVolumeInModerator}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <a
                    href={
                      "/precall-test/index.html?id=" +
                      Config.SESSION_ID +
                      "&token=" +
                      Config.TOKEN_PUBLISHER
                    }
                    target="_blank"
                  >
                    <span>Trouble with connection ?</span>
                  </a>
                </li>
                <li>
                  <a onClick={this.startMySpeedTest} target="_blank">
                    <span>Speed Test</span>
                  </a>
                </li>
                <li onClick={this.onSettings}>
                  <span>Media Settings</span>
                </li>
                <li onClick={this.onRefresh}>
                  <span>Refresh</span>
                </li>
                <li onClick={this.onLogout}>
                  <span>Logout</span>
                </li>
              </ul>
            </div>

            <div className="vdo-display-wrapper" id="confbg">
              <div className={this.state.sidebaropen ? "sidebar open" : "hide"}>
                <ul>
                  <li>
                    <a
                      href={
                        "/precall-test/index.html?id=" +
                        Config.SESSION_ID +
                        "&token=" +
                        Config.TOKEN_PUBLISHER
                      }
                      target=" _blank"
                      className="nav-link"
                    >
                      <span className="icon">?</span>
                      <span className="link">Run Test</span>
                    </a>
                    <a
                      onClick={this.startMySpeedTest}
                      className={
                        this.state.speedOfMe ? "nav-link mobile-hide" : "hide"
                      }
                    >
                      <span className="icon">
                        <img src="/img/bandwidth.svg" />
                      </span>
                      <span className="link">Speed Test</span>
                    </a>
                    <a className="nav-link" onClick={this.onRefresh}>
                      <span className="icon">
                        <img src="/img/refresh.svg" />
                      </span>
                      <span className="link">Refresh Page</span>
                    </a>
                    <a className="nav-link" onClick={this.onSettings}>
                      <span className="icon">
                        <img src="/img/settings.svg" />
                      </span>
                      <span className="link">Audio / Video Settings</span>
                    </a>

                    <a className="nav-link" onClick={this.onLogout}>
                      <span className="icon">
                        <img src="/img/logout.svg" />
                      </span>
                      <span className="link">Logout</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div
                id="middle-video-content"
                className="meeting-section video-area"
              >
                <div
                  className={
                    this.state.speakerMode &&
                      !this.state.audio &&
                      !this.state.video &&
                      !this.isSigned
                      ? "cam-mic-help"
                      : "hide"
                  }
                >
                  You have been assigned to Speaker, please activate your camera
                  and microphone by clicking the icons on the bottom of the page
                </div>
                <div
                  className={
                    this.state.screenLayout
                      ? "signed-video right300 index99 signmyvid"
                      : "signed-video index99 signmyvid"
                  }
                >
                  {this.isSigned && this.renderPublisher(false)}
                </div>
                <Draggable>
                  <div
                    className={
                      this.state.settingsOpen || !this.state.deviceInit
                        ? "hide"
                        : this.state.screenLayout
                          ? "signed-video right300"
                          : "signed-video"
                    }
                  >
                    {this.state.streams.map((stream) => {
                      let split = stream.name.split("_")
                      return split[3] == "signed"
                        ? this.renderStreams(stream)
                        : ""
                    })}
                  </div>
                </Draggable>
                <div
                  id="layout"
                  className={
                    this.state.screenLayout ||
                      this.state.videoLayout ||
                      this.state.docLayout
                      ? "screen-layout"
                      : ""
                  }
                >
                  {!this.isSigned && this.renderPublisher(false)}
                  {this.state.streams.map((stream) => {
                    let split = stream.name.split("_")
                    let role = split[0]
                    return role !== "interpreter" &&
                      split[3] != "screen" &&
                      split[3] != "signed"
                      ? this.renderStreams(stream)
                      : ""
                  })}
                </div>
                {this.renderScreenPublisher()}
                <div id="layout-hidden" className="hide">
                  {this.state.streams.map((stream) => {
                    let splithidden = stream.name.split("_")
                    let rolehidden = splithidden[0]
                    return rolehidden === "interpreter" &&
                      splithidden[3] != "screen"
                      ? this.renderStreams(stream)
                      : ""
                  })}
                </div>
                <div
                  id="layout-screen"
                  className={this.state.screenLayout ? "layout-screen" : "hide"}
                >
                  {this.state.streams.map((stream) => {
                    let screendt = stream.name.split("_")
                    return screendt[3] == "screen"
                      ? this.renderStreams(stream)
                      : ""
                  })}
                </div>
                <div
                  className={
                    this.state.videoLayout
                      ? "layout-screen mob-vdo-layout-screen"
                      : "hide"
                  }
                >
                  <div
                    onClick={this.closeVideoPlayer}
                    className={
                      this.state.role === "moderator"
                        ? "d-flex justify-content-end close-btn videoclose"
                        : "hide"
                    }
                  >
                    ×
                  </div>
                  <video
                    onContextMenu={() => false}
                    id="videoPlayer"
                    width="100%"
                    height="100%"
                    poster="/img/audioonly.jpeg"
                  />
                </div>
                <div
                  className={this.state.numSubs < 1 ? "hide" : ""}
                  id="layout-manage"
                >
                  <img
                    className={
                      this.state.numSubs <= 2
                        ? "disable-lay"
                        : this.state.currentLayout === "grid"
                          ? "bg-white"
                          : ""
                    }
                    src="/img/grid.svg"
                    onClick={this.changeLayout.bind(this, "grid")}
                  />
                  <img
                    className={
                      this.state.numSubs <= 2
                        ? "disable-lay"
                        : this.state.currentLayout === "vertical"
                          ? "bg-white flipimg"
                          : "flipimg"
                    }
                    src="/img/hangout_vertical.svg"
                    onClick={this.changeLayout.bind(this, "vertical")}
                  />
                  <img
                    className={
                      this.state.numSubs !== 2 ||
                        (!this.state.video && !this.state.audio) ||
                        this.isSigned
                        ? "disable-lay"
                        : this.state.currentLayout === "2user"
                          ? "bg-white"
                          : ""
                    }
                    src="/img/float.svg"
                    onClick={this.changeLayout.bind(this, "2user")}
                  />
                  <img
                    className={
                      this.state.numSubs !== 2
                        ? "disable-lay"
                        : this.state.currentLayout === "vertical2"
                          ? "bg-white"
                          : ""
                    }
                    src="/img/f2f_vertical.svg"
                    onClick={this.changeLayout.bind(this, "vertical2")}
                  />
                  <img
                    className="hide"
                    src="/img/f2f_horizontal.svg"
                    onClick={this.changeLayout.bind(this, "horizontal2")}
                  />
                </div>
                <div
                  id="docParent"
                  className={
                    this.state.docLayout
                      ? "layout-screen doc-index-2 noscroll"
                      : "hide"
                  }
                >
                  <div
                    onClick={this.closeDocPlayer}
                    className={
                      this.state.role === "moderator"
                        ? "d-flex justify-content-end close-btn videoclose"
                        : "hide"
                    }
                  >
                    ×
                  </div>
                  <iframe
                    onLoad={this.onLoadCompleteFrame}
                    id="docPlayer"
                    width="100%"
                    height="100%"
                  ></iframe>
                </div>
                <div className="vdo-enlarge-blk" onClick={this.onStageFullscrn}>
                  <div className="d-flex align-items-center justify-content-center vdo-enlarge-container">
                    <img
                      src={
                        this.state.fullscrn
                          ? "/img/vdo_compress.svg"
                          : "/img/resize.svg"
                      }
                    />
                  </div>
                </div>
              </div>
              <div
                className={
                  document.getElementById("layout")
                    ? document.getElementById("layout").childElementCount > 0 ||
                      document.getElementById("layout-screen")
                        .childElementCount > 0 ||
                      this.state.videoLayout ||
                      this.state.docLayout ||
                      this.state.videoLayout ||
                      this.state.screen
                      ? "hide"
                      : "meeting-section sess-not-started"
                    : "meeting-section sess-not-started"
                }
              >
                <h1>{this.state.notstartedtxt}</h1>
              </div>
              <div className={this.state.notification ? "" : "hide"}>
                <Toast
                  closeNotification={this.closeNotification}
                  notification={this.state.notification}
                />
              </div>
              <ChatComponent
                showNotification={this.showNotification}
                sessionId={this.state.sessionId}
                chatClose={this.chatClose}
                newChat={this.newChat}
                blockBodyClick={this.blockBodyClick}
                ref={(chatRef) => (this.chatRef = chatRef)}
              />
              <div
                className={
                  this.state.chatEnabled ? "hide" : "chat-mobile-view-wrapper"
                }
                onClick={this.enableOrDisableChat}
              >
                <div className="d-flex align-items-center justify-content-center chat-mobile-icon-container">
                  <img src="/img/chat-icon.svg" className="chat-mobile-icon" />
                </div>
              </div>
            </div>

            <div className="vdo_bottom-part d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-start align-items-center confer-cover-img-blk-inner">
                <div
                  className={
                    this.state.coverImageUrl.length > 0
                      ? "confer-cover-img-blk"
                      : "hide"
                  }
                >
                  <div className="confer-cover-wrapper">
                    {this.state.coverImageUrl.map((img, index) => {
                      return (
                        <div
                          key={img}
                          className={
                            index < 2 ? "confer-cover-container" : "hide"
                          }
                        >
                          <img src={img} className="object-fill" />
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="tot-participants mr-3">
                  Time:{" "}
                  <ConferenceTime
                    ref={(timeRef) => (this.timeRef = timeRef)}
                    onUpdateTime={this.onUpdateTime}
                  />
                </div>
              </div>
              <div
                className={
                  this.state.connected
                    ? "activityIcon d-flex align-items-center justify-content-center  mod-mob-botton-tool"
                    : "hide"
                }
              >
                <div className="d-flex align-items-center vdo-menu-blk">
                  <div className="vdo-menu-list-wrap hide">
                    <div
                      className="vdo-menu-list leave-call sett-active"
                      onClick={this.hangup}
                    >
                      <img src="/img/call-white.svg" />
                    </div>
                    <h4>Leave</h4>
                  </div>
                  <div
                    className={
                      this.state.speakerMode ? "vdo-menu-list-wrap" : "hide"
                    }
                  >
                    <div
                      className={
                        this.state.speakerMode
                          ? this.state.isPublishProcess
                            ? this.state.video
                              ? "vdo-menu-list video active isAudioProcessing"
                              : "vdo-menu-list video isAudioProcessing"
                            : this.state.video
                              ? "vdo-menu-list video active"
                              : "vdo-menu-list video"
                          : "hide"
                      }
                      onClick={this.videoControl}
                    >
                      <img
                        src={
                          this.state.video
                            ? "/img/video.svg"
                            : "/img/video-cross.svg"
                        }
                      />
                      <i className="fas fa-spinner fa-spin"></i>
                    </div>
                    <h4>Camera</h4>
                  </div>
                  <div
                    className={
                      this.state.speakerMode && !this.isSigned
                        ? "vdo-menu-list-wrap"
                        : "hide"
                    }
                  >
                    <div
                      className={
                        this.state.speakerMode && !this.isSigned
                          ? this.state.isPublishProcess
                            ? this.state.audio
                              ? "vdo-menu-list mic active isAudioProcessing"
                              : "vdo-menu-list mic isAudioProcessing"
                            : this.state.audio
                              ? "vdo-menu-list mic active"
                              : "vdo-menu-list mic"
                          : "hide"
                      }
                      onClick={this.audioControl}
                    >
                      <img
                        src={
                          this.state.audio
                            ? "/img/mic.svg"
                            : "/img/mic-cross.svg"
                        }
                      />
                      <i className="fas fa-spinner fa-spin"></i>
                    </div>
                    <h4>Mic</h4>
                  </div>
                  <div className="vdo-menu-list-wrap hide">
                    <div className="vdo-menu-list slowdown">
                      <img src="/img/slowdown.svg" />
                    </div>
                    <h4>Slow down</h4>
                  </div>
                  <div
                    className={
                      this.state.screenAllow
                        ? this.state.speakerMode && !this.isSigned
                          ? "vdo-menu-list-wrap"
                          : "hide"
                        : "hide"
                    }
                  >
                    <div
                      className={
                        this.state.screenAllow
                          ? this.state.speakerMode && !this.isSigned
                            ? this.state.screen
                              ? "vdo-menu-list screenshare active"
                              : "vdo-menu-list screenshare"
                            : "hide"
                          : "hide"
                      }
                      onClick={this.screenControl}
                    >
                      <img
                        src={
                          this.state.screen
                            ? "/img/share-screen.svg"
                            : "/img/share-screen-cross.svg"
                        }
                      />
                    </div>
                    <h4>Share</h4>
                  </div>
                  <div
                    className={this.isSigned ? "hide" : "vdo-menu-list-wrap"}
                  >
                    <div
                      className={
                        this.isSigned
                          ? "hide"
                          : this.state.raiseHanded
                            ? "vdo-menu-list raisehand active"
                            : "vdo-menu-list raisehand"
                      }
                      onClick={this.onRaiseHand}
                    >
                      <img src="/img/hand-raise.svg" />
                    </div>
                    <h4>Raise Hand</h4>
                  </div>
                  {(this.state.speakerUserList ||
                    this.state.allowMasterSpeaker) && (
                      <div className="vdo-menu-list-wrap">
                        <a
                          className="vdo-menu-list part"
                          href={
                            "/" +
                            this.state.sessionId +
                            "/speakerpanel/panel/" +
                            this.userId
                          }
                          target="_blank"
                        >
                          <i className="fa fa-users"></i>
                        </a>
                        <h4>Participants</h4>
                      </div>
                    )}
                  {!this.state.disableChat && (
                    <div className="vdo-menu-list-wrap">
                      <div
                        className={
                          this.state.chatEnabled
                            ? "vdo-menu-list message active"
                            : this.state.unReadChat
                              ? "vdo-menu-list message chat-not"
                              : "vdo-menu-list message"
                        }
                        onClick={this.enableOrDisableChat}
                      >
                        <img src="/img/message.svg" />
                      </div>
                      <h4>Message</h4>
                    </div>
                  )}

                  <div
                    className={
                      this.state.docLayout && this.state.enableDownloadPpt
                        ? "vdo-menu-list-wrap"
                        : "hide"
                    }
                    onClick={this.donwloadDocument}
                  >
                    <a
                      href={pptDownlodUrl}
                      target="_blank"
                      className="vdo-menu-list docdown"
                    >
                      <i className="fa fa-download"></i>
                    </a>
                    <h4>Download</h4>
                  </div>

                  <div
                    className={
                      this.state.presentationTimer > 0
                        ? "vdo-menu-list-wrap"
                        : "hide"
                    }
                  >
                    <div
                      className={
                        this.state.presentationTimer > 0
                          ? "vdo-menu-list message active cntmenu"
                          : "opacity0"
                      }
                    >
                      {this.state.presentationTimerDisplay}
                    </div>
                    <h4
                      className={this.state.presentationTimer > 0 ? "" : "hide"}
                    >
                      Countdown For You
                    </h4>
                  </div>
                </div>
              </div>
              <div
                className={
                  this.state.coverImageUrl.length > 0
                    ? "confer-cover-img-blk"
                    : "confer-cover-img-blk"
                }
              >
                <div className="confer-cover-wrapper">
                  {this.state.coverImageUrl.map((img, index) => {
                    return (
                      <div
                        key={img}
                        className={
                          index >= 2 ? "confer-cover-container" : "hide"
                        }
                      >
                        <img src={img} className="object-fill" />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <section className={this.state.alert ? "alert-wrap" : "hide"}>
              <Alert
                onCloseAlert={this.onCloseAlert}
                alert={this.state.alert}
                message={this.state.alertMessage}
              />
            </section>
          </section>
        )
      } else if (this.state.role == "listener") {
        return (
          <section
            onClick={this.bodyClick.bind(this)}
            className={
              this.state.chatEnabled ? "vc-session chat-enabled" : "vc-session"
            }
          >
            <div className={this.state.pollOpen ? "poll-backdrop" : "hide"}>
              <PollComponent
                sendToAllPusher={this.sendToAllPusher}
                getAllPolls={this.getAllPolls}
                role={this.state.role}
                showNotification={this.showNotification}
                onPollReceive={this.onPollReceive}
                closePoll={this.closePoll}
                ref={(pollRef) => (this.pollRef = pollRef)}
                sessionId={this.state.sessionId}
              />
            </div>
            <div
              className={
                this.state.showTechnicalSupport ? "fixed-backdrop" : "hide"
              }
            >
              <div className="custom-caption-pop-up">
                <div className="d-flex align-items-center justify-content-between custom-popup-cap-header-blk">
                  <div className="custom-caption-header">Technical Support</div>
                  <div className="support-close" onClick={this.cloeSupportPop}>
                    X
                  </div>
                </div>
                <TechnicalSupport
                  showNotification={this.showNotification}
                  sessionId={this.state.sessionId}
                  newChat={this.newSupportChat}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between vdo-top-part top-part-viewer">
              <div className="d-flex align-items-center">
                <div className="logoo">
                  <img
                    src={this.state.logo}
                    onError={this.onErrorImg.bind(this)}
                  />
                  {/* <button
                    id="load-test"
                    style={{
                      display: "absolute",
                      top: "35%",
                      left: "35%",
                      backgroundColor: this.state.allowLoad ? "green" : "red",
                    }}
                    onClick={() => this.setState({ allowLoad: true })}
                  >
                    LOAD TEST 3
                  </button> */}
                </div>
                <span className="username">{this.state.logoText}</span>
              </div>
              <div className="d-flex align-items-center top-menu-wrapper">
                <div className="tot-participants float-part-count d-flex align-items-center">
                  <i className="fa fa-user"></i>
                  <span id="totalCon">
                    {Object.keys(this.state.participants).length}
                  </span>
                </div>
                <div className="speaker-lang-blk">
                  <div className="interpreter-section arrow-up">
                    <div
                      className="d-flex align-items-center lang-container interpreter-arrow-down"
                      onClick={this.onToggleInterpreterListInModerator}
                    >
                      <div className="flag-avatar">
                        <img src="/img/interpreter.svg" />
                      </div>
                      <div className="flag-lang notranslate">
                        {this.state.selectedInterpreterInModerator ===
                          this.interpretersLabel
                          ? this.state.floorTitle
                          : this.state.selectedInterpreterInModerator}
                      </div>
                    </div>
                    <div
                      className={
                        this.state.moderatorInterpreterlistOpen
                          ? "interpreter-popover"
                          : "hide"
                      }
                    >
                      <div className="interpret-wrapper">
                        <div
                          onClick={this.switchToFloor.bind(this)}
                          className="d-flex align-items-center interpreter-container pointer"
                        >
                          <div className="active-blink"></div>
                          <div className="flag-lang notranslate">
                            {" "}
                            {this.state.floorTitle}
                          </div>
                        </div>
                        {(addedLanguage = [])}
                        {Object.keys(this.state.participants).map(
                          (key, index) => {
                            if (
                              this.state.participants[key].userData.role ===
                              "interpreter" &&
                              this.state.participants[key].userData.audio &&
                              !addedLanguage.hasOwnProperty(
                                this.state.participants[key].userData.language,
                              )
                            ) {
                              addedLanguage[
                                this.state.participants[key].userData.language
                              ] = "true"
                              return (
                                <div
                                  key={key}
                                  onClick={this.onChangeSelectedInterpreterInModerator.bind(
                                    this,
                                    this.state.participants[key].userData
                                      .language,
                                    this.state.participants[key].connection.id,
                                  )}
                                  className="d-flex align-items-center interpreter-container pointer"
                                >
                                  <div
                                    className={
                                      this.state.participants[key].userData
                                        .audio
                                        ? "active-blink"
                                        : "absolute"
                                    }
                                  ></div>
                                  <div className="flag-lang notranslate">
                                    {" "}
                                    {
                                      this.state.participants[key].userData
                                        .language
                                    }
                                  </div>
                                </div>
                              )
                            }
                          },
                        )}
                        {Object.keys(this.state.participants).map((key) => {
                          if (
                            this.state.participants[key].userData.role ===
                            "interpreter" &&
                            !this.state.participants[key].userData.audio &&
                            !addedLanguage.hasOwnProperty(
                              this.state.participants[key].userData.language,
                            )
                          ) {
                            addedLanguage[
                              this.state.participants[key].userData.language
                            ] = "true"
                            return (
                              <div
                                key={key}
                                onClick={this.onChangeSelectedInterpreterInModerator.bind(
                                  this,
                                  this.state.participants[key].userData
                                    .language,
                                  this.state.participants[key].connection.id,
                                )}
                                className="d-flex align-items-center interpreter-container pointer"
                              >
                                <div
                                  className={
                                    this.state.participants[key].userData.audio
                                      ? "active-blink"
                                      : "absolute"
                                  }
                                ></div>
                                <div className="flag-lang notranslate">
                                  {" "}
                                  {
                                    this.state.participants[key].userData
                                      .language
                                  }
                                </div>
                              </div>
                            )
                          }
                        })}
                        {this.state.avalilableLanguages.map((lang, i) => {
                          if (!addedLanguage.hasOwnProperty(lang)) {
                            addedLanguage[lang] = "true"
                            return (
                              <div
                                key={i}
                                onClick={this.onChangeSelectedInterpreterInModeratorBefore.bind(
                                  this,
                                  lang,
                                  1,
                                )}
                                className="d-flex align-items-center interpreter-container pointer"
                              >
                                <div className="absolute"></div>
                                <div className="flag-lang notranslate">
                                  {" "}
                                  {lang}
                                </div>
                              </div>
                            )
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="voice-controller">
                    <div className="voice-slidecontainer top-volume">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        onChange={this.onInterpreterVolumeChangeInModerator.bind(
                          this,
                        )}
                        value={this.state.InterpreterVolumeInModerator}
                        className={
                          this.state.selectedInterpreterInModerator ===
                            "Select Interpreter"
                            ? "voice-slider nomouse"
                            : "voice-slider pointer"
                        }
                      />
                      <div className="custom-menu-tooltip">
                        {this.state.InterpreterVolumeInModerator}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="speaker-lang-blk">
                  <div
                    className={
                      this.state.selectedInterpreterInModerator ===
                        this.interpretersLabel
                        ? "floor-active interpreter-section speaker-floor-blk"
                        : "interpreter-section speaker-floor-blk"
                    }
                  >
                    <div className="d-flex align-items-center lang-container">
                      <div className="d-flex align-items-center justify-content-center floor-avatar">
                        <i className="fa fa-user"></i>
                      </div>
                      <div className="floor-caption">
                        {this.state.floorTitle}
                      </div>
                    </div>
                  </div>
                  <div className="voice-controller">
                    <div className="voice-slidecontainer top-volume">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        onChange={this.onSpeakerVolumeChangeInModerator.bind(
                          this,
                        )}
                        value={this.state.speakerVolumeInModerator}
                        className="voice-slider pointer"
                      />
                      <div className="custom-menu-tooltip">
                        {this.state.speakerVolumeInModerator}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vdo-top-right-part mobile-video-topright">
                <div
                  className={
                    this.state.numSubs < 1 ? "hide" : "layoutman-teams"
                  }
                  id="layout-manage"
                >
                  <img
                    className={
                      this.state.numSubs <= 2
                        ? "disable-lay"
                        : this.state.currentLayout === "grid"
                          ? "bg-white"
                          : ""
                    }
                    src="/img/grid.svg"
                    onClick={this.changeLayout.bind(this, "grid")}
                  />
                  <img
                    className={
                      this.state.numSubs <= 2
                        ? "disable-lay"
                        : this.state.currentLayout === "vertical"
                          ? "bg-white flipimg"
                          : "flipimg"
                    }
                    src="/img/hangout_vertical.svg"
                    onClick={this.changeLayout.bind(this, "vertical")}
                  />
                  <img
                    className={
                      this.state.numSubs !== 2 ||
                        (!this.state.video && !this.state.audio) ||
                        this.isSigned
                        ? "disable-lay"
                        : this.state.currentLayout === "2user"
                          ? "bg-white"
                          : ""
                    }
                    src="/img/float.svg"
                    onClick={this.changeLayout.bind(this, "2user")}
                  />
                  <img
                    className={
                      this.state.numSubs !== 2
                        ? "disable-lay"
                        : this.state.currentLayout === "vertical2"
                          ? "bg-white"
                          : ""
                    }
                    src="/img/f2f_vertical.svg"
                    onClick={this.changeLayout.bind(this, "vertical2")}
                  />
                  <img
                    className="hide"
                    src="/img/f2f_horizontal.svg"
                    onClick={this.changeLayout.bind(this, "horizontal2")}
                  />
                </div>
                <a className="bandwidth-section trouble">
                  <div
                    className="translate-viewer notranslate"
                    id="google_translate_element"
                  ></div>
                  <button
                    className="skiptr notranslate"
                    onClick={this.skipTrans}
                  >
                    Skip Translation
                  </button>
                </a>
                <div
                  onClick={this.showTechnicalSupport}
                  className="toggle-menu ml-3 help-butn"
                  data-toggle="offcanvas"
                >
                  <img src="/img/support.svg" />
                  <div className="custom-menu-tooltip">Technical Support</div>
                </div>
                <div
                  onClick={this.toggleSidbar}
                  className="toggle-menu ml-3 mobile-hide"
                  data-toggle="offcanvas"
                >
                  <img src="/img/menu-icon.svg" />
                </div>
                <div
                  onClick={this.mobMenuToggle}
                  className="toggle-menu ml-3 desktop-hide"
                  data-toggle="offcanvas"
                >
                  <img src="/img/menu-icon.svg" />
                </div>
              </div>
            </div>

            <div
              className={this.state.mobMenuToggle ? "sidenav-blk open" : "hide"}
            >
              <div className="d-flex align-items-center justify-content-end sidebar-top-part">
                <span className="close" onClick={this.mobMenuToggle}>
                  ×
                </span>
              </div>
              <ul className="sidenav-menu-wrap">
                <li className={this.state.teamAppMobile ? "" : ""}>
                  <span
                    onClick={this.onToggleInterpreterListInModerator}
                    className="interpreter-dropnotranslate"
                  >
                    {this.state.selectedInterpreterInModerator}{" "}
                    <span
                      className={
                        this.state.moderatorInterpreterlistOpen
                          ? "arrow-open"
                          : "arrow-close"
                      }
                    ></span>
                  </span>
                  {(addedLanguage = [])}
                  <ul
                    className={
                      this.state.moderatorInterpreterlistOpen
                        ? "Interpreters-lang"
                        : "hide"
                    }
                  >
                    {Object.keys(this.state.participants).map((key) => {
                      if (
                        this.state.participants[key].userData.role ===
                        "interpreter" &&
                        this.state.participants[key].userData.audio &&
                        !addedLanguage.hasOwnProperty(
                          this.state.participants[key].userData.language,
                        )
                      ) {
                        addedLanguage[
                          this.state.participants[key].userData.language
                        ] = "true"
                        return (
                          <li
                            key={key}
                            onClick={this.onChangeSelectedInterpreterInModerator.bind(
                              this,
                              this.state.participants[key].userData.language,
                              this.state.participants[key].connection.id,
                            )}
                          >
                            <div
                              className={
                                this.state.participants[key].userData.audio
                                  ? "active-blink blinkmob"
                                  : "absolute"
                              }
                            ></div>
                            <span
                              className={
                                this.state.participants[key].userData
                                  .language ===
                                  this.state.selectedInterpreterInModerator
                                  ? "menulst-active"
                                  : ""
                              }
                            >
                              {this.state.participants[key].userData.language}
                            </span>
                          </li>
                        )
                      }
                    })}
                    {Object.keys(this.state.participants).map((key) => {
                      if (
                        this.state.participants[key].userData.role ===
                        "interpreter" &&
                        !this.state.participants[key].userData.audio &&
                        !addedLanguage.hasOwnProperty(
                          this.state.participants[key].userData.language,
                        )
                      ) {
                        addedLanguage[
                          this.state.participants[key].userData.language
                        ] = "true"
                        return (
                          <li
                            key={key}
                            onClick={this.onChangeSelectedInterpreterInModerator.bind(
                              this,
                              this.state.participants[key].userData.language,
                              this.state.participants[key].connection.id,
                            )}
                          >
                            <div
                              className={
                                this.state.participants[key].userData.audio
                                  ? "active-blink blinkmob"
                                  : "absolute"
                              }
                            ></div>
                            <span
                              className={
                                this.state.participants[key].userData
                                  .language ===
                                  this.state.selectedInterpreterInModerator
                                  ? "menulst-active"
                                  : ""
                              }
                            >
                              {this.state.participants[key].userData.language}
                            </span>
                          </li>
                        )
                      }
                    })}
                    {this.state.avalilableLanguages.map((lang, i) => {
                      if (!addedLanguage.hasOwnProperty(lang)) {
                        addedLanguage[lang] = "true"
                        return (
                          <div
                            key={i}
                            onClick={this.onChangeSelectedInterpreterInModeratorBefore.bind(
                              this,
                              lang,
                              1,
                            )}
                            className="d-flex align-items-center interpreter-container pointer"
                          >
                            <div className="absolute"></div>
                            <div className="flag-lang notranslate"> {lang}</div>
                          </div>
                        )
                      }
                    })}
                  </ul>
                </li>
                <li className={this.state.teamAppMobile ? "" : ""}>
                  <span>
                    Interpreter Audio <span className="arrow-open"></span>
                  </span>
                  <div className="mobile-voice-controller">
                    <div className="voice-slidecontainer">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        onChange={this.onInterpreterVolumeChangeInModerator.bind(
                          this,
                        )}
                        value={this.state.InterpreterVolumeInModerator}
                        className={
                          this.state.selectedInterpreterInModerator ===
                            "Select Interpreter"
                            ? "voice-slider nomouse"
                            : "voice-slider pointer"
                        }
                      />
                      <div className="custom-menu-tooltip">
                        {this.state.InterpreterVolumeInModerator}
                      </div>
                    </div>
                  </div>
                </li>
                <li className="hide">
                  <span>
                    Floor <span className="arrow-open"></span>
                  </span>
                </li>
                <li>
                  <span>
                    Floor Audio <span className="arrow-open"></span>
                  </span>
                  <div className="mobile-voice-controller">
                    <div className="voice-slidecontainer">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        onChange={this.onSpeakerVolumeChangeInModerator.bind(
                          this,
                        )}
                        value={this.state.speakerVolumeInModerator}
                        className="voice-slider pointer"
                      />
                      <div className="custom-menu-tooltip">
                        {this.state.speakerVolumeInModerator}
                      </div>
                    </div>
                  </div>
                </li>
                <li className="ipad-hide">
                  <a
                    href={
                      "/precall-test/index.html?id=" +
                      Config.SESSION_ID +
                      "&token=" +
                      Config.TOKEN_PUBLISHER
                    }
                    target="_blank"
                  >
                    <span>Trouble with connection ?</span>
                  </a>
                </li>
                <li className="ipad-hide">
                  <a onClick={this.startMySpeedTest}>
                    <span>Speed Test</span>
                  </a>
                </li>
                <li className="ipad-hide" onClick={this.onRefresh}>
                  <span>Refresh</span>
                </li>
                <li className="ipad-hide" onClick={this.onLogout}>
                  <span>Logout</span>
                </li>
              </ul>
            </div>

            <div className="vdo-display-wrapper" id="confbg">
              <div className={this.state.sidebaropen ? "sidebar open" : "hide"}>
                <ul>
                  <li>
                    <a
                      href={
                        "/precall-test/index.html?id=" +
                        Config.SESSION_ID +
                        "&token=" +
                        Config.TOKEN_PUBLISHER
                      }
                      target=" _blank"
                      className="nav-link"
                    >
                      <span className="icon">?</span>
                      <span className="link">Run Test</span>
                    </a>
                    <a
                      onClick={this.showRtmpLink}
                      className={
                        this.state.isStreamingOut ? "nav-link hide" : "hide"
                      }
                    >
                      <span className="icon">RTMP</span>
                      <div className="link">Shows RTMP links</div>
                    </a>
                    <a
                      onClick={this.startMySpeedTest}
                      className={
                        this.state.speedOfMe ? "nav-link mobile-hide" : "hide"
                      }
                    >
                      <span className="icon">
                        <img src="/img/bandwidth.svg" />
                      </span>
                      <span className="link">Speed Test</span>
                    </a>
                    <a className="nav-link" onClick={this.onRefresh}>
                      <span className="icon">
                        <img src="/img/refresh.svg" />
                      </span>
                      <span className="link">Refresh Page</span>
                    </a>
                    <a className="nav-link" onClick={this.onSettings}>
                      <span className="icon">
                        <img src="/img/settings.svg" />
                      </span>
                      <span className="link">Audio / Video Settings</span>
                    </a>

                    <a className="nav-link" onClick={this.onLogout}>
                      <span className="icon">
                        <img src="/img/logout.svg" />
                      </span>
                      <span className="link">Logout</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div
                id="middle-video-content"
                className="meeting-section video-area"
              >
                <Draggable>
                  <div
                    className={
                      this.state.screenLayout
                        ? "signed-video right300"
                        : "signed-video"
                    }
                  >
                    {this.state.streams.map((stream) => {
                      let split = stream.name.split("_")
                      return split[3] == "signed" && this.isSigned == false
                        ? this.renderStreams(stream)
                        : ""
                    })}
                  </div>
                </Draggable>
                <div
                  id="layout"
                  className={
                    this.state.screenLayout ||
                      this.state.videoLayout ||
                      this.state.docLayout
                      ? "screen-layout"
                      : ""
                  }
                >
                  {this.state.streams.map((stream) => {
                    let split = stream.name.split("_")
                    let role = split[0]
                    return role !== "interpreter" &&
                      split[3] != "screen" &&
                      split[3] != "signed"
                      ? this.renderStreams(stream)
                      : ""
                  })}
                </div>
                <div id="layout-hidden" className="hide">
                  {this.state.streams.map((stream) => {
                    let splithidden = stream.name.split("_")
                    let rolehidden = splithidden[0]
                    return rolehidden === "interpreter" &&
                      splithidden[3] != "screen"
                      ? this.renderStreams(stream)
                      : ""
                  })}
                </div>
                <div
                  id="layout-screen"
                  className={this.state.screenLayout ? "layout-screen" : "hide"}
                >
                  {this.state.streams.map((stream) => {
                    let screendt = stream.name.split("_")
                    return screendt[3] == "screen"
                      ? this.renderStreams(stream)
                      : ""
                  })}
                </div>
                <div
                  className={
                    this.state.videoLayout
                      ? "layout-screen mob-vdo-layout-screen"
                      : "hide"
                  }
                >
                  <div
                    onClick={this.closeVideoPlayer}
                    className={
                      this.state.role === "moderator"
                        ? "d-flex justify-content-end close-btn videoclose"
                        : "hide"
                    }
                  >
                    ×
                  </div>
                  <video
                    onContextMenu={() => false}
                    id="videoPlayer"
                    width="100%"
                    height="100%"
                    poster="/img/audioonly.jpeg"
                  />
                </div>

                <div
                  id="docParent"
                  className={
                    this.state.docLayout
                      ? "layout-screen doc-index-2 noscroll"
                      : "hide"
                  }
                >
                  <div
                    onClick={this.closeDocPlayer}
                    className={
                      this.state.role === "moderator"
                        ? "d-flex justify-content-end close-btn videoclose"
                        : "hide"
                    }
                  >
                    ×
                  </div>
                  <iframe
                    onLoad={this.onLoadCompleteFrame}
                    id="docPlayer"
                    width="100%"
                    height="100%"
                  ></iframe>
                </div>
                <div className="vdo-enlarge-blk" onClick={this.onStageFullscrn}>
                  <div className="d-flex align-items-center justify-content-center vdo-enlarge-container">
                    <img
                      src={
                        this.state.fullscrn
                          ? "/img/vdo_compress.svg"
                          : "/img/resize.svg"
                      }
                    />
                  </div>
                </div>
              </div>
              <div
                className={
                  document.getElementById("layout")
                    ? document.getElementById("layout").childElementCount > 0 ||
                      document.getElementById("layout-screen")
                        .childElementCount > 0 ||
                      this.state.videoLayout ||
                      this.state.docLayout ||
                      this.state.screen
                      ? "hide"
                      : "meeting-section sess-not-started"
                    : "meeting-section sess-not-started"
                }
              >
                <h1>{this.state.notstartedtxt}</h1>
              </div>
              <div className={this.state.notification ? "" : "hide"}>
                <Toast
                  closeNotification={this.closeNotification}
                  notification={this.state.notification}
                />
              </div>
              <ChatComponent
                showNotification={this.showNotification}
                sessionId={this.state.sessionId}
                chatClose={this.chatClose}
                newChat={this.newChat}
                blockBodyClick={this.blockBodyClick}
                ref={(chatRef) => (this.chatRef = chatRef)}
              />
              <div
                className={
                  this.state.chatEnabled ? "hide" : "chat-mobile-view-wrapper"
                }
                onClick={this.enableOrDisableChat}
              >
                <div className="d-flex align-items-center justify-content-center chat-mobile-icon-container">
                  <img src="/img/chat-icon.svg" className="chat-mobile-icon" />
                </div>
              </div>
            </div>

            <div className="vdo_bottom-part d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-start align-items-center confer-cover-img-blk-inner">
                <div
                  className={
                    this.state.coverImageUrl.length > 0
                      ? "confer-cover-img-blk"
                      : "hide"
                  }
                >
                  <div className="confer-cover-wrapper">
                    {this.state.coverImageUrl.map((img, index) => {
                      return (
                        <div
                          key={img}
                          className={
                            index < 2 ? "confer-cover-container" : "hide"
                          }
                        >
                          <img src={img} className="object-fill" />
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="tot-participants mr-3">
                  Time:{" "}
                  <ConferenceTime
                    ref={(timeRef) => (this.timeRef = timeRef)}
                    onUpdateTime={this.onUpdateTime}
                  />
                </div>
              </div>
              <div
                className={
                  this.state.connected
                    ? "activityIcon d-flex align-items-center justify-content-center  mod-mob-botton-tool"
                    : "hide"
                }
              >
                <div className="vdo-menu-list-wrap">
                  <div
                    className="d-flex align-items-center justify-content-center media-menu-wrapper call-close"
                    onClick={this.hangup}
                  >
                    <img src="/img/call-white.svg" />
                  </div>
                  <h4>Leave</h4>
                </div>
                {!this.state.disableChat && (
                  <div className="vdo-menu-list-wrap">
                    <div
                      className={
                        this.state.chatEnabled
                          ? "vdo-menu-list message active"
                          : this.state.unReadChat
                            ? "vdo-menu-list message chat-not"
                            : "vdo-menu-list message"
                      }
                      onClick={this.enableOrDisableChat}
                    >
                      <img src="/img/message.svg" />
                    </div>
                    <h4>Message</h4>
                  </div>
                )}

                <div
                  className={
                    this.state.docLayout && this.state.enableDownloadPpt
                      ? "vdo-menu-list-wrap"
                      : "hide"
                  }
                  onClick={this.donwloadDocument}
                >
                  <a
                    href={pptDownlodUrl}
                    target="_blank"
                    className="vdo-menu-list docdown"
                  >
                    <i className="fa fa-download"></i>
                  </a>
                  <h4>Download</h4>
                </div>
              </div>
              <div
                className={
                  this.state.coverImageUrl.length > 0
                    ? "confer-cover-img-blk"
                    : "confer-cover-img-blk"
                }
              >
                <div className="confer-cover-wrapper">
                  {this.state.coverImageUrl.map((img, index) => {
                    return (
                      <div
                        key={img}
                        className={
                          index >= 2 ? "confer-cover-container" : "hide"
                        }
                      >
                        <img src={img} className="object-fill" />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>

            <section className={this.state.alert ? "alert-wrap" : "hide"}>
              <Alert
                onCloseAlert={this.onCloseAlert}
                alert={this.state.alert}
                message={this.state.alertMessage}
              />
            </section>
          </section>
        )
      } else if (this.state.role == "interpreter") {
        return (
          <section
            onClick={this.bodyClick.bind(this)}
            className={
              this.state.chatEnabled ? "vc-session chat-enabled" : "vc-session"
            }
          >
            <section className={this.state.confirm ? "alert-wrap" : "hide"}>
              <Confirm
                ref={(confirmRef) => (this.confirmRef = confirmRef)}
                cancel={this.onCancelConfirm}
                proceed={this.onProceedParterStream}
              />
            </section>
            <div
              className={
                this.state.showTechnicalSupport ? "fixed-backdrop" : "hide"
              }
            >
              <div className="custom-caption-pop-up">
                <div className="d-flex align-items-center justify-content-between custom-popup-cap-header-blk">
                  <div className="custom-caption-header">Technical Support</div>
                  <div className="support-close" onClick={this.cloeSupportPop}>
                    X
                  </div>
                </div>
                <TechnicalSupport
                  showNotification={this.showNotification}
                  sessionId={this.state.sessionId}
                  newChat={this.newSupportChat}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between vdo-top-part">
              <div className="d-flex align-items-center">
                <div className="logoo">
                  <img
                    src={this.state.logo}
                    onError={this.onErrorImg.bind(this)}
                  />
                  {/* <button
                    id="load-test"
                    style={{
                      display: "absolute",
                      top: "35%",
                      left: "35%",
                      backgroundColor: this.state.allowLoad ? "green" : "red",
                    }}
                    onClick={() => this.setState({ allowLoad: true })}
                  >
                    LOAD TEST 4
                  </button>*/}
                  <div style={styles.radioContainer}>
                    <div id="config-radio-group" style={styles.radioGroup}>
                      <label style={styles.radioOption}>Config</label>
                      {["v2+advanced", "v2+custom1", "original"].map((value) => (
                        <label key={value} style={styles.radioOption}>
                          <input
                            type="radio"
                            name="stateGroup"
                            value={value}
                            checked={this.state.pubPropsKey === value}
                            onChange={() =>
                              this.setState({
                                pubPropsKey: value,
                                pubProps: configs[value],
                                customAudioStream: ["v2+custom1"].includes(value),
                              })
                            }
                          />
                          {value}
                        </label>
                      ))}
                    </div>
                    {/* <div id="noise-radio-group" style={styles.radioGroup}>
                      <label style={styles.radioOption}>Anti Noise</label>
                      {["on", "off"].map((value) => (
                        <label key={value} style={styles.radioOption}>
                          <input
                            type="radio"
                            name="noiseGroup"
                            value={value}
                            checked={
                              this.state.advancedNoise === (value === "on")
                            }
                            onChange={() =>
                              this.setState({
                                advancedNoise: value === "on",
                              })
                            }
                          />
                          {value}
                        </label>
                      ))}
                    </div> */}
                  </div>
                </div>
                <span className="username">{this.state.logoText}</span>
                <div className="tot-participants conf-time-part">
                  Time:{" "}
                  <ConferenceTime
                    ref={(timeRef) => (this.timeRef = timeRef)}
                    onUpdateTime={this.onUpdateTime}
                  />
                </div>

                <div className="d-flex align-items-center interpre-active-rep-blk">
                  <div className="tot-participants float-part-count d-flex align-items-center">
                    <i className="fa fa-user"></i>
                    <span id="totalCon">
                      {Object.keys(this.state.participants).length}
                    </span>
                  </div>
                  <div className="interpre-label-air">Interpreter</div>

                  <div
                    className={
                      this.state.settingsOpen
                        ? "hide"
                        : this.state.audio
                          ? "d-flex align-items-center interpre-active-blk onairInter"
                          : "d-flex align-items-center interpre-active-blk"
                    }
                  >
                    <div className="active-intre-name">
                      {UserSession.getSession().userName +
                        "-" +
                        this.state.language}
                    </div>
                    <div className="d-flex align-items-center active-on-air-blk">
                      <div
                        className={this.state.audio ? "active-blink" : "hide"}
                      ></div>
                      <div className="on-air-txt">
                        {this.state.audio ? "On Air" : "Off Air"}
                      </div>
                    </div>
                  </div>
                  {Object.keys(this.state.participants).map((key) => {
                    if (
                      this.state.participants[key].userData.role ===
                      "interpreter" &&
                      this.state.participants[key].userData.language ===
                      this.state.language
                    ) {
                      return (
                        <div
                          key={key}
                          className={
                            this.state.participants[key].userData.audio
                              ? "d-flex align-items-center interpre-active-blk onairInter"
                              : "d-flex align-items-center interpre-active-blk"
                          }
                        >
                          <div className="active-intre-name">
                            {this.state.participants[key].userData.userName +
                              "-" +
                              this.state.participants[key].userData.language}
                          </div>
                          <div className="d-flex align-items-center active-on-air-blk">
                            <div
                              className={
                                this.state.participants[key].userData.audio
                                  ? "active-blink"
                                  : "hide"
                              }
                            ></div>
                            <div className="on-air-txt">
                              {this.state.participants[key].userData.audio
                                ? "On Air"
                                : "Off Air"}
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
              <div className="vdo-top-right-part">
                <a className="bandwidth-section trouble">
                  <div
                    className="notranslate"
                    id="google_translate_element"
                  ></div>
                  <button
                    className="skiptr notranslate"
                    onClick={this.skipTrans}
                  >
                    Skip Translation
                  </button>
                </a>
                <div
                  onClick={this.showTechnicalSupport}
                  className="toggle-menu ml-3 help-butn"
                  data-toggle="offcanvas"
                >
                  <img src="/img/support.svg" />
                  <div className="custom-menu-tooltip">Technical Support</div>
                </div>
                <div
                  onClick={this.toggleSidbar}
                  className="toggle-menu ml-3 mobile-hide"
                  data-toggle="offcanvas"
                >
                  <img src="/img/menu-icon.svg" />
                </div>
                <div
                  onClick={this.mobMenuToggle}
                  className="toggle-menu ml-3 desktop-hide"
                  data-toggle="offcanvas"
                >
                  <img src="/img/menu-icon.svg" />
                </div>
              </div>
            </div>
            <div
              className={this.state.mobMenuToggle ? "sidenav-blk open" : "hide"}
            >
              <div className="d-flex align-items-center justify-content-end sidebar-top-part">
                <span className="close" onClick={this.mobMenuToggle}>
                  ×
                </span>
              </div>
              <ul className="sidenav-menu-wrap">
                <li>
                  <span>
                    Time :
                    <span className="c-time">
                      <ConferenceTime
                        ref={(timeRefMob) => (this.timeRefMob = timeRefMob)}
                        onUpdateTime={this.onUpdateTime}
                      />
                    </span>{" "}
                  </span>
                </li>
                <li>
                  <a
                    href={
                      "/precall-test/index.html?id=" +
                      Config.SESSION_ID +
                      "&token=" +
                      Config.TOKEN_PUBLISHER
                    }
                    target="_blank"
                  >
                    <span>Trouble with connection ?</span>
                  </a>
                </li>
                <li>
                  <a onClick={this.startMySpeedTest} target="_blank">
                    <span>Speed Test</span>
                  </a>
                </li>
                <li onClick={this.onSettings}>
                  <span>Media Settings</span>
                </li>
                <li onClick={this.onRefresh}>
                  <span>Refresh</span>
                </li>
                <li onClick={this.onLogout}>
                  <span>Logout</span>
                </li>
              </ul>
            </div>

            <div
              className="vdo-display-wrapper interpreter-display-wrapper"
              id="confbg"
            >
              <div className={this.state.sidebaropen ? "sidebar open" : "hide"}>
                <ul>
                  <li>
                    <a
                      href={
                        "/precall-test/index.html?id=" +
                        Config.SESSION_ID +
                        "&token=" +
                        Config.TOKEN_PUBLISHER
                      }
                      target=" _blank"
                      className="nav-link"
                    >
                      <span className="icon">?</span>
                      <span className="link">Run Test</span>
                    </a>
                    <a
                      onClick={this.startMySpeedTest}
                      className={
                        this.state.speedOfMe ? "nav-link mobile-hide" : "hide"
                      }
                    >
                      <span className="icon">
                        <img src="/img/bandwidth.svg" />
                      </span>
                      <span className="link">Speed Test</span>
                    </a>
                    <a className="nav-link" onClick={this.onRefresh}>
                      <span className="icon">
                        <img src="/img/refresh.svg" />
                      </span>
                      <span className="link">Refresh Page</span>
                    </a>
                    <a className="nav-link" onClick={this.onSettings}>
                      <span className="icon">
                        <img src="/img/settings.svg" />
                      </span>
                      <span className="link">Audio / Video Settings</span>
                    </a>

                    <a className="nav-link" onClick={this.onLogout}>
                      <span className="icon">
                        <img src="/img/logout.svg" />
                      </span>
                      <span className="link">Logout</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div
                id="middle-video-content"
                className="meeting-section video-area"
              >
                <Draggable>
                  <div
                    className={
                      this.state.settingsOpen || !this.state.deviceInit
                        ? "hide"
                        : this.state.screenLayout
                          ? "signed-video right300"
                          : "signed-video"
                    }
                  >
                    {this.state.streams.map((stream) => {
                      let split = stream.name.split("_")
                      return split[3] == "signed" && this.isSigned == false
                        ? this.renderStreams(stream)
                        : ""
                    })}
                  </div>
                </Draggable>
                <div
                  id="layout"
                  className={
                    this.state.settingsOpen
                      ? "hide"
                      : this.state.screenLayout ||
                        this.state.videoLayout ||
                        this.state.docLayout
                        ? "screen-layout"
                        : ""
                  }
                >
                  {this.state.streams.map((stream) => {
                    let split = stream.name.split("_")
                    let role = split[0]
                    return role !== "interpreter" &&
                      split[3] != "screen" &&
                      split[3] != "signed"
                      ? this.renderStreams(stream)
                      : ""
                  })}
                </div>
                <div
                  id="layout-hidden"
                  className={this.state.settingsOpen ? "" : "hide"}
                >
                  {this.state.role === "interpreter"
                    ? this.renderPublisher(false)
                    : ""}
                </div>
                <div
                  id="layout-screen"
                  className={this.state.screenLayout ? "layout-screen" : "hide"}
                >
                  {this.state.streams.map((stream) => {
                    let screendt = stream.name.split("_")
                    return screendt[3] == "screen"
                      ? this.renderStreams(stream)
                      : ""
                  })}
                </div>
                <div
                  className={
                    this.state.videoLayout
                      ? "layout-screen mob-vdo-layout-screen"
                      : "hide"
                  }
                >
                  <div
                    onClick={this.closeVideoPlayer}
                    className={
                      this.state.role === "moderator"
                        ? "d-flex justify-content-end close-btn videoclose"
                        : "hide"
                    }
                  >
                    ×
                  </div>
                  <video
                    onContextMenu={() => false}
                    id="videoPlayer"
                    width="100%"
                    height="100%"
                    poster="/img/audioonly.jpeg"
                  />
                </div>
                <div
                  className={this.state.numSubs < 1 ? "hide" : ""}
                  id="layout-manage"
                >
                  <img
                    className={
                      this.state.numSubs <= 2
                        ? "disable-lay"
                        : this.state.currentLayout === "grid"
                          ? "bg-white"
                          : ""
                    }
                    src="/img/grid.svg"
                    onClick={this.changeLayout.bind(this, "grid")}
                  />
                  <img
                    className={
                      this.state.numSubs <= 2
                        ? "disable-lay"
                        : this.state.currentLayout === "vertical"
                          ? "bg-white flipimg"
                          : "flipimg"
                    }
                    src="/img/hangout_vertical.svg"
                    onClick={this.changeLayout.bind(this, "vertical")}
                  />
                  <img
                    className={
                      this.state.numSubs !== 2 ||
                        (!this.state.video && !this.state.audio) ||
                        this.isSigned
                        ? "disable-lay"
                        : this.state.currentLayout === "2user"
                          ? "bg-white"
                          : ""
                    }
                    src="/img/float.svg"
                    onClick={this.changeLayout.bind(this, "2user")}
                  />
                  <img
                    className={
                      this.state.numSubs !== 2
                        ? "disable-lay"
                        : this.state.currentLayout === "vertical2"
                          ? "bg-white"
                          : ""
                    }
                    src="/img/f2f_vertical.svg"
                    onClick={this.changeLayout.bind(this, "vertical2")}
                  />
                  <img
                    className="hide"
                    src="/img/f2f_horizontal.svg"
                    onClick={this.changeLayout.bind(this, "horizontal2")}
                  />
                </div>
                <div
                  id="docParent"
                  className={
                    this.state.docLayout
                      ? "layout-screen doc-index-2 noscroll"
                      : "hide"
                  }
                >
                  <div
                    onClick={this.closeDocPlayer}
                    className={
                      this.state.role === "moderator"
                        ? "d-flex justify-content-end close-btn videoclose"
                        : "hide"
                    }
                  >
                    ×
                  </div>
                  <iframe
                    onLoad={this.onLoadCompleteFrame}
                    id="docPlayer"
                    width="100%"
                    height="100%"
                  ></iframe>
                </div>
                <div className="vdo-enlarge-blk" onClick={this.onStageFullscrn}>
                  <div className="d-flex align-items-center justify-content-center vdo-enlarge-container">
                    <img
                      src={
                        this.state.fullscrn
                          ? "/img/vdo_compress.svg"
                          : "/img/resize.svg"
                      }
                    />
                  </div>
                </div>
              </div>
              <div
                className={
                  document.getElementById("layout")
                    ? document.getElementById("layout").childElementCount > 0 ||
                      document.getElementById("layout-screen")
                        .childElementCount > 0 ||
                      this.state.videoLayout ||
                      this.state.docLayout ||
                      this.state.screen
                      ? "hide"
                      : "meeting-section sess-not-started"
                    : "meeting-section sess-not-started"
                }
              >
                <h1>{this.state.notstartedtxt}</h1>
              </div>
              <div className={this.state.notification ? "" : "hide"}>
                <Toast
                  closeNotification={this.closeNotification}
                  notification={this.state.notification}
                />
              </div>
              <ChatComponent
                showNotification={this.showNotification}
                sessionId={this.state.sessionId}
                chatClose={this.chatClose}
                newChat={this.newChat}
                blockBodyClick={this.blockBodyClick}
                ref={(chatRef) => (this.chatRef = chatRef)}
              />
              <div
                className={
                  this.state.chatEnabled ? "hide" : "chat-mobile-view-wrapper"
                }
                onClick={this.enableOrDisableChat}
              >
                <div className="d-flex align-items-center justify-content-center chat-mobile-icon-container">
                  <img src="/img/chat-icon.svg" className="chat-mobile-icon" />
                </div>
              </div>
            </div>

            <div className="vdo_bottom-part interpreter-bottom">
              <div className="d-flex align-items-center justify-content-between h-100">
                <div className="outer-left-blk-foot-rht">
                  <div className="speaker-lang-blk intrepre-languag-blk selectfloorlang">
                    <div
                      className="interpreter-section arrow-up hight32"
                      onClick={this.onToggleFloorLanguageOption}
                    >
                      <div className="d-flex align-items-center lang-container interpreter-arrow-down">
                        <div className="flag-lang notranslate">
                          {this.state.floorLanguage}
                        </div>
                      </div>
                      <div
                        className={
                          this.state.floorLangPopOpen
                            ? "hide"
                            : "custom-menu-tooltip"
                        }
                      >
                        Choose Language for incoming audio
                      </div>
                    </div>
                    <div
                      className={
                        this.state.floorLangPopOpen
                          ? "interpreter-popover"
                          : "hide"
                      }
                    >
                      <div className="interpret-wrapper">
                        <div
                          onClick={this.onChangeFloorLanguage.bind(
                            this,
                            "Floor",
                          )}
                          className="d-flex align-items-center interpreter-container pointer"
                        >
                          <div className="flag-lang min-60">Floor</div>
                        </div>
                        {Object.keys(this.state.participants).map(
                          (key, index) => {
                            if (
                              this.state.participants[key].userData.role ===
                              "interpreter" &&
                              this.state.participants[key].userData.audio &&
                              !addedLanguage.hasOwnProperty(
                                this.state.participants[key].userData.language,
                              ) &&
                              this.state.participants[key].userData.language !==
                              this.state.language
                            ) {
                              addedLanguage[
                                this.state.participants[key].userData.language
                              ] = "true"
                              return (
                                <div
                                  key={key}
                                  onClick={this.onChangeFloorLanguage.bind(
                                    this,
                                    this.state.participants[key].userData
                                      .language,
                                  )}
                                  className="d-flex align-items-center interpreter-container pointer"
                                >
                                  <div
                                    className={
                                      this.state.participants[key].userData
                                        .audio
                                        ? "active-blink"
                                        : "absolute"
                                    }
                                  ></div>
                                  <div className="flag-lang margin-left-10">
                                    {" "}
                                    {
                                      this.state.participants[key].userData
                                        .language
                                    }
                                  </div>
                                </div>
                              )
                            }
                          },
                        )}
                        {Object.keys(this.state.participants).map((key) => {
                          if (
                            this.state.participants[key].userData.role ===
                            "interpreter" &&
                            !this.state.participants[key].userData.audio &&
                            !addedLanguage.hasOwnProperty(
                              this.state.participants[key].userData.language,
                            ) &&
                            this.state.participants[key].userData.language !==
                            this.state.language
                          ) {
                            addedLanguage[
                              this.state.participants[key].userData.language
                            ] = "true"
                            return (
                              <div
                                key={key}
                                onClick={this.onChangeFloorLanguage.bind(
                                  this,
                                  this.state.participants[key].userData
                                    .language,
                                )}
                                className="d-flex align-items-center interpreter-container pointer"
                              >
                                <div
                                  className={
                                    this.state.participants[key].userData.audio
                                      ? "active-blink"
                                      : "absolute"
                                  }
                                ></div>
                                <div className="flag-lang notranslate">
                                  {" "}
                                  {
                                    this.state.participants[key].userData
                                      .language
                                  }
                                </div>
                              </div>
                            )
                          }
                        })}
                        {this.state.avalilableLanguages.map((lang, m) => {
                          if (
                            !addedLanguage.hasOwnProperty(lang) &&
                            lang !== this.state.language
                          ) {
                            addedLanguage[lang] = "true"
                            //console.log('Added language = '+JSON.stringify(addedLanguage))
                            return (
                              <div
                                key={m}
                                onClick={this.onChangeFloorLanguage.bind(
                                  this,
                                  lang,
                                )}
                                className="d-flex align-items-center interpreter-container pointer"
                              >
                                <div className="absolute"></div>
                                <div className="flag-lang notranslate">
                                  {" "}
                                  {lang}
                                </div>
                              </div>
                            )
                          }
                        })}
                      </div>
                    </div>

                    <div className="d-flex flex-column partner-audio-blk incoming-audio-blk">
                      <div className="interpre-audio-label">Incoming Audio</div>
                      <div className="voice-controller">
                        <div className="voice-slidecontainer">
                          <input
                            type="range"
                            min="0"
                            max="100"
                            onChange={this.onFloorVolumeChange.bind(this)}
                            value={this.state.floorVolume}
                            className="voice-slider pointer"
                          />
                          <div className="custom-menu-tooltip">
                            {Math.round(this.state.floorVolume * 1)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column partner-audio-blk">
                      <div className="interpre-audio-label">Partner Audio</div>
                      <div className="voice-controller">
                        <div className="voice-slidecontainer">
                          <input
                            type="range"
                            min="0"
                            max="100"
                            onChange={this.onInterpreterMyVolumeChange}
                            value={this.state.InterpreterMyvolumeSliderValue}
                            className="voice-slider"
                          />
                          <div className="custom-menu-tooltip">
                            {this.state.InterpreterMyvolumeSliderValue * 1}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div
                    className={
                      this.state.connected
                        ? "activityIcon d-flex align-items-center interpreter-menu-icons-wrapper"
                        : "hide"
                    }
                  >
                    <div
                      className="vdo-menu-list slowdown active hide"
                      onClick={this.hangup}
                    >
                      <img src="/img/call-white.svg" />
                      <div className="custom-menu-tooltip">Leave Call</div>
                    </div>
                    <div
                      className={
                        this.state.handOverTime > 0
                          ? "handover-remain-time"
                          : "hide"
                      }
                    >
                      Handovers in {this.state.handOverTimeDisplay}
                    </div>
                    <div className="vdo-menu-list-wrap">
                      <div
                        className="vdo-menu-list slowdown"
                        onClick={this.sendSlowDownMessage}
                      >
                        <img src="/img/traffic-lights.svg" />
                      </div>
                      <h4>Slow down</h4>
                    </div>
                    <div className="vdo-menu-list-wrap">
                      <div
                        className="vdo-menu-list handd-over"
                        onClick={this.showHandover}
                      >
                        <img src="/img/handover.svg" />

                        <div
                          className={
                            this.state.handoverPop
                              ? "hand-over-popover"
                              : "hide"
                          }
                        >
                          <div
                            onClick={this.onHandOver.bind(this, 30)}
                            className="d-flex align-items-center justify-content-between hand-over-option"
                          >
                            Handover in 30 sec
                            <div className="handover-timer-icon">
                              <img src="/img/timer-handover.svg" />
                            </div>
                          </div>
                          <div
                            onClick={this.onHandOver.bind(this, 180)}
                            className="d-flex align-items-center justify-content-between hand-over-option"
                          >
                            Handover in 3 mins
                            <div className="handover-timer-icon">
                              <img src="/img/timer-handover.svg" />
                            </div>
                          </div>

                          <div
                            onClick={this.onHandOver.bind(this, 300)}
                            className="d-flex align-items-center justify-content-between hand-over-option"
                          >
                            Handover in 5 mins
                            <div className="handover-timer-icon">
                              <img src="/img/timer-handover.svg" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <h4>Hand Over</h4>
                    </div>
                    {!this.state.disableChat && (
                      <div className="vdo-menu-list-wrap">
                        <div
                          className={
                            this.state.chatEnabled
                              ? "vdo-menu-list message active"
                              : this.state.unReadChat
                                ? "vdo-menu-list message chat-not"
                                : "vdo-menu-list message"
                          }
                          onClick={this.enableOrDisableChat}
                        >
                          <img src="/img/message.svg" />
                        </div>
                        <h4>Message</h4>
                      </div>
                    )}

                    <Draggable>
                      <div
                        className={
                          this.state.deviceInit ? "partnerVideo" : "hide"
                        }
                      >
                        <div
                          className={
                            this.state.video &&
                              this.state.parnterSelfVideoCreated
                              ? "vid-close hide"
                              : "hide"
                          }
                          onClick={this.closeMyVidePartner}
                        >
                          X
                        </div>
                        {this.state.streams.map((stream) => {
                          let splithidden = stream.name.split("_")
                          let rolehidden = splithidden[0]
                          return rolehidden === "interpreter" &&
                            splithidden[3] != "screen"
                            ? this.renderStreamsPartner(stream)
                            : ""
                        })}

                        {this.state.role === "interpreter" && this.state.video
                          ? this.renderPublisher(true)
                          : ""}
                      </div>
                    </Draggable>
                    <div
                      className={
                        this.state.showInterpreterVideo
                          ? "vdo-menu-list-wrap"
                          : "hide"
                      }
                    >
                      <div
                        className={
                          this.state.showInterpreterVideo
                            ? this.state.requestPartnerVideo ||
                              this.state.acceptPartnerVideo
                              ? "vdo-menu-list message active"
                              : "vdo-menu-list message"
                            : "hide"
                        }
                        onClick={this.requestVideo}
                      >
                        <img src="/img/vdo-request.svg" />
                      </div>
                      <h4>
                        {this.state.requestPartnerVideo ||
                          this.state.acceptPartnerVideo
                          ? "Revoke Partner Video"
                          : "Partner Video"}
                      </h4>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    this.state.settingsOpen
                      ? "d-flex align-items-center interpreter-floor-menu-ctrl"
                      : "d-flex align-items-center interpreter-floor-menu-ctrl interpreter-bottom-tool-index"
                  }
                >
                  <div
                    className={
                      this.state.audio
                        ? "d-flex align-items-center audio-spec-row audio-spec-active"
                        : "d-flex align-items-center audio-spec-row"
                    }
                  >
                    <div
                      className={
                        this.state.isAudioProcessing
                          ? "vdo-menu-list mic isAudioProcessing"
                          : "vdo-menu-list mic"
                      }
                      onClick={this.audioControl}
                    >
                      <img
                        src={
                          this.state.audio
                            ? "/img/mic.svg"
                            : "/img/mic-cross.svg"
                        }
                      />
                      <i className="fas fa-spinner fa-spin"></i>
                      <div className="custom-menu-tooltip">
                        {this.state.audio
                          ? "Click to turn mic off"
                          : "Click to turn mic on"}
                      </div>
                    </div>

                    <div className="audio-spectrum-blk">
                      <div className="audio-spec-container">
                        <div id="waveform">
                          {this.noMicIndicator ? <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontWeight: 600
                          }}>
                            {this.state.audio && !this.state.isAudioProcessing ? <span style={{color: 'green',}}>ON AIR</span> : <span style={{color: 'red',}}></span>}
                          </div>
                          : <div
                            className="audio-meter"
                            style={{
                              width:
                                (this.state.audio &&
                                  !this.state.isAudioProcessing
                                  ? this.state.volumeLevel
                                  : 0) + "%",
                              background:
                                this.state.volumeLevel > 80
                                  ? "#FF0000"
                                  : this.state.volumeLevel > 60
                                    ? "#FF7F00"
                                    : this.state.volumeLevel > 40
                                      ? "#FFFF00"
                                      : "#00FF00",
                            }}
                          ></div>}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between audio-spec-txt-notify">
                        <div className="audio-spec-txt-label">
                          {this.state.audio ? "On Air" : "Off Air"}
                        </div>
                        <div className="audio-spec-txt-label">
                          {this.state.language.length > 10
                            ? this.state.language.substr(0, 6) + ".."
                            : this.state.language}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-column interpre-outgoing-audio-blk">
                    {this.state.languageList.map((language, index) => {
                      return (
                        <div
                          key={language}
                          onClick={this.onChangeSelectedLanguage.bind(
                            this,
                            language,
                          )}
                          className={
                            this.state.floorLanguage !== language
                              ? this.state.language === language
                                ? "interpre-outgoing-audio selected bottom5"
                                : "interpre-outgoing-audio bottom5"
                              : "hide"
                          }
                        >
                          {language}
                        </div>
                      )
                    })}
                  </div>
                  <div
                    className={
                      this.state.language ? "hide" : "outgoing-lang-alert"
                    }
                  >
                    Choose your outgoing language
                  </div>
                </div>
              </div>
            </div>
            <section className={this.state.alert ? "alert-wrap" : "hide"}>
              <Alert
                onCloseAlert={this.onCloseAlert}
                alert={this.state.alert}
                message={this.state.alertMessage}
              />
            </section>
          </section>
        )
      } else if (this.state.role == "listenerhls") {
        return (
          <section
            onClick={this.bodyClick.bind(this)}
            className={
              this.state.chatEnabled ? "vc-session chat-enabled" : "vc-session"
            }
          >
            <div className={this.state.pollOpen ? "poll-backdrop" : "hide"}>
              <PollComponent
                sendToAllPusher={this.sendToAllPusher}
                getAllPolls={this.getAllPolls}
                role={this.state.role}
                showNotification={this.showNotification}
                onPollReceive={this.onPollReceive}
                closePoll={this.closePoll}
                ref={(pollRef) => (this.pollRef = pollRef)}
                sessionId={this.state.sessionId}
              />
            </div>
            <div
              className={
                this.state.showTechnicalSupport ? "fixed-backdrop" : "hide"
              }
            >
              <div className="custom-caption-pop-up">
                <div className="d-flex align-items-center justify-content-between custom-popup-cap-header-blk">
                  <div className="custom-caption-header">Technical Support</div>
                  <div className="support-close" onClick={this.cloeSupportPop}>
                    X
                  </div>
                </div>
                <TechnicalSupport
                  showNotification={this.showNotification}
                  sessionId={this.state.sessionId}
                  newChat={this.newSupportChat}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between vdo-top-part">
              <div className="d-flex align-items-center">
                <div className="logoo">
                  <img
                    src={this.state.logo}
                    onError={this.onErrorImg.bind(this)}
                  />
                </div>
                <span className="username">{this.state.logoText}</span>
              </div>
              <div className="d-flex align-items-center top-menu-wrapper">
                <div className="speaker-lang-blk">
                  <div
                    className={
                      this.state.selectedStream === "Floor"
                        ? "interpreter-section arrow-up"
                        : "interpreter-section arrow-up active"
                    }
                  >
                    <div
                      className="d-flex align-items-center lang-container interpreter-arrow-down"
                      onClick={this.onToggleInterpreterListInModerator}
                    >
                      <div className="flag-avatar">
                        <img src="/img/interpreter.svg" />
                      </div>
                      <div className="flag-lang notranslate">
                        {this.state.selectedStream === "Floor"
                          ? "Interpreting"
                          : this.state.selectedInterpreterInModerator}
                      </div>
                    </div>
                    <div
                      className={
                        this.state.moderatorInterpreterlistOpen
                          ? "interpreter-popover"
                          : "hide"
                      }
                    >
                      <div className="interpret-wrapper">
                        {(addedLanguage = [])}
                        {this.state.avalilableLanguages.map((lang, i) => {
                          return (
                            <div
                              key={i}
                              onClick={this.onChangeSelectedInterpreterInModerator.bind(
                                this,
                                lang,
                                1,
                              )}
                              className="d-flex align-items-center interpreter-container pointer"
                            >
                              <div className="active-blink"></div>
                              <div className="flag-lang notranslate">
                                {" "}
                                {lang}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="voice-controller hide">
                    <div className="voice-slidecontainer top-volume">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        onChange={this.onInterpreterVolumeChangeInModerator.bind(
                          this,
                        )}
                        value={this.state.InterpreterVolumeInModerator}
                        className={
                          this.state.selectedInterpreterInModerator ===
                            "Select Interpreter"
                            ? "voice-slider nomouse"
                            : "voice-slider pointer"
                        }
                      />
                      <div className="custom-menu-tooltip">
                        {this.state.InterpreterVolumeInModerator}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="speaker-lang-blk">
                  <div
                    className={
                      this.state.selectedStream === "Floor"
                        ? "interpreter-section speaker-floor-blk active"
                        : "interpreter-section speaker-floor-blk"
                    }
                    onClick={this.selectFloorStream}
                  >
                    <div className="d-flex align-items-center lang-container">
                      <div className="d-flex align-items-center justify-content-center floor-avatar">
                        <i className="fa fa-user"></i>
                      </div>
                      <div className="floor-caption">
                        {this.state.floorTitle}
                      </div>
                    </div>
                  </div>
                  <div className="voice-controller hide">
                    <div className="voice-slidecontainer top-volume">
                      <input
                        type="range"
                        min="0"
                        max="100"
                        onChange={this.onSpeakerVolumeChangeInModerator.bind(
                          this,
                        )}
                        value={this.state.speakerVolumeInModerator}
                        className="voice-slider pointer"
                      />
                      <div className="custom-menu-tooltip">
                        {this.state.speakerVolumeInModerator}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vdo-top-right-part">
                <a className="bandwidth-section trouble">
                  <div
                    className="notranslate"
                    id="google_translate_element"
                  ></div>
                  <button
                    className="skiptr notranslate"
                    onClick={this.skipTrans}
                  >
                    Skip Translation
                  </button>
                </a>

                <div
                  onClick={this.showTechnicalSupport}
                  className="toggle-menu ml-3 help-butn"
                  data-toggle="offcanvas"
                >
                  <img src="/img/support.svg" />
                  <div className="custom-menu-tooltip">Technical Support</div>
                </div>
                <div
                  onClick={this.toggleSidbar}
                  className="toggle-menu ml-3 mobile-hide"
                  data-toggle="offcanvas"
                >
                  <img src="/img/menu-icon.svg" />
                </div>
                <div
                  onClick={this.mobMenuToggle}
                  className="toggle-menu ml-3 desktop-hide"
                  data-toggle="offcanvas"
                >
                  <img src="/img/menu-icon.svg" />
                </div>
              </div>
            </div>

            <div
              className={this.state.mobMenuToggle ? "sidenav-blk open" : "hide"}
            >
              <div className="d-flex align-items-center justify-content-end sidebar-top-part">
                <span className="close" onClick={this.mobMenuToggle}>
                  ×
                </span>
              </div>
              <ul className="sidenav-menu-wrap">
                <li>
                  <span
                    onClick={this.onToggleInterpreterListInModerator}
                    className="interpreter-drop"
                  >
                    Interpreting{" "}
                    <span
                      className={
                        this.state.moderatorInterpreterlistOpen
                          ? "arrow-open"
                          : "arrow-close"
                      }
                    ></span>
                  </span>
                  {(addedLanguage = [])}
                  <ul
                    className={
                      this.state.moderatorInterpreterlistOpen
                        ? "Interpreters-lang"
                        : "hide"
                    }
                  >
                    {this.state.avalilableLanguages.map((lang, i) => {
                      return (
                        <li
                          key={i}
                          onClick={this.onChangeSelectedInterpreterInModerator.bind(
                            this,
                            lang,
                            1,
                          )}
                        >
                          <div className="active-blink blinkmob"></div>
                          <span
                            className={
                              lang === this.state.selectedInterpreterInModerator
                                ? "menulst-active"
                                : ""
                            }
                          >
                            {lang}
                          </span>
                        </li>
                      )
                    })}
                  </ul>
                </li>
                <li>
                  <span onClick={this.selectFloorStream}>
                    Floor <span className="arrow-open"></span>
                  </span>
                </li>
                <li>
                  <a href="https://www.speedtest.net/" target="_blank">
                    <span>Speed Test</span>
                  </a>
                </li>
                <li onClick={this.onRefresh}>
                  <span>Refresh</span>
                </li>
                <li onClick={this.onLogout}>
                  <span>Logout</span>
                </li>
              </ul>
            </div>

            <div className="vdo-display-wrapper" id="confbg">
              <div className={this.state.sidebaropen ? "sidebar open" : "hide"}>
                <ul>
                  <li>
                    <a
                      onClick={this.startMySpeedTest}
                      className={
                        this.state.speedOfMe ? "nav-link mobile-hide" : "hide"
                      }
                    >
                      <span className="icon">
                        <img src="/img/bandwidth.svg" />
                      </span>
                      <span className="link">Speed Test</span>
                    </a>
                    <a className="nav-link" onClick={this.onRefresh}>
                      <span className="icon">
                        <img src="/img/refresh.svg" />
                      </span>
                      <span className="link">Refresh Page</span>
                    </a>

                    <a className="nav-link" onClick={this.onLogout}>
                      <span className="icon">
                        <img src="/img/logout.svg" />
                      </span>
                      <span className="link">Logout</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div
                id="middle-video-content"
                className="meeting-section video-area"
              >
                <div className="hlsplayer-wrap">
                  <HlsPlayer
                    ref={(playerRef) => (this.playerRef = playerRef)}
                    sessionId={this.state.sessionId}
                    initialStream={
                      Config.WOWZA_URL +
                      this.state.selectedStream +
                      "/playlist.m3u8"
                    }
                  />
                </div>

                <div className="vdo-enlarge-blk" onClick={this.onStageFullscrn}>
                  <div className="d-flex align-items-center justify-content-center vdo-enlarge-container">
                    <img
                      src={
                        this.state.fullscrn
                          ? "/img/vdo_compress.svg"
                          : "/img/resize.svg"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={this.state.notification ? "" : "hide"}>
                <Toast
                  closeNotification={this.closeNotification}
                  notification={this.state.notification}
                />
              </div>
              <ChatComponent
                showNotification={this.showNotification}
                sessionId={this.state.sessionId}
                chatClose={this.chatClose}
                newChat={this.newChat}
                blockBodyClick={this.blockBodyClick}
                ref={(chatRef) => (this.chatRef = chatRef)}
              />
              <div
                className={
                  this.state.chatEnabled ? "hide" : "chat-mobile-view-wrapper"
                }
                onClick={this.enableOrDisableChat}
              >
                <div className="d-flex align-items-center justify-content-center chat-mobile-icon-container">
                  <img src="/img/chat-icon.svg" className="chat-mobile-icon" />
                </div>
              </div>
            </div>

            <div className="vdo_bottom-part d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-start align-items-center confer-cover-img-blk-inner">
                <div
                  className={
                    this.state.coverImageUrl.length > 0
                      ? "confer-cover-img-blk"
                      : "hide"
                  }
                >
                  <div className="confer-cover-wrapper">
                    {this.state.coverImageUrl.map((img, index) => {
                      return (
                        <div
                          key={img}
                          className={
                            index < 2 ? "confer-cover-container" : "hide"
                          }
                        >
                          <img src={img} className="object-fill" />
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="tot-participants mr-3">
                  Time:{" "}
                  <ConferenceTime
                    ref={(timeRef) => (this.timeRef = timeRef)}
                    onUpdateTime={this.onUpdateTime}
                  />
                </div>
              </div>
              <div className="activityIcon d-flex align-items-center justify-content-center h-100">
                <div className="vdo-menu-list-wrap">
                  <div
                    className="d-flex align-items-center justify-content-center media-menu-wrapper call-close"
                    onClick={this.hangup}
                  >
                    <img src="/img/call-white.svg" />
                  </div>
                  <h4>Leave</h4>
                </div>
                {!this.state.disableChat && (
                  <div className="vdo-menu-list-wrap">
                    <div
                      className={
                        this.state.chatEnabled
                          ? "vdo-menu-list message active"
                          : this.state.unReadChat
                            ? "vdo-menu-list message chat-not"
                            : "vdo-menu-list message"
                      }
                      onClick={this.enableOrDisableChat}
                    >
                      <img src="/img/message.svg" />
                    </div>
                    <h4>Message</h4>
                  </div>
                )}

                <div
                  className={
                    this.state.docLayout && this.state.enableDownloadPpt
                      ? "vdo-menu-list-wrap"
                      : "hide"
                  }
                  onClick={this.donwloadDocument}
                >
                  <a
                    href={pptDownlodUrl}
                    target="_blank"
                    className="vdo-menu-list docdown"
                  >
                    <i className="fa fa-download"></i>
                  </a>
                  <h4>Download</h4>
                </div>
              </div>
              <div
                className={
                  this.state.coverImageUrl.length > 0
                    ? "confer-cover-img-blk"
                    : "confer-cover-img-blk"
                }
              >
                <div className="confer-cover-wrapper">
                  {this.state.coverImageUrl.map((img, index) => {
                    return (
                      <div
                        key={img}
                        className={
                          index >= 2 ? "confer-cover-container" : "hide"
                        }
                      >
                        <img src={img} className="object-fill" />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <section className={this.state.alert ? "alert-wrap" : "hide"}>
              <Alert
                onCloseAlert={this.onCloseAlert}
                alert={this.state.alert}
                message={this.state.alertMessage}
              />
            </section>
          </section>
        )
      } else {
        return <div>Invalid url</div>
      }
    } else {
      if (this.state.role === "listener" || this.state.role === "listenerhls") {
        if (this.state.connected) {
          return (
            <div>
              <div className="waiting-room-header">
                <div className="d-flex justify-content-between vdo-top-part">
                  <div className="d-flex align-items-center">
                    <div className="logoo">
                      <img
                        src={this.state.logo}
                        onError={this.onErrorImg.bind(this)}
                      />
                    </div>
                    <span className="username">{this.state.logoText}</span>
                  </div>
                </div>
              </div>
              <div className="waiting-room-wrap">
                {this.state.waitingRoomResource.indexOf("mp4") > -1 && (
                  <video
                    id="waitingRoomVideo"
                    autoPlay
                    loop
                    src={this.state.waitingRoomResource}
                  />
                )}
                {this.state.waitingRoomResource.indexOf("mp4") === -1 && (
                  <img src={this.state.waitingRoomResource} />
                )}
              </div>
            </div>
          )
        } else {
          return (
            <div className="loader">
              <section
                className={this.state.confirmAudioPlay ? "alert-wrap" : "hide"}
              >
                <Confirm
                  ref={(confirmRef) => (this.confirmRef = confirmRef)}
                  cancel={this.onCancelAudioPlay}
                  proceed={this.onProceedAudioPlay}
                />
              </section>
              <div className="loader-gif">
                <div className="loadingtxt">{this.state.loadingMessage}</div>
                <img id="image-loader" src="/img/loader.gif" />
              </div>
            </div>
          )
        }
      } else if (this.moderatorpanel.length > 0) {
        return <div></div>
      } else {
        return (
          <div className="topnav_blk left-conference-blk moderator-view-page">
            <div className={this.state.apiresponse ? "hide" : "apiloader"}>
              <div className="apiloader-spinner"></div>
            </div>
            <div className="d-flex justify-content-between vdo-top-part">
              <div className="d-flex align-items-center fixed-top-left">
                <div className="logoo" onClick={this.onLogoClick}>
                  <img
                    src={this.state.logo}
                    onError={this.onErrorImg.bind(this)}
                  />
                </div>
                <span className="username">{this.state.logoText}</span>
              </div>
              <AudioVideoSettings
                settingsContinue={this.settingsContinue}
                setCamEnabled={this.setCamEnabled}
                setMicEnabled={this.setMicEnabled}
                role={this.state.role}
                isSigned={this.isSigned}
                ref={(settingRef) => (this.settingRef = settingRef)}
              />
            </div>
          </div>
        )
      }
    }
  }
}

export default preloadScript(App)

/*
NFO
:
Download and install OBS software from https://obsproject.com/download and put the following details on streaming tab to start the external streaming.
RTMP URL
:
rtmp://wowza.rafikyconnect.net/rafky
STREAM KEY
:
Floor_60921085c098567a13d81a48
NB :If multiple languages are added, language key will be the stream key

Moderator
https://admin.rafikyconnect.net/m-rafikyconnect-238-768-0189

Speaker
https://admin.rafikyconnect.net/s-rafikyconnect-238-768-0189

interpreter
https://admin.rafikyconnect.net/i-rafikyconnect-238-768-0189

viewer
https://admin.rafikyconnect.net/l-rafikyconnect-238-768-0189

*/
