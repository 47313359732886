import React, { Component } from 'react';
import RequestHandler from '../helpers/request-handler';
import UserSession from '../helpers/user-session';
import axios from 'axios';
import Config from '../config';
import { FileDrop } from 'react-file-drop'
class MediaUpload extends Component {
    constructor(props) {
        super(props); 
		this.state = {videoprogress:0,notTouched:true,mediaUrl:'',savedFiles:[],currentlyUploadingFile:'',showprogress:true,fileDeleteId:false}
        this.videoExtensions = ['mp4','mp3','MP4','MP3'];
        this.videoTypes = ['video/mp4','audio/mp3','audio/mpeg'];
        this.eventId = this.props.eventId;
    }
    componentDidMount =()=> {
        this.getFile();
	}
	onCancel =()=> {
        this.props.onClose();
	}
	onSave =()=> {
        this.props.onClose();
	}
	handleUploadFile = (event) => {
        this.upload(event.target.files)
    }
    upload = (files) =>{
        this.setState({videoprogress:0,showprogress:true,notTouched:true});
        var ext = files[0].name.substr(files[0].name.lastIndexOf('.') + 1);
        var filesizeMb = (files[0].size) / (1024 * 1024);
        if(this.videoExtensions.indexOf(ext) == -1){
            document.getElementById("upload-file").value = "";
            this.props.showNotification('File extension not supported',UserSession.getSession().userName);
            return;
        }
        if(filesizeMb  > 200){
            document.getElementById("upload-file").value = "";
            this.props.showNotification('File size should be less than 200 Mb',UserSession.getSession().userName);
            return;
        }
                
        const data = new FormData();
        var fileob     = files[0];
        data.append('event_file', fileob);
        data.append('name', 'event_file');
        axios.post(Config.API_URL+'event/upload_file/'+this.eventId, data,{onUploadProgress:  (progressEvent) => {
            const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
            if (totalLength !== null) {
                let videoprogress = (Math.round( (progressEvent.loaded * 100) / totalLength ));
                this.setState({videoprogress:videoprogress,currentlyUploadingFile:fileob.name});
            }
        }}).then((response) => {
            document.getElementById("upload-file").value = "";
            this.setState({videoprogress:0});
            if(response.data.status == 'success'){
                this.saveFile(response.data.result,fileob.name);    
            }else{
                this.hideProgress();
                this.props.showNotification('File uploaded failed',UserSession.getSession().userName);
            }
        }).catch(err => {
            this.props.showNotification('File size exceeded',UserSession.getSession().userName)
            this.hideProgress();
        })
    }
    saveFile = (url,title) =>{
        RequestHandler.post(Config.API_URL+'event/save_event_file',{event_id:this.eventId,url:url,title:title,type:'video/mp4'},(response)=>{
            if(response.status == 'success'){
                this.props.showNotification('File uploaded successfully',UserSession.getSession().userName);
                this.getFile();
            }
        });
    
    }
    deleteFileConfirm = (id) =>{
        this.setState({fileDeleteId:id})
    }
    
    deleteFile = (id) =>{
        RequestHandler.post(Config.API_URL+'event/delete_event_file',{event_file_id:id},(response)=>{
            if(response.status == 'success'){
                    ////console.log('PING SUCCESS - '+response.message)
                    if(this.state.savedFiles.length === 1){
                        this.setState({savedFiles:[]})
                    }
                    this.getFile();
                    this.props.showNotification('File deleted successfully',UserSession.getSession().userName);
            }
        });
    }
    getFile = () =>{
        RequestHandler.post(Config.API_URL+'event/event_files_list',{event_id:this.eventId},(response)=>{
            if(response.status == 'success'){
                    // //console.log('PING SUCCESS - '+response.message)
                    //this.setState({savedFiles:response.data})
                    let savedFiles = [];
                    for(let key in response.data){
                        if(this.videoTypes.indexOf(response.data[key].type)  > -1){
                            savedFiles.push(response.data[key])
                        }
                    }
                    this.setState({savedFiles:savedFiles})
                
            }
        });
    }
    hideProgress = () => {
        this.setState({showprogress:false});
    }
    onPlayMedia = (file) => {
        this.props.onPlayMedia(file.url);
        this.props.onClose();
        
    }
    addFileUrl =  () =>{
        if(this.state.mediaUrl){
            let fileList =  this.state.savedFiles;
            let split = this.state.mediaUrl.split('/');
            let fileName = split[split.length - 1]
            //fileList.push({title:fileName,url:this.state.mediaUrl})
            this.setState({mediaUrl:'',notTouched:true})
            this.saveFile(this.state.mediaUrl,fileName); 
        }else{
            this.setState({notTouched:false})
            this.props.showNotification('Media url required',UserSession.getSession().userName);
        }
    }
    onChangeUrlInput = (event) =>{
            this.setState({mediaUrl:event.target.value})
    }
    onDrop = (files, event) =>{
        this.upload(files);
    }
    onClickUpload = () =>{
        this.fileInputRef.click()
    }
    onFileInpClick = () =>{
        document.getElementById('upload-file').value = '';
    }
    confirmClose = () =>{
        this.setState({fileDeleteId:false})
    }
    confirmDelete = () =>{
        this.deleteFile(this.state.fileDeleteId)
        this.setState({fileDeleteId:false})
    }
    render() {
        return(
          <div className="fixed-backdrop">
          <div className="media-popup">
    
    
          <div className={this.state.fileDeleteId ? "alert-poup":"hide"}>
              <div className="d-flex justify-content-end close-btn" onClick={this.confirmClose}>×</div>
              <div className="d-flex justify-content-center">
                  <div className="alert-icon-blk">!</div>
              </div>
              <div className="alert-caption">Delete Event File</div>
              <div className="alert-subtitle">Are you sure you want to delete ?</div>
              <div className="d-flex justify-content-center media-button-blk">
                  <button type="button" className="cancel-media-btn" onClick={this.confirmClose}>No</button>
                  <button type="button" className="save-media-btn" onClick={this.confirmDelete}>Yes</button>
              </div>
          </div>
    
    
    
              <div className="d-flex justify-content-end close-btn" onClick={this.onCancel}>×</div>
              <div className="media-caption">Share Media [MP4,MP3]</div>
              <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex align-items-center justify-content-center media-uploader">
                      <label className="media-upload-btn"><input ref={(fileInputRef) => this.fileInputRef = fileInputRef} onChange={this.handleUploadFile} onClick={this.onFileInpClick} type="file" id="upload-file" className="d-none"/>Browse</label>
                      <FileDrop
                          onDrop={this.onDrop}
                          draggingOverFrameClassName="dragover"
                          onTargetClick={this.onClickUpload}/>
                  </div>
              </div>
              <div className="media-url-copier-blk">
                  <div className="media-label">Paste Media URL</div>
                  <input placeholder="mp4 or mp3 url" value={this.state.mediaUrl} onChange={this.onChangeUrlInput.bind(this)} type="text" className={(this.state.mediaUrl || this.state.notTouched) ? "media-url-input":"media-url-input urlerror"}/>
                  <div className="media-add-btn" onClick={this.addFileUrl}>Add File</div>
              </div>
              <div className="media-file-list-blk">
                  <div className={this.state.videoprogress && this.state.showprogress > 0 ? "d-flex align-items-center file-list-container":"hide"}>
                      <div className="filetype-icon-wrapper filter-img">
                          <img src="/img/vdo_icon.png"/>
                      </div>
                      <div className="filetype-desc">
        <div className="d-flex filename-desc"><div className="filename">{this.state.currentlyUploadingFile}</div></div>
                          <div className="file-upload-progress">
                              <div className="progress">
                                  <div className="progress-bar" style={{width:this.state.videoprogress+'%'}} role="progressbar" aria-valuenow={this.state.videoprogress} aria-valuemin="0" aria-valuemax="100">
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="file-upload-close" onClick={this.hideProgress}>×</div>
                  </div>
                  {
                      this.state.savedFiles.map((file,index)=>{
                          return(
                                  <div key={index} className="d-flex align-items-center file-list-container">
                                      <div className="filetype-icon-wrapper">
                                          <img src="/img/vdo_icon.png"/>
                                      </div>
                                      <div className="filetype-desc">
                                          <div className="d-flex filename-desc"><div className="filename">{file.title}</div></div>
                                      </div>
                                      <div className="playic" onClick={this.onPlayMedia.bind(this,file)}>
                                          <i className="fa fa-play" />
                                      </div>
                                      <div onClick={this.deleteFileConfirm.bind(this,file._id)} className="file-delete-icon">
                                          <img src="/img/trash.svg"/>
                                      </div>
                                     
    
                                  </div>
                          )
                      })
                  }
                  
              </div>
              <div className="d-flex justify-content-center media-button-blk hide">
                  <button onClick={this.onCancel} type="button" className="cancel-media-btn">Cancel</button>
                  <button onClick={this.onSave} type="button" className="save-media-btn">Save</button>
              </div>
          </div>
      </div>
        )
    }
}

export default MediaUpload;