import React, { Component } from 'react';
import Config from '../config';
import Common from '../helpers/common';
import RequestHandler from '../helpers/request-handler';
import UserSession from '../helpers/user-session';
class PollComponent extends Component {
    constructor(props) {
        super(props); 
        this.state = {
            pollMode : 'report', // create , create , report , edit
            sessionId:this.props.sessionId,
            pollTitle:'',
            pollQuestions:[
                {
                    question:'',
                    correctAnswer:'',
                    options:{
                        option1:'',option2:''
                    },
                    _id:''
                }
            ],
            reports:[

            ],
            pollId:'',
            error:'',
            role:this.props.role
        }
        this.optionLabals = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t'];
        this.errorTimeout = 0;
    }
    componentDidMount =()=> {
        if(Common.getSession()){
            Common.getSession().on("signal", (event) => {
                if(event.from.id != Common.getSession().connection.connectionId){
                let data = JSON.parse(event.data)
                switch (data.method) {
                    case 'onPoll':
                        this.onPoll(data);
                    break;
                }
                }
            });
        }
    }
    onPoll = (data) => {
        if(this.state.role == 'listener' || this.state.role == 'speaker' || this.state.role == 'listenerhls'){
            data.data.pollQuestions.map((value)=>{
                    value.correctAnswer = '';
            })
            let pollQuestions = data.data.pollQuestions;
            this.setState({pollQuestions:pollQuestions,pollId:data.data.pollId})
            this.props.onPollReceive();
        }
    }
    loadPollState(pollMode,data){
        let reports = [];
        if(pollMode == 'report'){
            data.map((report,index)=>{
                let optionCount = {};
                for(let k=0; k<report.responses.length;k++){
                    let optlb = report.responses[k].answer;
                    if(optionCount.hasOwnProperty(optlb)){
                        optionCount[optlb]++;
                    }else{
                        optionCount[optlb] = 1;
                    }
                }
                let questData = {};
                questData.question = report.question;
                questData.options = report.options;
                questData.report = report.report;
                questData.optUserCount = optionCount;
                reports.push(questData)
            })
            this.setState({pollId:data[0].poll_id})

        }else if(pollMode == 'edit'){
            this.setState({pollId:data._id})
            RequestHandler.post(Config.API_URL+'polls/listquestions',{poll_id:data._id},(response)=>{
                if(response.status == 'success'){
                    let pollQuestions = []
                    response.data.map((questOb)=>{
                        let questionObj = {};
                        questionObj.question = questOb.question;
                        questionObj.options = {};
                        for(let i =0 ; i < questOb.options.length ; i++){
                            questionObj.options['option'+(i+1)] = questOb.options[i][this.optionLabals[i]];

                        }

                        /*
                        questionObj.options = {
                            option1:questOb.options[0].a,
                            option2:questOb.options[1].b,
                        }
                        if(questOb.options[2]){
                            questionObj.options.option3 = questOb.options[2].c;
                        }
                        if(questOb.options[3]){
                            questionObj.options.option4 = questOb.options[3].d;
                        }
                        */
                        questionObj.correctAnswer = questOb.correct_answer;
                        questionObj._id = questOb._id;
                        pollQuestions.push(questionObj)
                    })
                    this.setState({pollQuestions:pollQuestions,pollTitle:data.title})
                    
                    
                }
            })
        }else if(pollMode == 'create'){
            this.clearPoll();
        }
        this.setState({pollMode:pollMode,reports:reports})
    }
    pollTitleChange = (event) =>{
       this.setState({pollTitle:event.target.value})
    }
    addQuestionBlock = () =>{
        let pollQuestions = this.state.pollQuestions;
        pollQuestions.push(
            {
                question:'',
                options:{
                    option1:'',option2:''
                }
            }
        )
        setTimeout(() => {
            document.getElementById('add-poll-scroll').scrollTop = document.getElementById('add-poll-scroll').scrollHeight;
        }, 100);
    }
    addOption = (index,optno) =>{
       let pollQuestions = this.state.pollQuestions;
       let options = this.state.pollQuestions[index].options;
       let nextOptionKey = 'option'+(parseInt((Object.keys(options).length))+1);
       options[nextOptionKey] = ''
       pollQuestions[index].options = options;
       this.setState({pollQuestions:pollQuestions})
       console.log(JSON.stringify(pollQuestions));
       setTimeout(() => {
           // document.getElementById('add-poll-scroll').scrollTop = document.getElementById('add-poll-scroll').scrollHeight;
        }, 100);
       
    }
    removeOption = (index,key,ind) =>{
        let pollQuestions = this.state.pollQuestions;
        let options = this.state.pollQuestions[index].options;
        delete options[key];
        let reArrangedOptions = {};
        
        for(let i = 0; i < Object.keys(options).length; i++){
            reArrangedOptions['option'+(i+1)] = options[Object.keys(options)[i]];
        }
        pollQuestions[index].options = reArrangedOptions;
        this.setState({pollQuestions:pollQuestions})
     }
    closePoll = () =>{
        this.props.closePoll();
    }
    clearPoll = () =>{
        this.setState({pollTitle:'',
            error:'',
            pollQuestions:[
                {
                    question:'',
                    options:{
                        option1:'',option2:''
                    },
                    correctAnswer:''
                }
            ]
        })
    }
    onQuestionChange = (index,event) =>{
        let pollQuestions = this.state.pollQuestions;
        pollQuestions[index].question = event.target.value;
        this.setState({pollQuestions:pollQuestions})
    }
    onChangeOption = (index,optkey,event) =>{
        let pollQuestions = this.state.pollQuestions;
        pollQuestions[index].options[optkey] = event.target.value;
        this.setState({pollQuestions:pollQuestions})
   }
   answerChange = (index,event) =>{
        let pollQuestions = this.state.pollQuestions;
        pollQuestions[index].correctAnswer = event.target.value;
        this.setState({pollQuestions:pollQuestions})
   }
   chooseAnswer = (index,ind) =>{
        let pollQuestions = this.state.pollQuestions;
        pollQuestions[index].correctAnswer = this.optionLabals[ind]
        this.setState({pollQuestions:pollQuestions})
    }
   
   saveAndLaunchPoll = () =>{
       let error = false;
       this.state.pollQuestions.map((value)=>{
            if(!value.correctAnswer){
                //error = 'Correct answer required !';
            }
       })
       this.state.pollQuestions.map((value)=>{
                if(!value.options['option1'] || !value.options['option2']){
                    error = 'Atleast two options required for all questions';
                }
                 
                for(let key in value.options){
                    if(value.options[key]){
                        if(value.options[key].length > 100){
                            error = 'Maximum 100 chars allowed for options';
                        }
                    }
                }


                
        })
       this.state.pollQuestions.map((value)=>{
            if(!value.question){
                error = 'Question required ! ';
            }
            if(value.question.length > 200){
                error = 'Maximum 200 characters allowed for questions';
            }
       })

       if(!this.state.pollTitle){
           error = 'Poll title required !';
       }else{
        if(this.state.pollTitle.length > 100){
            error = 'Maximum 100 characters allowed for poll title';
        }
       }
       
       if(!error){
           this.closePoll();
           if(this.state.pollMode == 'create'){
                RequestHandler.post(Config.API_URL+'polls/create',{session_id:this.state.sessionId,title:this.state.pollTitle},(response)=>{
                    if(response.status == 'success'){
                    this.setState({pollId:response.data._id})
                    this.saveAllQuestionsRecursively(response.data._id)
                    }
                })
            }else{
                RequestHandler.post(Config.API_URL+'polls/update',{session_id:this.state.sessionId,title:this.state.pollTitle,id:this.state.pollId},(response)=>{
                    if(response.status == 'success'){
                    this.saveAllQuestionsRecursively(this.state.pollId)
                    }
                })
            }
       }else{
           this.setState({error:error})
           if(this.errorTimeout){
               clearTimeout(this.errorTimeout)
           }
           this.errorTimeout = setTimeout(() => {
            this.setState({error:''})
           }, 5000);
           //alert(error)
       }
   }
   saveAllQuestionsRecursively = (pollId) =>{
       let responseCount = 0;
       let pollPublished = false;
       this.state.pollQuestions.map((questionObj,index)=>{
            let param = {
                    poll_id: pollId,
                    question: questionObj.question,
                    options: [],
                    correct_answer: questionObj.correctAnswer
                }
                if(questionObj._id){
                    param.id = questionObj._id;
                }
                let optionKeyArray = Object.keys(questionObj.options)
                for(let j=0; j<optionKeyArray.length;j++){
                    let optOb = {};
                    optOb[this.optionLabals[j]] = questionObj.options[optionKeyArray[j]]
                    param.options.push(optOb)
                }
                RequestHandler.post(Config.API_URL+'polls/'+(questionObj._id  ?'question/update':'addquestion'),param,(response)=>{
                    if(response.status == 'success'){
                        responseCount++;
                        let pollQuestions = this.state.pollQuestions;
                        pollQuestions[index]['_id'] = response.data._id;
                        this.setState({pollQuestions:pollQuestions})
                        if(responseCount >= this.state.pollQuestions.length){
                            this.publishPoll();
                            setTimeout(() => {
                                pollPublished = true;
                                this.props.showNotification('Poll published successfully',UserSession.getSession().userName);
                                this.props.getAllPolls();
                            }, 1000);
                        }
                       
                    }
                })
                

       })
       
       setTimeout(() => {
           if(!pollPublished){
            this.publishPoll();
            this.props.showNotification('Poll published successfully',UserSession.getSession().userName);
           }
       }, 10000);
       
       
   }
   savePollAnswer = () =>{
       let error = '';
       this.state.pollQuestions.map((value)=>{
            if(!value.correctAnswer){
                error = 'Please choose your answer !';
            }
        })
        if(!error){
            this.saveAnswerRecursive();
        }else{
            //alert(error)
            this.setState({error:error})
            if(this.errorTimeout){
                clearTimeout(this.errorTimeout)
            }
            this.errorTimeout = setTimeout(() => {
                this.setState({error:''})
               }, 5000);
        }

   }
   saveAnswerRecursive = () =>{
        this.state.pollQuestions.map((questionObj,index)=>{
            let param = {
                    poll_id: this.state.pollId,
                    question_id: questionObj._id,
                    user_id:UserSession.getSession().userId,
                    answer: questionObj.correctAnswer
                }
                RequestHandler.post(Config.API_URL+'polls/saveanswer',param,(response)=>{
                    if(response.status == 'success'){
                        
                    }
                })

        })
        this.closePoll();
        setTimeout(() => {
            this.props.showNotification('Poll answers saved successfully',UserSession.getSession().userName);
        }, 1000);
    }
   publishPoll = () =>{
        let data = this.state;
        Common.sendToAll({method:'onPoll',data:data})
        this.props.showNotification('Poll published successfully',UserSession.getSession().userName);
        this.props.sendToAllPusher({method:'onPoll',data:data});
   }
   shareResult = () =>{
       Common.sendToAll({method:'onPollResult',data:{_id:this.state.pollId} })
       this.props.sendToAllPusher({method:'onPollResult',data:{_id:this.state.pollId} });
       this.props.showNotification('Poll results shared successfully',UserSession.getSession().userName);
       this.closePoll();
   }
   render() {
    if(this.state.pollMode == 'create' || this.state.pollMode == 'edit'){
          return(
              <div className="custom-poll-popup mod">
                  <div className="custom-poll-popup-header">
                      <div className="d-flex align-items-center custom-poll-header-wrapper">
                          <div className="popup-poll-icon">
                              <img src="/img/poll-black-icon.svg"/>
                          </div>
                          <div className="poll-header-caption">Poll</div>
                      </div>
                  </div>
                  <div className="poll-body">
                       <div className="pollError">{this.state.error}</div>
                      <div className="poll-ques-wrapper">
                          <div className="add-poll-blk" id="add-poll-scroll">
                              <div className="add-poll-blk-container">
                              <div className="d-flex align-items-center add-poll-title-session">
                                  <div className="add-poll-ques-choic">T :</div>
                                  <div className="formgroup-input add-poll-ques-wrapper">
                                      <input type="text" placeholder="Title" className="poll-input"
                                      value={this.state.pollTitle} onChange={this.pollTitleChange}/>
                                  </div>
                              </div>
                              {
                                  this.state.pollQuestions.map( (value,index) =>{
                                      return(
                                          <section>
                                          <div className="d-flex align-items-center">
                                              <div className="add-poll-ques-choic">Q :</div>
                                              <div className="formgroup-input add-poll-ques-wrapper">
                                                  <input value={this.state.pollQuestions[index].question} onChange={this.onQuestionChange.bind(this,index)} type="text" placeholder="Add Poll Question" className="poll-input"/>
                                              </div>
                                              
                                          </div>
                                          
                                          <div className="add-poll-ques-option-part">
                                              {
                                                  
                                              Object.keys(this.state.pollQuestions[index].options).map( (key,ind) =>{
                                                    // console.log(JSON.stringify(this.state.pollQuestions))
                                                      return(
                                                          
                                                          <div key={ind} className="d-flex align-items-center poll-option-wrapper">
                                                              <div className="poll-option-bubble"></div>
                                                              <div className="formgroup-input add-poll-opt-wrapper">
                                                                  <input value={this.state.pollQuestions[index].options[key]} onChange={this.onChangeOption.bind(this,index,key)} type="text" placeholder={'Option '+(ind+1)} className="poll-input"/>
                                                              </div>
                                                              <div onClick={this.removeOption.bind(this,index,key,ind)} className={(ind >1 && ind<=18) ? "add-option rmoptn":"hide"}>-</div>
                                                              <div onClick={this.addOption.bind(this,index,ind)} className={(ind >=1 && ind<=18) && (ind == (Object.keys(this.state.pollQuestions[index].options).length - 1)) ? "add-option":"hide"}>+</div>
                                                          </div>
                                                      
                                                      )
                                                  })
                                              }
                                              </div>
                                              <div className="d-flex align-items-center margin-btm-30">
                                                  <div className="add-poll-ques-choic">A :</div>
                                                  <div className="formgroup-input add-poll-ques-wrapper">
                                                      <select onChange={this.answerChange.bind(this,index)} value={this.state.pollQuestions[index].correctAnswer} className="poll-input correct-ans-padd">
                                                          <option value="" disabled selected>Choose Correct Answer</option>
                                                          {
                                                          Object.keys(this.state.pollQuestions[index].options).map( (key,i) =>{
                                                                  return(
                                                                  <option key={i} value={this.optionLabals[i]}>{this.optionLabals[i].toUpperCase()}</option>
                                                                  )
                                                              })
                                                          }
                                                      </select>
                                                  </div>
                                                  
                                           </div>
                                          </section>
                                      
                                      )
                                  })
                              }
                              </div>
                          </div>
                          <div className={this.state.pollQuestions[this.state.pollQuestions.length-1].question ? "more-option-polls":"more-option-polls disabled"}>
                              <button onClick={this.addQuestionBlock} type="button" className="d-flex align-items-center poll-launch-btn"><span>+</span>Add Question</button>
                          </div>
                      </div>
                  </div>
                  <div className="custom-poll-footer">
                      <div className="poll-btn-blk">
                          <button type="button" className="poll-cancel-btn" onClick={this.closePoll}>Cancel</button>
                          <button type="button" className="poll-launch-btn margin-left-10" onClick={this.clearPoll}>Clear</button>
                          <button type="button" className="poll-launch-btn margin-left-10" onClick={this.saveAndLaunchPoll}>Launch</button>
                      </div>
                  </div>
                  </div>
          )
     }else if(this.state.pollMode == 'answering'){
         return(
          <div className="custom-poll-popup mod">
          <div className="custom-poll-popup-header">
              <div className="d-flex align-items-center custom-poll-header-wrapper">
                  <div className="popup-poll-icon">
                      <img src="/img/poll-black-icon.svg"/>
                  </div>
                  <div className="poll-header-caption">Poll</div>
              </div>
          </div>
          <div className="poll-body">
          <div className="pollError">{this.state.error}</div>
                  <div className="poll-ques-view">
                  {
                                  this.state.pollQuestions.map( (value,index) =>{
                                      return(
                                          <div key={index} className="poll-ques-view-wrapper">
                                          <div className="dash-poll-ques">{(index+1)}: {this.state.pollQuestions[index].question}</div>
                                          <div className="dash-polls-choice-wrapper">
                                              {
                                              Object.keys(this.state.pollQuestions[index].options).map( (key,ind) =>{
                                                      return(
                                                          <div key={ind} className={this.state.pollQuestions[index].correctAnswer === this.optionLabals[ind] ?  "d-flex align-items-center dash-polls-choice-container selected":"d-flex align-items-center dash-polls-choice-container"} onClick={this.chooseAnswer.bind(this,index,ind)}>
                                                              <div className="dash-polls-bubble"></div>
                                                              <div className="dash-polls-options">{this.state.pollQuestions[index].options[key]}</div>
                                                          </div>
                                                      )
                                                  })
                                              }
                                           </div>
                                          </div>
                                      
                                      )
                                  })
                              }

              </div>
              </div>
              <div className="custom-poll-footer">
                  <div className="poll-btn-blk">
                      <button type="button" className="poll-launch-btn margin-left-10" onClick={this.savePollAnswer}>Submit</button>
                  </div>
              </div>
              </div>
         )

     }else if(this.state.pollMode == 'report'){
         return(
          <div className="custom-poll-popup mod">
          <div className="custom-poll-popup-header">
              <div className="d-flex align-items-center custom-poll-header-wrapper">
                  <div className="popup-poll-icon">
                      <img src="/img/poll-black-icon.svg"/>
                  </div>
                  <div className="poll-header-caption">Poll</div>
              </div>
          </div>  
          <div className="poll-body">
          <div className="poll-ques-view">
          {
              this.state.reports.map((report,index) =>{
                  return(
                      <div key={index} className="poll-ques-view-wrapper">
                      <div className="dash-poll-ques">{(index+1)}: {report.question}</div>
                      <div className="dash-polls-choice-wrapper">
                              {
                                  report.options.map((option,i)=>{
                                      return(
                                          <div key={i} className="d-flex align-items-center dash-poll-ans-container">
                                                  <div className="d-flex align-items-center justify-content-between w-100">
                                                      <div className="d-flex dash-polls-ans">
                                                          {this.optionLabals[i].toUpperCase()}.
                                                          <div>{option[this.optionLabals[i]]}</div>
                                                      </div>
                                                      {report.report && (
                              <div className="dash-polls-percen">{parseInt(report.report[i][this.optionLabals[i]])}%  ( {report.optUserCount.hasOwnProperty(this.optionLabals[i]) ? report.optUserCount[this.optionLabals[i]]:0} Votes)</div>
                          )}

                                             {!report.report && (
                                                 <div className="dash-polls-percen">0 Votes </div>
                                             )}
                                      
                                                  </div>
                                                  {report.report && (
                                          <div className="progress-indicator-color" style={{width: report.report[i][this.optionLabals[i]]+"%"}}></div>
                                          )}
                                          </div>
                                      )
                                  })
                              }
                      </div>
                      
                      </div>
                  )
              }) 
          }

          </div>
          </div>
          <div className="custom-poll-footer">
          <div className="poll-btn-blk">
              
              <button type="button" className="poll-cancel-btn" onClick={this.closePoll}>Close</button>
              <button type="button" className={this.state.role == 'moderator' ? "poll-launch-btn margin-left-10":"hide"} onClick={this.shareResult}>Share Result</button>
          </div>
      </div>
      </div>

         )
     }
}
}

export default PollComponent;
