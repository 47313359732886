import React, { Component } from 'react';
class Toast extends Component {
    constructor(props) {
        super(props); 
        this.state = {notification:this.props.notification}
    }
    componentDidMount =()=> {
          
    }
    componentDidUpdate(prevProps) {
        if(prevProps!== this.props) {
            
          this.setState({notification:this.props.notification});
          
        }
      }
      closeNotification = () =>{
          this.props.closeNotification();
      }
    render() {
      return(
        <div className="d-flex align-items-center slow-down-toast-blk">
			 <div className="participant-profile-icon-section">
                <div className="avatar-section">
                    <div className="avatar_initial avatar-not">{this.state.notification.name?this.state.notification.name.charAt(0).toUpperCase():''}</div>
                </div>
            </div>
            <div className="slow-down-txt">{this.state.notification.text}</div>
			<div className="toast-close-btn" onClick={this.closeNotification}>×</div>
		</div>
      )
  }
}

export default Toast;
