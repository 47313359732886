import UserSession from "./user-session";

var RequestHandler = (function() {
    var post = function(url,param,callback) {
        let headers = {'Accept': 'application/json','Content-Type': 'application/json'}
        if(UserSession.getSession())
        {
            headers = {'Accept': 'application/json','Content-Type': 'application/json','Auth-Token':UserSession.getSession().authToken}
        }
        fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(param)
        })
        .then((response) => response.json())
        .then((responseData) => {
            callback(responseData);  
        })
        .catch(error => {
            callback({status:'error',data:'Please check your internet connectivity'});  
        }
        );
    };
    var del = function(url,param,callback) {
        let headers = {'Accept': 'application/json','Content-Type': 'application/json'}
        if(UserSession.getSession())
        {
            headers = {'Accept': 'application/json','Content-Type': 'application/json','Auth-Token':UserSession.getSession().authToken}
        }
        fetch(url, {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(param)
        })
        .then((response) => response.json())
        .then((responseData) => {
            callback(responseData);  
        })
        .catch(error => console.warn(error));
    };
    return {
        post: post,
        delete:del
    }
  
  })();
 export default RequestHandler;