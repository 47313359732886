import React, { Component } from 'react';
class Thankyou extends Component {
    constructor(props) {
        super(props); 
        this.state = {message:'Thank you for attending the session',logo:'',logoText:''}
        this.count = 0;
    }
    componentDidMount =()=> {
          if(localStorage.getItem('logo')){
            let logoText = '';
            if(localStorage.getItem('logo').indexOf('logo.3r4r5t.png') > -1 ){
                logoText = '';
            }
              this.setState({logo:localStorage.getItem('logo'),logoText:logoText})
          }
    }
    onErrorImg(event){
        event.target.src = '/img/logo.png';
    }
  render() {
      return(
        <section>
        <div class="topnav_blk left-conference-blk moderator-view-page">
                <div class="d-flex justify-content-between vdo-top-part">
                        <div class="d-flex align-items-center fixed-top-left">
                            <div class="logoo">
                                <img alt="logo" src={this.state.logo} onError={this.onErrorImg.bind(this)}/>
                            </div>
                            <span class="username">{this.state.logoText}</span>
                        </div>
                </div>
        </div>
       
        <section class="vdo-sys-session settings-overl">
                <div class="vdo-sys-check-wrapper top35">
                    <div class="vdo-in-sys thanktxt">
                        <span>Thank you</span><br/>
                        <span style={{opacity:0}} className="thanksmall">For attending the session</span>
                    </div>
                </div>
        </section>
        </section>
      )
  }
}

export default Thankyou;
