import React, { Component } from 'react';
class HlsPlayer extends Component {
    constructor(props) {
        super(props); 
        this.sessionId = this.props.sessionId;
       
    }
    componentDidMount =()=> {

    }
    switchStream = (stream) =>{
         //const streamUrl = Config.WOWZA_URL+stream+'/playlist.m3u8';
         //document.getElementById('player').src = '/videojs.html?stream='+stream;
         stream = stream+'_'+this.sessionId;
         document.getElementById('player').contentWindow.setSource(stream);
    }
   
  render() {
          return (
            <iframe id="player" title="hlsplayerframe" className="player-frame" src={'/videojs.html?stream=Floor_'+this.sessionId} />
          );
  }
}

export default HlsPlayer;
