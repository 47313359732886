
import {isMobileSafari,isChrome,isFirefox,isSafari,isOpera,isEdge} from "react-device-detect";
import Config from "../config";
import RequestHandler from "./request-handler";
import UserSession from "./user-session";
var Common = (function() {
    var session = null;
    var connectedUsers = {};
    var setSession = (ses)=>{
         session = ses;
         session.on("connectionCreated", function(event) {
            if (event.connection.connectionId != session.connection.connectionId) {
                connectedUsers[event.connection.connectionId] = event.connection
            }
        });
        session.on("connectionDestroyed", function(event) {
            if(connectedUsers[event.connection.connectionId]){
                delete connectedUsers[event.connection.connectionId];
            }
        });
        
    }
    var sendToAll = (data) => {
        //console.log('SIGNAL SEND TO ALL USERS : '+data.method);
        if(session){
          if(session.currentState === 'connected'){
            session.signal(
              {
                data:JSON.stringify(data)
              },
              function(error) {
                if (error) {
                  console.log("signal error send to all("
                              + error.name
                              + "): " + error.message);
                } else {
                  //console.log("signal sent.");
                }
              }
            );
          }
        }
        
    };
    var sendToAllPusher = (data) => {
      //Config.API_URL+'get-session-details'
        data.channel = Config.SESSION_ID;
        if(UserSession.getSession().role === 'listenerhls'){
          data.sendFromHls = true;
        }
        data.userId = UserSession.getSession().userId;
        data.requestId = new Date(Date.now()+(new Date().getTimezoneOffset()*60000)).getTime()/1000|0;
        //RequestHandler.post('https://localhost:9091/pusher-send',data,(response)=>{
        //alert(JSON.stringify(data))
        RequestHandler.post(Config.API_URL+'pusher-send',data,(response)=>{
          if(response.status == 'success'){

          }
        });
    }
    var sendToOne = (con,data) => {
        //console.log('SIGNAL SEND TO ONE USERS : '+data.method);
        if(session){
        if(session.currentState === 'connected'){
          session.signal(
              {
                to:con,
                data:JSON.stringify(data)
              },
              function(error) {
                if (error) {
                  console.log("signal error send to one ("
                              + error.name
                              + "): " + error.message);
                } else {
                  //console.log("signal sent.");
                }
              }
            );
          }
        }
    };
    var audio = false;
    var playAudio = () =>{
        if(!audio){
            audio = new Audio('/sounds/sound.mp3');
            audio.volume = .01;
        }
        try{
            audio.currentTime = 0;
        }catch(e){

        }
        audio.play();
    }
    var getOS = () => {
      var userAgent = window.navigator.userAgent,
          platform = window.navigator.platform,
          macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
          windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
          iosPlatforms = ['iPhone', 'iPad', 'iPod'],
          os = null;
    
      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Android/.test(userAgent)) {
        os = 'Android';
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
      }
    
      return os;
    }
    var getBrowserVesion = () => {
      var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []; 
      if(/trident/i.test(M[1])){
          tem=/\brv[ :]+(\d+)/g.exec(ua) || []; 
          return {name:'IE',version:(tem[1]||'')};
          }   
      if(M[1]==='Chrome'){
          tem=ua.match(/\bOPR|Edge\/(\d+)/)
          if(tem!=null)   {return {name:'Opera', version:tem[1]};}
      }   
      M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
      return {
        name: M[0],
        version: M[1]
      };
   }
    var getBrowser = () => { 
        if(isMobileSafari){
          return('Mobile Safari')
        }
        if(isChrome){
          return('Chrome')
        }
        if(isFirefox){
          return('Firefox')
        }
        if(isFirefox){
          return('Firefox')
        }
        if(isSafari){
          return('Safari')
        }
        if(isOpera){
          return('Opera')
        }
        if(isEdge){
          return('Edge')
        }
        return('unknown');
     }
    var toHHMMSS = (secs) => {
        var sec_num = parseInt(secs, 10)
        var hours   = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60
    
        return [hours,minutes,seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v,i) => v !== "00" || i > 0)
            .join(":")
    }
    var setItem = (key, value, ttl) => {
      const now = new Date()
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item))
    }
    var getItem = (key) => {
      const itemStr = localStorage.getItem(key)
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null
      }
      const item = JSON.parse(itemStr)
      const now = new Date()
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
      }
      return item.value
    }
    var getSession = () =>{
      if(UserSession.getSession().role !== 'listenerhls'){
      return session;
      }else{
        return false;
      }
    }
    var getSession = () =>{
      if(UserSession.getSession().role !== 'listenerhls'){
      return session;
      }else{
        return false;
      }
    }
    let generateUUID = ()=> {
        var uuid = (Math.floor(Math.random() * 1000000000) + 1000000000).toString().substring(1);
        return uuid;
    }
    
    return {
        setSession: setSession,
        sendToOne:sendToOne,
        sendToAll:sendToAll,
        toHHMMSS:toHHMMSS,
        getSession:getSession,
        playAudio:playAudio,
        setItem:setItem,
        getItem:getItem,
        getBrowser:getBrowser,
        getOS:getOS,
        getBrowserVesion:getBrowserVesion,
        sendToAllPusher:sendToAllPusher
    }
  
  })();
 export default Common;