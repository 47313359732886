import React, { Component } from 'react';
import UserSession from '../helpers/user-session';
import Common from '../helpers/common';
import Config from '../config';
import RequestHandler from '../helpers/request-handler';
import Confirm from './Confirm';
class TechnicalSupport extends Component {
    constructor(props) {
        super(props); 
        let selectedChatGroup = 'Technical Support';
        this.listenerPrivate = false;
        // no interaction sessions
        this.noListenerInteractionSessions = ['5ffea7d29967920b16040ec0'];
        if(UserSession.getSession().role === 'listener' || UserSession.getSession().role === 'listenerhls'){
            if(this.noListenerInteractionSessions.indexOf(this.props.sessionId) > -1){
                selectedChatGroup = 'moderator';
                this.listenerPrivate = true;
            }
        }
        this.state = {confirm:false,chatList:[],sessionId:this.props.sessionId,currentChat:'',selectedChatGroup:selectedChatGroup,isChatGroupOpen:false,participants:{},connection:false,pvUserName:'',pvUserId:'',pvUserRole:'',
        selectedChatTab:'group'}
        this.lastDate = '';
        this.days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        

    }
    componentDidMount =()=> {
        this.getChat();
        this.listenMessage();
    }
    listenMessage = () =>{
        if(Common.getSession()){
            Common.getSession().on("signal", (event) => {
                if(event.from.id != Common.getSession().connection.connectionId){
                let data = JSON.parse(event.data)
                    this.onSignal(data)
                }
            });
        }else{
            setTimeout(this.listenMessage,1000);
            // console.log('Retry for connection object')
        }
    }
    onSignal = (data) => {
        switch (data.method) {
            case 'clearchat':
                this.setState({chatList:[]})
                this.props.showNotification('Chat history deleted by host',UserSession.getSession().userName);
            break;
            case 'chat':
                let allow = false;
                if(data.group.split('_')[0] === 'Private'){
                    if(data.group.split('_')[1] == UserSession.getSession().userId){
                        allow = true;
                    }
                }else{
                    if(data.group == 'general' || data.group == UserSession.getSession().role ){
                        allow = true;
                    }
                    if(data.group === 'general' && data.role ==='listener'){
                        if(UserSession.getSession().role === 'moderator'){
                            allow = true;
                        }else{
                            allow = false;
                        }
                    }
                    if(data.group == 'Technical Support' && (UserSession.getSession().role === 'moderator' || data.role === 'moderator')){
                        allow = true;
                    }
                    
                    if(data.group == 'Questions and Answers' && (UserSession.getSession().role === 'moderator' || UserSession.getSession().role === 'listener' || UserSession.getSession().role === 'speaker')){
                        allow = true;
                    }
                    
                }
                if(data.group != 'Technical Support Listener' && data.group != 'Technical Support'){
                        allow = false;
                }
                if(allow){
                    data.myChat = false;
                    let chatList = this.state.chatList;
                    let date = new Date(data.utcTime).toLocaleString('en-US').split(',')[0]
                    let time = new Date(data.utcTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                    data.date = date;
                    data.time = this.days[new Date(data.utcTime).getDay()]+' '+time;
                    chatList.push(data);
                    this.props.newChat(data);
                    this.setState({chatList:chatList})
                    setTimeout(() => {
                        document.getElementById('chat-scroll').scrollTop = document.getElementById('chat-scroll').scrollHeight;
                    }, 100);
                }
            break;
        }
    }
    updateParticipants = (participants)=>{
        this.setState({participants:participants})
    }
    openChat = () =>{
        setTimeout(() => {
            document.getElementById('chat-scroll').scrollTop = document.getElementById('chat-scroll').scrollHeight;
         }, 100);
    }
    sendChat = () =>{
         if(this.state.currentChat.replace(/\s/g, '')){
             let chatList = this.state.chatList;
             let dateObj = new Date();
             let utcTime = dateObj.toUTCString();
             let date = new Date().toLocaleString('en-US').split(',')[0]
             let time = this.days[new Date().getDay()]+' '+new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
             let chatObject = {method:'chat',group:this.state.selectedChatGroup,name:UserSession.getSession().userName,message:this.state.currentChat,myChat:true,date:date,time:time,role:UserSession.getSession().role,utcTime:utcTime,bot:false};
             if(UserSession.getSession().role === 'moderator'){
                 let botmsg = false;
                 if(this.state.currentChat === '!refreshall324242'){
                    botmsg = true;
                    Common.sendToAll({method:'refreshall'});
                    chatObject = {method:'chat',group:this.state.selectedChatGroup,name:UserSession.getSession().userName,message:'will refresh all users excluding moderator',myChat:true,date:date,time:time,role:UserSession.getSession().role,utcTime:utcTime,bot:true};
                 }
                 if(this.state.currentChat === '!refreshspeakers324242'){
                    botmsg = true;
                    Common.sendToAll({method:'refreshspeakers'});
                    chatObject = {method:'chat',group:this.state.selectedChatGroup,name:UserSession.getSession().userName,message:'will refresh all speakers',myChat:true,date:date,time:time,role:UserSession.getSession().role,utcTime:utcTime,bot:true};
                 }
                 if(this.state.currentChat === '!refreshinter324242'){
                    botmsg = true;
                    Common.sendToAll({method:'refreshinter'});
                    chatObject = {method:'chat',group:this.state.selectedChatGroup,name:UserSession.getSession().userName,message:'will refresh all interpreters',myChat:true,date:date,time:time,role:UserSession.getSession().role,utcTime:utcTime,bot:true};
                 }
                 if(this.state.currentChat === '!refreshlisteners324242'){
                    botmsg = true;
                    Common.sendToAll({method:'refreshlisteners'});
                    chatObject = {method:'chat',group:this.state.selectedChatGroup,name:UserSession.getSession().userName,message:'will refresh all listeners',myChat:true,date:date,time:time,role:UserSession.getSession().role,utcTime:utcTime,bot:true};
                 }
                 if(botmsg){
                    chatList.push(chatObject)
                    this.setState({chatList:chatList,currentChat:''})
                    setTimeout(() => {
                        document.getElementById('chat-scroll').scrollTop = document.getElementById('chat-scroll').scrollHeight;
                     }, 100);
    
                    return;
                 }
                
            }
             chatList.push(chatObject)
             this.setState({chatList:chatList})
             
             if(UserSession.getSession().role !== 'listenerhls'){
                if(this.state.connection){
                    chatObject.group = 'Private_'+this.state.pvUserId;
                    chatObject.name = chatObject.name+'_'+this.state.pvUserName+'-'+this.state.pvUserRole;
                    Common.sendToOne(this.state.connection,chatObject);
                }else{
                    Common.sendToAll(chatObject);
                }
            }
           
            Common.sendToAllPusher(chatObject);
            
             
             //alert(JSON.stringify(chatObject))
             setTimeout(() => {
                document.getElementById('chat-scroll').scrollTop = document.getElementById('chat-scroll').scrollHeight;
             }, 100);
             let data = {};
             data.session_id = this.state.sessionId;
             data.sender_name = UserSession.getSession().userName;
             data.sender_userid = UserSession.getSession().userId;
             data.sender_time = time;
             data.sender_role = UserSession.getSession().role;
             data.message = this.state.currentChat;
             data.sendto_group = this.state.selectedChatGroup;
             data.utc_time = utcTime;
             if(this.state.connection){
                data.sendto_group = 'Private_'+this.state.pvUserId;
                data.sender_name = UserSession.getSession().userName+'_'+this.state.pvUserName+'-'+this.state.pvUserRole;
            }
             this.saveChat(data)
             
         }
         this.setState({currentChat:''})
    }
    saveChat = (data) =>{
        RequestHandler.post(Config.API_URL+'save-chat',data,(response)=>{
            if(response.status == 'success'){

            }
        });
    }
    getChat = () =>{
        RequestHandler.post(Config.API_URL+'get-chats',{session_id:this.state.sessionId},(response)=>{
            if(response.status == 'success'){
                  let respData = response.data;
                  let chatList = [];
                  for(let key in respData){
                    let data = {method:'chat',group:respData[key].sendto_group,name:respData[key].sender_name,message:respData[key].message,myChat:respData[key].sender_userid ==UserSession.getSession().userId ? true:false,date:respData[key].date_time,time:respData[key].sender_time,role:respData[key].sender_role,utcTime:respData[key].utc_time};
                    let allow = false;
                    if(data.group.split('_')[0] === 'Private'){
                        if(data.group.split('_')[1] == UserSession.getSession().userId){
                            allow = true;
                        }
                    }else{
                        if(data.group == 'general' || data.group == UserSession.getSession().role ){
                            allow = true;
                        }
                        if(data.group === 'general' && data.role ==='listener'){
                            if(UserSession.getSession().role === 'moderator'){
                                allow = true;
                            }else{
                                allow = false;
                            }
                        }
                        if(data.group == 'Technical Support Listener' && (UserSession.getSession().role === 'listener' || UserSession.getSession().role === 'moderator')){
                            allow = true;
                        }
                        if(data.group == 'Technical Support' && (UserSession.getSession().role === 'moderator' || data.role === 'moderator')){
                            allow = true;
                        }
                        if(data.group == 'Questions and Answers' && (UserSession.getSession().role === 'moderator' || UserSession.getSession().role === 'listener' || UserSession.getSession().role === 'speaker')){
                            allow = true;
                        }
                    }
                    if(respData[key].sender_userid === UserSession.getSession().userId){
                        allow = true;
                    }
                    if(data.group != 'Technical Support Listener' && data.group != 'Technical Support'){
                        allow = false;
                    }
                    if(allow){
                        let date = new Date(data.utcTime).toLocaleString('en-US').split(',')[0]
                        let time = this.days[new Date(data.utcTime).getDay()]+' '+new Date(data.utcTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                        data.date = date;
                        data.time = time;
                        chatList.push(data)
                    }
                      //alert(data[key].sender_name)
                  }
                  this.setState({chatList:chatList})
                  setTimeout(() => {
                    document.getElementById('chat-scroll').scrollTop = document.getElementById('chat-scroll').scrollHeight;
                 }, 100);
            }
        });
    }
    onMessageKeyUp = (e)=>{
        var code = (e.keyCode ? e.keyCode : e.which);
        if (code == 13) {
           this.sendChat();
        }
    }
    onSelectedChatGroupChange = (val)=>{
        this.setState({selectedChatGroup:val,isChatGroupOpen:false,connection:false})
    }
    onSelectedPrivateChatChange = (participant) =>{
        this.setState({connection:participant.connection,isChatGroupOpen:false,pvUserName:participant.userData.userName,pvUserId:participant.userData.userId,pvUserRole:participant.userData.role})
    }
    toggleChatGroups = () =>{
        this.props.blockBodyClick();
        let bol = this.state.isChatGroupOpen;
        bol = !bol;
        this.setState({isChatGroupOpen:bol})
    }
    hideGroup = () =>{
        this.setState({isChatGroupOpen:false})
    }
    onMsgInputChange = (e)=>{
         this.setState({currentChat:e.target.value})
    }
    chatClose = () => {
        this.props.chatClose();
    }
    blockBodyClick = ()=>{
        this.props.blockBodyClick();
    }
    chooseChatTab = (tab) =>{
        this.setState({selectedChatTab:tab})
    }
    clearAllChat = () =>{
        this.setState({confirm:true})
        this.confirmRef.show('Confirm','Are you sure, you want to delete all chat history ?',{})
    }

    onCancel = () =>{
        this.setState({confirm:false})
    }

    onProceed = (data) =>{
        this.setState({confirm:false})
        
        RequestHandler.post(Config.API_URL+'delete-chat-history',{session_id:this.state.sessionId},(response)=>{
            if(response.status == 'success'){
                this.setState({chatList:[]})
                this.props.showNotification('Chat history deleted successfully',UserSession.getSession().userName);
                let chatObject = {method:'clearchat'};
                Common.sendToAll(chatObject);
                Common.sendToAllPusher(chatObject);
            }
        });
    }
    
    render() {
        this.lastDate = ''
        return(
          <div className="support-wrap">
              <div className="support-cmn-block">
              <div className="d-flex align-items-center chat-header-blk hide">
                  <div className="chat-close-button" onClick={this.chatClose}>×</div>
                  <div className="chat-caption">Chat </div>
    
                  <div className={UserSession.getSession().role == 'moderator' ? "chat-clear":"hide"} onClick={this.clearAllChat}> 
                      <img src="/img/chat-delete-icon.svg"/>
                      <div className="custom-menu-tooltip">Clear All Chat</div>
                  </div>
              </div>
    
    
              <div className={UserSession.getSession().role === 'interpreter' ? "comm-chat-wrapper interpreter-chatwrap suport-wrap-inner":"comm-chat-wrapper"} id="chat-scroll">
                  {
                      this.state.chatList.map((chat,index)=>{
                          chat.hideDate = true;
                          if(chat.date == this.lastDate){
                              chat.hideDate = true;
                          }else{
                              this.lastDate = true;//chat.date;
                          }
                          
                      })
                  }
    
                  {
                      this.state.chatList.map((chat,index)=>{
                          let nameCondition = chat.myChat ? ('Me to '+(chat.group == 'general'?'general':(chat.group.split('_')[0] === 'Private' ? chat.name.split('_')[1]: chat.group))):'<span className="chat-classified-name">'+chat.name.split('_')[0]+'-'+chat.role+'</span> to '+(chat.group.split('_')[0] === 'Private' ? 'You': chat.group);
                          if(chat.bot){
                            nameCondition = 'Rafiky Bot';
                          }
                          return(
                              <div key={index} className={chat.myChat ? "d-flex flex-column comm-chat-container right":"d-flex flex-column comm-chat-container left"}>				
                                  <div className="d-flex">
                                          <div className={chat.bot ? 'msg-pointer right bot':(chat.myChat ?"msg-pointer right me":"msg-pointer incoming left")}>
                                              <div className="d-flex justify-content-between align-items-center chat-classifier-blk">
                                                  <div className="chat-classifier" dangerouslySetInnerHTML={{__html: nameCondition}}></div>
                                                  <div className="chat-time-disp">{chat.time}</div>
                                              </div>
                                              <div className="chat-type-spec">{chat.group.split('_')[0] === 'Private' ?'Private':''}</div>
                                              <p className="msg-blk" dangerouslySetInnerHTML={{ __html: chat.message}} ></p>
                                          </div>
                                  </div>
                              </div>
                          )
                      })
                  }
              </div>
              <div className="comm-chat-reply-blk">
                  <div className="comm-chat-input-holder reply-input-focused w-100 support-inp-wrap">
                    <input placeholder='Type message and press enter key to send...' onKeyUp={this.onMessageKeyUp} value={this.state.currentChat} onChange={this.onMsgInputChange} type="text" className="chat-inputt"/>
                  </div>
              </div>
          </div>
       </div>
        )
    }
}

export default TechnicalSupport;
