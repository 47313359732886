var Config = (function() {
    var data = {};
    var getItem = function(key) {
      return data[key];
    };

    var setItem = function(key,value) {
        data[key] = value;  
    };

    var removeItem = function(key) {
        if(data.hasOwnProperty(key)){
            delete data[key];
        }
    }
    let API_URL = 'https://api.rafikyconnect.net/';
    let HOME_PAGE_URL = 'https://admin.rafikyconnect.net/events';
    if(window.location.href.indexOf('rafiky.enfinlabs.com') > -1){
      API_URL = 'https://rafikyapi.enfinlabs.com/';
      HOME_PAGE_URL = 'https://rafiky.enfinlabs.com/events';
    }
    if(window.location.href.indexOf('localhost') > -1){
      /*
      API_URL = 'http://localhost:9000/';
      HOME_PAGE_URL = 'http://localhost:3000/events';
      API_URL = 'https://rafikyapi.enfinlabs.com/';
      HOME_PAGE_URL = 'https://rafiky.enfinlabs.com/events';
      */
    }
    return {
      API_KEY    : '46760582',
      API_SECRET: '69f8a7805643bb116b451278cd782dc9dec95979',
      SESSION_ID_bk    : '2_MX40Njc2MDU4Mn5-MTU5ODA3MDg0NTk2N35aYmNCVGtiMkJHaTlrTzd1aitFUjdnLy9-fg',
      TOKEN_PUBLISHER_bk: 'T1==cGFydG5lcl9pZD00Njc2MDU4MiZzaWc9NzA4NGRkZDczMDgyMTJlOTc3OTYxOTkzZjI5YmE2ODdiNDkyNDdkMjpzZXNzaW9uX2lkPTJfTVg0ME5qYzJNRFU0TW41LU1UVTVPREEzTURnME5UazJOMzVhWW1OQ1ZHdGlNa0pIYVRsclR6ZDFhaXRGVWpkbkx5OS1mZyZjcmVhdGVfdGltZT0xNTk4MDcwODgwJm5vbmNlPTAuMzgzMTM5NjQxMzQyMDMwMjcmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTYwMDY2Mjg3OSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==',
      TOKEN_SUBSCRIBER_bk: 'T1==cGFydG5lcl9pZD00Njc2MDU4MiZzaWc9ZTBlMTRhZDJlNWYxYjc2MjllMDlhOGQyNTY1OTRkZGQxMDdlOGM5ZDpzZXNzaW9uX2lkPTJfTVg0ME5qYzJNRFU0TW41LU1UVTVPREEzTURnME5UazJOMzVhWW1OQ1ZHdGlNa0pIYVRsclR6ZDFhaXRGVWpkbkx5OS1mZyZjcmVhdGVfdGltZT0xNTk4MDcwODk2Jm5vbmNlPTAuMTkzMzgyNTE5MDg2Mzc4MDUmcm9sZT1zdWJzY3JpYmVyJmV4cGlyZV90aW1lPTE2MDA2NjI4OTYmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=',
      SESSION_ID    : '',//'2_MX40Njc2MDU4Mn5-MTU5ODQxNzc1NDEzM35LMVA4NEFLZVE1KzBZc20yZi9LR2RBRFp-fg',
      TOKEN_PUBLISHER: '',//'T1==cGFydG5lcl9pZD00Njc2MDU4MiZzaWc9ZGU5MzhhNzU4ZDdiZGI2MzVkNGI4MTg2ODlmM2NmYjM2MTk3YWRlYzpzZXNzaW9uX2lkPTJfTVg0ME5qYzJNRFU0TW41LU1UVTVPRFF4TnpjMU5ERXpNMzVMTVZBNE5FRkxaVkUxS3pCWmMyMHlaaTlMUjJSQlJGcC1mZyZjcmVhdGVfdGltZT0xNTk4NDE3ODMzJm5vbmNlPTAuNDAxNjkxNjQ1MTUwOTU2MjMmcm9sZT1tb2RlcmF0b3ImZXhwaXJlX3RpbWU9MTYwMTAwOTgzMiZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==',
      TOKEN_SUBSCRIBER: '',//'T1==cGFydG5lcl9pZD00Njc2MDU4MiZzaWc9ZThhZDQyYTU5YWI0MTI2ZTdhYjM0YmNjNGZhYzkyYzc3ZGJhYWM5YjpzZXNzaW9uX2lkPTJfTVg0ME5qYzJNRFU0TW41LU1UVTVPRFF4TnpjMU5ERXpNMzVMTVZBNE5FRkxaVkUxS3pCWmMyMHlaaTlMUjJSQlJGcC1mZyZjcmVhdGVfdGltZT0xNTk4NDE3ODcxJm5vbmNlPTAuNjAzNzc4NzUzMjQ4NzA5NiZyb2xlPXN1YnNjcmliZXImZXhwaXJlX3RpbWU9MTYwMTAwOTg3MCZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==',
      CHROME_EXTENSION_ID: 'baz',
      API_URL: API_URL,
      HOME_PAGE_URL: HOME_PAGE_URL,
      getItem: getItem,
      setItem: setItem,
      removeItem: removeItem,
      MY_NETWORK_STATUS : 'UNABLE TO GET NETWORK INFO.  REOSON WILL BE ONE OF THE FOLLOWING <br/> 1. USER NOT SUBSCRIBING ANY STREAMS <br/> 2. NETWORK CHECKING IS ON PROGRESS ( CHECK AFTER 30 SECONDS )',
      WOWZA_URL : 'https://wowza.tokbird.com/tokbird/',
      WOWZA_APP : 'tokbird',
      PUSHER_KEY: '32875e23b9b303261f77',
      CUE_POINT_URL :'http://wowza.tokbird.com:8086/insertadmarker/'
    }
  })();
export default Config;