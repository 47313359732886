import React, { Component } from 'react';
class Alert extends Component {
  
  constructor(props) {
    super(props);
    this.state = {alert:this.props.alert,header:this.props.alert,body:this.props.message,handoverTime : 30,blockUpdate:false}
    this.timeout = 0;
  }
  

  componentDidMount = ()=> {
    this.updateState();
  }
  componentDidUpdate = (prevProps) => {
    if(prevProps!== this.props) {
      this.updateState();
    }
  }
  updateState = ()=>{
    let header = this.props.alert;
    let body = this.props.message;
    let alert = this.props.alert;
    if(this.props.alert){
        switch (this.props.alert) {
            case '':
                
            break;
            default:
                break;
        }
    }
    this.setState({alert:alert,body:body,header:header})
    if(header == 'Hand Over Notification' || header == 'Hand Over'){
      if(this.state.blockUpdate == false){
        this.setState({handoverTime:30,blockUpdate:true})
        if(this.timeout){
          clearInterval(this.timeout)
        }
        this.timeout = setInterval(this.startTimer,1000);
      }
    }
    
    
  }
  startTimer = ()=>{
      let handoverTime = this.state.handoverTime;
      if(handoverTime <= 0){
        let type = this.state.header == 'Hand Over' ? 'turnoffmic':'turnonmic';
        this.onCloseAlert(type);
        clearInterval(this.timeout);
      }else{
        this.setState({handoverTime:handoverTime-1})
      }
}
  onCloseAlert = (type)=>{
      this.props.onCloseAlert(type);
      this.setState({blockUpdate:false,handoverTime:30});
  }
  RenderHTML = () => (<div className="alert-body" dangerouslySetInnerHTML={{__html:this.state.body}}></div>)
  render() {
    return (
       <div className="alert-backdrop">
              <div className="alert-content">
                   <div className="alert-head">{this.state.header}</div>
                   {
                     this.RenderHTML()
                   }
                   <div className="alert-footer">
                        <div className={(this.state.header == 'Hand Over Notification' || this.state.header == 'Hand Over') ? "handover-timer":"hide"}>{this.state.handoverTime}</div>
                       <button className={(this.state.header == 'Hand Over Notification' || this.state.header == 'Hand Over') ? "hide":""} onClick={this.onCloseAlert}>Ok</button>
                   </div>
              </div>
       </div>
      )
    
  }
}
export default Alert;
