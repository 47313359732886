import React, { Component} from 'react';
import ReactDom from 'react-dom';
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import Thankyou from './components/Thankyou';
import Common from './helpers/common';
import Browsers from './components/Browsers';
import HlsPlayer from './components/HlsPlayer';
import TeamsLogin from './components/TeamsLogin';
class Main extends Component{
  /*
  constructor(props) {
      super(props); 
      
  }
  */
  componentDidMount(){
      
  }
  render(){
      return (
        <App
          key="app"
          role = {this.props.match.params.role}
          userName = {this.props.match.params.userName}
          sessionId = {this.props.match.params.sessionId}
          userId = {this.props.match.params.userId}
          loadingDelegate={<div>Loading...</div>}
          opentokClientUrl="https://static.opentok.com/v2/js/opentok.min.js"
        />
      )
  }
}
ReactDom.render(
  <BrowserRouter >
        <Switch>
        <Route exact path='/teams-login' component={TeamsLogin} />
        <Route exact path='/:sessionId/:role/:userName' component={Main} />
        <Route exact path='/:sessionId/:role/:userName/:userId' component={Main} />
        <Route exact path='/thankyou' component={Thankyou} />
        <Route exact path='/notsupported' component={Browsers} />
        <Route exact path='/player/:streamName' component={HlsPlayer} />
        </Switch>
   </BrowserRouter>
 , document.getElementById('root'))
serviceWorker.unregister();

