import React, { Component } from 'react';
class Confirm extends Component {
  
  constructor(props) {
    super(props);
    this.state = {header:'',body:'',data:{}}
  }
  

  componentDidMount = ()=> {

  }
  show = (header,body,data)=>{
    this.setState({header:header,body:body,data:data})
  }
  cancel = ()=>{
    this.props.cancel();
  }
  proceed = ()=>{
    this.props.proceed(this.state.data);
  }
  RenderHTML = () => (<div className="alert-body" dangerouslySetInnerHTML={{__html:this.state.body}}></div>)
  render() {
    return (
       <div className="alert-backdrop">
              <div className="alert-content">
                   <div className="alert-head">{this.state.header}</div>
                   {
                     this.RenderHTML()
                   }
                   <div className="alert-footer">
                       <button className={this.state.header === 'Message' ? 'hide':''}  onClick={this.cancel}>Cancel</button>
                       <button className="right-10" onClick={this.proceed}>Proceed</button>
                   </div>
              </div>
       </div>
      )
    
  }
}
export default Confirm;
