import React, { Component } from 'react';
import Config from '../config';
import Common from '../helpers/common';
import RequestHandler from '../helpers/request-handler';
import UserSession from '../helpers/user-session';
class Participant extends Component {
    constructor(props) {
        super(props); 
        this.state = {masterSpeaker:this.props.masterSpeaker,speedOfMe:true,data:this.props.data,connection:this.props.connection,captionMenu:false,savedFiles:[],requestComplete:false,isSpeakerPanel:false}
        this.count = 0;
        this.capchecktimeout = {};
    }
    componentDidMount =()=> {
        this.setState({data:this.props.data,connection:this.props.connection,isSpeakerPanel:this.props.isSpeakerPanel});
    }
    componentDidUpdate(prevProps) {
        if(prevProps!== this.props) {
            if(this.props.data.raiseHanded === true && prevProps.data.raiseHanded === false){
                // this.props.showNotification(this.state.data.userName.toUpperCase()+' HAS SEND RAISE HAND REQUEST, PLEASE CLICK ON THE HAND RAISE BUTTON IN USER LIST TO GIVE PUBLISH PREVILAGE TO HIM',this.state.data.userName.toUpperCase());
            }
          this.setState({data:this.props.data,connection:this.props.connection});
          
        }
      }
    onRefresh = ()=>{
        if(this.state.data.role === 'listenerhls'){
            this.props.sendToAllPusher({method:'refresh',sendTo:this.state.data.userId,sendFromHls:true})
        }else{
            Common.sendToOne(this.state.connection,{method:'refresh'})
        }
        
    }
    onRemoveUser = ()=>{
        if(this.state.data.role === 'listenerhls'){
            this.props.sendToAllPusher({method:'remove',sendTo:this.state.data.userId,sendFromHls:true})
        }else{
            Common.sendToOne(this.state.connection,{method:'remove'})
        }
        
    }
    onGetStatus = ()=>{
        Common.sendToOne(this.state.connection,{method:'onGetStatus'})
    }
    
    
    lowerHand = ()=>{
        Common.sendToOne(this.state.connection,{method:'lowerHand',userName:UserSession.getSession().userName})
        let data = this.state.data;
        data.raiseHanded = false;
        this.setState({data:data});
    }
    
    allowMasterSpeaker = () => {
        let data = this.state.data;
        data.allowMasterSpeaker = !data.allowMasterSpeaker;
        Common.sendToOne(this.state.connection,{method:'allowMasterSpeaker',enable:data.allowMasterSpeaker,userName:UserSession.getSession().userName})
        this.setState({data:data});
    }
    onGiveFullControl = () => {
        let data = this.state.data;
        data.speakerMode = !data.speakerMode;
        data.raiseHanded = false;
        Common.sendToOne(this.state.connection,{method:'allowRaisePermission',enable:data.speakerMode,userName:UserSession.getSession().userName})
        this.setState({data:data});
    }
    enableOrDisableSpeaker = () =>{
        let data = this.state.data;
        data.speaker = !data.speaker;
        if(this.state.data.role === 'listenerhls'){
            this.props.sendToAllPusher({method:'controlSpeaker',enable:data.speaker,userName:UserSession.getSession().userName,sendTo:this.state.data.userId,sendFromHls:true})
        }else{
            Common.sendToOne(this.state.connection,{method:'controlSpeaker',enable:data.speaker,userName:UserSession.getSession().userName})
        }
        
        this.setState({data:data});
    }
    addCountDown = () =>{
        this.props.sendCountDown(this.state.connection,this.state.data.userName)
    }
    hideCaptionPop = () =>{
            this.setState({captionMenu:false})
        //  alert(JSON.stringify(this.state.connection))
    }
    showHideCaptionPop = () =>{
        let captionMenu = this.state.captionMenu;
        if(captionMenu){
            this.setState({captionMenu:false})
        }else{
            this.setState({captionMenu:true})
            this.getCaptionList();
        }
        //  alert(JSON.stringify(this.state.connection))
    }
    getCaptionList = () =>{
        RequestHandler.post(Config.API_URL+'speaker/get_user_details',{user_id:this.state.data.userId},(response)=>{
           // alert(JSON.stringify(response))
            if(response.status === 'success'){
                this.setState({savedFiles:response.result})
            }
            this.setState({requestComplete:true})
            
        });
    }
    
    editCaption = (data) =>{
        if(data.speaker_status === "enabled"){
            this.props.openCaptionPop(this.state.data,this.state.data.userId,data,'edit',true)
        }else{
            this.props.openCaptionPop(this.state.data,this.state.data.userId,data,'edit',false)
        }
       
        this.setState({captionMenu:false})
    }
    viewCaption = (data) =>{
        this.props.openCaptionPop(this.state.data,this.state.data.userId,data,'view')
        this.setState({captionMenu:false})
    }
    publishCaption = (data,status) =>{
        let splitRole = data.role.split('_');
        let captionChoice = splitRole[2];
        let captionData = {title:{value:'',error:''},name:{value:data.name,error:''},role:{value:splitRole[0],error:''},duration:{value:splitRole[1],error:''}}
        
        if(document.getElementById(this.state.data.userId+'1')){
            document.getElementById(this.state.data.userId+'1').style.display = 'none';
            document.getElementById(this.state.data.userId+'2').style.display = 'none';
            document.getElementById(this.state.data.userId+'3').style.display = 'none';
            document.getElementById(this.state.data.userId+'4').style.display = 'none';
            document.getElementById(this.state.data.userId+'5').style.display = 'none';
        }
        if(status === 'enabled'){
            if(document.getElementById(this.state.data.userId+captionChoice)){
                document.getElementById(this.state.data.userId+captionChoice).classList.add(data.id)
                document.getElementById(this.state.data.userId+captionChoice).style.display = 'flex';
                document.getElementById(this.state.data.userId+captionChoice+'name').innerHTML = captionData.name.value;
                document.getElementById(this.state.data.userId+captionChoice+'role').innerHTML = captionData.role.value;
                
                if(data.logo){
                    document.getElementById(this.state.data.userId+captionChoice+'captionlogo').src = data.logo;
                    document.getElementById(this.state.data.userId+captionChoice+'captionlogowrap').style.display = 'block';
                }else{
                    document.getElementById(this.state.data.userId+captionChoice+'captionlogowrap').style.display = 'none';
                }
                if(window.captionTimeout){
                    if(window.captionTimeout[this.state.data.userId]){
                        clearTimeout(window.captionTimeout[this.state.data.userId])
                    }
                    
                }else{
                    window.captionTimeout = {};
                }
                window.captionTimeout[this.state.data.userId] = setTimeout((capId,captionChoice) => {
                    document.getElementById(capId+captionChoice).style.display = 'none';
                    RequestHandler.post(Config.API_URL+'speaker/update_status/'+this.state.data.userId,{speaker_id:data.id,status:"disabled"},(response)=>{
                        if(response.status === 'success'){
                            this.getCaptionList();
                            Common.sendToAll({method:'caption',capid:data.id,id:this.state.data.userId,captionData:captionData,logo:data.logo,speakerInfoChoice:captionChoice,status:status})
                        }
                    });

                }, captionData.duration.value * 1000,this.state.data.userId,captionChoice);
                this.props.showNotification('Caption added successfully','Moderator')
            }
        }else{
            this.props.showNotification('Caption unpublished successfully','Moderator')
        }
        RequestHandler.post(Config.API_URL+'speaker/update_status/'+this.state.data.userId,{speaker_id:data.id,status:status},(response)=>{
            if(response.status === 'success'){
                this.getCaptionList();
                Common.sendToAll({method:'caption',capid:data.id,id:this.state.data.userId,captionData:captionData,logo:data.logo,speakerInfoChoice:captionChoice,status:status})
            }
        });
    }
    
    deleteCaption = (data) =>{
        RequestHandler.post(Config.API_URL+'speaker/delete_user_details',{speaker_id:data.id},(response)=>{
            //alert(JSON.stringify(response))
            if(response.status === 'success'){
                this.props.showNotification('Caption deleted successfully','Moderator')
                this.getCaptionList();
                let senddta = {method:'captiondelete',id:this.state.data.userId};
                Common.sendToAll(senddta)
            }
        });
    }
    openCaptionPop = () =>{
        this.props.openCaptionPop(this.state.data,this.state.data.userId,false,'create')
        this.setState({captionMenu:false})
    }

    onChangeStatus = (file,event) =>{
        // alert(event.target.checked)
        if(event.target.checked){
            this.publishCaption(file,'enabled')
        }else{
            this.publishCaption(file,'disabled')
        }
        event.target.classList.add('dis');
        if(this.capchecktimeout[file.id]){
            clearTimeout(this.capchecktimeout[file.id])
        }
        this.capchecktimeout[file.id] = setTimeout((target) => {
            target.classList.remove('dis');
        }, 2000,event.target);
            
            
    }
    
    
    
    render() {
        return(
          <div className="participant-list-tile">
              <div className="participant-profile-icon-section">
                  <div className="avatar-section">
                      <div className={"avatar_initial "+(this.state.data.role === 'listener' ? 'viewer':(this.state.data.role === 'interpreter' ?'interpreter':(this.state.data.isSigned ? 'sign':'moderator')))}>{this.state.data.userName.charAt(0).toUpperCase()}</div>
                  </div>
              </div>
              <div className="participant-name-section">
              <div className="participant-name">{this.state.data.userName}</div>
              </div>
              {!this.state.isSpeakerPanel && (
              <div className="action-icons-wrapper">
                  <div className="part-menu refresh-icon-wrapper" onClick={this.onRefresh}>
                      <i className="fa fa-sync"></i>
                      <div className="custom-menu-tooltip">Refresh</div>
                  </div>
                  <span className={this.state.data.isSigned ? 'hide':''}>
                  <div className={(this.state.data.role === 'speaker')? (this.state.data.speakerMode ?"part-menu vdo-icon-wrap pointer enable":"part-menu refresh-icon-wrapper pointer"):"hide"} onClick={this.onGiveFullControl}>
                    <i className="fa fa-user"></i>
                    <div className="custom-menu-tooltip">{this.state.data.speakerMode ? 'Revoke presenting power':'Give presenting power'}</div>
                  </div>
                  </span>

                  <span className={(this.state.data.isSigned || !this.state.masterSpeaker )? 'hide':''}>
                  <div className={(this.state.data.role === 'speaker')? (this.state.data.speakerMode ?"part-menu vdo-icon-wrap pointer enable":"part-menu refresh-icon-wrapper pointer"):"hide"} onClick={this.allowMasterSpeaker}>
                    <i className="fa fa-crown"></i>
                    <div className="custom-menu-tooltip">{this.state.data.speakerMode ? 'Revoke master speaker':'Assign master speaker'}</div>
                  </div>
                  </span>

                  <div className={this.state.data.raiseHanded ? "part-menu vdo-icon-wrap pointer enable":"hide"} onClick={this.lowerHand}>
                  <i className="fa fa-hand-paper"></i> 
                  <div className="custom-menu-tooltip">Lower Hand</div>
                  </div>
                  <div className={(this.state.data.role === 'speaker' || this.state.data.role === 'moderator')? (this.state.data.video ? "part-menu vdo-icon-wrap  enable nopointer":"part-menu vdo-icon-wrap nopointer"):"hide"}>
                  <i className="fa fa-video"></i>
                  <div className="custom-menu-tooltip">{this.state.data.video ? 'Video Is ON':'Video Is OFF'}</div>
                  </div>
                  <span className={this.state.data.isSigned ? 'hide':''}>
                  <div className={(this.state.data.role === 'speaker' || this.state.data.role === 'moderator' || this.state.data.role === 'interpreter')? (this.state.data.audio ? "part-menu mic-icon-wrap enable nopointer":"part-menu mic-icon-wrap nopointer"):"hide"}>
                  <i className="fa fa-microphone"></i>
                  <div className="custom-menu-tooltip">{this.state.data.audio ? 'Audio Is ON':'Audio Is OFF'}</div>
                  </div>
                  </span>
                  <div className={this.state.data.role === 'listenerhls' ? 'hide':( this.state.data.speaker ? "part-menu spek-icon-wrap pointer enable":"part-menu spek-icon-wrap pointer")} onClick={this.enableOrDisableSpeaker}>
                       <i className={this.state.data.speaker ? "fa fa-volume-up":"fa fa-volume-down"}></i> 
                       <div className="custom-menu-tooltip">{this.state.data.speaker ? 'Speaker Is ON':'Speaker Is OFF'}</div>
                  </div>
                  <div className={this.state.data.role === 'speaker' && !this.state.data.isSigned && this.state.data.speakerMode ? "part-menu refresh-icon-wrapper":"hide"} onClick={this.addCountDown}>
                  <img alt="timer" src="/img/timer.svg"/>
                  <div className="custom-menu-tooltip">Countdown</div>
                  </div>
                  <div className={this.state.data.role === 'speaker' && !this.state.data.isSigned ? "speaker-vdo-info-bottom-wrap":"hide"}>
                  <img alt="speakerinfo" src="/img/speaker-info-display.svg" onClick={this.showHideCaptionPop}/>
                  <div className="custom-menu-tooltip">Speaker Information</div>
                  <div className={this.state.captionMenu ? "speaker-info-general-popup":"hide"}>
                      <div className="d-flex flex-column">
                          <div className="speaker-cap-close" onClick={this.hideCaptionPop}>X</div>
                          <div className="mod-spek-display-wrapper">
                          <div className="spek-caption-container">
                                 <div className={this.state.savedFiles.length === 0 &&  this.state.requestComplete? "speaker-caption-title margin-8px":"hide"}>No Captions Found !</div>
    
                                 </div>
    
                                 {
                                      this.state.savedFiles.map((file,index)=>{
                                          return(
                                              <div key={index} className={file.speaker_status === "enabled" ? "spek-caption-container speaker-selected":"spek-caption-container"}>
                                                  <div className="speaker-caption-title margin-8px">{file.name}</div>
                                                  <div className="speaker-mod-actions">
                                                 
                                                  <div title="Publish Caption" className="spek-edit-icon statuscheck">
                                                      <input id={file.id} className="pointer" type="checkbox" checked={file.speaker_status === "enabled" ? true:false} onChange={this.onChangeStatus.bind(this,file)}/>
                                                  </div>
                                                  <div title="View Caption" className="spek-view-icon" onClick={this.viewCaption.bind(this,file)}>
                                                      <img alt="eye" src="/img/password-eye.svg"/>
                                                  </div>
                                                  <div title="Edit Caption" className="spek-view-icon rightmarg9" onClick={this.editCaption.bind(this,file)}>
                                                      <img alt="editpoll" src="/img/edit-poll.svg"/>
                                                  </div>
                                                  <div title="Delete Caption" className="spek-trash-icon" onClick={this.deleteCaption.bind(this,file)}>
                                                      <img alt="deletecpation" src="/img/trash-speaker.svg"/>
                                                  </div>
                                                  </div>
                                              </div>
                                          )
                                      })
                                  }
                          </div>
                          <div className="create-caption-btn" onClick={this.openCaptionPop.bind(false)}>create caption</div>
                      </div>
                      </div>
                  </div>
                  <div className="part-menu refresh-icon-wrapper" onClick={this.onRemoveUser}>
                      <i className="fa fa-window-close"></i>
                      <div className="custom-menu-tooltip">Remove</div>
                  </div>
                  <div className={this.state.speedOfMe ? "part-menu refresh-icon-wrapper":"hide"} onClick={this.onGetStatus}>
                      <i className={"fa fa-exclamation-triangle "+this.state.data.mySpeedStatus}></i>
                      <div className="custom-menu-tooltip">Bandwidth</div>
                  </div>
                 
              </div>
              )}
          </div>
        )
    }
}

export default Participant;
